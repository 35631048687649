import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isOutsideDates } from 'utils/helpers'

export const TripContext = React.createContext({
  trip: {},
  isTripOwner: undefined,
  refetchTrip: () => {},
})

export const TripProvider = ({
  children,
  trip,
  isTripOwner,
  refetchTrip,
  accessLevel,
  tripMembers,
}) => {
  const value = useMemo(
    () => ({
      trip,
      isTripOwner,
      refetchTrip,
      accessLevel,
      tripMembers,
      isOutsideTripDates: (date) =>
        isOutsideDates(date, trip?.data?.dateFrom, trip?.data?.dateTo),
    }),
    [trip, isTripOwner, refetchTrip, accessLevel, tripMembers]
  )

  return <TripContext.Provider value={value}>{children}</TripContext.Provider>
}

export const useTrip = () => useContext(TripContext)

TripProvider.propTypes = {
  children: PropTypes.node.isRequired,
  trip: PropTypes.object.isRequired,
  isTripOwner: PropTypes.bool,
  accessLevel: PropTypes.object,
  tripMembers: PropTypes.array,
  refetchTrip: PropTypes.func.isRequired,
}
