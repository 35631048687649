import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { shouldForwardPropOptions } from 'utils/helpers'
import { Box } from './atoms/Layout'

export const Dot = styled(Box, shouldForwardPropOptions)`
  width: 4px;
  height: 4px;
  border-radius: 4px;
  display: inline-block;
  background-color: ${({ $color }) => $color};
`

Dot.defaultProps = {
  $color: theme.COLORS.TXT_DEFAULT,
  mx: '6px',
  my: '2px',
}
