import { forwardRef } from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { mq } from 'Theme'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Device from 'components/Device'
import { Drawer } from 'components/Drawer'
import { Icon } from 'components/Icon'
import { LoadingWrapper } from 'components/LoadingWrapper'
import { useModal } from 'components/Modal'
import { CircleButtonWithText } from 'routes/Trip/CircleButtonWithText'
import Filter from 'sharedComponents/Filter'
import { tripPopoverPassingProps } from './ModalManagement/TripPopover'

const StyledMoreButton = styled(Button.Tertiary, {
  shouldForwardProp: (prop) => !tripPopoverPassingProps.includes(prop),
})`
  padding: ${rem(10)};
`

export const MenuItemWrap = styled('a')`
  display: flex;
  align-items: center;

  padding: ${rem(12)} ${rem(16)};
  font-size: 16px;
  line-height: 24px;
  width: 100%;

  text-decoration: none;
  white-space: nowrap;
  text-align: left;

  color: ${theme.COLORS.TXT_DEFAULT};

  &:hover {
    color: ${theme.COLORS.TXT_MAIN};
    background-color: ${theme.COLORS.BG_DISABLED};
  }

  ${mq.to.tablet`
    padding: 8px 16px;
    font-weight: 510;
  `}
`

export const MenuItem = ({ text, icon, isLoading, iconProps, ...props }) => (
  <MenuItemWrap
    {...props}
    onClick={(e) => {
      if (props.onClick) {
        e.preventDefault()
        e.stopPropagation()
        props.onClick(e)
      }
    }}
  >
    <LoadingWrapper
      style={{ height: '24px', width: '24px' }}
      loading={isLoading}
    >
      {typeof icon === 'string' ? (
        <MenuIcon name={icon} {...iconProps} />
      ) : (
        icon
      )}
    </LoadingWrapper>
    {text}
  </MenuItemWrap>
)

MenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconProps: PropTypes.object,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
}

const MoreIconButton = forwardRef((props, ref) => (
  <StyledMoreButton {...props} ref={ref}>
    <Icon fontSize="20px" color={theme.COLORS.TXT_DEFAULT} name="more_vert" />
  </StyledMoreButton>
))

export const MoreButton = ({
  children,
  label = 'Actions',
  wrapperProps,
  targetProps = {},
  ...passingProps
}) => {
  const { isOpen, closeModal, openModal } = useModal()

  const TargetComp = passingProps.targetComp || MoreIconButton

  const childrenFunctionChecker =
    typeof children === 'function' ? children(closeModal) : children

  return (
    <Flex alignItems="center" {...wrapperProps}>
      <Device sizes={['desktop', 'tv']}>
        <Filter
          label=""
          hasFooter={false}
          hasPadding={false}
          hasMargin={false}
          customMinWidth="180px"
          {...passingProps}
          targetComp={TargetComp}
        >
          <Box width="100%" py="8px">
            {childrenFunctionChecker}
          </Box>
        </Filter>
      </Device>

      <Device sizes={['mobile', 'tablet']}>
        <TargetComp
          {...targetProps}
          disabled={passingProps.isDisabled}
          onClick={(e) => {
            e?.preventDefault()
            openModal()
          }}
        />
      </Device>

      <Device sizes={['mobile', 'tablet']}>
        <Drawer title={label} onClose={closeModal} isOpen={isOpen}>
          <Box width="100%" py="8px">
            {childrenFunctionChecker}
          </Box>
        </Drawer>
      </Device>
    </Flex>
  )
}

MoreButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  label: PropTypes.string,
  wrapperProps: PropTypes.object,
  targetProps: PropTypes.object,
}

const StyledIcon = styled(Icon, {
  shouldForwardProp: (prop) => !tripPopoverPassingProps.includes(prop),
})`
  cursor: pointer;
  margin-right: 0 !important;
  color: ${theme.COLORS.TXT_DEFAULT};

  ${({ $disabled }) => $disabled && 'cursor:  not-allowed;'}
`

export const MoreIcon = forwardRef((props, ref) => (
  <StyledIcon
    ref={ref}
    $disabled={props.disabled}
    name="more_vert"
    fontSize="20px"
    {...props}
    onClick={(e) => {
      if (props.onClick) {
        e.preventDefault()
        e.stopPropagation()
        props.onClick(e)
      }
    }}
  />
))

MoreIcon.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export const MenuIcon = styled(Icon)`
  font-size: 22px;
  margin-right: ${rem(10)};

  ${mq.to.tablet`
    margin-right: 14px;
  `}
`

export const RemoveButton = forwardRef((props, ref) => (
  <MenuItemWrap ref={ref} as="button" type="button" {...props}>
    <MenuIcon color={theme.COLORS.ERROR_DEFAULT} name="delete" />
    <Text color={theme.COLORS.ERROR_DEFAULT}>{props.children || 'Remove'}</Text>
  </MenuItemWrap>
))

RemoveButton.propTypes = {
  children: PropTypes.node,
}

export const MoreButtonMobileTarget = forwardRef((props, ref) => (
  <CircleButtonWithText
    title="Other actions"
    icon={<Icon name="more_vert" />}
    {...props}
    ref={ref}
  />
))
