import { useCallback } from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'
import { MAX_FILE_SIZE } from 'constants/constants'
import { toast } from 'utils/helpers'

const Circle = styled(Flex)`
  justify-content: center;
  align-items: center;
  background-color: ${theme.COLORS.BG_SOFT};
  padding: ${rem(16)};
  border-radius: 100px;
`

const StyledDropboxWrap = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${rem(16)};
  margin-bottom: 16px;

  border: 1px dashed ${theme.COLORS.BG_DIVIDER};
  cursor: pointer;
  padding: ${rem(48)} ${rem(5)};
  border-radius: 4px;
  ${({ canDrop }) => `border-style: ${canDrop ? 'solid' : 'dashed'}`}

  & > p {
    color: ${theme.COLORS.TXT_DEFAULT};
  }
`

export const Dropzone = ({
  onDrop,
  title = 'Click or drag & drop files to upload them',
  accept,
  maxSize = MAX_FILE_SIZE,
}) => {
  const dropzoneDrop = useCallback(
    (acceptedFiles) => {
      const filteredFiles = acceptedFiles.filter((file) => file.size < maxSize)

      const notUploadedFilesCount = acceptedFiles.length - filteredFiles.length
      if (notUploadedFilesCount)
        toast.error(
          `Some files weren't uploaded because of size! count: ${notUploadedFilesCount}`
        )
      if (filteredFiles.length) {
        onDrop(filteredFiles.map((file) => ({ file })))
      }
    },
    [onDrop]
  )

  const onDropRejected = useCallback((rejectedFiles) => {
    toast.error(
      `Some files weren't uploaded because of size! count: ${rejectedFiles.length}`
    )
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: accept || {
      'image/*': ['.jpeg', '.png'],
    },
    onDrop: dropzoneDrop,
    onDropRejected,
    maxSize,
  })

  return (
    <StyledDropboxWrap canDrop={isDragActive} {...getRootProps()}>
      <input {...getInputProps()} />

      <Circle>
        <Icon name="file_upload" color={theme.COLORS.BRAND_DEFAULT} />
      </Circle>

      <Text color={theme.COLORS.TXT_DEFAULT}>
        {isDragActive ? 'Drop the files here ...' : title}
      </Text>
    </StyledDropboxWrap>
  )
}

Dropzone.propTypes = {
  onDrop: PropTypes.func,
  maxSize: PropTypes.number,
  accept: PropTypes.object,
  title: PropTypes.string,
}
