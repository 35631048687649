import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'
import { PRIVATE_ROUTES } from 'constants/routes'
import { useOpenCreateTripModal } from 'hooks/trip/useOpenCreateTripModal'

const StyledFlex = styled(Flex)`
  align-items: center;
  gap: ${rem(16)};
  &:not(:last-of-type) {
    margin-bottom: ${rem(16)};
  }
  justify-content: space-between;
`

const CheckmarkWrap = styled(Flex)`
  padding: 4px;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
`

const GamificationItem = ({
  isDone,
  prevDone,
  name,
  disabled,
  onClick,
  closeModal,
  index,
  ...passingProps
}) => (
  <StyledFlex
    style={{ cursor: !disabled && !isDone && prevDone ? 'pointer' : '' }}
    onClick={
      !disabled && !isDone && prevDone
        ? () => {
            closeModal()
            onClick()
          }
        : undefined
    }
    {...passingProps}
  >
    <CheckmarkWrap
      background={
        isDone || prevDone ? theme.COLORS.BRAND_DEFAULT : theme.COLORS.WHITE
      }
      style={{
        border:
          !isDone && !prevDone ? `1px solid ${theme.COLORS.BG_DIVIDER}` : '',
      }}
    >
      {isDone ? (
        <Icon name="check" color={theme.COLORS.WHITE} fontSize="24px" />
      ) : (
        <Text
          fontWeight="700"
          color={prevDone ? theme.COLORS.WHITE : ''}
          fontSize={theme.fontSizes.s}
        >
          {index}
        </Text>
      )}
    </CheckmarkWrap>

    <Text
      style={{ flex: '1', whiteSpace: 'break-spaces' }}
      as="p"
      fontWeight="700"
    >
      {isDone ? <strike>{name}</strike> : name}
    </Text>

    {prevDone && !isDone && (
      <Icon
        name="chevron_right"
        color={theme.COLORS.TXT_DEFAULT}
        fontSize="24px"
      />
    )}
  </StyledFlex>
)

GamificationItem.propTypes = {
  name: PropTypes.string,
  isDone: PropTypes.bool,
  prevDone: PropTypes.bool,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
}

export const GamificationItems = ({
  gamificationData,
  firstTripId,
  closeModal,
}) => {
  const openTripModal = useOpenCreateTripModal()
  const navigate = useNavigate()

  const navigateToTrip = (tab) => navigate(`/trip/${firstTripId}#${tab}`)

  const gamificationItems = [
    {
      disabled: gamificationData?.firstTrip,
      isDone: gamificationData?.firstTrip,
      name: 'Create your first trip',
      onClick: () => openTripModal,
      key: 'firstTrip',
    },
    {
      disabled: !gamificationData?.firstTrip,
      isDone: gamificationData?.firstTeamMember,
      name: 'Invite a team member',
      onClick: firstTripId ? () => navigateToTrip('my-team') : undefined,
      key: 'firstTeamMember',
    },
    {
      disabled: !gamificationData?.firstTrip,
      isDone: gamificationData?.firstTodo,
      name: 'Complete first todo',
      onClick: firstTripId ? () => navigateToTrip('todo_list') : undefined,
      key: 'firstTodo',
    },
    {
      disabled: !gamificationData?.firstTrip,
      isDone: gamificationData?.firstVenueToTrip,
      name: 'Add venue to your trip',
      onClick: () => navigate(`/${PRIVATE_ROUTES.PLAN}`),
      key: 'firstVenueToTrip',
    },
  ]

  return gamificationItems.map((item, index) => (
    <GamificationItem
      flex="1"
      key={item.key}
      prevDone={index === 0 || gamificationItems[index - 1]?.isDone}
      index={index + 1}
      closeModal={closeModal}
      {...item}
    />
  ))
}

GamificationItems.propTypes = {
  gamificationData: PropTypes.object,
  firstTripId: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
}
