import { useState } from 'react'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { Input } from '@nextretreat/ui-components/dist/Input'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useAuth } from 'AuthProvider'
import { Field, Form, Formik, useFormikContext } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import { mq } from 'Theme'
import * as Yup from 'yup'
import { LayoutMutations } from 'api/Layout/LayoutMutations'
import { ReactComponent as HappyEmojiSVG } from 'assets/images/svg/nps-emojis/happy-emoji.svg'
import { ReactComponent as NeutralEmojiSVG } from 'assets/images/svg/nps-emojis/neutral-emoji.svg'
import { ReactComponent as NotSoHappyEmojiSVG } from 'assets/images/svg/nps-emojis/not-so-happy-emoji.svg'
import { ReactComponent as NotSoSadEmojiSVG } from 'assets/images/svg/nps-emojis/not-so-sad-emoji.svg'
import { ReactComponent as SadEmojiSVG } from 'assets/images/svg/nps-emojis/sad-emoji.svg'
import { Box, Flex } from 'components/atoms/Layout'
import Modal from 'components/Modal'
import { LOCAL_STORAGE_NPS_MODAL_CLOSED } from 'constants/constants'
import { local } from 'utils/storage'
import Button from './atoms/Button'
import { Text } from './atoms/Typography'
import Device from './Device'

const FeedbackOptionFlex = styled(Flex)`
  border-radius: ${rem(4)};
  align-items: center;
  justify-content: center;
  padding: ${rem(28)} ${rem(40)};
  margin-bottom: ${rem(12)};

  ${mq.to.desktop`
      padding: 16px;
      gap: 12px;

      & > svg {
        width: 20px;
        height: 20px;
      }
    `}

  ${({ $background }) => `
  background-color: ${$background};
`}
`

const validationSchema = Yup.object().shape({
  feedback: Yup.string().ensure(),
  score: Yup.number(),
})

const svgStyles = `
  & svg path {
    fill: ${theme.COLORS.WHITE};
  }
`

const FeedbackOptionWrap = styled(Box)`
  cursor: pointer;

  ${mq.to.desktop`
      width:100%;

      & > * {
        width: 100%;
      }
    `}

  ${({ $color, $isSelected }) => `  
    &:hover {
        ${FeedbackOptionFlex} {
            background-color: ${$color};
            color: ${theme.COLORS.WHITE};
        } 
        
       ${svgStyles}
    }

    ${
      $isSelected &&
      `
          ${FeedbackOptionFlex} {
              background-color: ${$color};
              color: ${theme.COLORS.WHITE};
          }
  
          ${svgStyles} 
      `
    }
`}
`

const FeedbackOption = ({ icon, text, value, color, background, saveData }) => {
  const { values, setFieldValue } = useFormikContext()

  return (
    <FeedbackOptionWrap
      $color={color}
      $isSelected={values.score === value}
      onClick={() => {
        saveData({ score: value })
        setFieldValue('score', value)
      }}
    >
      <FeedbackOptionFlex $background={background}>
        {icon}

        <Device sizes={['mobile', 'tablet']}>
          <Text
            fontWeight={700}
            color={values.score === value ? theme.COLORS.WHITE : color}
            as="p"
            textAlign="center"
          >
            {text}
          </Text>
        </Device>
      </FeedbackOptionFlex>

      <Device sizes={['desktop', 'tv']}>
        <Text fontWeight={700} color={color} as="p" textAlign="center">
          {text}
        </Text>
      </Device>
    </FeedbackOptionWrap>
  )
}

FeedbackOption.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  background: PropTypes.string,
  value: PropTypes.number,
  saveData: PropTypes.func,
}

const NPS_OPTIONS = [
  {
    icon: <SadEmojiSVG />,
    text: 'Not at all',
    value: 1,
    color: theme.COLORS.ERROR_DEFAULT,
    background: theme.COLORS.ERROR_LIGHT,
  },
  {
    icon: <NotSoSadEmojiSVG />,
    text: 'Not likely',
    value: 2,
    color: `${theme.COLORS.ERROR_DEFAULT}b8`,
    background: `${theme.COLORS.ERROR_LIGHT}b8`,
  },
  {
    icon: <NeutralEmojiSVG />,
    text: 'Neutral',
    value: 3,
    color: theme.COLORS.WARNING_DEFAULT,
    background: theme.COLORS.WARNING_LIGHT,
  },
  {
    icon: <NotSoHappyEmojiSVG />,
    text: 'Likely',
    value: 4,
    color: `${theme.COLORS.SUCCESS_DEFAULT}b8`,
    background: `${theme.COLORS.SUCCESS_LIGHT}b8`,
  },
  {
    icon: <HappyEmojiSVG />,
    text: 'Absolutely',
    value: 5,
    color: theme.COLORS.SUCCESS_DEFAULT,
    background: theme.COLORS.SUCCESS_LIGHT,
  },
]

const StyledTextarea = styled(Input.Textarea)`
  min-height: ${rem(130)};
`

const StyledFlex = styled(Flex)`
  gap: ${rem(16)};

  ${mq.to.desktop`
      flex-direction: column;
      gap: 0;
  `}
`

const StyledBox = styled(Box)`
  padding: ${rem(48)} ${rem(40)};

  ${mq.to.desktop`
      padding: ${rem(48)} 20px;
  `}
`

export const NPSModal = () => {
  const { user } = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(
    !(user?.npsScore || local.getItem(LOCAL_STORAGE_NPS_MODAL_CLOSED))
  )

  const matches = useMediaQuery()

  const [saveNpsData, { loading }] = useMutation(LayoutMutations.SAVE_NPS_DATA)

  const closeModal = () => {
    local.setItem(LOCAL_STORAGE_NPS_MODAL_CLOSED, true)
    setIsModalOpen(false)
  }

  const saveData = (input) =>
    saveNpsData({
      variables: { input },
    })

  const onSubmit = async (input) => {
    await saveData(input)

    closeModal()
  }

  return isModalOpen ? (
    <Modal
      isOpen
      closeModal={closeModal}
      ariaLabel="Welcome to your Attendance page"
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={validationSchema.cast({})}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <StyledBox>
              <Text
                fontWeight={700}
                textAlign="center"
                as="p"
                mb={rem(32)}
                fontSize={theme.fontSizes.xxl}
                lineHeight={theme.lineHeights.xxl}
              >
                How much do you like the app?
              </Text>

              <StyledFlex alignItems="center" justifyContent="center">
                {NPS_OPTIONS.map((option) => (
                  <FeedbackOption
                    key={option.value}
                    {...option}
                    saveData={saveData}
                  />
                ))}
              </StyledFlex>

              {values.score && (
                <Flex flexDirection="column" alignItems="center">
                  <Box
                    width="100%"
                    mt={{ mobile: '32px', desktop: rem(48) }}
                    mb={rem(12)}
                  >
                    <Field name="feedback">
                      {({ field, meta }) => (
                        <StyledTextarea
                          isBlock
                          label="Can you tell us more?"
                          placeholder="Type feedback"
                          invalid={meta.touched && meta.error !== undefined}
                          {...field}
                        />
                      )}
                    </Field>
                  </Box>

                  <Button.Primary
                    isBlock={matches.mobile || matches.tablet}
                    type="submit"
                    isLoading={loading}
                  >
                    Send feedback
                  </Button.Primary>
                </Flex>
              )}
            </StyledBox>
          </Form>
        )}
      </Formik>
    </Modal>
  ) : null
}
