import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import theme, { lineHeights } from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { COLORS, fontSizes, mq } from 'Theme'
import { ReactComponent as Checkmark } from 'assets/images/svg/checkmark.svg'
import { ReactComponent as XMark } from 'assets/images/svg/xmark-big.svg'
import { shouldForwardPropOptions } from 'utils/helpers'
import Button from './atoms/Button'
import { Box, Flex } from './atoms/Layout'
import { H1, Text } from './atoms/Typography'

const StyledBox = styled(Box || 'div')`
  text-align: center;
  padding: 0 20px;
`

const Heading = styled(H1, shouldForwardPropOptions)`
  font-size: ${({ $size }) =>
    $size === 'large' ? fontSizes.xxxxxxxl : theme.fontSizes.xxl};
  line-height: ${({ $size }) =>
    $size === 'large' ? fontSizes.xxxxxxxl : theme.lineHeights.xxl};
  margin-top: ${rem(24)};
  margin-bottom: ${({ $size }) => ($size === 'large' ? rem(32) : rem(12))};

  ${mq.to.tablet`
    font-size: 20px;
    line-height: 24px;
  `}
`

const CheckmarkWrap = styled(Flex, shouldForwardPropOptions)`
  background: ${({ $type }) =>
    $type === 'success'
      ? '#36b37e26'
      : $type === 'disabled'
      ? theme.COLORS.BG_SOFT
      : `${theme.COLORS.ERROR_DEFAULT}26`};
  height: ${({ $size }) => ($size === 'large' ? '70px' : '56px')};
  width: ${({ $size }) => ($size === 'large' ? '70px' : '56px')};
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;

  & > svg {
    width: 30px;
    path {
      fill: ${({ $type }) => $type === 'success' && COLORS.EXPLORATION_GREEN};
    }
  }

  ${mq.to.tablet`
    height: 50px;
    width: 50px;
    
    & > svg {
      width: 20px;
    }
  `}
`

const StyledText = styled(Text, shouldForwardPropOptions)`
  max-width: ${rem(730)};
  margin: 0 auto ${({ $size }) => ($size === 'large' ? rem(40) : rem(24))};
  font-size: ${({ $size }) =>
    $size === 'large' ? fontSizes.xxl : theme.fontSizes.s};
  line-height: ${({ $size }) =>
    $size === 'large' ? lineHeights.xxl : theme.lineHeights.s};
  color: ${COLORS.DEEP_RESERVOIR};
  letter-spacing: -0.01em;
  white-space: pre-wrap;

  ${mq.to.tablet`
    font-size: 16px;
    line-height: 24px;
  `}
`

StyledText.defaultProps = {
  as: 'p',
}

export const Callout = ({
  title,
  text,
  buttonText,
  buttonElement,
  onClick,
  className,
  type = 'success',
  size = 'large',
  icon,
  timer = 5,
}) => {
  const [countdown, setCountdown] = useState(timer)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (countdown > 1) {
        setCountdown((prev) => prev - 1)
      } else if (timer > 0) {
        onClick?.()
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [countdown])

  return (
    <StyledBox className={className}>
      <CheckmarkWrap $size={size} $type={type}>
        {icon || (type === 'success' ? <Checkmark /> : <XMark />)}
      </CheckmarkWrap>

      <Heading $size={size}>{title}</Heading>

      <StyledText $size={size}>
        {text}{' '}
        {timer > 0 && (
          <Text color={theme.COLORS.BRAND_DEFAULT}>{countdown} seconds.</Text>
        )}
      </StyledText>

      {buttonElement ||
        (buttonText && (
          <Flex alignItems="center" mt={rem(16)} justifyContent="center">
            <Button.Primary data-cy="callout-btn" onClick={onClick}>
              {buttonText}
            </Button.Primary>
          </Flex>
        ))}
    </StyledBox>
  )
}

Callout.propTypes = {
  icon: PropTypes.node,
  type: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  timer: PropTypes.number,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonElement: PropTypes.node,
  onClick: PropTypes.func,
}
