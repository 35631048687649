import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { Flex } from 'components/atoms/Layout'
import { Ellipsis } from 'components/atoms/Typography'
import { EmailAvatar } from 'components/Avatar'
import { Dot } from 'components/Dot'
import { formatUTC } from 'utils/date'

export const TodoInfoBox = ({ assigneeData, deadline }) =>
  assigneeData || deadline ? (
    <Flex
      minWidth={0}
      mr={rem(24)}
      px={{ mobile: rem(16), tablet: 0 }}
      pb={{ mobile: rem(12), tablet: 0 }}
      alignItems="center"
    >
      {assigneeData && (
        <Flex alignItems="center" $gap={rem(12)}>
          <EmailAvatar
            size={32}
            url={assigneeData.avatar}
            email={assigneeData.email}
          />

          <Ellipsis fontSize={theme.fontSizes.s}>{assigneeData.email}</Ellipsis>
        </Flex>
      )}

      {assigneeData && deadline && <Dot mx={rem(12)} my="0" />}

      {deadline && (
        <Ellipsis fontSize={theme.fontSizes.s}>
          Deadline: {formatUTC(new Date(+deadline), 'yyyy-MM-dd')}
        </Ellipsis>
      )}
    </Flex>
  ) : null

TodoInfoBox.propTypes = {
  assigneeData: PropTypes.shape({
    avatar: PropTypes.string,
    email: PropTypes.string.isRequired,
  }),
  deadline: PropTypes.string,
}
