import { GTM_EVENTS } from 'hooks/useGoogleTracking'

export const subscriptions = [
  {
    name: 'Basic',
    title: 'Annual',
    description: 'Access for 12 months to the NextRetreat app.',
    price: 89,
    finalPrice: 89 * 12,
    interval: 'month',
    intervalCount: 1,
    id: process.env.REACT_APP_ANNUAL_PRICE,
    checked: [
      'All NextRetreat features',
      'Full venue inventory',
      'Unlimited access (number of team locations, trips)',
    ],
    gtmEventName: GTM_EVENTS.packageBuyAnnual,
  },
  {
    name: 'Basic',
    title: 'Quarterly',
    description: 'Access for 1 month to the NextRetreat app.',
    price: 99,
    finalPrice: 99 * 3,
    interval: 'month',
    intervalCount: 1,
    id: process.env.REACT_APP_QUARTERLY_PRICE,
    checked: [
      'All NextRetreat features',
      'Full venue inventory',
      'Unlimited access (number of team locations, trips)',
    ],
    gtmEventName: GTM_EVENTS.packageBuyQuarterly,
  },
  {
    name: 'Basic',
    title: 'Monthly',
    description: 'Access for 1 month to the NextRetreat app.',
    price: 119,
    finalPrice: 119,
    interval: 'month',
    intervalCount: 1,
    id: process.env.REACT_APP_MONTHLY_PRICE,
    checked: [
      'All NextRetreat features',
      'Full venue inventory',
      'Unlimited access (number of team locations, trips)',
    ],
    gtmEventName: GTM_EVENTS.packageBuyMonhtly,
  },
]
