import styled from '@emotion/styled'
import { Input } from '@nextretreat/ui-components/dist/Input'
import theme from '@nextretreat/ui-components/dist/Theme'
import { Field, useFormikContext } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'

const ItemFlex = styled(Flex)`
  padding: ${rem(16)} ${rem(20)};
  gap: ${rem(16)};
  align-items: center;
  background-color: ${theme.COLORS.BG_SOFT};
  border-radius: 8px;
`

export const CollaboratorItem = ({
  collaborator,
  onEditPermissions,
  index,
  onRemove,
}) => {
  const { values } = useFormikContext()

  const userAlreadyAdded = values.collaborators.some(
    (collab, i) =>
      collab.email === collaborator.email && index !== i && collaborator.email
  )

  return (
    <Box mb={rem(12)} data-cy="collab-item">
      <ItemFlex>
        <Field name={`collaborators.${index}.email`}>
          {({ field, meta }) => (
            <Input
              isBlock
              data-cy="inp-collab-email"
              placeholder="E-mail..."
              invalid={
                meta.touched && (meta.error !== undefined || userAlreadyAdded)
              }
              {...field}
            />
          )}
        </Field>

        <Flex flexShrink="0">
          <Button.Tertiary
            iconLeft={<Icon name="tune" />}
            type="button"
            onClick={onEditPermissions}
          >
            Set permissions
          </Button.Tertiary>
        </Flex>

        <Icon name="cancel" onClick={() => onRemove(index)} />
      </ItemFlex>

      {userAlreadyAdded && (
        <Text fontSize="xs" color={theme.COLORS.ERROR_DEFAULT}>
          This user is already added
        </Text>
      )}
    </Box>
  )
}

CollaboratorItem.propTypes = {
  collaborator: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string,
  }),
  onEditPermissions: PropTypes.func,
  index: PropTypes.number,
  onRemove: PropTypes.func,
}
