import { useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'
import { PlanQueries } from 'api/Plan/PlanQueries'
import { TripQueries } from 'api/Trip/TripQueries'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'

const Wrap = styled(Flex)`
  margin-top: ${rem(10)};
  padding: ${rem(16)};
  flex-direction: column;
  border-radius: 8px;
  background-color: ${theme.COLORS.BG_SOFT};
  gap: ${rem(16)};
`

const StyledAtVenueButton = styled('button')`
  border: none;
  background: none;
  color: ${theme.COLORS.BRAND_DEFAULT};

  &:hover {
    text-decoration: underline;
  }
`

export const PrefillDestinationSearch = ({ value, additionalContentProps }) => {
  const [searchedDestinations, setSearchedDestinations] = useState([])

  const { onChange } = additionalContentProps

  const { data } = useQuery(TripQueries.GET_DESTINATIONS_FOR_TRIP_REQUIREMENTS)

  const [searchDestinations] = useLazyQuery(PlanQueries.SEARCH_DESTINATIONS, {
    onCompleted: ({ searchDestinations }) => {
      if (value) {
        setSearchedDestinations(searchDestinations)
      } else setSearchedDestinations([])
    },
  })

  const searchDebounce = useDebouncedCallback((value) => {
    searchDestinations({ variables: { search: value } })
  }, 300)

  useEffect(() => {
    if (value) searchDebounce(value)
  }, [value])

  const destinationsToShow = searchedDestinations
    .slice(0, 2)
    .map(({ destinationId, cities }) => {
      const destinationFromList = data?.availableDestinations?.find(
        ({ id }) => id === destinationId
      )

      return destinationFromList ? (
        <Text
          color={theme.COLORS.TXT_DEFAULT}
          key={destinationId}
          as="p"
          mb="0"
        >
          We found <b>{cities?.[0] || value}</b> near{' '}
          {destinationFromList.title}.{' '}
          <StyledAtVenueButton
            type="button"
            onClick={() =>
              onChange?.(
                {
                  fromList: true,
                  value: destinationFromList.id,
                  key: 'fromList',
                  label: destinationFromList.title,
                  type: 'destination_select',
                },
                destinationFromList.title
              )
            }
          >
            Select {destinationFromList.title} as destination
          </StyledAtVenueButton>
        </Text>
      ) : null
    })
    .filter(Boolean)

  return destinationsToShow?.length ? <Wrap>{destinationsToShow}</Wrap> : null
}

PrefillDestinationSearch.propTypes = {
  value: PropTypes.string,
  additionalContentProps: PropTypes.object,
}
