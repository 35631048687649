import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { ellipsis, rem } from 'polished'
import PropTypes from 'prop-types'
import { Box, Flex } from 'components/atoms/Layout'
import { Ellipsis, Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'
import { FallbackImg } from 'sharedComponents/FallbackImg'

const IMAGE_SIZE = 64

const StyledBox = styled(Box)`
  background-color: ${theme.COLORS.BG_SOFT};
  border-radius: 8px;
`

const StyledImage = styled(FallbackImg)`
  width: ${rem(IMAGE_SIZE)};
  height: ${rem(IMAGE_SIZE)};
  border-radius: 50%;
  object-fit: cover;
`

const TripItemTextWrapper = styled(Flex)`
  ${ellipsis()}
  flex-direction: column;
`
export const HighlightedVenueBox = ({
  thumbnailUrl,
  country,
  city,
  type,
  address,
  title,
  children,
  ...passingProps
}) => (
  <StyledBox flex="1" p={rem(16)} {...passingProps}>
    <Flex alignItems="center">
      <Box position="relative">
        <StyledImage src={thumbnailUrl || ''} alt="" />
      </Box>
      <TripItemTextWrapper ml={rem(16)}>
        {type && (
          <Text
            fontSize={theme.fontSizes.s}
            lineHeight={theme.lineHeights.s}
            fontWeight="510"
            color={theme.COLORS.TXT_DEFAULT}
          >
            {type}
          </Text>
        )}
        <Ellipsis
          as="p"
          fontSize={theme.fontSizes.m}
          lineHeight={theme.lineHeights.m}
          color={theme.COLORS.TXT_MAIN}
          fontWeight="bold"
        >
          {title}
        </Ellipsis>

        <Flex alignItems="center">
          <Icon color={theme.COLORS.TXT_DEFAULT} name="place" />
          <Ellipsis
            as="p"
            fontSize={theme.fontSizes.s}
            lineHeight={theme.lineHeights.s}
            color={theme.COLORS.TXT_DEFAULT}
          >
            <Text ml="4px" fontWeight="510" color={theme.COLORS.TXT_MAIN}>
              {country}
              {city ? `, ${city}` : ''}
            </Text>{' '}
            • {address}
          </Ellipsis>
        </Flex>
      </TripItemTextWrapper>
    </Flex>

    {children}
  </StyledBox>
)

HighlightedVenueBox.propTypes = {
  thumbnailUrl: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string,
  type: PropTypes.string,
  address: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
}
