import React from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import { BORDER_WIDTH, COLORS, mq, space } from 'Theme'
import { ReactComponent as Instagram } from 'assets/images/svg/instagram.svg'
import { ReactComponent as Linkedin } from 'assets/images/svg/linkedin.svg'
import logoIcon from 'assets/images/svg/logo-next-retreat-icon.svg'
import { ReactComponent as Twitter } from 'assets/images/svg/twitter.svg'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Device from 'components/Device'
import meta from '../../meta.json'

const PADDING_X = space.m
const footerMQ = mq.to.tablet

const StyledFooter = styled('footer')`
  display: flex;
  align-items: center;

  min-height: ${rem('70px')};

  border-top: ${BORDER_WIDTH} solid ${COLORS.IRRADIANT_IRIS};
`

const Content = styled('div')`
  display: flex;
  justify-content: space-between;

  width: 100%;
  max-width: calc(2 * ${PADDING_X} + ${rem('1120px')});
  margin: 0 auto;

  > * {
    padding: ${space.xs} ${PADDING_X};
  }

  ${footerMQ`
    align-items: center;
    flex-direction: column;

    padding: ${space.s} 0;

    > :last-of-type {
      order: -1;
    }
  `}
`

const StyledLink = styled('a')`
  font-size: ${theme.fontSizes.s};
  white-space: nowrap;
  text-decoration: underline;
  font-weight: 590;
  padding: 0 ${rem(16)};
  color: ${theme.COLORS.TXT_DEFAULT};

  &:hover {
    text-decoration: none;
  }
`

StyledLink.defaultProps = {
  target: '_blank',
  rel: 'noreferrer noopener',
}

const SocialLink = styled('a')`
  display: inline-flex;

  padding: ${rem(12)};

  & path {
    fill: ${theme.COLORS.BRAND_DEFAULT};
  }
`

SocialLink.defaultProps = {
  target: '_blank',
}

const Logo = styled('img')`
  width: ${rem('30px')};
  margin-right: ${space.m};

  ${footerMQ`
    margin: 0;
  `}
`

const StartContent = styled('div')`
  display: flex;
  align-items: center;

  ${footerMQ`
    flex-direction: column;
  `}
`

const BR = styled('br')`
  display: none;

  ${footerMQ`
    display: initial;
  `}
`

const Footer = () => (
  <StyledFooter>
    <Content>
      <Device sizes={['tablet', 'desktop', 'tv']}>
        <StartContent>
          <Logo src={logoIcon} alt="" />
          <Text
            fontSize="s"
            textAlign="center"
            color={theme.COLORS.TXT_DEFAULT}
          >
            App version {meta.version}{' '}
            <Text px={rem(16)} fontWeight="590">
              •
            </Text>{' '}
            © {new Date().getFullYear()} NextRetreat. <BR /> All rights
            reserved.
          </Text>
        </StartContent>
      </Device>
      <Flex
        alignItems="center"
        $gap={rem(16)}
        flexWrap="wrap"
        flexDirection={{ mobile: 'column-reverse', tablet: 'row' }}
      >
        <Flex alignItems="center">
          <StyledLink
            href={process.env.REACT_APP_WEBSITE_URL}
            data-cy="footer-home"
          >
            Home
          </StyledLink>
          <Text color={theme.COLORS.TXT_DEFAULT}>•</Text>
          <StyledLink
            href={`${process.env.REACT_APP_WEBSITE_URL}/terms`}
            data-cy="footer-terms"
          >
            Terms & Conditions
          </StyledLink>
          <Text color={theme.COLORS.TXT_DEFAULT}>•</Text>
          <StyledLink
            href={`${process.env.REACT_APP_WEBSITE_URL}/contact`}
            data-cy="footer-contact"
          >
            Contact us
          </StyledLink>
        </Flex>

        <Flex alignItems="center">
          <SocialLink href="https://twitter.com/nextretreat">
            <Twitter />
          </SocialLink>
          <SocialLink href="https://instagram.com/nextretreat_com">
            <Instagram />
          </SocialLink>
          <SocialLink href="https://www.linkedin.com/company/nextretreat">
            <Linkedin />
          </SocialLink>
        </Flex>
      </Flex>
    </Content>
  </StyledFooter>
)

export default Footer
