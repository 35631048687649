import React, { lazy, Suspense } from 'react'
import { useAuth } from 'AuthProvider'
import { MODAL_TYPES, useModalManagement } from 'ModalManagementProvider'
import TodoModal from './TodoModal'
import TripOnboardingModal from './TripOnboarding'

const ActivityModal = lazy(() => import('./ActivityModal/ActivityModal'))
const AutoGroupModal = lazy(() => import('./AutoGroupModal/AutoGroupModal'))
const CreateTripModal = lazy(() => import('./CreateTripModal/CreateTripModal'))
const SmallCreateTripModal = lazy(() =>
  import('./CreateTripModal/SmallCreateTripModal')
)
const CustomVenueModal = lazy(() =>
  import('./CustomVenueModal/CustomVenueModal')
)
const SettingsModal = lazy(() => import('./SettingsModal/SettingsModal'))
const VenueSelectModal = lazy(() =>
  import('./VenueSelectModal/VenueSelectModal')
)
const FeedbacksDrawer = lazy(() => import('./FeedbacksDrawer'))
const ReviewGroupDrawer = lazy(() => import('./ReviewGroupDrawer'))
const AddGroupMemberModal = lazy(() => import('./AddGroupMemberModal'))
const AddTripVenueModal = lazy(() => import('./AddTripVenueModal'))
const CalendlyModal = lazy(() => import('./CalendlyModal'))
const ContactsModal = lazy(() => import('./ContactsModal'))
const QuestionModal = lazy(() => import('./QuestionModal'))
const DeadlinesModal = lazy(() => import('./DeadlinesModal'))
const EditGroupModal = lazy(() => import('./EditGroupModal'))
const EmissionCalculationsDrawer = lazy(() => import('./EmissionsDrawer'))
const ExpenseModal = lazy(() => import('./ExpenseModal'))
const HistoryDrawer = lazy(() => import('./HistoryDrawer'))
const MergeGroupsModal = lazy(() => import('./MergeGroupsModal'))
const PersonalDataDrawer = lazy(() => import('./PersonalDetailsDrawer'))
const PreferencesDrawer = lazy(() => import('./PreferencesDrawer'))
const RefreshModal = lazy(() => import('./RefreshModal'))
const RemoveTripVenueModal = lazy(() => import('./RemoveTripVenueModal'))
const SendTeamMailModal = lazy(() => import('./SendTeamMailModal'))
const SplitGroupModal = lazy(() => import('./SplitGroupModal'))
const TripAskQuestionModal = lazy(() => import('./TripAskQuestionModal'))
const VotersDrawer = lazy(() => import('./VotersDrawer'))
const TripCheckAvailabilityModal = lazy(() =>
  import('./TripCheckAvailabilityModal')
)
const TripSuccessFormRequestModal = lazy(() =>
  import('./TripSuccessFormRequestModal')
)
const NewsDrawer = lazy(() => import('./NewsDrawer'))
const VotingModal = lazy(() => import('./VotingModal/VotingModal'))

const ModalManagementModals = () => {
  const { refetchData } = useAuth()
  const { closeModal, openedModals } = useModalManagement()

  const onSuccess = React.useCallback(
    (originalOnSuccess) => async (data) => {
      if (originalOnSuccess) {
        await originalOnSuccess(data)
      }
      refetchData()
    },
    [refetchData]
  )

  return (
    <Suspense fallback={<div />}>
      {openedModals[MODAL_TYPES.CREATE_MODAL] && (
        <CreateTripModal
          {...openedModals[MODAL_TYPES.CREATE_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.CREATE_MODAL)}
          onSuccess={onSuccess(
            openedModals[MODAL_TYPES.CREATE_MODAL].onSuccess
          )}
        />
      )}
      {openedModals[MODAL_TYPES.CREATE_TRIP_ONBOARDING] && (
        <TripOnboardingModal
          {...openedModals[MODAL_TYPES.CREATE_TRIP_ONBOARDING]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.CREATE_TRIP_ONBOARDING)}
        />
      )}
      {openedModals[MODAL_TYPES.SMALL_CREATE_TRIP_MODAL] && (
        <SmallCreateTripModal
          {...openedModals[MODAL_TYPES.SMALL_CREATE_TRIP_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.SMALL_CREATE_TRIP_MODAL)}
          onSuccess={onSuccess(
            openedModals[MODAL_TYPES.SMALL_CREATE_TRIP_MODAL].onSuccess
          )}
        />
      )}
      {openedModals[MODAL_TYPES.SETTINGS_MODAL] &&
        openedModals[MODAL_TYPES.SETTINGS_MODAL].trip && (
          <SettingsModal
            {...openedModals[MODAL_TYPES.SETTINGS_MODAL]}
            isOpen
            closeModal={() => closeModal(MODAL_TYPES.SETTINGS_MODAL)}
            onSuccess={onSuccess(
              openedModals[MODAL_TYPES.SETTINGS_MODAL].onSuccess
            )}
          />
        )}
      {openedModals[MODAL_TYPES.DEADLINES_MODAL] && (
        <DeadlinesModal
          {...openedModals[MODAL_TYPES.DEADLINES_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.DEADLINES_MODAL)}
          onSuccess={onSuccess(
            openedModals[MODAL_TYPES.DEADLINES_MODAL].onSuccess
          )}
        />
      )}
      {openedModals[MODAL_TYPES.ADD_VENUE_MODAL] &&
        openedModals[MODAL_TYPES.ADD_VENUE_MODAL].venueId && (
          <AddTripVenueModal
            {...openedModals[MODAL_TYPES.ADD_VENUE_MODAL]}
            isOpen
            closeModal={() => closeModal(MODAL_TYPES.ADD_VENUE_MODAL)}
            onSuccess={onSuccess(
              openedModals[MODAL_TYPES.ADD_VENUE_MODAL].onSuccess
            )}
          />
        )}
      {openedModals[MODAL_TYPES.REMOVE_VENUE_MODAL] &&
        openedModals[MODAL_TYPES.REMOVE_VENUE_MODAL].id && (
          <RemoveTripVenueModal
            {...openedModals[MODAL_TYPES.REMOVE_VENUE_MODAL]}
            isOpen
            closeModal={() => closeModal(MODAL_TYPES.REMOVE_VENUE_MODAL)}
            onSuccess={onSuccess(
              openedModals[MODAL_TYPES.REMOVE_VENUE_MODAL].onSuccess
            )}
          />
        )}
      {openedModals[MODAL_TYPES.ASK_QUESTION_MODAL] &&
        (openedModals[MODAL_TYPES.ASK_QUESTION_MODAL].trip ||
          openedModals[MODAL_TYPES.ASK_QUESTION_MODAL].venueId) && (
          <TripAskQuestionModal
            {...openedModals[MODAL_TYPES.ASK_QUESTION_MODAL]}
            isOpen
            closeModal={() => closeModal(MODAL_TYPES.ASK_QUESTION_MODAL)}
            onSuccess={onSuccess(
              openedModals[MODAL_TYPES.ASK_QUESTION_MODAL].onSuccess
            )}
          />
        )}
      {openedModals[MODAL_TYPES.CHECK_AVAILABILITY_MODAL] &&
        openedModals[MODAL_TYPES.CHECK_AVAILABILITY_MODAL].trip && (
          <TripCheckAvailabilityModal
            {...openedModals[MODAL_TYPES.CHECK_AVAILABILITY_MODAL]}
            isOpen
            closeModal={() => closeModal(MODAL_TYPES.CHECK_AVAILABILITY_MODAL)}
            onSuccess={onSuccess(
              openedModals[MODAL_TYPES.CHECK_AVAILABILITY_MODAL].onSuccess
            )}
          />
        )}
      {openedModals[MODAL_TYPES.SUCCESS_FORM_REQUEST_MODAL] &&
        openedModals[MODAL_TYPES.SUCCESS_FORM_REQUEST_MODAL].title && (
          <TripSuccessFormRequestModal
            {...openedModals[MODAL_TYPES.SUCCESS_FORM_REQUEST_MODAL]}
            isOpen
            closeModal={() =>
              closeModal(MODAL_TYPES.SUCCESS_FORM_REQUEST_MODAL)
            }
          />
        )}
      {openedModals[MODAL_TYPES.SELECT_VENUE_MODAL] && (
        <VenueSelectModal
          {...openedModals[MODAL_TYPES.SELECT_VENUE_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.SELECT_VENUE_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.ACTIVITY_MODAL] && (
        <ActivityModal
          {...openedModals[MODAL_TYPES.ACTIVITY_MODAL]}
          isOpen
          key={openedModals[MODAL_TYPES.ACTIVITY_MODAL].activity?.id}
          closeModal={() => closeModal(MODAL_TYPES.ACTIVITY_MODAL)}
          onCloseDetail={
            openedModals[MODAL_TYPES.ACTIVITY_MODAL].onCloseDetail ||
            (() => {
              //
            })
          }
        />
      )}
      {openedModals[MODAL_TYPES.HISTORY_MODAL] && (
        <HistoryDrawer
          {...openedModals[MODAL_TYPES.HISTORY_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.HISTORY_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.PERSONAL_DATA_MODAL] && (
        <PersonalDataDrawer
          {...openedModals[MODAL_TYPES.PERSONAL_DATA_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.PERSONAL_DATA_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.PREFERENCES_MODAL] && (
        <PreferencesDrawer
          {...openedModals[MODAL_TYPES.PREFERENCES_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.PREFERENCES_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.VOTERS_MODAL] &&
        openedModals[MODAL_TYPES.VOTERS_MODAL].voters && (
          <VotersDrawer
            {...openedModals[MODAL_TYPES.VOTERS_MODAL]}
            isOpen
            closeModal={() => closeModal(MODAL_TYPES.VOTERS_MODAL)}
          />
        )}
      {openedModals[MODAL_TYPES.VOTING_MODAL] && (
        <VotingModal
          {...openedModals[MODAL_TYPES.VOTING_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.VOTING_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.EXPENSE_MODAL] && (
        <ExpenseModal
          {...openedModals[MODAL_TYPES.EXPENSE_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.EXPENSE_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.EDIT_GROUP_MODAL] && (
        <EditGroupModal
          {...openedModals[MODAL_TYPES.EDIT_GROUP_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.EDIT_GROUP_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.GROUP_SPLIT_MODAL] && (
        <SplitGroupModal
          {...openedModals[MODAL_TYPES.GROUP_SPLIT_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.GROUP_SPLIT_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.ADD_GROUP_MEMBER_MODAL] && (
        <AddGroupMemberModal
          {...openedModals[MODAL_TYPES.ADD_GROUP_MEMBER_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.ADD_GROUP_MEMBER_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.GROUPS_MERGE_MODAL] && (
        <MergeGroupsModal
          {...openedModals[MODAL_TYPES.GROUPS_MERGE_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.GROUPS_MERGE_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.SEND_TEAM_MAIL_MODAL] && (
        <SendTeamMailModal
          {...openedModals[MODAL_TYPES.SEND_TEAM_MAIL_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.SEND_TEAM_MAIL_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.CUSTOM_VENUE_MODAL] && (
        <CustomVenueModal
          {...openedModals[MODAL_TYPES.CUSTOM_VENUE_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.CUSTOM_VENUE_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.CALENDLY_MODAL] && (
        <CalendlyModal
          {...openedModals[MODAL_TYPES.CALENDLY_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.CALENDLY_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.EMISSION_CALCULATIONS_MODAL] && (
        <EmissionCalculationsDrawer
          {...openedModals[MODAL_TYPES.EMISSION_CALCULATIONS_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.EMISSION_CALCULATIONS_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.TODO_MODAL] && (
        <TodoModal
          {...openedModals[MODAL_TYPES.TODO_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.TODO_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.CONTACTS_MODAL] && (
        <ContactsModal
          {...openedModals[MODAL_TYPES.CONTACTS_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.CONTACTS_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.QUESTION_MODAL] && (
        <QuestionModal
          {...openedModals[MODAL_TYPES.QUESTION_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.QUESTION_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.AUTO_GROUP_MODAL] && (
        <AutoGroupModal
          {...openedModals[MODAL_TYPES.AUTO_GROUP_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.AUTO_GROUP_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.FEEDBACKS_MODAL] && (
        <FeedbacksDrawer
          {...openedModals[MODAL_TYPES.FEEDBACKS_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.FEEDBACKS_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.REVIEW_GROUP_MODAL] && (
        <ReviewGroupDrawer
          {...openedModals[MODAL_TYPES.REVIEW_GROUP_MODAL]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.REVIEW_GROUP_MODAL)}
        />
      )}
      {openedModals[MODAL_TYPES.NEWS_DRAWER] && (
        <NewsDrawer
          {...openedModals[MODAL_TYPES.NEWS_DRAWER]}
          isOpen
          closeModal={() => closeModal(MODAL_TYPES.NEWS_DRAWER)}
        />
      )}

      <RefreshModal />
    </Suspense>
  )
}

export default ModalManagementModals
