import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { format } from 'date-fns'
import { MODAL_TYPES, useModalManagement } from 'ModalManagementProvider'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Drawer } from 'components/Drawer'
import { MenuItem } from 'sharedComponents/MoreButton'

export const HistoryMenuItem = ({
  editedAt,
  createdAt,
  editedBy,
  closePopup,
}) => {
  const { openModal } = useModalManagement()

  return (
    <MenuItem
      as="button"
      type="button"
      onClick={() => {
        openModal({
          type: MODAL_TYPES.HISTORY_MODAL,
          editedAt,
          createdAt,
          editedBy,
        })
        closePopup?.()
      }}
      icon="history"
      text="Show history"
    />
  )
}

HistoryMenuItem.propTypes = {
  createdAt: PropTypes.string,
  editedAt: PropTypes.string,
  editedBy: PropTypes.string,
  closePopup: PropTypes.func,
}

const Item = styled(Flex)`
  justify-content: space-between;
  padding: ${rem(16)} 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.COLORS.BG_DIVIDER};
  }

  & > * {
    flex: 1;
    color: ${theme.COLORS.TXT_DEFAULT};
  }

  & *:first-of-type {
    font-weight: 510;
  }
`

const formatDate = (date) =>
  date ? format(new Date(+date), 'yyyy-MM-dd HH:mm') : undefined

const HistoryDrawer = ({
  isOpen,
  closeModal,
  createdAt,
  editedAt,
  editedBy,
}) => {
  const valuesMap = [
    {
      label: 'Edited at',
      value: formatDate(editedAt),
    },
    {
      label: 'Created at',
      value: formatDate(createdAt),
    },
    {
      label: 'Edited by',
      value: editedBy,
    },
  ]
  return (
    <Drawer
      isOpen={isOpen}
      onClose={closeModal}
      width={rem(550)}
      height="100vh"
      direction="right"
      ariaLabel="History"
      title="History"
    >
      <Box py="m" px={rem(24)}>
        {valuesMap
          .filter(({ value }) => value)
          .map(({ value, label }) => (
            <Item key={label}>
              <Text>{label}</Text>
              <Text>{value}</Text>
            </Item>
          ))}
      </Box>
    </Drawer>
  )
}

HistoryDrawer.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  createdAt: PropTypes.string,
  editedAt: PropTypes.string,
  editedBy: PropTypes.string,
}

export default HistoryDrawer
