import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Accordion } from '@nextretreat/ui-components/dist/Accordion'
import theme from '@nextretreat/ui-components/dist/Theme'
import { getYear } from 'date-fns'
import { Form, Formik, useFormikContext } from 'formik'
import get from 'lodash.get'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import { mq } from 'Theme'
import * as Yup from 'yup'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Device from 'components/Device'
import { Icon } from 'components/Icon'
import { ConciergeItemValues } from 'routes/Trip/Tabs/TripRequirements/ConciergeItem'
import { getSeasonFromDate, shouldForwardPropOptions } from 'utils/helpers'
import { fields } from './fields'

const createTripValidationSchema = Yup.object().shape({
  teamSize: Yup.number().nullable().required('Required').min(1),
  budget: Yup.object()
    .shape({
      maximumBudget: Yup.number().nullable(),
      minimumBudget: Yup.number().nullable(),
    })
    .required(),
  date: Yup.object()
    .shape({
      exactDate: Yup.boolean().nullable(),
      flexible: Yup.boolean().nullable(),
      period: Yup.boolean().nullable(),
      value: Yup.mixed().when('flexible', ([flexible], schema) =>
        flexible
          ? schema
          : Yup.object()
              .shape({
                startDate: Yup.date().nullable().required(),
                endDate: Yup.date().nullable().required(),
              })
              .required()
      ),
    })
    .required(),
  destination: Yup.object()
    .shape({
      label: Yup.string().nullable(),
      value: Yup.string()
        .nullable()
        .when('helpNeeded', ([helpNeeded], schema) =>
          helpNeeded ? schema : schema.required()
        ),
      fromList: Yup.boolean().nullable(),
      specific: Yup.boolean().nullable(),
      helpNeeded: Yup.boolean().nullable(),
    })
    .required(),
  name: Yup.string().required('Required'),
})

const StepAccordion = styled(Accordion)`
  background-color: ${theme.COLORS.WHITE};
  border-radius: 8px;
  max-width: ${rem(740)};
  width: 100%;
  padding-bottom: ${rem(20)};

  ${mq.to.tablet`
     padding-bottom: ${rem(20)};
  `}
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${rem(12)};

  width: 100%;
  padding: ${rem(32)} 0;

  ${mq.to.tablet`
    padding: ${rem(16)};
  `}
`

const StepCircle = styled(Flex, shouldForwardPropOptions)`
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;

  ${({ $isCompleted, $isActive }) =>
    $isCompleted
      ? `background-color: ${theme.COLORS.BRAND_DEFAULT};color: ${theme.COLORS.WHITE};`
      : $isActive
      ? `border: 1px solid ${theme.COLORS.BRAND_DEFAULT};color: ${theme.COLORS.BRAND_DEFAULT};`
      : `border: 1px solid ${theme.COLORS.BG_DIVIDER};color: ${theme.COLORS.TXT_DEFAULT};`}
`

const Step = ({
  step,
  index,
  stepsLength,
  currentStep,
  handleEditClick,
  handleNextStep,
  className,
  hideCircle,
}) => {
  const matches = useMediaQuery()
  const { values, isSubmitting, errors, setFieldValue } = useFormikContext()

  const leftMarginQuestion = `calc(${rem(20)} + 40px)`

  const editIcon = currentStep > index && (
    <Icon
      color={theme.COLORS.TXT_DEFAULT}
      name="edit"
      type="button"
      onClick={() => handleEditClick(index)}
    />
  )

  return (
    <StepAccordion
      className={className}
      expanded={index === currentStep}
      key={step.key}
      data-cy={`acc-${step.key}`}
      title={
        <Box
          p={{ mobile: `${rem(20)} ${rem(16)}`, tablet: rem(24) }}
          pb={{ mobile: 0, tablet: 0 }}
        >
          <Flex alignItems="center" $gap={rem(20)}>
            {!hideCircle && (
              <StepCircle
                $isCompleted={currentStep > index}
                $isActive={currentStep === index}
              >
                {currentStep > index ? (
                  <Icon fontSize="24px" name="check" />
                ) : (
                  index + 1
                )}
              </StepCircle>
            )}
            <Text
              style={{ flex: 1 }}
              as="h3"
              mb={0}
              fontSize={theme.fontSizes.xl}
              lineHeights={theme.lineHeights.xl}
            >
              {step.question}
            </Text>

            <Device sizes={['tablet', 'desktop', 'tv']}>{editIcon}</Device>
          </Flex>

          {currentStep > index && !errors[step.key] ? (
            <Flex
              pt={rem(8)}
              ml={leftMarginQuestion}
              alignItems="center"
              justifyContent="space-between"
            >
              <Text as="p" color={theme.COLORS.TXT_DEFAULT}>
                {step.getFilledText(values)}
              </Text>
              <Device sizes={['mobile']}>{editIcon}</Device>
            </Flex>
          ) : null}
        </Box>
      }
      onToggle={() => {}}
    >
      <Flex
        px={{ mobile: rem(16), tablet: rem(24) }}
        pt={{ mobile: rem(16), tablet: rem(8) }}
        pb={rem(4)}
        $gap={rem(16)}
        flexDirection={step.flexDirection || 'column'}
        ml={{ tablet: leftMarginQuestion }}
      >
        <ConciergeItemValues
          itemKey={step.key}
          onChange={(val, label) => {
            if (label) {
              setFieldValue(step.key, { ...val, label })
            } else setFieldValue(step.key, val)

            if (step.hideContinue && index !== fields.length - 1)
              handleNextStep()
          }}
          value={get(values, step.key)}
          {...step}
        />

        {step.hideContinue ? null : (
          <Box>
            <Button.Primary
              data-cy="btn-continue"
              type={index === stepsLength - 1 ? 'submit' : 'button'}
              isBlock={matches.mobile}
              disabled={!!errors[step.key]}
              isLoading={isSubmitting}
              onClick={
                index === stepsLength - 1
                  ? undefined
                  : () => {
                      if (currentStep === stepsLength - 2)
                        setFieldValue(
                          'name',
                          `${
                            values.destination?.fromList ||
                            values.destination?.specific
                              ? `${
                                  values.destination?.label?.split(',')?.[0] ||
                                  values.destination?.value ||
                                  ''
                                } `
                              : ''
                          }${
                            values.date?.value?.startDate
                              ? `${getSeasonFromDate(
                                  values.date.value.startDate
                                )} `
                              : ''
                          }Retreat ${
                            values.date?.value?.startDate
                              ? getYear(values.date?.value?.startDate)
                              : ''
                          }`
                        )
                      handleNextStep()
                    }
              }
            >
              {index === stepsLength - 1 ? 'Create my trip' : 'Continue'}
            </Button.Primary>
          </Box>
        )}
      </Flex>
    </StepAccordion>
  )
}

Step.propTypes = {
  step: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  stepsLength: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  hideCircle: PropTypes.bool,
}

const CreateTripStepper = ({
  onSubmit,
  hideSubmit,
  endContent,
  ...passingProps
}) => {
  const [currentStep, setCurrentStep] = useState(0)

  const handleNextStep = () => setCurrentStep(currentStep + 1)

  const handleEditClick = (index) => setCurrentStep(index)

  return (
    <Formik
      validateOnMount
      initialValues={createTripValidationSchema.cast(
        {
          budget: {
            minimumBudget: null,
            maximumBudget: null,
          },
          date: {
            value: {
              startDate: null,
              endDate: null,
            },
            flexible: false,
            period: false,
            exactDate: false,
          },
          destination: {
            label: '',
            value: '',
            fromList: false,
            specific: false,
            helpNeeded: false,
          },
          name: '',
        },
        { assert: false }
      )}
      validationSchema={createTripValidationSchema}
      onSubmit={onSubmit}
    >
      <StyledForm>
        {fields.map((step, index) => (
          <Step
            key={step.key}
            step={{
              ...step,
              hideContinue:
                step.key === 'name' ? hideSubmit : step.hideContinue,
            }}
            index={index}
            currentStep={currentStep}
            stepsLength={fields.length}
            handleEditClick={handleEditClick}
            handleNextStep={handleNextStep}
            {...passingProps}
          />
        ))}

        {endContent}
      </StyledForm>
    </Formik>
  )
}

CreateTripStepper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  endContent: PropTypes.node,
  hideSubmit: PropTypes.bool,
}

export default CreateTripStepper
