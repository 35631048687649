import { useState } from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { COLORS, mq, radius } from 'Theme'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { H3, Text } from 'components/atoms/Typography'
import { shouldForwardPropOptions } from 'utils/helpers'
import { subscriptions } from 'utils/subscriptions'

const Package = styled(Flex, shouldForwardPropOptions)`
  border-radius: ${radius.m};
  border: 1px solid
    ${({ $isSelected }) =>
      $isSelected ? theme.COLORS.BRAND_DEFAULT : COLORS.SILK_SOX};
  padding: ${rem(30)};
  gap: 10px;
  cursor: pointer;
  & > * {
    flex: 1;
  }
  ${({ $isSelected }) => ($isSelected ? '' : 'opacity: .5')};

  &:hover {
    border: 1px solid ${theme.COLORS.BRAND_DEFAULT};
  }

  ${mq.to.tablet`
    display: block
    `}
`

const CheckmarkWrap = styled(Flex, shouldForwardPropOptions)`
  background: ${({ $blue }) =>
    $blue ? theme.COLORS.BRAND_DEFAULT : COLORS.EXPLORATION_GREEN}26;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  & > svg {
    width: 30px;
  }
`

const Checkmark = ({ concierge }) => (
  <CheckmarkWrap $blue={concierge}>
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.06 8.31a.75.75 0 01-.53-.219L0 4.561 1.06 3.5l3 3 6.5-6.5 1.061 1.06-7.03 7.031a.75.75 0 01-.53.22z"
        fill={concierge ? theme.COLORS.BRAND_DEFAULT : COLORS.EXPLORATION_GREEN}
      />
    </svg>
  </CheckmarkWrap>
)

Checkmark.propTypes = {
  concierge: PropTypes.bool,
}

export const CheckmarkText = ({ isFirst, isSelected, text }) => (
  <Flex mt={isFirst ? undefined : { mobile: '15px', tablet: 'l' }}>
    <Checkmark />
    <Text
      color={COLORS.DEEP_RESERVOIR}
      letterSpacing="0"
      fontSize={isSelected ? 'l' : 'm'}
      ml="10px"
    >
      {text}
    </Text>
  </Flex>
)

CheckmarkText.propTypes = {
  isFirst: PropTypes.bool,
  isSelected: PropTypes.bool,
  text: PropTypes.string.isRequired,
}

export const ChooseSubscriptionModal = ({ loading, onChoosePackage }) => {
  const [selectedPackage, setSelectedPackage] = useState()

  return (
    <Box px={rem(24)} py={rem(16)} pb={{ mobile: rem(40) }}>
      {subscriptions.map(
        (
          { id, title, price, interval, description, intervalCount, checked },
          index
        ) => (
          <Package
            key={id}
            data-cy={`card-subscription-${title.toLowerCase()}`}
            onClick={() => setSelectedPackage(id)}
            $isSelected={selectedPackage === id}
            mt={index !== 0 ? rem(30) : undefined}
            mb={rem(30)}
          >
            <Box>
              <H3 mb={rem(20)}>{title}</H3>
              <Text
                mb={rem(20)}
                maxWidth={rem(270)}
                as="p"
                color={COLORS.DEEP_RESERVOIR}
                fontSize="l"
              >
                {description}
              </Text>
              <Text as="p" fontWeight="600" fontSize="xxl">
                €{price} / {intervalCount !== 1 && intervalCount} {interval}
              </Text>
            </Box>
            <Box mt={{ mobile: rem(25), tablet: 0 }}>
              {checked.map((text, index) => (
                <CheckmarkText key={index} isFirst={index === 0} text={text} />
              ))}
            </Box>
          </Package>
        )
      )}

      <Button.Primary
        isBlock
        data-cy="btn-choose-package-modal"
        isLoading={loading}
        disabled={!selectedPackage}
        onClick={() => {
          onChoosePackage(selectedPackage)
        }}
      >
        Choose package
      </Button.Primary>
    </Box>
  )
}

ChooseSubscriptionModal.propTypes = {
  onChoosePackage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}
