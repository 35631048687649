import styled from '@emotion/styled'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import { ButtonSwitcher } from '@nextretreat/ui-components/dist/ButtonSwitcher'
import { Input } from '@nextretreat/ui-components/dist/Input'
import theme from '@nextretreat/ui-components/dist/Theme'
import { Field, Form, useFormikContext } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { LoadingWrapper } from 'components/LoadingWrapper'
import { CountrySelect, TaxInput } from '../../SubscriptionInputs'

const StyledForm = styled(Form)`
  width: 100%;

  & > *:not(:last-of-type) {
    margin-bottom: ${rem(20)};
  }
`

export const BillingDetailsFormFields = ({ loading }) => {
  const { isSubmitting, values, setFieldValue, resetForm } = useFormikContext()

  return (
    <StyledForm>
      <LoadingWrapper loading={loading}>
        <Flex $gap={rem(20)}>
          <Field name="firstName">
            {({ field, meta }) => (
              <Input
                isBlock
                required
                label="Your last name"
                placeholder="Enter name"
                invalid={meta.touched && meta.error !== undefined}
                {...field}
              />
            )}
          </Field>

          <Field name="lastName">
            {({ field, meta }) => (
              <Input
                isBlock
                required
                label="Your surname"
                placeholder="Enter name"
                invalid={meta.touched && meta.error !== undefined}
                {...field}
              />
            )}
          </Field>
        </Flex>

        <Field name="email">
          {({ field, meta }) => (
            <Input
              isBlock
              disabled
              required
              label="Email address"
              placeholder="Enter your email address"
              invalid={meta.touched && meta.error !== undefined}
              {...field}
            />
          )}
        </Field>
        <Field name="phoneNumber">
          {({ field, meta }) => (
            <Input
              isBlock
              required
              label="Phone number"
              placeholder="Enter phone number"
              invalid={meta.touched && meta.error !== undefined}
              {...field}
            />
          )}
        </Field>

        <Box>
          <Text
            as="h3"
            mb={rem(8)}
            fontWeight="700"
            fontSize={theme.fontSizes.xl}
            lineHeight={theme.lineHeights.xl}
          >
            Billing details
          </Text>
          <Text
            as="p"
            color={theme.COLORS.TXT_DEFAULT}
            fontSize={theme.fontSizes.m}
            lineHeight={theme.lineHeights.m}
          >
            Add or manage your personal information
          </Text>
        </Box>

        <ButtonSwitcher
          isBlock
          selectedValue={values.payAsCompany}
          onSelect={(val) => setFieldValue('payAsCompany', val)}
          options={[
            { label: 'Pay as company', value: true },
            { label: 'Pay as individual', value: false },
          ]}
        />

        {values.payAsCompany && (
          <>
            <Field name="company">
              {({ field, meta }) => (
                <Input
                  isBlock
                  required
                  label="Company name"
                  placeholder="Enter company"
                  invalid={meta.touched && meta.error !== undefined}
                  {...field}
                />
              )}
            </Field>

            <TaxInput
              setFieldValue={setFieldValue}
              name="taxId"
              taxIdType={values.taxId.type}
            />
          </>
        )}

        <Field name="address.line1">
          {({ field, meta }) => (
            <Input
              isBlock
              required
              label="Your name & surname"
              placeholder="Enter name"
              invalid={meta.touched && meta.error !== undefined}
              {...field}
            />
          )}
        </Field>
        <Field name="email">
          {({ field, meta }) => (
            <Input
              isBlock
              disabled
              required
              label="Email address"
              placeholder="Enter your email address"
              invalid={meta.touched && meta.error !== undefined}
              {...field}
            />
          )}
        </Field>
        <Field name="phoneNumber">
          {({ field, meta }) => (
            <Input
              isBlock
              required
              label="Phone number"
              placeholder="Enter phone number"
              invalid={meta.touched && meta.error !== undefined}
              {...field}
            />
          )}
        </Field>

        <Box>
          <Text
            as="h3"
            mb={rem(8)}
            fontWeight="700"
            fontSize={theme.fontSizes.xl}
            lineHeight={theme.lineHeights.xl}
          >
            Billing details
          </Text>
          <Text
            as="p"
            color={theme.COLORS.TXT_DEFAULT}
            fontSize={theme.fontSizes.m}
            lineHeight={theme.lineHeights.m}
          >
            Add or manage your personal information
          </Text>
        </Box>

        <ButtonSwitcher
          isBlock
          selectedValue={values.payAsCompany}
          onSelect={(val) => setFieldValue('payAsCompany', val)}
          options={[
            { label: 'Pay as company', value: true },
            { label: 'Pay as individual', value: false },
          ]}
        />

        {values.payAsCompany && (
          <>
            <Field name="company">
              {({ field, meta }) => (
                <Input
                  isBlock
                  required
                  label="Company name"
                  placeholder="Enter company"
                  invalid={meta.touched && meta.error !== undefined}
                  {...field}
                />
              )}
            </Field>

            <TaxInput
              setFieldValue={setFieldValue}
              name="taxId"
              taxIdType={values.taxId.type}
            />
          </>
        )}

        <Box>
          <Field name="address.line1">
            {({ field, meta }) => (
              <Input
                isBlock
                required
                label="Address"
                placeholder="Address line 1"
                invalid={meta.touched && meta.error !== undefined}
                {...field}
              />
            )}
          </Field>
          <Field name="address.line2">
            {({ field, meta }) => (
              <Box mt={rem(10)}>
                <Input
                  isBlock
                  placeholder="Address line 2"
                  invalid={meta.touched && meta.error !== undefined}
                  {...field}
                />
              </Box>
            )}
          </Field>
        </Box>

        <Flex $gap={rem(20)}>
          <Field name="address.postalCode">
            {({ field, meta }) => (
              <Box flex="1.2">
                <Input
                  isBlock
                  required
                  label="Post ZIP code"
                  placeholder="Zip code"
                  invalid={meta.touched && meta.error !== undefined}
                  {...field}
                />
              </Box>
            )}
          </Field>
          <Field name="address.city">
            {({ field, meta }) => (
              <Box flex="3">
                <Input
                  isBlock
                  required
                  label="City"
                  placeholder="Enter city"
                  invalid={meta.touched && meta.error !== undefined}
                  {...field}
                />
              </Box>
            )}
          </Field>
        </Flex>
        <Flex $gap={rem(20)}>
          <Field name="address.state">
            {({ field, meta }) => (
              <Box flex="1">
                <Input
                  isBlock
                  label="State"
                  placeholder="Enter state"
                  invalid={meta.touched && meta.error !== undefined}
                  {...field}
                />
              </Box>
            )}
          </Field>

          <Box flex="1">
            <CountrySelect name="address" />
          </Box>
        </Flex>

        <Flex $gap={rem(20)}>
          <Button.Primary type="submit" isLoading={isSubmitting}>
            Save changes
          </Button.Primary>

          <Button.Secondary
            onClick={resetForm}
            type="button"
            viewType={EButtonType.PLAIN}
          >
            Discard changes
          </Button.Secondary>
        </Flex>
      </LoadingWrapper>
    </StyledForm>
  )
}

BillingDetailsFormFields.propTypes = {
  loading: PropTypes.bool,
}
