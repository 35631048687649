import styled from '@emotion/styled'
import { useFormikContext } from 'formik'
import { rem } from 'polished'
import { mq } from 'Theme'
import { ReactComponent as DiscoverSVG } from 'assets/images/svg/discover.svg'
import { ReactComponent as PlanningSVG } from 'assets/images/svg/planning.svg'
import { ReactComponent as SupportSVG } from 'assets/images/svg/support.svg'
import { Box, Flex } from 'components/atoms/Layout'
// import { DEMO_TRIP_ID } from 'constants/constants'
import { PRIVATE_ROUTES } from 'constants/routes'
import { SegmentationBox } from './SegmentationBox'
import {
  SegmentationDescription,
  SegmentationTitle,
} from './SegmentationComponents'

export const FIRST_LOGIN_SEGMENTS = [
  {
    title: 'Discover destination and venues',
    description:
      'Find the most suitable destination and venues according to your team location and needs',
    icon: <DiscoverSVG />,
    event: 'user-segmentation-discover',
    route: PRIVATE_ROUTES.PLAN,
    buttons: [
      {
        text: 'Discover',
        type: 'primary',
      },
    ],
  },
  {
    title: 'Plan my trip',
    description: 'Start planning your trip right away',
    icon: <PlanningSVG />,
    event: 'user-segmentation-trip',
    route: `${PRIVATE_ROUTES.MY_TRIPS}?createTrip=true`,
    buttons: [
      // {
      //   text: 'Show demo trip',
      //   type: 'secondary',
      //   buttonRoute: `${PRIVATE_ROUTES.TRIP}/${DEMO_TRIP_ID}`,
      // },
      {
        text: 'Plan',
        type: 'primary',
      },
    ],
  },

  {
    title: 'Ask for assistance',
    description:
      'You are not sure how to start planning? Our Retreat Specialists are ready to help you out',
    icon: <SupportSVG />,
    event: 'user-segmentation-concierge',
    route: PRIVATE_ROUTES.CONCIERGE,
    buttons: [
      {
        text: 'Ask',
        type: 'primary',
      },
    ],
  },
]

const StyledFlex = styled(Flex)`
  gap: ${rem(24)};
  ${mq.to.tablet`gap: 12px;`}
`

export const JourneyStartStep = () => {
  const { setFieldValue, submitForm } = useFormikContext()

  return (
    <>
      <SegmentationTitle text="How would you like to start your journey?" />

      <SegmentationDescription text="We can help you get started faster" />

      <Box px={rem(16)} mb={rem(24)} width="100%" maxWidth={rem(864)}>
        <StyledFlex
          flexDirection={{ mobile: 'column', tablet: 'row' }}
          $gap={rem(24)}
        >
          {FIRST_LOGIN_SEGMENTS.map(({ title, ...passingProps }) => (
            <SegmentationBox
              key={title}
              title={title}
              {...passingProps}
              onClick={(route) => {
                setFieldValue('purpose', title)
                setFieldValue('route', route)
                submitForm()
              }}
            />
          ))}
        </StyledFlex>
      </Box>
    </>
  )
}
