import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'

const WrapBox = styled(Box)`
  border-radius: 8px;
  background-color: ${theme.COLORS.BG_SOFT};
  padding: ${rem(16)};

  ${({ $canDrop }) =>
    $canDrop && `background-color: ${theme.COLORS.BG_DISABLED};`}
`

const BoxItem = styled(Flex)`
  background-color: ${theme.COLORS.WHITE};
  border-radius: 4px;
  padding: ${rem(8)} ${rem(12)};
  align-items: center;
  gap: 4px;
  cursor: move;
  color: ${theme.COLORS.TXT_DEFAULT};
  &:not(:last-of-type) {
    margin-bottom: ${rem(8)};
  }
`

const Item = ({ id, name, column }) => {
  const [, drag] = useDrag({
    type: 'item',
    item: { id, column },
  })

  return (
    <BoxItem ref={drag}>
      <Icon name="drag_indicator" />
      {name}
    </BoxItem>
  )
}

Item.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  column: PropTypes.string.isRequired,
}

export const PermissionsColumn = ({ title, column, items, moveItem }) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'item',
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item) => moveItem(item, column),
  }))

  return (
    <WrapBox $canDrop={canDrop && isOver} flex="1" ref={drop}>
      <Text
        as="p"
        mb={rem(16)}
        fontWeight="700"
        fontSize={theme.fontSizes.l}
        lineHeight={theme.lineHeights.l}
      >
        {title}
      </Text>

      {items.map(({ id, name }) => (
        <Item key={id} id={id} name={name} column={column} />
      ))}
    </WrapBox>
  )
}

PermissionsColumn.propTypes = {
  title: PropTypes.string.isRequired,
  column: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  moveItem: PropTypes.func.isRequired,
}
