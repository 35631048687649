import { createContext, useMemo, useState } from 'react'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useAuth } from 'AuthProvider'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { EmailAvatar } from 'components/Avatar'
import Device from 'components/Device'
import Modal from 'components/Modal'
import { PRIVATE_ROUTES } from 'constants/routes'
import { Atendees } from './steps/Atendees/Atendees'
import { BasicInformation } from './steps/BasicInformation'
import { TripCollaboration } from './steps/TripCollaboration/TripCollaboration'
import { Venues } from './steps/Venues/Venues'
import { OnboargingLayout } from './OnboardingLayout'
import { ONBOARDING_STEP_NAMES, OnboardingStepper } from './OnboardingStepper'
import { SuccessCallout } from './SuccessMessage'

export const StepContext = createContext({
  setActiveStep: () => {},
  activeStep: null,
})

export const TripOnboardingModal = ({ closeModal, isOpen }) => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const [createdTripId, setCreatedTripId] = useState(null)
  const [requirements, setRequirements] = useState({})
  const [activeStep, setActiveStep] = useState(ONBOARDING_STEP_NAMES.BASIC_INFO)

  const steps = [
    {
      name: ONBOARDING_STEP_NAMES.BASIC_INFO,
      component: (
        <BasicInformation
          setCreatedTripId={setCreatedTripId}
          setRequirements={setRequirements}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      name: ONBOARDING_STEP_NAMES.VENUES,
      component: (
        <Venues
          tripId={createdTripId}
          requirements={requirements}
          setActiveStep={setActiveStep}
          closeModal={closeModal}
        />
      ),
    },
    {
      name: ONBOARDING_STEP_NAMES.COLLABORATION,
      component: (
        <TripCollaboration
          tripId={createdTripId}
          tripName={requirements.tripName}
        />
      ),
    },
    {
      name: ONBOARDING_STEP_NAMES.ATENDEES,
      component: (
        <Atendees tripId={createdTripId} tripName={requirements.tripName} />
      ),
    },
    {
      name: ONBOARDING_STEP_NAMES.SUCCESS,
      component: (
        <SuccessCallout
          onSuccessClick={() => {
            navigate(`/${PRIVATE_ROUTES.TRIP}/${createdTripId}`)
            closeModal()
          }}
        />
      ),
    },
  ]

  const contextValue = useMemo(
    () => ({
      activeStep,
      setActiveStep,
      closeModal,
    }),
    [activeStep]
  )

  return isOpen ? (
    <Device sizes={['desktop', 'tv']}>
      <Modal
        isOpen={isOpen}
        ariaLabel={requirements?.tripName || 'Trip onboarding'}
        closeModal={closeModal}
        isFullscreen
        title={
          <>
            <Text
              as="p"
              fontWeight="700"
              fontSize="l"
              textAlign={{ mobile: 'left', tablet: 'center' }}
              color={theme.COLORS.TXT_MAIN}
            >
              {requirements?.tripName || 'Trip onboarding'}
            </Text>

            <Flex
              justifyContent="flex-end"
              alignItems="center"
              flex={{ mobile: 0.5, tablet: 1 }}
            >
              <EmailAvatar url={user.avatarUrl} size={35} />

              <Text color={theme.COLORS.TXT_MAIN} mx="s">
                {user.email}
              </Text>
            </Flex>
          </>
        }
        hasCloseButton={false}
      >
        <StepContext.Provider value={contextValue}>
          <OnboargingLayout
            stepper={
              <OnboardingStepper
                tripId={createdTripId}
                activeStep={activeStep}
                closeModal={closeModal}
              />
            }
          >
            {steps.find((step) => step.name === activeStep)?.component}
          </OnboargingLayout>
        </StepContext.Provider>
      </Modal>
    </Device>
  ) : null
}

TripOnboardingModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
}
