import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { Flex } from 'components/atoms/Layout'
import { Callout } from 'components/Callout'
import { Icon } from 'components/Icon'

const StyledCallout = styled(Callout)`
  & > div:first-of-type {
    width: ${rem(80)};
    height: ${rem(80)};
  }

  & h1 {
    margin-bottom: ${rem(16)};
    margin-top: ${rem(32)};
  }
`

export const SuccessCallout = ({ onSuccessClick }) => (
  <Flex
    minHeight="100%"
    justifyContent="center"
    alignItems="center"
    data-cy="callout-flex"
  >
    <StyledCallout
      icon={
        <Icon
          name="check"
          fontSize={rem(32)}
          color={theme.COLORS.SUCCESS_DEFAULT}
        />
      }
      size="small"
      title="Trip has been created!"
      text={`Now you can access trip dashboard, create surveys, plan team activities or\n fill up missing information needed for your trip.`}
      buttonText="Go to trip dashboard"
      onClick={onSuccessClick}
      timer={5}
    />
  </Flex>
)

SuccessCallout.propTypes = {
  onSuccessClick: PropTypes.func.isRequired,
}
