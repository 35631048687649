import { useAuth } from 'AuthProvider'
import Avatar from 'boring-avatars'
import PropTypes from 'prop-types'

export const BoringAvatar = ({ email, size = 35 }) => {
  const { user } = useAuth()

  return (
    <Avatar
      size={size}
      name={email || user.email}
      variant="beam"
      colors={['#D4CDC5', '#4492BB', '#E3ECFB', '#0F264E', '#175EDC']}
    />
  )
}

BoringAvatar.propTypes = {
  size: PropTypes.number,
  email: PropTypes.string,
}
