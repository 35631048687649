import styled from '@emotion/styled'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useAuth } from 'AuthProvider'
import { rem } from 'polished'
import { useLocation } from 'react-router-dom'
import { mq } from 'Theme'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Callout } from 'components/Callout'
import { Icon } from 'components/Icon'
import { LoadingWrapper } from 'components/LoadingWrapper'
import Modal, { useModal } from 'components/Modal'
import { ProgressBar } from 'components/ProgressBar'
import { PRIVATE_ROUTES } from 'constants/routes'
import { shouldForwardPropOptions } from 'utils/helpers'
import { local } from 'utils/storage'
import { GamificationItems } from './GamificationItems'
import { useGamificationData } from './GamificationProvider'

const LOCAL_STORAGE_GAMIFICATION_CLOSED = 'is_gamification_closed'
const GAMIFICATION_ITEMS = [
  'firstTeamMember',
  'firstTodo',
  'firstTrip',
  'firstVenueToTrip',
]

const StyledDiscoverButton = styled(Button.Primary, shouldForwardPropOptions)`
  position: fixed;
  bottom: ${rem(16)};
  left: ${rem(16)};
  background-color: ${theme.COLORS.BG_DARK};
  border-radius: 54px;
  ${({ $isOpen }) => $isOpen && ` z-index: 100;`}
`

const HeaderFlex = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: ${rem(16)} ${rem(24)};
  border-bottom: 1px solid ${theme.COLORS.BG_DIVIDER};
`

const StyledModal = styled(Modal)`
  ${mq.from.tablet`position: fixed;
  bottom: ${rem(70)};
  left: ${rem(16)};
  border-radius: 12px;`}
`

const StyledMiddleBox = styled(Box)`
  padding: ${rem(16)} ${rem(24)};
  border-bottom: 1px solid ${theme.COLORS.BG_DIVIDER};
`

const StyledProgressBar = styled(ProgressBar)`
  height: ${rem(8)};
`

const StyledCallout = styled(Callout)`
  & > h1 {
    font-size: ${theme.fontSizes.l};
    margin-top: ${rem(12)};
  }
`

export const GamificationModal = () => {
  const { isOpen, openModal, closeModal } = useModal()
  const { refetch, data, loading } = useGamificationData()
  const { userTrips } = useAuth()
  const location = useLocation()

  const onOpenModal = async () => {
    await refetch()
    openModal()
  }

  const doneItems = Object.keys(data || {}).filter(
    (key) => data[key] === true && GAMIFICATION_ITEMS.includes(key)
  ).length

  return !local.getItem(LOCAL_STORAGE_GAMIFICATION_CLOSED) &&
    !location.pathname.includes(PRIVATE_ROUTES.MY_TRIPS) &&
    location.pathname.includes(PRIVATE_ROUTES.TRIP) ? (
    <>
      <StyledDiscoverButton
        $isOpen={isOpen}
        isLoading={loading}
        iconLeft={<Icon name="check_circle" />}
        onClick={!isOpen ? onOpenModal : closeModal}
      >
        Discover NextRetreat
      </StyledDiscoverButton>

      <StyledModal
        contentWidth="400px"
        preventScroll={false}
        isOpen={isOpen}
        closeModal={closeModal}
        ariaLabel="Gamification"
        hasCloseButton={false}
      >
        {doneItems === GAMIFICATION_ITEMS.length ? (
          <StyledMiddleBox>
            <StyledCallout
              icon={
                <Icon
                  name="check"
                  fontSize="24px"
                  color={theme.COLORS.SUCCESS_DEFAULT}
                />
              }
              text="You’ve successfully set your first team trip. No you can continue and add trip activities and plan other stuff you’ll need."
              size="small"
              timer={0}
              title="Congratulations! You’re all set!"
            />
          </StyledMiddleBox>
        ) : (
          <>
            <HeaderFlex>
              <Text as="p" m={0} fontWeight="700">
                Get started with NextRetreat app
              </Text>

              <Icon name="expand_more" onClick={closeModal} />
            </HeaderFlex>

            <StyledMiddleBox>
              <Text as="p" mb={rem(12)}>
                It’s a breeze. Simply follow the steps below, and your team will
                be ready to go in no time.
              </Text>

              <Flex alignItems="center">
                <StyledProgressBar
                  hideLabel
                  bgColor={theme.COLORS.BRAND_DEFAULT}
                  end={GAMIFICATION_ITEMS.length}
                  current={doneItems}
                />

                <Text as="p" m={0} ml={rem(16)}>
                  {((doneItems / GAMIFICATION_ITEMS.length) * 100).toFixed(0)}%
                </Text>
              </Flex>
            </StyledMiddleBox>
          </>
        )}
        <StyledMiddleBox>
          <LoadingWrapper loading={loading}>
            <GamificationItems
              firstTripId={userTrips?.[0]?.id}
              gamificationData={data}
              closeModal={closeModal}
            />
          </LoadingWrapper>
        </StyledMiddleBox>
        <Flex
          px={rem(24)}
          py={rem(16)}
          alignItems="center"
          justifyContent={
            doneItems === GAMIFICATION_ITEMS.length ? 'center' : 'flex-end'
          }
        >
          {doneItems !== GAMIFICATION_ITEMS.length ? (
            <Button.Secondary
              onClick={() => {
                local.setItem(LOCAL_STORAGE_GAMIFICATION_CLOSED, true)
                closeModal()
              }}
              viewType={EButtonType.PLAIN}
            >
              No thanks
            </Button.Secondary>
          ) : (
            <Button.Primary
              onClick={() => {
                closeModal()
                local.setItem(LOCAL_STORAGE_GAMIFICATION_CLOSED, true)
              }}
            >
              Finish guide
            </Button.Primary>
          )}
        </Flex>
      </StyledModal>
    </>
  ) : null
}
