import React, { useEffect, useState } from 'react'
import { ApolloLink } from '@apollo/client'
import { rem } from 'polished'
import { useLocation } from 'react-router-dom'
import Button from 'components/atoms/Button'
import { Box } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Modal, { useModal } from 'components/Modal'
import meta from '../../meta.json'

const REFRESH_MODAL_EVENT_KEY = 'openRefreshModalEvent'

export const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    // Access headers from the response object

    const { headers } = operation.getContext().response

    // check if build happened more than 15 min ago
    const buildAt = new Date(+headers.get('nr-build-at'))
    const now = new Date()
    const diff = now.getTime() - buildAt.getTime()
    const diffInMinutes = Math.round(diff / 60000)

    if (
      headers.get('nr-version')?.localeCompare(meta.version, undefined, {
        numeric: true,
        sensitivity: 'base',
      }) > 0 &&
      diffInMinutes > 15
    ) {
      const event = new CustomEvent(REFRESH_MODAL_EVENT_KEY)
      window.dispatchEvent(event)
    } // Dispatch the event

    // Do something with the headers or the response

    return response
  })
)

const MINUTES_TO_INACTIVE = 60

const RefreshModal = () => {
  const { isOpen, openModal } = useModal()
  const { pathname } = useLocation()

  const [reason, setReason] = useState(null)

  let timeout = null

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(
      () => {
        setReason('inactive')
        openModal()
      },
      1000 * 60 * MINUTES_TO_INACTIVE
    )
  }

  const onMouseMove = () => {
    restartAutoReset()
  }

  const onVersionChange = () => {
    setReason('version')
    openModal()
  }

  const removeEventListeners = () => {
    window.removeEventListener('mousemove', onMouseMove)
    window.removeEventListener(REFRESH_MODAL_EVENT_KEY, onVersionChange)
  }

  useEffect(() => {
    // initiate timeout
    restartAutoReset()

    // listen for mouse events
    window.addEventListener('mousemove', onMouseMove)

    // cleanup
    return () => {
      if (timeout) {
        clearTimeout(timeout)
        removeEventListeners()
      }
    }
  }, [pathname])

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'development') return
    window.addEventListener(REFRESH_MODAL_EVENT_KEY, onVersionChange)

    return () => removeEventListeners()
  }, [])

  const label = reason === 'inactive' ? 'Inactivity check' : 'New version'

  return (
    <Modal
      hasCloseButton={false}
      isOpen={isOpen}
      closeModal={() => {}}
      contentWidth={rem(550)}
      ariaLabel={label}
      title={label}
    >
      <Box px={rem(20)} py={rem(24)}>
        <Text as="p" mb="m">
          {reason === 'inactive'
            ? `You have been inactive for more than ${MINUTES_TO_INACTIVE} minutes. To have fresh data please reload the application.`
            : 'There is a new and better version of the app. Please reload the application.'}
        </Text>

        <Button.Primary isBlock onClick={() => window.location.reload()}>
          Reload
        </Button.Primary>
      </Box>
    </Modal>
  )
}

export default RefreshModal
