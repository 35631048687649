import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { differenceInDays, isValid, parseISO } from 'date-fns'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { TripMutations } from 'api/Trip/TripMutations'
import { Box } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { useCreateTrip } from 'hooks/trip/useCreateTrip'
import CreateTripStepper from 'sharedComponents/ModalManagement/CreateTripModal/CreateTripStepper'
import { toast } from 'utils/helpers'
import { NavigationBox } from '../NavigationBox'
import { OnboardingHeading } from '../OnboardingLayout'
import { findNextStepName, ONBOARDING_STEP_NAMES } from '../OnboardingStepper'
import { StepContext } from '../TripOnboarding'

const StyledCreateTripStepper = styled(CreateTripStepper)`
  max-width: unset;
  width: 100%;
  background-color: ${theme.COLORS.BG_SOFT};

  & > div > div,
  & > div > div > div {
    margin-left: 0 !important;
  }

  & > div {
    background-color: ${theme.COLORS.BG_SOFT} !important;
  }

  & > div:first-of-type > div:first-of-type > div:first-of-type {
    margin-bottom: ${rem(8)};
  }
`

const StyledWrap = styled(Box)`
  & > form {
    padding: ${rem(16)} 0 ${rem(20)} !important;
  }
`

export const BasicInformation = ({ setCreatedTripId, setRequirements }) => {
  const { setActiveStep } = useContext(StepContext)
  const [createTrip] = useCreateTrip()

  const [saveTripRequirements, { loading }] = useMutation(
    TripMutations.SAVE_TRIP_REQUIREMENTS
  )

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)

      const response = await createTrip({
        variables: {
          input: { name: values.name },
        },
      })

      if (response?.data?.createTrip) {
        const tripId = response?.data?.createTrip.id
        const startDate = values.date?.value?.startDate
        const endDate = values.date?.value?.endDate

        const saveResponse = await saveTripRequirements({
          variables: {
            input: {
              destination: {
                destination:
                  (values.destination.fromList ||
                    values.destination.specific) &&
                  values.destination.value
                    ? values.destination.value
                    : undefined,
                fromList: !!values.destination.fromList,
                specific: !!values.destination.specific,
                helpNeeded: !!values.destination.helpNeeded,
                departingPoints: values.destination.value?.departingPoints,
                transport: values.destination.value?.transport,
                idealDestinationFixProperties:
                  values.destination.value?.idealDestinationFixProperties,
                idealDestinationOtherProperties:
                  values.destination.value?.idealDestinationOtherProperties,
              },
              team: {
                teamSize: values.teamSize ? +values.teamSize : undefined,
              },
              date: {
                period: !!values.date.period,
                exactDate: !!values.date.exactDate,
                startDate: !startDate
                  ? undefined
                  : isValid(startDate)
                  ? startDate
                  : parseISO(startDate),
                endDate: !endDate
                  ? undefined
                  : isValid(endDate)
                  ? endDate
                  : parseISO(endDate),
              },
              budget: {
                maximumBudget: values.budget.maximumBudget || undefined,
                minimumBudget: values.budget.minimumBudget || undefined,
              },
            },
            tripId,
          },
        })

        if (saveResponse.data.saveTripRequirements) {
          setSubmitting(false)
          setCreatedTripId(tripId)

          const { maximumBudget } = values.budget
          const { minimumBudget } = values.budget

          setRequirements({
            fullBudget:
              maximumBudget && minimumBudget
                ? (minimumBudget + maximumBudget) / 2
                : minimumBudget
                ? minimumBudget + 200
                : maximumBudget - 100,
            teamSize: values.teamSize,
            dayCount:
              values.date?.exactDate && startDate && endDate
                ? differenceInDays(new Date(endDate), new Date(startDate))
                : 3,
            destination: values.destination.fromList
              ? values.destination.value || ''
              : '',
            startDate: values.date?.exactDate && startDate,
            endDate: values.date?.exactDate && endDate,
            tripName: values.name,
          })

          toast.success('Trip successfully created!')
          setActiveStep(findNextStepName(ONBOARDING_STEP_NAMES.BASIC_INFO))
        }
      }
    } catch {
      // do nothing
    }
  }

  return (
    <Box pb={rem(20)}>
      <OnboardingHeading>Basic information</OnboardingHeading>

      <Text as="p" color={theme.COLORS.TXT_DEFAULT}>
        Fill out the basic details for your trip to help us navigate you trough
        the planning process. You will receive recommendations and tips.
      </Text>

      <StyledWrap>
        <StyledCreateTripStepper
          hideCircle
          hideSubmit
          endContent={<NavigationBox isLoading={loading} />}
          onSubmit={onSubmit}
        />
      </StyledWrap>
    </Box>
  )
}

BasicInformation.propTypes = {
  setCreatedTripId: PropTypes.func.isRequired,
  setRequirements: PropTypes.func.isRequired,
}
