import { rem } from 'polished'
import PropTypes from 'prop-types'
import Button from './atoms/Button'
import { Box, Flex } from './atoms/Layout'
import { Text } from './atoms/Typography'
import Modal, { useModal } from './Modal'

export const Confirmation = ({
  customMinWidth,
  targetLabel,
  label,
  buttonLabel,
  type = 'submit',
  targetComp: TargetComp,
  onConfirm,
  isLoading,
  onOpen,
  onClose,
  ...passingProps
}) => {
  const { isOpen, openModal, closeModal } = useModal()

  const onOpenModal = () => {
    onOpen?.()
    openModal()
  }

  const onCloseModal = () => {
    onClose?.()
    closeModal()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeModal={onCloseModal}
        ariaLabel={label}
        contentWidth={rem(600)}
        {...passingProps}
      >
        <Box p={`${rem(16)} ${rem(24)}`}>
          <Text
            display="block"
            fontSize="l"
            fontWeight="700"
            textAlign="center"
          >
            {label}
          </Text>
          <Flex
            alignItems="center"
            justifyContent="center"
            mt="m"
            $gap={rem(12)}
          >
            <Button.Tertiary onClick={onCloseModal}>Cancel</Button.Tertiary>
            {type === 'delete' ? (
              <Button.Negative
                data-cy="btn-confirm"
                onClick={onConfirm}
                isLoading={isLoading}
              >
                {buttonLabel || targetLabel}
              </Button.Negative>
            ) : (
              <Button.Primary
                data-cy="btn-confirm"
                onClick={onConfirm}
                isLoading={isLoading}
              >
                {buttonLabel || targetLabel}
              </Button.Primary>
            )}
          </Flex>
        </Box>
      </Modal>

      <TargetComp type="button" onClick={onOpenModal}>
        {targetLabel}
      </TargetComp>
    </>
  )
}

Confirmation.propTypes = {
  customMinWidth: PropTypes.string,
  label: PropTypes.string.isRequired,
  targetComp: PropTypes.any.isRequired,
  targetLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonLabel: PropTypes.string,
  type: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
}
