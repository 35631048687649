import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import PropTypes from 'prop-types'
import Button from 'components/atoms/Button'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { shouldForwardPropOptions } from 'utils/helpers'

const StyledButton = styled(Button.Primary, shouldForwardPropOptions)`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  padding: 10px;
  & span {
    color: ${theme.COLORS.BRAND_DEFAULT};
  }

  &:hover span {
    color: white;
  }

  ${({ $primary }) =>
    !$primary &&
    `background-color: ${theme.COLORS.BRAND_LIGHT}; 
    color: ${theme.COLORS.WHITE};


    & path {
      fill: ${theme.COLORS.BRAND_DEFAULT};
    }
    `}
`

export const CircleButtonWithText = ({
  title,
  icon,
  primary,
  onClick,
  ...passingProps
}) => (
  <Flex flex="1" flexDirection="column" $gap="8px" alignItems="center">
    <StyledButton $primary={primary} onClick={onClick} {...passingProps}>
      {icon}
    </StyledButton>
    <Text
      textAlign="center"
      fontSize="s"
      fontWeight="500"
      color={theme.COLORS.TXT_DEFAULT}
    >
      {title}
    </Text>
  </Flex>
)

CircleButtonWithText.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
}
