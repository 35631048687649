import React, { forwardRef, useMemo } from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useAuth } from 'AuthProvider'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import { useNavigate } from 'react-router-dom'
import { mq } from 'Theme'
import arrowRightIcon from 'assets/images/svg/arrow-right.svg'
import heartFilledIcon from 'assets/images/svg/heart-filled.svg'
import tentCircleIcon from 'assets/images/svg/tent-circle.svg'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'
import { LAST_OPENED_TRIPS } from 'constants/constants'
import { PRIVATE_ROUTES } from 'constants/routes'
import { useOpenCreateTripModal } from 'hooks/trip/useOpenCreateTripModal'
import TripItem from 'sharedComponents/ModalManagement/TripItem'
import TripPopover from 'sharedComponents/ModalManagement/TripPopover'
import RouterLink from 'sharedComponents/RouterLink'
import { local } from 'utils/storage'
import { MiddleButton, StyledArrowheadIcon } from './Header'
import { Border } from './UserSignedMenu'

const StyledButton = forwardRef(({ isActive, isOutlined, ...props }, ref) => (
  <MiddleButton
    to="/my-trips"
    {...props}
    ref={ref}
    data-cy="btn-header-my-trips"
  >
    <Icon name="airplanemode_active" />
    <div>My trips</div>
  </MiddleButton>
))

StyledButton.propTypes = {
  isActive: PropTypes.bool,
  isOutlined: PropTypes.bool,
}

const StyledLink = styled(RouterLink)`
  width: 100%;
  text-align: center;
  padding: 12px;
  border-top: 1px solid ${theme.COLORS.BG_DIVIDER};
  color: ${theme.COLORS.BRAND_DEFAULT};
  font-weight: 700;
  margin-top: 6px;

  ${mq.to.tablet`
    text-align: left;
    font-weight: 510;
    font-size: 16px;
    padding: 20px 16px;
    padding-top: 4px;
    margin-top: 0;
    border-top: none;
  `}
`

const ContentWrapper = styled(Flex)`
  flex: 1;
  position: relative;
  overflow: hidden;
  height: 100%;
`

const Content = styled(Flex)`
  flex-direction: column;
  flex: 1;
  overflow: auto;

  padding-top: 6px;

  ${mq.from.tablet`
  max-height: 70vh;
  `}
`

const Trips = ({
  customTargetComponent,
  onOpen,
  onCloseSideMenu,
  closeMenu,
}) => {
  const matches = useMediaQuery()
  const { userTrips, areTripsLoading } = useAuth()
  const openTripModal = useOpenCreateTripModal()
  const navigate = useNavigate()

  const trips = useMemo(() => {
    const lastOpenedTripIds = JSON.parse(local.getItem(LAST_OPENED_TRIPS)) || []

    const sortedTrips = userTrips
      .filter(({ id }) => !lastOpenedTripIds.includes(id))
      .sort((a, b) => a.id - b.id)

    const lastOpenedTrips = userTrips
      .filter(({ id }) => lastOpenedTripIds.includes(id))
      .sort(
        (a, b) =>
          lastOpenedTripIds.indexOf(a.id) - lastOpenedTripIds.indexOf(b.id)
      )

    return [...lastOpenedTrips, ...sortedTrips].slice(0, 5)
  }, [userTrips, local.getItem(LAST_OPENED_TRIPS)])

  return !areTripsLoading ? (
    <TripPopover
      placement="bottom"
      targetComp={customTargetComponent || StyledButton}
      label={
        <div data-cy="header-trips">
          <img src={heartFilledIcon} alt="heartFilled" />
          <Text color={theme.COLORS.TXT_MAIN} mx="s">
            {!matches.mobile && 'Saved Trips '}({userTrips?.length})
          </Text>
          <StyledArrowheadIcon />
        </div>
      }
      onCancel={() => {
        onCloseSideMenu?.()
      }}
      onOpen={onOpen}
    >
      {(closeModal) => (
        <ContentWrapper>
          <Content>
            <TripItem
              onClick={() => {
                closeModal()

                openTripModal()
              }}
              primaryText="Create New Trip"
              icon={<Icon name="add" color={theme.COLORS.BRAND_DEFAULT} />}
              dataCy="btn-create-new-trip"
            />
            {trips.map((trip) => (
              <TripItem
                key={trip.id}
                onClick={() => {
                  navigate(`/${PRIVATE_ROUTES.TRIP}/${trip.id}#dashboard`)
                  closeModal()
                  closeMenu?.()
                }}
                primaryText={trip.data.name}
                imageSrc={trip.data.thumbnail || tentCircleIcon}
                actionIconSrc={arrowRightIcon}
              />
            ))}

            {matches.mobile && (
              <Box px="16px">
                <Border />
              </Box>
            )}
            <StyledLink onClick={closeMenu} to="/my-trips">
              Show all trips
            </StyledLink>
          </Content>
        </ContentWrapper>
      )}
    </TripPopover>
  ) : null
}

Trips.propTypes = {
  closeMenu: PropTypes.func,
  onCloseSideMenu: PropTypes.func,
  onOpen: PropTypes.func,
  customTargetComponent: PropTypes.object,
}

export default Trips
