import { useEffect, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { ButtonSwitcher } from '@nextretreat/ui-components/dist/ButtonSwitcher'
import { CardElement } from '@stripe/react-stripe-js'
import { useFormikContext } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { COLORS } from 'Theme'
import { AccountQueries } from 'api/Account/AccountQueries'
import Button from 'components/atoms/Button'
import { Box } from 'components/atoms/Layout'
import { ErrorMessage, FakeLink, H3, Text } from 'components/atoms/Typography'
import { Label } from 'components/Input/Input'
import { capitalize, parseStripePrice } from 'utils/helpers'
import { SummaryTable } from './SummaryTable'

export const stripeElementOptions = {
  classes: {
    base: 'stripeInputElement',
  },
  style: {
    base: {
      fontSize: '16px',
    },
  },
  hidePostalCode: true,
}

export const Payment = ({
  priceId,
  paymentMethod,
  showCardInput,
  onShowClick,
}) => {
  const timeout = useRef()
  const { values, isSubmitting, setFieldValue } = useFormikContext()
  const [timeoutLoading, setTimeoutLoading] = useState()

  const [fetchPrices, { data, loading, error }] = useLazyQuery(
    AccountQueries.CALCULATE_PRICES
  )

  const handleFetch = () => {
    fetchPrices({
      variables: {
        input: {
          billingDetails: {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            taxIds: values.payAsCompany ? [values.taxId] : [],
            company: values.payAsCompany ? values.company : '',
            address: {
              line1: values?.line1,
              line2: values?.line2,
              postalCode: values?.postalCode,
              city: values?.city,
              state: values?.state,
              country:
                typeof values?.country === 'string'
                  ? values.country
                  : values?.country?.value?.code,
              taxId: undefined,
            },
          },
          priceId,
          coupon: values.hasCoupon && values.coupon ? values.coupon : undefined,
        },
      },
    })
    setTimeoutLoading(false)
  }

  useEffect(() => {
    timeout.current = setTimeout(() => handleFetch(), 0)
  }, [])

  useEffect(() => {
    setTimeoutLoading(true)
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => handleFetch(), 1000)

    return () => clearTimeout(timeout.current)
  }, [values.coupon])

  const invoice = data?.calculateUpcomingInvoice

  return (
    <>
      <Box my={rem(20)}>
        <ButtonSwitcher
          isBlock
          selectedValue={values.payWithInvoice}
          onSelect={(val) => setFieldValue('payWithInvoice', val)}
          options={[
            { label: 'Pay with card', value: false },
            { label: 'Pay with invoice', value: true },
          ]}
        />
      </Box>

      {!values.payWithInvoice && (
        <>
          <Label>Card number</Label>
          {paymentMethod && !showCardInput ? (
            <>
              <Text as="p" color={COLORS.DEEP_RESERVOIR} mb="s">
                {capitalize(paymentMethod.brand)} ending ****
                {paymentMethod.lastFour}
              </Text>
              <FakeLink
                fontWeight="600"
                fontSize="m"
                display="block"
                onClick={() => onShowClick(true)}
              >
                Edit payment method
              </FakeLink>
            </>
          ) : (
            <CardElement options={stripeElementOptions} />
          )}
        </>
      )}

      <H3 fontWeight="600" my={rem(20)}>
        Total
      </H3>

      <Box mb={rem(20)}>
        <SummaryTable
          rows={[
            {
              cells: [
                {
                  label: (
                    <>
                      <Text as="p" mb={rem(5)}>
                        Price:
                      </Text>
                      <Text as="p">
                        VAT
                        {invoice?.taxPercent ? ` (${invoice.taxPercent}%)` : ''}
                        :
                      </Text>
                      {invoice?.discount?.length > 0 &&
                        invoice?.discount.map((discount) => (
                          <Text mt={rem(5)} as="p">
                            Coupon: {discount.name}
                            {discount.percent_off
                              ? ` (${discount.percent_off}%)`
                              : null}
                          </Text>
                        ))}
                    </>
                  ),
                },
                {
                  label: (
                    <Text textAlign="right">
                      <Text as="p" mb={rem(5)}>
                        {invoice?.subtotal
                          ? parseStripePrice(invoice.subtotal)
                          : '0'}{' '}
                        €
                      </Text>
                      <Text as="p">
                        {invoice?.tax
                          ? `${parseStripePrice(invoice.tax)}`
                          : '0'}{' '}
                        €
                      </Text>
                      {invoice?.discount?.length > 0 &&
                        invoice?.discount.map((discount) => (
                          <Text mt={rem(5)} as="p">
                            -
                            {discount.amount_off
                              ? parseStripePrice(discount.amount_off)
                              : parseStripePrice(discount.amount)}{' '}
                            €
                          </Text>
                        ))}
                    </Text>
                  ),
                },
              ],
            },
            {
              cells: [
                {
                  label: <Text fontWeight="600">Total:</Text>,
                },
                {
                  label: (
                    <Text as="p" textAlign="right">
                      {invoice?.total ? parseStripePrice(invoice.total) : '0'} €
                    </Text>
                  ),
                },
              ],
            },
          ]}
        />
      </Box>

      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      <Button.Primary
        disabled={!!error || timeoutLoading}
        isLoading={loading || timeoutLoading || isSubmitting}
        type="submit"
        data-cy="btn-pay"
        isBlock
      >
        {values.payWithInvoice
          ? 'Request invoice'
          : `Pay ${
              invoice?.total ? `${parseStripePrice(invoice.total)}€` : '0 €'
            }`}
      </Button.Primary>
    </>
  )
}

Payment.propTypes = {
  priceId: PropTypes.string.isRequired,
  paymentMethod: PropTypes.object,
  showCardInput: PropTypes.bool,
  onShowClick: PropTypes.func,
}
