import { gql } from '@apollo/client'

export const AuthMutations = {
  CONFIRM: gql`
    mutation ConfirmAccount($token: String!) {
      confirmAccount(token: $token) {
        accessToken
        refreshToken
      }
    }
  `,

  REGISTRATION: gql`
    mutation Registration($input: RegistrationInput!) {
      registration(input: $input)
    }
  `,

  REQUEST_RESET_PASSWORD: gql`
    mutation RequestResetPassword($userName: String!) {
      requestResetPassword(userName: $userName)
    }
  `,

  SET_NEW_PASSWORD: gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
      resetPassword(input: $input)
    }
  `,

  REFRESH_TOKEN: gql`
    mutation RefreshToken($token: String!) {
      refreshToken(token: $token) {
        accessToken
        refreshToken
      }
    }
  `,

  STORE_GAMIFICATION_DATA: gql`
    mutation StoreGamificationData($step: String!) {
      storeGamificationData(step: $step)
    }
  `,

  SAVE_SEGMENTATION_DATA: gql`
    mutation SaveSegmentationData($input: SaveSegmentationDataInput!) {
      saveSegmentationData(input: $input)
    }
  `,

  RESEND_CONFIRMATION_EMAIL: gql`
    mutation ResendConfirmationEmail($email: String!) {
      resendConfirmationEmail(email: $email)
    }
  `,
}
