import { useMutation } from '@apollo/client'
import * as Yup from 'yup'
import { TripMutations } from 'api/Trip/TripMutations'
import { TripQueries } from 'api/Trip/TripQueries'

export const FORM_FIELD_NAMES = {
  NAME: 'name',
}

export const validationSchema = Yup.object({
  [FORM_FIELD_NAMES.NAME]: Yup.string().required('This field is required'),
})

export const useCreateTrip = () =>
  useMutation(TripMutations.CREATE_TRIP, {
    refetchQueries: ['GamificationData'],
    update: (cache, { data: newData }) => {
      if (newData.createTrip) {
        const query = {
          query: TripQueries.GET_ALL_TRIPS,
        }

        const data = cache.readQuery(query)

        if (data)
          cache.writeQuery({
            ...query,
            data: {
              ...data,
              userMe: {
                ...data.userMe,
                trips: [
                  ...data.userMe.trips,
                  {
                    id: newData.createTrip.id,
                    data: {
                      name: newData.createTrip.data?.name,
                      dateFrom: newData.createTrip.data?.dateFrom,
                      dateTo: newData.createTrip.data?.dateTo,
                      membersCount: 0,
                      venuesCount: newData.createTrip.tripVenues?.length || 0,
                      thumbnail: newData.createTrip.data?.heroUrl,
                      token: null,
                      createdAt: newData.createTrip.data?.createdAt,
                      __typename: 'TripListDetails',
                    },
                    __typename: 'TripListData',
                  },
                ],
              },
            },
          })
      }
    },
  })
