import React from 'react'
import PropTypes from 'prop-types'

export const MODAL_TYPES = {
  CREATE_MODAL: 'CREATE_MODAL',
  SETTINGS_MODAL: 'SETTINGS_MODAL',
  DEADLINES_MODAL: 'DEADLINES_MODAL',
  ADD_VENUE_MODAL: 'ADD_VENUE_MODAL',
  ADD_PROPOSAL_VENUE_MODAL: 'ADD_PROPOSAL_VENUE_MODAL',
  REMOVE_VENUE_MODAL: 'REMOVE_VENUE_MODAL',
  ASK_QUESTION_MODAL: 'ASK_QUESTION_MODAL',
  CHECK_AVAILABILITY_MODAL: 'CHECK_AVAILABILITY_MODAL',
  SUCCESS_FORM_REQUEST_MODAL: 'SUCCESS_FORM_REQUEST_MODAL',
  SELECT_VENUE_MODAL: 'SELECT_VENUE_MODAL',
  ACTIVITY_MODAL: 'ACTIVITY_MODAL',
  HISTORY_MODAL: 'HISTORY_MODAL',
  PREFERENCES_MODAL: 'PREFERENCES_MODAL',
  VOTERS_MODAL: 'VOTERS_MODAL',
  VOTING_MODAL: 'VOTING_MODAL',
  EXPENSE_MODAL: 'EXPENSE_MODAL',
  EDIT_GROUP_MODAL: 'EDIT_GROUP_MODAL',
  GROUP_SPLIT_MODAL: 'GROUP_SPLIT_MODAL',
  GROUPS_MERGE_MODAL: 'GROUPS_MERGE_MODAL',
  ADD_GROUP_MEMBER_MODAL: 'ADD_GROUP_MEMBER_MODAL',
  SEND_TEAM_MAIL_MODAL: 'SEND_TEAM_MAIL_MODAL',
  CUSTOM_VENUE_MODAL: 'CUSTOM_VENUE_MODAL',
  CALENDLY_MODAL: 'CALENDLY_MODAL',
  EMISSION_CALCULATIONS_MODAL: 'EMISSION_CALCULATIONS_MODAL',
  SMALL_CREATE_TRIP_MODAL: 'SMALL_CREATE_TRIP_MODAL',
  TODO_MODAL: 'TODO_MODAL',
  CONTACTS_MODAL: 'CONTACTS_MODAL',
  AUTO_GROUP_MODAL: 'AUTO_GROUP_MODAL',
  CREATE_TRIP_ONBOARDING: 'CREATE_TRIP_ONBOARDING',
  PERSONAL_DATA_MODAL: 'PERSONAL_DATA_MODAL',
  REVIEW_GROUP_MODAL: 'REVIEW_GROUP_MODAL',
  FEEDBACKS_MODAL: 'FEEDBACKS_MODAL',
  NEWS_DRAWER: 'NEWS_DRAWER',
  QUESTION_MODAL: 'QUESTION_MODAL',
}

const ModalManagementContext = React.createContext({
  openModal: () => {},
  closeModal: () => {},
  openedModals: () => {},
  addedVenueIds: [],
  setAddedVenueIds: () => {},
})

export const useModalManagement = () => React.useContext(ModalManagementContext)

const ModalManagementProvider = ({ children }) => {
  const [openedModals, setOpenedModals] = React.useState({})
  const [addedVenueIds, setAddedVenueIds] = React.useState([])

  const openModal = React.useCallback(({ type, ...rest }) => {
    setOpenedModals((previousOpenedModals) => ({
      ...previousOpenedModals,
      [type]: rest,
    }))
  }, [])

  const closeModal = React.useCallback((type) => {
    setOpenedModals((previousOpenedModals) =>
      Object.entries(previousOpenedModals)
        .filter(([previousType]) => previousType !== type)
        .reduce(
          (acc, [previousType, props]) => ({ ...acc, [previousType]: props }),
          {}
        )
    )
  }, [])

  const contextValue = React.useMemo(
    () => ({
      openedModals,
      openModal,
      closeModal,
      addedVenueIds,
      setAddedVenueIds,
    }),
    [openedModals, openModal, closeModal, addedVenueIds]
  )

  return (
    <ModalManagementContext.Provider value={contextValue}>
      {children}
    </ModalManagementContext.Provider>
  )
}

ModalManagementProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ModalManagementProvider
