import { useAuth } from 'AuthProvider'
import { Navigate, useLocation } from 'react-router-dom'
import { LoadingWrapper } from 'components/LoadingWrapper'
import { PRIVATE_ROUTES } from 'constants/routes'

const MyTrip = () => {
  const { search, hash } = useLocation()
  const { userTrips, areTripsLoading } = useAuth()

  const sortedTrips = userTrips?.sort((a, b) => b.id - a.id)

  return (
    <LoadingWrapper isSplashScreen loading={areTripsLoading}>
      <Navigate
        to={
          sortedTrips?.[0]?.id
            ? `/${PRIVATE_ROUTES.TRIP}/${sortedTrips[0].id}${search}${hash}`
            : PRIVATE_ROUTES.MY_TRIP
        }
      />
    </LoadingWrapper>
  )
}

export default MyTrip
