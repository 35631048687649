import { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useWindowWidth } from 'hooks/useWindowWidth'

const defaultValue = {
  mobile: undefined,
  tablet: undefined,
  desktop: undefined,
  tv: undefined,
  smallDevice: undefined,
  largeDevice: undefined,
}

const QueryContext = createContext(defaultValue)

export const useMediaQuery = () => useContext(QueryContext)

const QueryProvider = ({ children }) => {
  const { matches } = useWindowWidth()

  const value = useMemo(
    () =>
      matches
        ? {
            ...matches,
            smallDevice: matches.mobile || matches.tablet,
            largeDevice: matches.desktop || matches.tv,
          }
        : defaultValue,
    [matches]
  )

  return <QueryContext.Provider value={value}>{children}</QueryContext.Provider>
}

QueryProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default QueryProvider
