import { lazy, Suspense, useEffect } from 'react'
import styled from '@emotion/styled'
import { useAuth } from 'AuthProvider'
import { rem } from 'polished'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import nextRetreatLogo from 'assets/images/svg/logo-next-retreat.svg'
import { Flex } from 'components/atoms/Layout'
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'constants/routes'
import { toast } from 'utils/helpers'
import MyTrip from './MyTrip'

const RequestResetPassword = lazy(() =>
  import('routes/Auth/RequestResetPassword')
)
const AttendancePage = lazy(() =>
  import('routes/AttendancePage/AttendancePage')
)
const DemoAttendancePage = lazy(() =>
  import('routes/AttendancePage/DemoAttendancePage')
)
const SetNewPassword = lazy(() => import('routes/Auth/SetNewPassword'))
const Login = lazy(() => import('routes/Auth/Login'))
const Registration = lazy(() => import('routes/Auth/Registration'))
const Confirmation = lazy(() => import('routes/Auth/Confirmation'))
const NotFound = lazy(() => import('routes/NotFound'))
const InternalServerError = lazy(() => import('routes/InternalServerError'))
const Plan = lazy(() => import('routes/Plan'))
const Concierge = lazy(() => import('routes/Concierge'))
const MyTrips = lazy(() => import('routes/MyTrips'))
const Account = lazy(() => import('routes/Account'))
const Trip = lazy(() => import('routes/Trip'))
const TripShared = lazy(() => import('routes/TripShared'))
const GrantAccess = lazy(() => import('routes/GrantAccess'))

const Logo = styled('img')`
  width: ${rem('250px')};
  max-width: 80%;
  animation: pulse 2s infinite;
  transform: scale(1);

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.95);
    }
  }
`

const SplashScreen = styled(Flex)`
  width: 100vw;
  height: 100vh;
`

export const SplashScreenLoader = () => (
  <SplashScreen alignItems="center" justifyContent="center">
    <Logo src={nextRetreatLogo} />
  </SplashScreen>
)

const NoSubscriptionRedirect = () => {
  useEffect(() => {
    toast.warn('You do not have a valid subscription to access this page')
  }, [])

  return <Navigate to="/account#subscription" />
}

export const Router = () => {
  const { pathname, search, state, hash } = useLocation()
  const { isSignedIn, isSigningIn, user } = useAuth()

  return isSigningIn ? (
    <SplashScreenLoader />
  ) : (
    <Suspense fallback={<SplashScreenLoader />}>
      <Routes>
        <Route
          path={`/${PUBLIC_ROUTES.TRIP_SHARED}/:shareToken`}
          element={<TripShared />}
        />
        <Route
          path="/internal-server-error"
          element={<InternalServerError />}
        />
        <Route path={PUBLIC_ROUTES.ATTENDANCE} element={<AttendancePage />} />
        <Route
          path={PUBLIC_ROUTES.DEMO_ATTENDANCE}
          element={<DemoAttendancePage />}
        />
        <Route path={PUBLIC_ROUTES.GRANT_ACCESS} element={<GrantAccess />} />

        {isSignedIn ? (
          <>
            {user.isActive ? (
              <Route path={PRIVATE_ROUTES.PLAN} element={<Plan />} />
            ) : (
              <Route
                path={PRIVATE_ROUTES.PLAN}
                element={<NoSubscriptionRedirect />}
              />
            )}
            <Route path={PRIVATE_ROUTES.ACCOUNT} element={<Account />} />
            <Route path={PRIVATE_ROUTES.MY_TRIPS} element={<MyTrips />} />
            <Route path={PRIVATE_ROUTES.MY_TRIP} element={<MyTrip />} />
            <Route path={`/${PRIVATE_ROUTES.TRIP}/:id`} element={<Trip />} />
            <Route
              path={`/${PRIVATE_ROUTES.CONCIERGE}`}
              element={<Concierge />}
            />

            <Route
              path="login"
              element={<Navigate to={state?.path || '/plan'} />}
            />
            <Route
              path="registration"
              element={<Navigate to={state?.path || '/plan'} />}
            />
            <Route path="/" element={<Navigate to="my-trips" />} />
            <Route path="*" element={<NotFound />} />
          </>
        ) : (
          <>
            <Route path={PUBLIC_ROUTES.LOGIN} element={<Login />} />
            <Route
              path={PUBLIC_ROUTES.REGISTRATION}
              element={<Registration />}
            />
            <Route
              path={PUBLIC_ROUTES.CONFIRMATION}
              element={<Confirmation />}
            />
            <Route
              path={PUBLIC_ROUTES.REQUEST_RESET_PASSWORD}
              element={<RequestResetPassword />}
            />
            <Route
              path={PUBLIC_ROUTES.SET_NEW_PASSWORD}
              element={<SetNewPassword />}
            />
            <Route
              path="*"
              element={
                <Navigate
                  to={PUBLIC_ROUTES.LOGIN}
                  state={{ path: pathname + search + hash }}
                />
              }
            />
          </>
        )}
      </Routes>
    </Suspense>
  )
}
