import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { Input } from '@nextretreat/ui-components/dist/Input'
import { Field, Form, Formik } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { AccountMutations } from 'api/Account/AccountMutations'
import Button from 'components/atoms/Button'
import { Box } from 'components/atoms/Layout'
import { ErrorMessage } from 'components/atoms/Typography'
import { FooterBox } from 'components/Modal'
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
  SYMBOL_REGEX,
} from 'constants/constants'
import { PasswordStrength } from 'routes/Auth/sharedComponents/PasswordStrength'
import { toast } from 'utils/helpers'
import { authStorage } from 'utils/storage'

const StyledBox = styled(Box)`
  & > *:not(:last-of-type) {
    margin-bottom: ${rem(20)};
  }
`

const changePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old password is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'New password must have at least 8 characters')
    .matches(/[A-Z]/, 'New password must have at least 1 uppercase characters')
    .matches(SYMBOL_REGEX, 'New password must have at least 1 special symbol'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation is required'),
})

export const ChangePassword = ({ closeModal }) => {
  const [changePassword] = useMutation(AccountMutations.CHANGE_PASSWORD)

  return (
    <Formik
      initialValues={changePasswordValidationSchema.cast({
        oldPassword: '',
        password: '',
        confirmPassword: '',
      })}
      validationSchema={changePasswordValidationSchema}
      onSubmit={async (
        { oldPassword, password, confirmPassword },
        { setSubmitting }
      ) => {
        setSubmitting(true)
        try {
          const response = await changePassword({
            variables: {
              input: {
                oldPassword,
                newPassword: {
                  password1: password,
                  password2: confirmPassword,
                },
              },
            },
          })

          if (response.data) {
            const { refreshToken, accessToken } =
              response.data?.changePassword ?? {}
            if (refreshToken) {
              authStorage.set(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refreshToken)
            }
            if (accessToken) {
              authStorage.set(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken)
            }

            toast.success('Password changed succesfully')
            closeModal()
          }
        } catch {
          // do nothing
        }
        setSubmitting(false)
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <StyledBox p={rem(24)}>
            <Field name="oldPassword">
              {({ field, meta }) => (
                <Input.Password
                  isBlock
                  required
                  label="Old password"
                  placeholder="Enter old password"
                  invalid={meta.touched && meta.error !== undefined}
                  {...field}
                />
              )}
            </Field>
            <Field name="password">
              {({ field, meta }) => (
                <div>
                  <Input.Password
                    isBlock
                    required
                    label="New password"
                    placeholder="Enter new password"
                    invalid={meta.touched && meta.error !== undefined}
                    {...field}
                  />

                  <PasswordStrength
                    touched={meta.touched}
                    password={field.value}
                  />
                </div>
              )}
            </Field>
            <Field name="confirmPassword">
              {({ field, meta }) => (
                <div>
                  <Input.Password
                    isBlock
                    required
                    label="Repeat password"
                    placeholder="Repeat password"
                    invalid={meta.touched && meta.error !== undefined}
                    {...field}
                  />

                  {meta.touched && meta.error && (
                    <ErrorMessage>{meta.error}</ErrorMessage>
                  )}
                </div>
              )}
            </Field>
          </StyledBox>

          <FooterBox>
            <Button.Tertiary type="button" onClick={closeModal}>
              Cancel
            </Button.Tertiary>

            <Button.Primary isLoading={isSubmitting} type="submit">
              Save
            </Button.Primary>
          </FooterBox>
        </Form>
      )}
    </Formik>
  )
}

ChangePassword.propTypes = {
  closeModal: PropTypes.func,
}
