import styled from '@emotion/styled'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'
import { useModal } from 'components/Modal'
import { MobilePageSelect } from './MobilePageSelect'
import { EmptyLine } from '../TripShareModal'

const BoxItem = styled(Flex)`
  justify-content: space-between;
  padding: ${rem(16)} 0;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  color: ${theme.COLORS.TXT_DEFAULT};
  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.COLORS.BG_DIVIDER};
  }
`

const Item = ({ name, removeItem }) => (
  <BoxItem>
    {name}

    {removeItem && <Icon name="cancel" onClick={removeItem} />}
  </BoxItem>
)

Item.propTypes = {
  name: PropTypes.string.isRequired,
  removeItem: PropTypes.func,
}

export const PermissionsColumnMobile = ({
  column,
  title,
  moveItem,
  columnsState,
}) => {
  const { isOpen, openModal, closeModal } = useModal()

  return (
    <>
      <Box px={rem(16)} py={rem(12)}>
        <Flex justifyContent="space-between" alignItems="center" mb={rem(8)}>
          <Text as="p" fontWeight="700" fontSize="18px" lineHeight="24px">
            {title}
          </Text>

          {column !== 'noAccess' && (
            <Button.Primary
              iconLeft={<Icon name="add" />}
              viewType={EButtonType.PLAIN}
              onClick={openModal}
            >
              Add page
            </Button.Primary>
          )}
        </Flex>

        {columnsState[column].map(({ id, name }) => (
          <Item
            key={id}
            id={id}
            name={name}
            column={column}
            removeItem={
              column !== 'noAccess'
                ? () => moveItem({ id, column }, 'noAccess')
                : undefined
            }
          />
        ))}
      </Box>

      {isOpen && (
        <MobilePageSelect
          initValues={columnsState[column]}
          isOpen={isOpen}
          closeModal={closeModal}
          onSelect={(values) =>
            values
              .filter(
                ({ value }) =>
                  value &&
                  !columnsState[column].find((item) => item.id === value)
              )
              .forEach(({ value }) => {
                moveItem(
                  {
                    id: value,
                    column: Object.keys(columnsState).find((key) =>
                      columnsState[key].find(({ id }) => id === value)
                    ),
                  },
                  column
                )
              })
          }
        />
      )}

      <EmptyLine />
    </>
  )
}

PermissionsColumnMobile.propTypes = {
  title: PropTypes.string.isRequired,
  column: PropTypes.string.isRequired,
  moveItem: PropTypes.func.isRequired,
  columnsState: PropTypes.shape({
    noAccess: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    viewer: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    editor: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
}
