import React from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { COLORS } from 'Theme'
import Button from 'components/atoms/Button'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Page from 'sharedComponents/Page'

const FirstLayer = styled('div')`
  width: 100%;
  text-align: center;
`
const BackgroundText = styled(Text)`
  font-size: calc(45vw);
  color: ${COLORS.IRRADIANT_IRIS};
  font-weight: 700;
`

const SecondLayer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: -100%;
  height: 100;
  text-align: center;
  justify-content: center;
`

const InternalServerError = () => {
  const navigate = useNavigate()

  return (
    <Page loginRequired={false}>
      <Flex>
        <FirstLayer>
          <BackgroundText>500</BackgroundText>
        </FirstLayer>
        <SecondLayer>
          <Text as="p" fontSize="xxxxxxxl" fontWeight="bold" mb="l">
            Internal server error
          </Text>
          <Text as="p" fontSize="l" fontWeight="normal" mb="l">
            We&apos;re working on improving the experience. Won&apos;t be
            long...
          </Text>
          <Flex width="100%" justifyContent="center">
            <Button.Primary
              width="180px"
              onClick={() => {
                navigate('/')
              }}
            >
              Return to home
            </Button.Primary>
          </Flex>
        </SecondLayer>
      </Flex>
    </Page>
  )
}

export default InternalServerError
