import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { TOPBAR_HEIGHT } from 'constants/constants'
import Footer from './Footer'
import Header from './Header'

const Content = styled('main')`
  z-index: 0;
  position: relative;

  min-height: calc(var(--nr-100vh, 100vh) - ${TOPBAR_HEIGHT});
`

export const Page = ({ children, loginRequired, goToPlan, className }) => (
  <>
    <Header loginRequired={loginRequired} goToPlan={goToPlan} />
    <Content className={className}>{children}</Content>
    <Footer />
  </>
)

Page.propTypes = {
  children: PropTypes.node.isRequired,
  loginRequired: PropTypes.bool,
  goToPlan: PropTypes.bool,
  className: PropTypes.string,
}
