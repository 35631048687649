import React from 'react'
import { createRoot } from 'react-dom/client'
// import TagManager from 'react-gtm-module'
import App from './App'
import * as serviceWorker from './serviceWorker'

import 'sanitize.css'

// if (process.env.REACT_APP_ENV !== 'development') {
//   const tagManagerArgs = {
//     gtmId: process.env.REACT_APP_GTM_ID || 'GTM-594Q2PNM',
//   }

//   TagManager.initialize(tagManagerArgs)
// }

const container = document.getElementById('root')

const root = createRoot(container)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
