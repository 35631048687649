import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import { Text } from './atoms/Typography'

export const Tag = styled(Text)`
  background-color: ${theme.COLORS.BRAND_DEFAULT};
  color: ${theme.COLORS.WHITE};
  border-radius: 50px;
  font-weight: 510;
  width: fit-content;
`

Tag.defaultProps = {
  as: 'p',
  fontSize: theme.fontSizes.s,
  lineHeight: theme.lineHeights.s,
  px: rem(8),
}
