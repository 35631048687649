import { gql } from '@apollo/client'
import { TripFragments } from './TripFragments'

export const TripMutations = {
  SHARE_TRIP: gql`
    mutation ShareTripWithUser($input: ShareTripInput!, $tripId: Int!) {
      shareTripWithUser(input: $input, tripId: $tripId) {
        id
        userEmail
        summary
        todos
        venues
        tripRequirements
        dates
        itinerary
        team
        settings
        level
        activities
        expenses
        transfer
        sleepingLayout
        reviews
        contacts
        voting
        questions

        userData {
          avatar
        }
      }
    }
  `,

  REMOVE_USER: gql`
    mutation deleteTripSharedUser($id: Int!, $tripId: Int!) {
      deleteTripSharedUser(id: $id, tripId: $tripId)
    }
  `,

  SAVE_TODO: gql`
    mutation SaveTodo(
      $input: TodoInput!
      $tripId: Int!
      $sendAssigneeNotification: Boolean
    ) {
      saveTodo(
        input: $input
        tripId: $tripId
        sendAssigneeNotification: $sendAssigneeNotification
      ) {
        id
        ...TodoFragment
      }
    }
    ${TripFragments.TODO_FRAGMENT}
  `,

  REMOVE_TODO: gql`
    mutation RemoveTodo($id: Int!, $tripId: Int!) {
      removeTodo(id: $id, tripId: $tripId)
    }
  `,

  REORDER_TODOS: gql`
    mutation ReorderTodos($tripId: Int!) {
      reorderTodos(tripId: $tripId) {
        id
        ...TodoFragment
      }
    }
    ${TripFragments.TODO_FRAGMENT}
  `,

  SAVE_VARIATIONS: gql`
    mutation saveTripVariations($input: CreateTripVariationInput!) {
      saveTripVariations(input: $input)
    }
  `,

  UPDATE_TRIP: gql`
    mutation UpdateTrip($input: UpdateTripInput!, $tripId: Int!) {
      updateTrip(input: $input, tripId: $tripId) {
        id
        data {
          name
          userId
          logoUrl
          heroUrl
          contactName
          contactEmail
          externalLink1
          dateFrom
          dateTo
          lat
          lon
          createdAt

          deadlineNotifications
          changeNotifications
          defaultEmails
        }
      }
    }
  `,

  UPDATE_TRIP_LOGO: gql`
    mutation UpdateTrip($input: UpdateTripInput!, $tripId: Int!) {
      updateTrip(input: $input, tripId: $tripId) {
        id
        data {
          logoUrl
        }
      }
    }
  `,

  TRIP_CHECK_AVAILABILITY: gql`
    mutation TripCheckAvailability($input: TripCheckAvailabilityInput!) {
      tripCheckAvailability(input: $input)
    }
  `,

  TRIP_ASK_QUESTION: gql`
    mutation TripAskQuestion($input: TripAskQuestionInput!) {
      tripAskQuestion(input: $input)
    }
  `,

  REMOVE_TRIP_VENUE: gql`
    mutation RemoveTripVenue($id: Int!, $tripId: Int!) {
      removeTripVenue(id: $id, tripId: $tripId)
    }
  `,

  DELETE_TRIP: gql`
    mutation DeleteTrip($tripId: Int!) {
      deleteTrip(tripId: $tripId)
    }
  `,

  CREATE_TRIP: gql`
    mutation CreateTrip($input: CreateTripInput!) {
      createTrip(input: $input) {
        id
        ...TripCardFragment
      }
    }
    ${TripFragments.TRIP_CARD_FRAGMENT}
  `,

  ADD_TRIP_VENUE: gql`
    mutation AddTripVenue($input: AddTripVenueInput!, $tripId: Int!) {
      addTripVenue(input: $input, tripId: $tripId) {
        id
        venueId
        tripVenueId
      }
    }
  `,

  ADD_CUSTOM_VENUE: gql`
    mutation AddCustomVenue($input: AddCustomVenueInput!, $tripId: Int!) {
      addCustomVenue(input: $input, tripId: $tripId) {
        id
        isSelected
        venue {
          id
          ...TripVenueFragment
        }
        prices {
          id
          from
          to
          pax
          isAvailable
          externalLink
          note
          roomsCount
          rooms
          price {
            amount
          }
        }
      }
    }
    ${TripFragments.TRIP_FRAGMENT}
  `,

  ADD_PROPOSAL_VENUE: gql`
    mutation addProposalVenue($input: AddProposalVenueInput!) {
      addProposalVenue(input: $input) {
        id
        data {
          name
        }
      }
    }
  `,

  ADD_TEAM_LOCATION: gql`
    mutation AddTeamLocation($input: TeamLocationInput!, $tripId: Int!) {
      addTeamLocation(input: $input, tripId: $tripId) {
        id
        ...TeamLocationFragment
      }
    }
    ${TripFragments.TEAM_LOCATION}
  `,

  ADD_TEAM_MEMBER: gql`
    mutation AddTeamMembers($input: TeamMembersInput!, $tripId: Int!) {
      addTeamMembers(input: $input, tripId: $tripId) {
        id
        token
        ...TeamMemberFragment
      }
    }
    ${TripFragments.TEAM_MEMBER}
  `,

  ADD_TEAM_MEMBERS_ONBOARDING: gql`
    mutation AddTeamMembersOnboarding(
      $input: TeamMembersInput!
      $tripId: Int!
    ) {
      addTeamMembers(input: $input, tripId: $tripId) {
        id
      }
    }
  `,

  REMOVE_TEAM_LOCATION: gql`
    mutation RemoveTeamLocation($id: Int!, $tripId: Int!) {
      removeTeamLocation(id: $id, tripId: $tripId)
    }
  `,

  REMOVE_TEAM_MEMBER: gql`
    mutation RemoveTeamMember($id: Int!, $tripId: Int!) {
      removeTeamMember(id: $id, tripId: $tripId)
    }
  `,

  UPDATE_TEAM_MEMBER: gql`
    mutation UpdateTeamMember($input: UpdateTeamMemberInput!, $tripId: Int!) {
      updateTeamMember(input: $input, tripId: $tripId) {
        id
        ...TeamMemberFragment
      }
    }
    ${TripFragments.TEAM_MEMBER}
  `,

  SEND_ATTENDANCE_MAILS: gql`
    mutation SendAttendanceMails(
      $input: SendAttendanceMailsInput!
      $tripId: Int!
    ) {
      sendAttendanceMails(input: $input, tripId: $tripId)
    }
  `,

  UPDATE_ATTENDANCE: gql`
    mutation UpdateAttendance(
      $memberKey: String!
      $reason: String
      $status: Int!
    ) {
      updateAttendance(
        memberKey: $memberKey
        reason: $reason
        status: $status
      ) {
        token
        status
      }
    }
  `,

  ADD_TRIP_DESTINATION: gql`
    mutation AddTripDestination($destinationId: String!, $tripId: Int!) {
      addTripDestination(destinationId: $destinationId, tripId: $tripId) {
        destinationId
        id
      }
    }
  `,

  REMOVE_TRIP_DESTINATION: gql`
    mutation RemoveTripDestination($id: Int!, $tripId: Int!) {
      removeTripDestination(id: $id, tripId: $tripId)
    }
  `,

  SAVE_ITINERARY_ENTRY: gql`
    mutation SaveItineraryEntry(
      $input: SaveItinararyEntryInput!
      $tripId: Int!
    ) {
      saveItineraryEntry(input: $input, tripId: $tripId) {
        id
        name
        placeName
        placeDescription
        address
        lat
        lon
        dateStart
        dateEnd
        order
        isAllDay
        photoUrl
        note
        publicNote
        source
        sourceId
        eventCategory
        editedAt
        createdAt
        editedBy
        attachments {
          id
          entryId
          url
          name
          type
        }
      }
    }
  `,

  REORDER_ITINERARY_GROUP: gql`
    mutation ReorderItineraryGroup($tripId: Int!, $date: String) {
      reorderItineraryGroup(tripId: $tripId, date: $date) {
        id
        ...ItineraryFragment
      }
    }
    ${TripFragments.ITINERARY}
  `,

  ADD_ITINERARY_ATTACHMENT: gql`
    mutation AddItineraryAttachment($input: SaveFileInput!, $tripId: Int!) {
      addItineraryAttachment(input: $input, tripId: $tripId) {
        id
        entryId
        url
        name
        type
      }
    }
  `,

  REMOVE_ITINERARY_ATTACHMENT: gql`
    mutation RemoveItineraryAttachment(
      $input: RemoveItineraryAttachmentInput!
      $tripId: Int!
    ) {
      removeItineraryAttachment(input: $input, tripId: $tripId)
    }
  `,

  REMOVE_ITINERARY_ENTRY: gql`
    mutation RemoveItineraryEntry($id: Int!, $tripId: Int!) {
      removeItineraryEntry(id: $id, tripId: $tripId)
    }
  `,

  REQUEST_TRANSPORT_DETAILS: gql`
    mutation RequestTransportDetails($ids: [Int!]!, $tripId: Int!) {
      requestTransportDetails(ids: $ids, tripId: $tripId) {
        id
        ...TeamMemberTransportFragment
      }
    }
    ${TripFragments.TEAM_MEMBER_TRANSPORT}
  `,

  SAVE_TRANSPORT_DETAILS: gql`
    mutation SaveTeamMemberTransport(
      $input: SaveTeamMemberTransportInput!
      $memberId: Int!
      $tripId: Int!
    ) {
      saveTeamMemberTransport(
        input: $input
        memberId: $memberId
        tripId: $tripId
      ) {
        id
        ...TeamMemberTransportFragment
      }
    }
    ${TripFragments.TEAM_MEMBER_TRANSPORT}
  `,

  SAVE_TRANSPORT_DETAILS_PUBLIC: gql`
    mutation SaveTeamMemberTransportPublic(
      $input: SaveTeamMemberTransportInput!
      $memberKey: String!
      $notifyOrganisers: Boolean
    ) {
      saveTeamMemberTransportPublic(
        input: $input
        memberKey: $memberKey
        notifyOrganisers: $notifyOrganisers
      ) {
        id
        ...TeamMemberTransportFragment
      }
    }
    ${TripFragments.TEAM_MEMBER_TRANSPORT}
  `,

  ADD_TRANSPORT_ATTACHMENT: gql`
    mutation AddTransportAttachment($input: SaveFileInput!, $tripId: Int!) {
      addTransportAttachment(input: $input, tripId: $tripId) {
        id
        transportId
        url
        name
        type
        isArrival
      }
    }
  `,

  ADD_TRANSPORT_ATTACHMENT_PUBLIC: gql`
    mutation AddTransportAttachmentPublic(
      $input: SaveFileInput!
      $memberKey: String!
    ) {
      addTransportAttachmentPublic(input: $input, memberKey: $memberKey) {
        id
        transportId
        url
        name
        type
        isArrival
      }
    }
  `,

  REMOVE_TRANSPORT_ATTACHMENT: gql`
    mutation RemoveTransportAttachment(
      $input: RemoveTeamMemberTransportAttachmentInput!
      $tripId: Int!
    ) {
      removeTransportAttachment(input: $input, tripId: $tripId)
    }
  `,
  REMOVE_TRANSPORT_ATTACHMENT_PUBLIC: gql`
    mutation RemoveTransportAttachmentPublic(
      $input: RemoveTeamMemberTransportAttachmentInput!
      $memberKey: String!
    ) {
      removeTransportAttachmentPublic(input: $input, memberKey: $memberKey)
    }
  `,

  SELECT_TRIP_VENUE: gql`
    mutation SelectTripVenue($id: Int!, $tripId: Int!) {
      selectTripVenue(id: $id, tripId: $tripId) {
        id
        isSelected
      }
    }
  `,

  SELECT_TRIP_VENUE_ONBOARDING: gql`
    mutation SelectTripVenue($id: Int!, $tripId: Int!) {
      selectTripVenue(id: $id, tripId: $tripId) {
        id
        isSelected
        venue {
          data {
            coordinates {
              lat
              lon
            }
          }
        }
      }
    }
  `,

  SAVE_TRIP_REQUIREMENTS: gql`
    mutation SaveTripRequirements(
      $input: TripRequirementsInput!
      $tripId: Int!
    ) {
      saveTripRequirements(input: $input, tripId: $tripId) {
        ...TripRequirementsFragment
      }
    }
    ${TripFragments.TRIP_REQUIREMENTS_FRAGMENT}
  `,

  REQUEST_TRIP_TAB_ACCESS: gql`
    mutation RequestReadAccess($tripId: Int!, $tab: String!) {
      requestReadAccess(tripId: $tripId, tab: $tab)
    }
  `,

  REMOVE_ACTIVITY: gql`
    mutation RemoveActivity($removeActivityId: Int!, $tripId: Int!) {
      removeActivity(id: $removeActivityId, tripId: $tripId)
    }
  `,

  SAVE_ACTIVITY: gql`
    mutation SaveActivity($input: SaveActivityInput!, $tripId: Int!) {
      saveActivity(input: $input, tripId: $tripId) {
        id
        ...ActivityFragment
      }
    }
    ${TripFragments.ACTIVITY_FRAGMENT}
  `,

  SAVE_VOTING_TO_CLOSE: gql`
    mutation SaveVoting($input: SaveVotingInput!, $tripId: Int!) {
      saveVoting(input: $input, tripId: $tripId) {
        id
        isActive
      }
    }
  `,

  SAVE_VOTING: gql`
    mutation SaveVoting($input: SaveVotingInput!, $tripId: Int!) {
      saveVoting(input: $input, tripId: $tripId) {
        id
        ...VotingFragment
      }
    }
    ${TripFragments.VOTING_FRAGMENT}
  `,

  REMOVE_VOTING: gql`
    mutation RemoveVoting($id: Int!, $tripId: Int!) {
      removeVoting(id: $id, tripId: $tripId)
    }
  `,

  REMOVE_VOTING_OPTION: gql`
    mutation RemoveVotingOption($id: Int!, $tripId: Int!) {
      removeVotingOption(optionId: $id, tripId: $tripId)
    }
  `,

  ADD_VOTING_OPTION: gql`
    mutation AddVotingOption($input: AddVotingOptionInput!, $tripId: Int!) {
      addVotingOption(input: $input, tripId: $tripId) {
        id
        venueId
        activityId
        title
        description
        photoUrl
        votes {
          id
          memberId
        }
      }
    }
  `,

  CAST_VOTE: gql`
    mutation CastVote($memberKey: String!, $optionId: Int!) {
      castVote(memberKey: $memberKey, optionId: $optionId) {
        id
        isSelf
      }
    }
  `,
  REMOVE_VOTE: gql`
    mutation RemoveVote($memberKey: String!, $voteId: Int!) {
      removeVote(memberKey: $memberKey, voteId: $voteId)
    }
  `,

  SAVE_TEAM_MEMBER_PREFERENCES: gql`
    mutation SaveTeamMemberPreferences(
      $input: SaveTeamMemberPreferences!
      $memberKey: String!
    ) {
      saveTeamMemberPreferences(input: $input, memberKey: $memberKey) {
        food
        sleeping
        other
      }
    }
  `,

  GRANT_READ_ACCESS: gql`
    mutation GrantReadAccess($userKey: String!, $tab: String!) {
      grantReadAccess(userKey: $userKey, tab: $tab)
    }
  `,

  REMOVE_TRIP_EXPENSE: gql`
    mutation RemoveTripExpense($removeTripExpenseId: Int!, $tripId: Int!) {
      removeTripExpense(id: $removeTripExpenseId, tripId: $tripId)
    }
  `,

  SAVE_TRIP_EXPENSE: gql`
    mutation SaveTripExpense($input: SaveTripExpenseInput!, $tripId: Int!) {
      saveTripExpense(input: $input, tripId: $tripId) {
        id
        ...ExpenseFragment
      }
    }
    ${TripFragments.EXPENSE_FRAGMENT}
  `,

  REMOVE_TRIP_VENUE_PRICE: gql`
    mutation RemoveTripVenuePrice(
      $input: RemoveTripVenuePriceInput!
      $tripId: Int!
    ) {
      removeTripVenuePrice(input: $input, tripId: $tripId)
    }
  `,

  SAVE_TRIP_VENUE_PRICE: gql`
    mutation SaveTripVenuePrice(
      $input: SaveTripVenuePriceInput!
      $tripId: Int!
    ) {
      saveTripVenuePrice(input: $input, tripId: $tripId) {
        id
        from
        to
        pax
        isAvailable
        externalLink
        presentationLink
        workspaceFrom
        workspaceTo
        foodFrom
        foodTo
        transportFrom
        transportTo
        activitiesFrom
        activitiesTo
        serviceFeeFrom
        serviceFeeTo
        note
        roomsCount
        rooms
        price {
          amount
        }
      }
    }
  `,

  UPDATE_MEMBER_DATA: gql`
    mutation UpdateMemberData(
      $memberKey: String!
      $location: UpdateMemberDataLocationInput!
    ) {
      updateMemberData(memberKey: $memberKey, location: $location) {
        token
        attendancePageOpenedAt
        ...OriginLocationFragment
      }
    }
    ${TripFragments.ORIGIN_LOCATION_FRAGMENT}
  `,

  UPDATE_PASSPORT_DATA: gql`
    mutation UpdateMemberData(
      $memberKey: String!
      $input: UpdateMemberDataInput!
    ) {
      updateMemberData(memberKey: $memberKey, input: $input) {
        token
        name

        birthDate
        documentId
        documentType
        validUntil
        phoneNumber
        address
      }
    }
  `,

  CREATE_REVIEW: gql`
    mutation CreateReview($input: CreateReviewInput!, $tripId: Int!) {
      createReview(input: $input, tripId: $tripId) {
        id
        ...ReviewGroupFragment
      }
    }
    ${TripFragments.REVIEW_GROUP_FRAGMENT}
  `,

  SAVE_REVIEW: gql`
    mutation SaveReview($input: SaveReviewInput!, $memberKey: String!) {
      saveReview(input: $input, memberKey: $memberKey) {
        feedback {
          id
          value
        }
        reviews {
          id
          value
          groupId
        }
      }
    }
  `,

  SEND_REQUEST_ANSWERS: gql`
    mutation SendRequestAnswers($tripId: Int!, $votingId: Int!) {
      sendRequestAnswers(tripId: $tripId, votingId: $votingId)
    }
  `,

  SAVE_TRANSFER_GROUP: gql`
    mutation SaveTransferGroup($tripId: Int!, $input: SaveTransferGroupInput!) {
      saveTransferGroup(tripId: $tripId, input: $input) {
        id
        ...TransferGroupFragment
      }
    }
    ${TripFragments.TRANSFER_GROUP_FRAGMENT}
  `,

  ADD_MEMBER_TO_TRANSFER_GROUP: gql`
    mutation AddMemberToTransferGroup(
      $groupId: Int!
      $tripId: Int!
      $memberId: Int!
    ) {
      addMemberToTransferGroup(
        groupId: $groupId
        tripId: $tripId
        memberId: $memberId
      ) {
        id
        groupId
        memberId
      }
    }
  `,

  REMOVE_MEMBER_FROM_TRANSFER_GROUP: gql`
    mutation RemoveMemberFromTransferGroup(
      $groupId: Int!
      $memberId: Int!
      $tripId: Int!
    ) {
      removeMemberFromTransferGroup(
        groupId: $groupId
        memberId: $memberId
        tripId: $tripId
      )
    }
  `,

  REMOVE_TRANSFER_GROUP: gql`
    mutation RemoveTransferGroup($groupId: Int!, $tripId: Int!) {
      removeTransferGroup(id: $groupId, tripId: $tripId)
    }
  `,

  SAVE_SLEEPING_LAYOUT_GROUP: gql`
    mutation SaveSleepingLayoutGroup(
      $tripId: Int!
      $input: SaveSleepingLayoutGroupInput!
    ) {
      saveSleepingLayoutGroup(tripId: $tripId, input: $input) {
        id
        ...SleepingLayoutGroupFragment
      }
    }
    ${TripFragments.SLEEPING_LAYOUT_GROUP_FRAGMENT}
  `,

  ADD_MEMBER_TO_SLEEPING_LAYOUT_GROUP: gql`
    mutation AddMemberToSleepingLayoutGroup(
      $groupId: Int!
      $tripId: Int!
      $memberId: Int!
    ) {
      addMemberToSleepingLayoutGroup(
        groupId: $groupId
        tripId: $tripId
        memberId: $memberId
      ) {
        id
        groupId
        memberId
      }
    }
  `,

  REMOVE_MEMBER_FROM_SLEEPING_LAYOUT_GROUP: gql`
    mutation RemoveMemberFromSleepingLayoutGroup(
      $groupId: Int!
      $memberId: Int!
      $tripId: Int!
    ) {
      removeMemberFromSleepingLayoutGroup(
        groupId: $groupId
        memberId: $memberId
        tripId: $tripId
      )
    }
  `,

  REMOVE_SLEEPING_LAYOUT_GROUP: gql`
    mutation RemoveSleepingLayoutGroup($groupId: Int!, $tripId: Int!) {
      removeSleepingLayoutGroup(id: $groupId, tripId: $tripId)
    }
  `,

  SEND_GROUP_EMAIL: gql`
    mutation SendGroupEmail($input: SendGroupEmailInput!, $tripId: Int!) {
      sendGroupEmail(input: $input, tripId: $tripId)
    }
  `,

  SAVE_DEADLINES: gql`
    mutation SaveDeadlines($input: [SaveDeadlineInput!]!, $tripId: Int!) {
      saveDeadlines(input: $input, tripId: $tripId) {
        id
        type
        date
      }
    }
  `,

  LEAVE_TRIP: gql`
    mutation LeaveTrip($tripId: Int!) {
      leaveTrip(tripId: $tripId)
    }
  `,

  GET_FLIGHT_BOOKING_LINK: gql`
    mutation GetFlightBookingLink($memberKey: String!) {
      getFlightBookingLink(memberKey: $memberKey)
    }
  `,

  STORE_FLIGHT_BOOKING_DATA: gql`
    mutation StoreFlightBookingData(
      $memberKey: String!
      $reference: String!
      $orderId: String!
    ) {
      storeFlightBookingData(
        memberKey: $memberKey
        reference: $reference
        orderId: $orderId
      ) {
        id
        ...TeamMemberTransportFragment
      }
    }
    ${TripFragments.TEAM_MEMBER_TRANSPORT}
  `,

  SAVE_ATTENDANCE_PAGE_SETTINGS: gql`
    mutation SaveAttendancePageSettings(
      $input: SaveAttendancePageSettingsInput!
      $tripId: Int!
    ) {
      saveAttendancePageSettings(input: $input, tripId: $tripId) {
        id
        ...AttendancePageSettingsFragment
      }
    }
    ${TripFragments.ATTENDANCE_PAGE_SETTINGS_FRAGMENT}
  `,

  SEND_TEST_ATTENDANCE_MAIL: gql`
    mutation SendTestAttendanceMail(
      $input: SendTestAttendanceMailInput!
      $tripId: Int!
    ) {
      sendTestAttendanceMail(input: $input, tripId: $tripId)
    }
  `,

  SAVE_TRIP_CONTACT: gql`
    mutation SaveTripContact($input: SaveTripContactInput!, $tripId: Int!) {
      saveTripContact(input: $input, tripId: $tripId) {
        id
        ...TripContactsFragment
      }
    }
    ${TripFragments.TRIP_CONTACTS_FRAGMENT}
  `,

  REMOVE_TRIP_CONTACT: gql`
    mutation RemoveTripContact($id: Int!, $tripId: Int!) {
      removeTripContact(id: $id, tripId: $tripId)
    }
  `,

  SAVE_TRIP_QUESTION: gql`
    mutation SaveTripQuestion($input: SaveTripQuestionInput!, $tripId: Int!) {
      saveTripQuestion(input: $input, tripId: $tripId) {
        id
        ...TripQuestionsFragment
      }
    }
    ${TripFragments.TRIP_QUESTIONS_FRAGMENT}
  `,

  REMOVE_TRIP_QUESTION: gql`
    mutation RemoveTripQuestion($id: Int!, $tripId: Int!) {
      removeTripQuestion(id: $id, tripId: $tripId)
    }
  `,

  SAVE_ATTENDEE_AVATAR_PUBLIC: gql`
    mutation SaveAttendeeAvatarPublic(
      $avatarUrl: String!
      $memberKey: String!
    ) {
      saveAttendeeAvatarPublic(avatarUrl: $avatarUrl, memberKey: $memberKey) {
        token
        avatarUrl
      }
    }
  `,
  SAVE_TRIP_QUESTION_PUBLIC: gql`
    mutation SaveTripQuestionPublic(
      $input: SaveTripQuestionInput!
      $memberKey: String!
    ) {
      saveTripQuestionPublic(input: $input, memberKey: $memberKey) {
        id
        ...TripQuestionFragment
      }
    }
    ${TripFragments.TRIP_QUESTION_FRAGMENT}
  `,

  REMOVE_TRIP_QUESTION_PUBLIC: gql`
    mutation RemoveTripQuestionPublic($id: Int!, $memberKey: String!) {
      removeTripQuestionPublic(id: $id, memberKey: $memberKey)
    }
  `,
}
