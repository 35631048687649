import styled from '@emotion/styled'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { COLORS, fontSizes } from 'Theme'

const StyledTable = styled('table')`
  width: 100%;
`

const StyledRow = styled('tr')`
  &:nth-of-type(even) {
    background-color: ${COLORS.LYNX_WHITE};
  }

  font-size: ${fontSizes.s};
  color: ${COLORS.DEEP_RESERVOIR};

  & > td:first-of-type {
    font-weight: 600;
  }
`

const StyledCell = styled('td')`
  padding: ${rem(10)};
`

export const SummaryTable = ({ rows }) => (
  <StyledTable>
    <tbody>
      {rows.map(({ cells }, rowIndex) => (
        <StyledRow key={rowIndex}>
          {cells.map(({ label }, cellIndex) => (
            <StyledCell key={`${rowIndex}_${cellIndex}`}>{label}</StyledCell>
          ))}
        </StyledRow>
      ))}
    </tbody>
  </StyledTable>
)

SummaryTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      cells: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.any,
        })
      ),
    })
  ),
}
