import { useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import theme from '@nextretreat/ui-components/dist/Theme'
import { FieldArray, Form, Formik } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import { TripMutations } from 'api/Trip/TripMutations'
import Button from 'components/atoms/Button'
import { Box } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'
import { useModal } from 'components/Modal'
import { ProductGuide } from 'components/ProductGuide'
import { TRIP_TAB_ACCESS_OPTIONS } from 'constants/constants'
import { PermissionsSetter } from 'routes/Trip/Sharing/PermissionsSetter/PermissionsSetter'
import { convertPermissionsToObject } from 'routes/Trip/Sharing/TripShareModal'
import { NavigationBox } from 'sharedComponents/ModalManagement/TripOnboarding/NavigationBox'
import {
  findNextStepName,
  ONBOARDING_STEP_NAMES,
} from 'sharedComponents/ModalManagement/TripOnboarding/OnboardingStepper'
import { StepContext } from 'sharedComponents/ModalManagement/TripOnboarding/TripOnboarding'
import { toast } from 'utils/helpers'
import { CollaboratorItem } from './CollaboratorItem'
import { OnboardingHeading } from '../../OnboardingLayout'

const INIT_TABS_VALUE = {
  noAccess: [],
  viewer: TRIP_TAB_ACCESS_OPTIONS.map(({ value, label }) => ({
    id: value,
    name: label,
  })),
  editor: [],
}

const validationSchema = Yup.object().shape({
  collaborators: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email('Invalid email'),
      id: Yup.string().required('Required'),
      tabs: Yup.mixed().required(),
    })
  ),
})

export const TripCollaboration = ({ tripId, tripName }) => {
  const { setActiveStep } = useContext(StepContext)
  const { isOpen, openModal, closeModal } = useModal(true)
  const [changedIndex, setChangedIndex] = useState(null)

  const [shareTrip, { loading }] = useMutation(TripMutations.SHARE_TRIP)

  const onCloseModal = () => {
    closeModal()
    setChangedIndex(null)
  }

  const onSubmit = async (values) => {
    try {
      const validCollaborators = values.collaborators.filter(
        ({ email }) => !!email
      )

      let addedUsers = 0
      await Promise.all(
        validCollaborators.map(async ({ email, tabs }) => {
          const response = await shareTrip({
            variables: {
              input: {
                email,
                sendNotificationMail: true,
                ...convertPermissionsToObject(tabs),
              },
              tripId,
            },
          })

          if (response?.data?.shareTripWithUser) addedUsers += 1
        })
      )

      if (addedUsers === validCollaborators.length) {
        if (addedUsers !== 0) toast.success('Collaborators successfully added!')
        setActiveStep(findNextStepName(ONBOARDING_STEP_NAMES.COLLABORATION))
      }
    } catch {
      // do nothing
    }
  }

  return (
    <Box pb={rem(40)}>
      <OnboardingHeading>Trip collaborators</OnboardingHeading>
      <ProductGuide
        id="plan-destinations"
        title="Why you should add collaborators?"
        description={`Your collaborators will be invited to join you on planning the ${tripName}. You can control each collaborators permissions by each tab and can invite more collaborators at any time in the Sharing section of the trip.`}
        mb={rem(24)}
      />

      <Text mb={rem(24)} as="p" color={theme.COLORS.TXT_DEFAULT}>
        Invite your organiser colleagues, who are going to actively help you in
        the planning. In the next step you will be able to invite all your team
        members who are going to travel, but not involved in the core planning
        team.
      </Text>

      <Text
        as="p"
        fontWeight="700"
        fontSize={theme.fontSizes.xxl}
        lineHeight={theme.lineHeights.xxl}
        mb={rem(16)}
      >
        Invite collaborators
      </Text>

      <Formik
        validationSchema={validationSchema}
        initialValues={validationSchema.cast({
          collaborators: [{ email: '', id: uuidv4(), tabs: INIT_TABS_VALUE }],
        })}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray name="collaborators">
              {({ push, remove }) => (
                <>
                  {values.collaborators.map((collaborator, index) => (
                    <CollaboratorItem
                      key={collaborator.id}
                      collaborator={collaborator}
                      index={index}
                      onRemove={remove}
                      onEditPermissions={() => {
                        setChangedIndex(index)
                        openModal()
                      }}
                    />
                  ))}
                  <Button.Secondary
                    viewType={EButtonType.PLAIN}
                    type="button"
                    iconLeft={<Icon name="add_circle_outline" />}
                    onClick={() =>
                      push({ email: '', id: uuidv4(), tabs: INIT_TABS_VALUE })
                    }
                  >
                    Add new collaborator
                  </Button.Secondary>
                </>
              )}
            </FieldArray>

            {isOpen && changedIndex !== null && (
              <PermissionsSetter
                isOpen
                closeModal={onCloseModal}
                initialValues={{ ...values.collaborators[changedIndex].tabs }}
                onSubmit={(val) => {
                  setFieldValue(`collaborators.${changedIndex}.tabs`, val)
                }}
              />
            )}

            <NavigationBox isLoading={loading} />
          </Form>
        )}
      </Formik>
    </Box>
  )
}

TripCollaboration.propTypes = {
  tripId: PropTypes.number,
  tripName: PropTypes.string,
}
