import PropTypes from 'prop-types'
import { SplashScreenLoader } from 'routes/Router'
import { LoaderGif } from './Loader'

export const LoadingWrapper = ({
  loading,
  children,
  loadingComponent,
  isSplashScreen,
  ...passingProps
}) => {
  if (!loading) return children

  if (isSplashScreen) return <SplashScreenLoader />

  return loadingComponent || <LoaderGif {...passingProps} />
}

LoadingWrapper.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  loadingComponent: PropTypes.node,
  isSplashScreen: PropTypes.bool,
}
