import { format } from 'date-fns'
import { rem } from 'polished'
import { formatPrice } from 'utils/money'
import { PrefillDestinationSearch } from './PrefillDestinationSearch'

const getTotalSpend = (value) =>
  `(Total estimated spend: ${formatPrice({ value, currency: 'EUR' })})`

export const fields = [
  {
    key: 'teamSize',
    question: 'How many people will join your trip?',
    type: 'number',
    flexDirection: 'row',
    hideButtons: true,
    maxWidth: rem(96),
    isBlock: true,
    getFilledText: ({ teamSize }) => `${teamSize} people`,
  },
  {
    key: 'budget',
    question: 'What is your budget per person?',
    type: 'radio_chip',
    hideContinue: true,
    getFilledText: ({ budget: { maximumBudget, minimumBudget }, teamSize }) =>
      minimumBudget && maximumBudget
        ? `${minimumBudget} - ${maximumBudget}€ ${getTotalSpend(
            ((maximumBudget + minimumBudget) / 2) * teamSize
          )}`
        : maximumBudget
        ? `less than ${maximumBudget}€ ${getTotalSpend(
            (maximumBudget - 100) * teamSize
          )}`
        : `more than ${minimumBudget}€ ${getTotalSpend(
            (minimumBudget + 200) * teamSize
          )}`,
    options: [
      {
        name: 'less than 700€',
        value: {
          minimumBudget: 0,
          maximumBudget: 700,
        },
      },
      {
        name: '700€ - 1200€',
        value: {
          minimumBudget: 700,
          maximumBudget: 1200,
        },
      },
      {
        name: 'more than 1200€',
        value: {
          minimumBudget: 1200,
          maximumBudget: undefined,
        },
      },
    ],
  },
  {
    type: 'radio',
    key: 'date',
    question: 'When do you plan to go on your trip?',
    getFilledText: ({
      date: { flexible, period, exactDate, value: { startDate, endDate } = {} },
    }) => {
      const isSameMonth = period
        ? format(new Date(startDate), 'MMMM yyyy') ===
          format(new Date(endDate), 'MMMM yyyy')
        : false

      return flexible
        ? 'I don’t know yet'
        : `${
            !isSameMonth
              ? `${format(
                  new Date(startDate),
                  exactDate ? 'd MMM' : 'MMMM'
                )} - `
              : ''
          }${format(new Date(endDate), exactDate ? 'd MMM yyyy' : 'MMMM yyyy')}`
    },
    options: [
      {
        type: 'datepicker',
        name: 'I know exact days',
        placeholder: 'Select dates...',
        exactDate: true,
        key: 'exactDate',
      },
      {
        name: 'I know the month or a period',
        type: 'monthpicker',
        placeholder: 'Select months...',
        period: true,
        key: 'period',
      },
      {
        name: 'I don’t know yet',
        flexible: true,
        key: 'flexible',
      },
    ],
  },
  {
    type: 'radio',
    key: 'destination',
    question: 'What destination do you prefer?',
    getFilledText: ({ destination: { value, label, fromList, helpNeeded } }) =>
      helpNeeded ? 'I don’t know yet' : fromList ? label : value,
    options: [
      {
        type: 'destination_select',
        name: 'I will choose from the list',
        placeholder: 'Select destination...',
        fromList: true,
        key: 'fromList',
      },
      {
        name: 'Did not find in the list? Add your specific destination',
        placeholder: 'Specify destination...',
        type: 'input',
        specific: true,
        key: 'specific',
        additionalContent: (value, onInputChange, additionalContentProps) => (
          <PrefillDestinationSearch
            value={value}
            additionalContentProps={additionalContentProps}
          />
        ),
      },

      {
        name: 'I don’t know yet',
        helpNeeded: true,
        key: 'helpNeeded',
      },
    ],
  },
  {
    key: 'name',
    question: 'Name your trip',
    type: 'input',
    placeholder: 'Enter a trip name',
  },
]
