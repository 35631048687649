import React from 'react'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import Button from 'components/atoms/Button'
import { Box } from 'components/atoms/Layout'

export const ChildMarginBottom = styled(Box)`
  & > * {
    margin-bottom: ${rem(20)};
  }
`

export const FormikStepper = ({ children, step, setStep, ...props }) => {
  const childrenArray = React.Children.toArray(children)
  const currentChild = childrenArray[step]

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (step === childrenArray.length - 1) {
          await props.onSubmit(values, helpers)
        } else {
          setStep((s) => s + 1)

          helpers.setTouched({})
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          {currentChild}

          {step < 2 && (
            <Box mt={rem(20)}>
              <Button.Primary
                isLoading={isSubmitting}
                data-cy="btn-form-continue"
                isBlock
                type="submit"
              >
                Continue
              </Button.Primary>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  )
}

FormikStepper.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  step: PropTypes.number,
}
