import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { math, rem } from 'polished'
import {
  BORDER_WIDTH,
  BOXSHADOWS,
  COLORS,
  fontSizes,
  fontWeights,
  radius,
  space,
} from 'Theme'
import arrowIcon from 'assets/images/svg/arrow.svg'

import 'react-datepicker/dist/react-datepicker.css'

const DAY_SIZE = rem('35px')
const ARROW_SIZE = rem('13px')
const ARROW_BUTTON_SIZE = rem('29px')

export const CalendarStyle = styled('div')`
  display: contents;

  .react-datepicker {
    display: flex;
    flex-wrap: wrap;

    margin: 0 ${ARROW_BUTTON_SIZE};

    font-family: inherit;

    border: none;
  }

  .react-datepicker__navigation {
    z-index: 0;
    width: ${ARROW_BUTTON_SIZE};
    height: ${ARROW_BUTTON_SIZE};

    background: url('${arrowIcon}') center / ${ARROW_SIZE} ${ARROW_SIZE}
      no-repeat;
    background-color: ${theme.COLORS.WHITE};
    box-shadow: ${BOXSHADOWS.DARK};
    border: ${BORDER_WIDTH} solid ${COLORS.IRRADIANT_IRIS};
    border-radius: ${radius.circle};
  }

  .react-datepicker__navigation--previous {
    left: -${ARROW_BUTTON_SIZE};

    transform: rotateY(180deg);

    ${({ showTitle }) =>
      !showTitle &&
      `
      top: ${rem(8)};
    `}
  }

  .react-datepicker__navigation--next {
    right: -${ARROW_BUTTON_SIZE};

    ${({ showTitle }) =>
      !showTitle &&
      `
      top: ${rem(8)};
    `}
  }

  .react-datepicker__navigation-icon {
    display: none;
  }

  .react-datepicker__navigation--previous--disabled,
  .react-datepicker__navigation--next--disabled {
    box-shadow: none;

    opacity: 0.5;
  }

  .react-datepicker__header {
    background-color: transparent;
    border: none;
  }

  .react-datepicker__month-container {
    float: none;
    margin: 0 auto;
  }

  .react-datepicker__month {
    min-height: ${math(`${DAY_SIZE} * 6`)};
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    user-select: none;

    width: ${DAY_SIZE};
    line-height: ${DAY_SIZE};
    margin: 0;
  }

  .react-datepicker__day {
    font-size: ${fontSizes.m};

    color: ${theme.COLORS.TXT_MAIN};

    &,
    &:hover {
      border-radius: 0;
    }
  }

  .react-datepicker__day--today {
    font-weight: inherit;
  }

  .react-datepicker__day--disabled {
    text-decoration: line-through;

    opacity: 0.25;

    &:hover {
      background-color: transparent !important;
    }
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    background-color: ${COLORS.IRRADIANT_IRIS};
  }

  .react-datepicker__day:hover,
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-end {
    background-color: ${theme.COLORS.BRAND_DEFAULT};
    color: ${theme.COLORS.WHITE};
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
    pointer-events: none;
  }

  .react-datepicker__day-name {
    font-size: ${fontSizes.xs};
    font-weight: ${fontWeights.semi_bold};
    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: ${COLORS.BLUEBERRY_SODA};
  }

  .react-datepicker__current-month {
    padding-top: ${space.xs};

    font-size: ${fontSizes.l};
    font-weight: ${fontWeights.semi_bold};

    color: ${theme.COLORS.TXT_MAIN};
  }
`
