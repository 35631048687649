import { useState } from 'react'
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from 'constants/constants'
import { authStorage } from 'utils/storage'

export const useUploadImage = (endpoint = 'upload') => {
  const [loading, setLoading] = useState(false)

  const uploadImage = async (file, name, data) => {
    setLoading(true)
    const token = authStorage.get(LOCAL_STORAGE_ACCESS_TOKEN_KEY)

    const formData = new FormData()
    formData.append('file', file)
    formData.append('name', name)

    if (data) {
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
      })
    }

    try {
      const responseRaw = await fetch(
        `${process.env.REACT_APP_API_URL}/${endpoint}/` || '',
        {
          body: formData,
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
          method: 'POST',
        }
      )

      const { status } = responseRaw
      const obj = await responseRaw.json()

      const { error, ok } = obj

      let success = ok
      if (typeof error !== 'undefined') {
        success = !error
      }
      setLoading(false)

      return { content: obj, status, success }
    } catch (err) {
      setLoading(false)
      return {
        content: err,
        message: err?.message,
        status: 0,
        success: false,
      }
    }
  }

  return {
    uploadImage,
    loading,
  }
}
