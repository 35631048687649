import React from 'react'
import styled from '@emotion/styled'
import { Input } from '@nextretreat/ui-components/dist/Input'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { BORDER_WIDTH, fontSizes, radius, space } from 'Theme'
import {
  activeStyle,
  defaultStyle,
  disabledStyle,
  hoverStyle,
} from 'components/mixins'
import { shouldForwardPropOptions } from 'utils/helpers'

const RADIO_HEIGHT = rem('40px')

const StyledOption = styled('label', shouldForwardPropOptions)`
  ${defaultStyle}

  cursor: pointer;
  user-select: none;
  overflow: hidden;
  display: inline-block;

  flex: 1;

  min-width: ${rem('74px')};
  line-height: ${RADIO_HEIGHT};
  padding: 0 ${space.m};

  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;

  @media (hover: hover) {
    &:hover {
      ${hoverStyle}
    }
  }

  &:first-of-type {
    border-radius: ${radius.m} 0 0 ${radius.m};
  }

  &:last-of-type {
    border-radius: 0 ${radius.m} ${radius.m} 0;
  }

  &:not(:last-of-type) {
    margin-right: -${BORDER_WIDTH};
  }

  ${({ $isActive }) => $isActive && activeStyle}
  ${({ $isDisabled }) => $isDisabled && disabledStyle}

  input {
    display: none;
  }

  ${({ $isInvalid }) =>
    $isInvalid &&
    `
    background-color: ${theme.COLORS.ERROR_LIGHT};
    border: 0.5px solid ${theme.COLORS.ERROR_DEFAULT};
  `}
`

const Label = styled('label', shouldForwardPropOptions)``

const Option = ({
  className,
  checked,
  disabled,
  label,
  onSelect,
  value,
  isUnstyled,
  isInvalid,
  dataCy,
}) => {
  const Element = isUnstyled ? Label : StyledOption

  return (
    <Element
      className={className}
      $isActive={checked}
      $isDisabled={disabled}
      $isInvalid={isInvalid}
      data-cy={dataCy}
    >
      <input
        type="radio"
        checked={checked}
        disabled={disabled}
        onChange={() => onSelect(value)}
      />
      {label}
    </Element>
  )
}

Option.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  label: PropTypes.node.isRequired,
  isUnstyled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  dataCy: PropTypes.string,
}

const Wrapper = styled('div')`
  display: flex;

  font-size: ${fontSizes.m};
`

const RadioGroup = ({
  children,
  selectedValue,
  onSelect,
  isUnstyled,
  ...props
}) => {
  const optionsWithRadioProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      checked: child.props.value === selectedValue,
      onSelect,
      isUnstyled,
    })
  )
  const Element = isUnstyled ? 'div' : Wrapper

  return <Element {...props}>{optionsWithRadioProps}</Element>
}

RadioGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  selectedValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  onSelect: PropTypes.func.isRequired,
  isUnstyled: PropTypes.bool,
}

RadioGroup.Option = Option
RadioGroup.CircleOption = Input.Radio

export default RadioGroup
