import React, { useCallback } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem, triangle } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import { mq, space } from 'Theme'
import Button from 'components/atoms/Button'
import { Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'
import OutsideClickHandler from 'components/OutsideClickHandler'
import Popover from 'components/Popover'
import { disableBodyScroll, enableBodyScroll } from 'utils/scroll'

const filterMQ = mq.to.tablet

export const tripPopoverPassingProps = ['isOutlined', 'isActive']

const INVERSE_PLACEMENT_MAP = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
}

const ARROW_HEIGHT = rem('8px')
export const PADDING = space.m

const arrowStyle = ({ placement }) => css`
  position: absolute;

  &[data-placement*='bottom'] {
    top: 0;
    left: 0;
  }

  &[data-placement*='top'] {
    bottom: 0;
    left: 0;
  }

  &[data-placement*='right'] {
    left: 0;
  }

  &[data-placement*='left'] {
    right: 0;
  }

  &::before,
  &::after {
    content: '';
    display: block;

    ${placement &&
    triangle({
      pointingDirection: INVERSE_PLACEMENT_MAP[placement],
      width: rem('18px'),
      height: ARROW_HEIGHT,
      foregroundColor: theme.COLORS.WHITE,
    })}
  }

  &::before {
    z-index: -1;
    position: absolute;

    filter: drop-shadow(0px -2px 2px rgba(66, 149, 165, 0.25));
  }

  ${filterMQ`
    display: none;
  `}
`

const Card = styled('div')`
  width: 280px;
  margin: ${ARROW_HEIGHT};

  background-color: ${theme.COLORS.WHITE};
  box-shadow: ${theme.BOXSHADOWS.LEVEL_1};
  border-radius: 8px;

  ${filterMQ`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: var(--nr-100vh, 100vh);
    width: auto;
    min-width: none;
    margin: 0;

    box-shadow: none;
    border-radius: none;
  `}
`

const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px 20px;
  border-bottom: 1px solid ${theme.COLORS.BG_DIVIDER};
`

const Body = styled('div')`
  display: flex;
  flex-direction: column;

  min-height: 0;
  flex: 1;
`

const Content = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  ${({ hasPadding }) =>
    hasPadding &&
    `
      padding: ${PADDING};
    `}
`

const mobileOverrideStyle = {
  zIndex: 1,
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}

const TripPopover = ({
  children,
  label,
  placement,
  isChanged,
  isOpen,
  onOpen,
  onCancel,
  targetComp: TargetComp,
  value,
  isMobile,
  preventOutsideClick,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(isOpen)
  const contentRef = React.useRef(null)

  React.useEffect(() => {
    if (value) {
      setIsPopoverOpen(false)
    }
  }, [value])

  React.useEffect(() => {
    if (isPopoverOpen && isMobile && contentRef.current) {
      disableBodyScroll(contentRef.current)
    } else if (contentRef.current) {
      enableBodyScroll(contentRef.current)
    }
  }, [isPopoverOpen, isMobile])

  const closePopover = useCallback(() => {
    if (onCancel) {
      onCancel()
    }

    setIsPopoverOpen(false)
    if (contentRef.current) {
      enableBodyScroll(contentRef.current)
    }
  }, [onCancel, setIsPopoverOpen])

  return (
    <Popover
      targetComp={
        <TargetComp
          isActive={isPopoverOpen}
          isOutlined={isChanged}
          onMouseEnter={() => {
            setIsPopoverOpen(true)
          }}
          onClick={onOpen}
        >
          {label}
        </TargetComp>
      }
      arrowStyle={arrowStyle}
      placement={placement}
      isVisible={isPopoverOpen}
      popoverStyle={isMobile ? mobileOverrideStyle : undefined}
      zIndex={mobileOverrideStyle.zIndex}
    >
      <OutsideClickHandler
        disabled={!isPopoverOpen}
        onOutsideClick={() => {
          if (!preventOutsideClick) {
            closePopover()
          }
        }}
      >
        <Card
          onMouseLeave={() => {
            if (!preventOutsideClick) {
              closePopover()
            }
          }}
        >
          <Body>
            {isMobile && (
              <Header>
                <Icon name="arrow_back" onClick={closePopover} />
                <Text
                  color={theme.COLORS.TXT_MAIN}
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="24px"
                  mx="s"
                >
                  My Trips
                </Text>
                <Icon name="close" onClick={closePopover} />
              </Header>
            )}
            <Content ref={contentRef}>
              {typeof children === 'function'
                ? children(closePopover)
                : children}
            </Content>
          </Body>
        </Card>
      </OutsideClickHandler>
    </Popover>
  )
}

TripPopover.defaultProps = {
  isOpen: false,
  targetComp: Button.Pill,
}

TripPopover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  label: PropTypes.node.isRequired,
  placement: PropTypes.string,
  isChanged: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onCancel: PropTypes.func,
  targetComp: PropTypes.object,
  value: PropTypes.any,
  isMobile: PropTypes.bool,
  preventOutsideClick: PropTypes.bool,
}

const ResponsiveFilter = (props) => {
  const matches = useMediaQuery()
  return <TripPopover isMobile={matches.mobile} {...props} />
}

export default ResponsiveFilter
