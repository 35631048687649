import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { LayoutQueries } from 'api/Layout/LayoutQueries'
import {
  DEFAULT_CURRENCY_ISO_CODE,
  DEFAULT_CURRENCY_SYMBOL,
  ENABLED_CURRENCIES,
  LOCAL_STORAGE_CURRENCY_KEY,
} from 'constants/constants'
import { local } from './utils/storage'

const NOOP = () => {}

export const CurrencyContext = React.createContext({
  currency: DEFAULT_CURRENCY_ISO_CODE,
  currencies: [DEFAULT_CURRENCY_ISO_CODE],
  setCurrency: NOOP,
  getCurrencySymbol: NOOP,
})

const CurrencyProvider = ({ children }) => {
  const { data } = useQuery(LayoutQueries.GET_CURRENCIES, {
    nextFetchPolicy: 'cache-only',
    returnPartialData: true,
  })

  const currencies = data?.currencies
    .filter(({ isoCode }) => ENABLED_CURRENCIES.includes(isoCode))
    .map(({ isoCode }) => isoCode) ?? [DEFAULT_CURRENCY_ISO_CODE]

  const [currency, setCurrency] = React.useState(() => {
    try {
      const localStorageValue = local.getItem(LOCAL_STORAGE_CURRENCY_KEY)
      if (localStorageValue !== null) {
        return localStorageValue
      }
      local.setItem(LOCAL_STORAGE_CURRENCY_KEY, DEFAULT_CURRENCY_ISO_CODE)
      return DEFAULT_CURRENCY_ISO_CODE
    } catch {
      return DEFAULT_CURRENCY_ISO_CODE
    }
  })

  const getCurrencySymbol = (isoCode) =>
    data?.currencies.find(
      (currencyObject) => currencyObject.isoCode === isoCode
    )?.symbol ?? DEFAULT_CURRENCY_SYMBOL

  React.useEffect(() => {
    try {
      local.setItem(LOCAL_STORAGE_CURRENCY_KEY, currency)
    } catch {
      // do nothing
    }
  }, [currency])

  const value = useMemo(
    () => ({ currency, currencies, setCurrency, getCurrencySymbol }),
    [currency, currencies, setCurrency, getCurrencySymbol]
  )

  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  )
}

CurrencyProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CurrencyProvider
