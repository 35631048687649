import styled from '@emotion/styled'
import { Input } from '@nextretreat/ui-components/dist/Input'
import theme from '@nextretreat/ui-components/dist/Theme'
import { Field, useFormikContext } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'

const ItemFlex = styled(Flex)`
  padding: ${rem(16)} ${rem(20)};
  gap: ${rem(16)};
  align-items: center;
  background-color: ${theme.COLORS.BG_SOFT};
  border-radius: 8px;
`

export const AtendeeItem = ({ atendee, index, onRemove }) => {
  const { values } = useFormikContext()

  const userAlreadyAdded = values.atendees.some(
    (collab, i) =>
      collab.email === atendee.email && index !== i && atendee.email
  )

  return (
    <Box mb={rem(12)} data-cy="atendee-item">
      <ItemFlex>
        <Field name={`atendees.${index}.name`}>
          {({ field, meta }) => (
            <Input
              isBlock
              data-cy="inp-atendee-name"
              placeholder="Name"
              invalid={meta.touched && meta.error !== undefined}
              {...field}
            />
          )}
        </Field>

        <Field name={`atendees.${index}.email`}>
          {({ field, meta }) => (
            <Input
              isBlock
              data-cy="inp-atendee-email"
              placeholder="E-mail..."
              invalid={
                meta.touched && (meta.error !== undefined || userAlreadyAdded)
              }
              {...field}
            />
          )}
        </Field>

        <Icon name="cancel" onClick={() => onRemove(index)} />
      </ItemFlex>

      {userAlreadyAdded && (
        <Text fontSize="xs" color={theme.COLORS.ERROR_DEFAULT}>
          This atendee is already added
        </Text>
      )}
    </Box>
  )
}

AtendeeItem.propTypes = {
  atendee: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string,
  }),
  index: PropTypes.number,
  onRemove: PropTypes.func,
}
