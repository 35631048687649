import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { Box } from './atoms/Layout'

const Container = styled(Box)`
  width: 100%;
  border-radius: ${rem(15)};
  background-color: ${theme.COLORS.BG_SOFT};
  overflow: hidden;
`

const Label = styled('div')`
  color: ${theme.COLORS.WHITE};
  margin-right: 10px;
  font-weight: 510;
`

const Filler = styled('div')`
  height: 100%;
  width: ${({ completed }) => completed}%;
  transition: width 1s ease-in-out;
  background-color: ${({ $bgColor }) =>
    $bgColor || theme.COLORS.SUCCESS_DEFAULT};
  border-radius: ${rem(15)};
  text-align: 'right';
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const ProgressBar = ({
  end,
  current,
  bgColor,
  className,
  hideLabel,
  ...passingProps
}) => {
  const completed = (current / end) * 100

  return (
    <Container height={rem(30)} className={className} {...passingProps}>
      <Filler $bgColor={bgColor} completed={completed}>
        {!hideLabel && <Label>{`${current}/${end}`}</Label>}
      </Filler>
    </Container>
  )
}

ProgressBar.propTypes = {
  bgColor: PropTypes.string,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  end: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
}
