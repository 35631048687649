import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { COLORS, radius, space } from 'Theme'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { passwordStrength, shouldForwardPropOptions } from 'utils/helpers'

const Bar = styled('span', shouldForwardPropOptions)`
  flex: 1;
  height: ${rem(5)};
  border-radius: ${radius.xs};
  ${({ $color }) => `background-color: ${$color}`};
`

const BarsWrap = styled(Flex)`
  align-items: center;
  padding: ${rem(10)} 0;
  gap: ${rem(5)};
`

const ListItem = styled('li', shouldForwardPropOptions)`
  line-height: ${rem(21)};
  margin: ${rem(5)} 0;
  color: ${COLORS.BLUEBERRY_SODA};

  ${({ $touched, $crossed }) => `${
    $crossed ? `opacity: 0.5;text-decoration: line-through;` : ''
  }
    ${$touched && !$crossed ? `color: ${theme.COLORS.ERROR_DEFAULT}` : ''}`}
`

const List = styled('ul')`
  margin: 0 0 ${rem(10)} 0;
  padding-left: ${rem(30)};
`

export const PasswordStrength = ({ password, touched }) => {
  const strengthObj = passwordStrength(password || '')

  return password ? (
    <>
      <BarsWrap>
        {Array(4)
          .fill('')
          .map((_, index) => (
            <Bar
              key={index}
              $color={
                index < strengthObj.id
                  ? strengthObj.color
                  : COLORS.IRRADIANT_IRIS
              }
            />
          ))}
        <Text ml={space.m} color={strengthObj.color}>
          {strengthObj.value} password
        </Text>
      </BarsWrap>

      <List>
        <ListItem $touched={touched} $crossed={strengthObj.length >= 8}>
          Min 8 characters
        </ListItem>
        <ListItem
          $touched={touched}
          $crossed={strengthObj.contains.includes('uppercase')}
        >
          1 Uppercase
        </ListItem>
        <ListItem
          $touched={touched}
          $crossed={strengthObj.contains.includes('symbol')}
        >
          1 special symbol is required
        </ListItem>
      </List>
    </>
  ) : null
}

PasswordStrength.propTypes = {
  password: PropTypes.string,
  touched: PropTypes.bool,
}
