import { gql } from '@apollo/client'

export const AuthQueries = {
  LOGIN: gql`
    query Login($input: LoginInput!) {
      login(input: $input) {
        accessToken
        refreshToken
      }
    }
  `,

  ONE_TIME_TOKEN_LOGIN: gql`
    query OneTimeTokenLogin($token: String!) {
      oneTimeTokenLogin(token: $token) {
        accessToken
        refreshToken
      }
    }
  `,

  GET_USER_ME: gql`
    query GetUserMe {
      userMe {
        trips {
          id
          data {
            name
            thumbnail
            venuesCount
          }
        }
      }
    }
  `,

  AUTH_INFO: gql`
    query UserMe {
      userMe {
        id
        email
        firstName
        lastName
        firstLogin
        hasSubscription
        trialEnd
        isActive
        betaFeaturesEnabled
        agencyFeaturesEnabled
        productGuidesDisabled
        avatarUrl
        readNewsId
        npsScore
        company
        phoneNumber
      }
    }
  `,

  GAMIFICATION_DATA: gql`
    query GamificationData {
      gamificationData {
        firstTrip
        firstTeamMember
        firstTodo
        firstVenueToTrip
        deadlineSeen
        attendancePageSeen
        discoverySeen
      }
    }
  `,
}
