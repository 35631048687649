import theme from '@nextretreat/ui-components/dist/Theme'
import {
  addDays,
  differenceInCalendarDays,
  format,
  formatDistance,
  getMonth,
  isValid,
  parseISO,
} from 'date-fns'
import { toast as toastLibFunc } from 'react-toastify'
import { COLORS } from 'Theme'
import { v4 as uuidv4 } from 'uuid'
import { EMAIL_VARIABLES, SYMBOL_REGEX } from 'constants/constants'
import { parseQuery, stringifyQuery } from 'routes/Plan/urlHelpers'
import { MAX_DATE_RANGE } from 'sharedComponents/AvailabilityCalendar'
import { convertLocalToUTCDate } from './date'

export const convertMinutesToHours = (minutes) =>
  Math.round((minutes / 60) * 10) / 10

export const clamp = (min, max) => (number) =>
  Math.min(Math.max(min, number), max)

export const initializeArrayWithRange = (end, start = 0) =>
  Array.from({ length: end + 1 - start }).map((v, i) => i + start)

export const sum = (list) => list.reduce((a, b) => a + b, 0)

export const roundUp = (number, precision) =>
  Math.ceil(number * 10 ** precision) / 10 ** precision

export const updateArrayByIndex = ({ value, index, array }) =>
  Object.assign([], array, { [index]: value })

export const modulo = (left, right) => ((left % right) + right) % right

export const ifDev = (val) =>
  process.env.REACT_APP_ENV === 'development' ? val : val

export const getNearestDateFromInterval = (startDate, dateIntervals) => {
  const sortedDateIntervals = dateIntervals
    .filter(({ start }) => start > startDate)
    .sort(
      (a, b) =>
        differenceInCalendarDays(startDate, b.start) -
        differenceInCalendarDays(startDate, a.start)
    )

  if (
    sortedDateIntervals[0].start &&
    differenceInCalendarDays(sortedDateIntervals[0].start, startDate) <=
      MAX_DATE_RANGE
  ) {
    return sortedDateIntervals[0].start
  }

  return addDays(startDate, MAX_DATE_RANGE)
}

export const calculateOrder = (items, moveTargetIndex, STEP) => {
  const itemBefore = items[moveTargetIndex - 1]?.order
  const itemAfter = items[moveTargetIndex]?.order

  const itemBeforeOrder =
    typeof itemBefore === 'number' ? itemBefore : itemAfter - 2 * STEP
  const itemAfterOrder =
    typeof itemAfter === 'number' ? itemAfter : itemBefore + 2 * STEP

  return items.length
    ? !!itemAfterOrder || !!itemBeforeOrder
      ? (itemBeforeOrder + itemAfterOrder) / 2
      : (items[items.length - 1]?.order || 0) + STEP
    : STEP
}

const passwordStrengthOptions = [
  {
    id: 0,
    value: 'Too weak',
    minDiversity: 0,
    minLength: 0,
    color: COLORS.IRRADIANT_IRIS,
  },
  {
    id: 1,
    value: 'Weak',
    minDiversity: 2,
    minLength: 6,
    color: theme.COLORS.ERROR_DEFAULT,
  },
  {
    id: 2,
    value: 'Medium',
    minDiversity: 3,
    minLength: 8,
    color: COLORS.GOUDA_GOLD,
  },
  {
    id: 3,
    value: 'Good',
    minDiversity: 4,
    minLength: 10,
    color: theme.COLORS.BRAND_DEFAULT,
  },
  {
    id: 4,
    value: 'Strong',
    minDiversity: 4,
    minLength: 12,
    color: COLORS.EXPLORATION_GREEN,
  },
]

export const escapeRegExp = (string) =>
  string.replace(/[-.*+?^${}()|[\]\\]/g, '\\$&')

export const passwordStrength = (password) => {
  const passwordCopy = password || ''

  const rules = [
    {
      regex: '[a-z]',
      message: 'lowercase',
    },
    {
      regex: '[A-Z]',
      message: 'uppercase',
    },
    {
      regex: '[0-9]',
      message: 'number',
    },
    {
      regex: SYMBOL_REGEX,
      message: 'symbol',
    },
  ]

  const strength = {}

  strength.contains = rules
    .filter((rule) => new RegExp(`${rule.regex}`).test(passwordCopy))
    .map((rule) => rule.message)

  strength.length = passwordCopy.length

  const fulfilledOptions = passwordStrengthOptions
    .filter((option) => strength.contains.length >= option.minDiversity)
    .filter((option) => strength.length >= option.minLength)
    .sort((o1, o2) => o2.id - o1.id)
    .map((option) => ({
      id: option.id,
      value: option.value,
      color: option.color,
    }))

  Object.assign(strength, fulfilledOptions[0])

  return strength
}

export const dateAgo = (date) => {
  const startDate = new Date(+date)
  const endDate = new Date()
  const diffDate = new Date(new Date() - startDate)

  const yearDiff = diffDate.toISOString().slice(0, 4) - 1970
  const monthDiff = diffDate.getMonth()
  const dayDiff = diffDate.getDate() - 1

  const msec = endDate - startDate
  const mins = Math.floor(msec / 60000)
  const hrs = Math.floor(mins / 60)

  if (mins <= 30) {
    return ' Just Now'
  }
  if (hrs <= 24) {
    return ' Today'
  }

  if (yearDiff > 0) {
    return yearDiff > 1 ? `${yearDiff} years ago ` : `${yearDiff} year ago `
  }

  if (monthDiff > 0) {
    return monthDiff > 1
      ? `${monthDiff} months ago `
      : `${monthDiff} month ago `
  }

  if (dayDiff > 0) {
    return dayDiff > 1 ? `${dayDiff} days ago ` : `${dayDiff} day ago`
  }

  return dayDiff
}

export const parseStripePrice = (price) => (price / 100).toFixed(2)

export const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1)

export const getInitials = (fullName) => {
  const allNames = fullName.trim().split(' ')
  const initials = allNames.reduce((acc, curr, index) => {
    let copiedAcc = acc
    if (index === 0 || index === allNames.length - 1) {
      copiedAcc = `${copiedAcc}${curr.charAt(0).toUpperCase()}`
    }
    return copiedAcc
  }, '')
  return initials
}

export const downloadURI = (uri, name) => {
  const link = document.createElement('a')
  link.href = uri
  link.download = name
  link.target = '_blank'
  link.click()
}

export const saveCsvFile = (name, objectToSave) => {
  let fileString = ''
  const separator = '\t'
  const fileType = 'csv'
  const file = `${name}.${fileType}`

  Object.keys(objectToSave[0]).forEach((value) => {
    fileString += `${value}${separator}`
  })
  fileString = fileString.slice(0, -1)
  fileString += '\n'

  objectToSave.forEach((transaction) => {
    Object.values(transaction).forEach((value) => {
      fileString += `${value}${separator}`
    })
    fileString = fileString.slice(0, -1)
    fileString += '\n'
  })

  downloadURI(
    `data:text/csv;charset=UTF-8,${encodeURIComponent(fileString)}`,
    file
  )
}

export const insertEverywhere = (arr, thing) =>
  []
    .concat(...arr.map((n, index) => [n, { ...thing, key: index }]))
    .slice(0, -1)

export const detectDelimiter = (input) => {
  const separators = [',', ';', '|', '\t', ' ']
  const idx = separators
    .map((separator) => input.indexOf(separator))
    .reduce((prev, cur) =>
      prev === -1 || (cur !== -1 && cur < prev) ? cur : prev
    )
  return input[idx] || ','
}

export const csvToJson = (csvString, useheader = true) => {
  const lines = csvString.split('\n')

  const result = []
  const delimiter = detectDelimiter(csvString)
  const headers =
    useheader === true
      ? lines[0].split(delimiter)
      : lines[0].split(delimiter).map((v, i) => `property${i}`)

  for (let i = useheader ? 1 : 0; i < lines.length; i += 1) {
    if (!lines[i]) continue
    const obj = {}
    const currentline = lines[i].split(delimiter)

    for (let j = 0; j < headers.length; j += 1) {
      obj[headers[j].trim().replace('"', '')] = currentline[j]
        .replace('\r', '')
        .replace('"', '')
    }

    result.push(obj)
  }
  return result
}

export const maxLengthEllipsis = (string, maxSize = 10) =>
  string?.length > maxSize ? `${string.slice(0, maxSize)}...` : string

// note: google api must be loaded
export const getLatLon = async (address) => {
  const geocoder = new window.google.maps.Geocoder()

  const response = await geocoder.geocode({ address })

  const { lat, lng } = response.results[0].geometry.location

  return { lat: lat(), lng: lng() }
}

export const getAddress = async (lat, lng) => {
  if (!(lat && lng)) return
  const geocoder = new window.google.maps.Geocoder()

  const response = await geocoder.geocode({ location: { lat, lng } })

  return response.results?.[0]?.formatted_address
}

export const isOutsideDates = (checkingDate, start, end) =>
  !!(
    checkingDate &&
    ((start &&
      differenceInCalendarDays(new Date(checkingDate), new Date(start)) < 0) ||
      (end &&
        differenceInCalendarDays(new Date(checkingDate), new Date(end)) > 0))
  )

export const getMinMaxPrice = (prices) => {
  let minPrice = Infinity
  let maxPrice = 0

  prices.forEach((price) => {
    if (price.isAvailable !== false && minPrice > price.price?.amount)
      minPrice = price.price?.amount
    if (price.isAvailable !== false && maxPrice < price.price?.amount)
      maxPrice = price.price?.amount
  })

  return { minPrice, maxPrice }
}

export const getGetYourGuideLink = (trip) => {
  const tripDestination = trip?.tripVenues?.find(({ isSelected }) => isSelected)
    ?.venue?.destination

  return `https://www.getyourguide.com/s?${
    tripDestination ? `q=${tripDestination}&` : ''
  }partner_id=${process.env.REACT_APP_GET_YOUR_GUIDE_PARTNER_ID}${
    trip.dateFrom
      ? `&date_from=${format(new Date(+trip.dateFrom), 'yyyy-MM-dd')}`
      : ''
  }${
    trip.dateTo
      ? `&date_to=${format(new Date(+trip.dateTo), 'yyyy-MM-dd')}`
      : ''
  }`
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export const getRandomInt = (min, max) => {
  const minimum = Math.ceil(min)
  const maximum = Math.floor(max)
  return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum
}

export const downloadFile = async (fileToDownload) => {
  const res = await fetch(fileToDownload)

  return res.blob()
}

export const validateEmail = (email) =>
  !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

export const indexToAlpha = (columnNumber) => {
  let copiedColumnNumber = columnNumber + 1
  // To store result (Excel column name)
  const columnName = []

  while (copiedColumnNumber > 0) {
    // Find remainder
    const rem = copiedColumnNumber % 26

    // If remainder is 0, then a
    // 'Z' must be there in output
    if (rem === 0) {
      columnName.push('Z')
      copiedColumnNumber = Math.floor(copiedColumnNumber / 26) - 1
    } // If remainder is non-zero
    else {
      columnName.push(String.fromCharCode(rem - 1 + 'A'.charCodeAt(0)))
      copiedColumnNumber = Math.floor(copiedColumnNumber / 26)
    }
  }

  // Reverse the string and print result
  return `${columnName.reverse().join('')}`
}

export const getStartsInText = (date) => {
  const startsIn = differenceInCalendarDays(new Date(date), new Date())

  return startsIn < 10 && startsIn >= 0
    ? `Starts in ${formatDistance(new Date(), new Date(date))}`
    : undefined
}

export const parseDateFromDatepicker = (date) =>
  convertLocalToUTCDate(isValid(date) ? date : parseISO(date))

export const getTypeFromGeocodeAPI = (addrComponents, types) =>
  addrComponents?.find(({ types: addrTypes }) =>
    addrTypes?.some((type) => types.includes(type))
  )

export const getSeasonFromDate = (date) => {
  const monthIndex = getMonth(new Date(date))

  if ((monthIndex >= 0 && monthIndex <= 1) || monthIndex === 11) return 'Winter'
  if (monthIndex >= 2 && monthIndex <= 4) return 'Spring'
  if (monthIndex >= 5 && monthIndex <= 7) return 'Summer'
  if (monthIndex >= 8 && monthIndex <= 10) return 'Fall'
}

export const roundNum = (num, precision = 2) =>
  +`${Math.round(`${num}e+${precision}`)}e-${precision}`

export const formatEmissions = (amount) =>
  `${roundNum(amount > 500 ? amount / 1000 : amount)}${
    amount > 500 ? 't' : 'kg'
  }`

export const shouldForwardPropOptions = {
  shouldForwardProp: (prop) => !prop.startsWith('$'),
}

export const replaceEmailContent = (textToReplace, data) => {
  let finalText = textToReplace

  EMAIL_VARIABLES.forEach((variable) => {
    finalText = finalText.replace(`{${variable}}`, data[variable] || '')
  })

  return finalText
}

export const getCornersFromBounds = (bounds) => {
  // the only corners that maps gives us are the NE and SW corners
  const NECorner = bounds?.getNorthEast()
  const SWCorner = bounds?.getSouthWest()

  // so we need to calculate the other two corners manually
  if (!(SWCorner && NECorner)) return

  const NWCorner = { lat: NECorner?.lat(), lon: SWCorner?.lng() }
  const SECorner = { lat: SWCorner?.lat(), lon: NECorner?.lng() }

  return { southEast: SECorner, northWest: NWCorner }
}

export const convertLatLonToLatLng = ({ lat, lon: lng }) => ({ lat, lng })

export const shrinkBoundsByPixels = (map, paddingX = 50, paddingY = 50) => {
  const SW = map.getBounds().getSouthWest()
  const NE = map.getBounds().getNorthEast()
  const topRight = map.getProjection().fromLatLngToPoint(NE)
  const bottomLeft = map.getProjection().fromLatLngToPoint(SW)
  const scale = 2 ** map.getZoom()

  const SWtopoint = map.getProjection().fromLatLngToPoint(SW)
  const SWpoint = new window.google.maps.Point(
    (SWtopoint.x - bottomLeft.x) * scale + paddingX,
    (SWtopoint.y - topRight.y) * scale - paddingY
  )
  const SWworld = new window.google.maps.Point(
    SWpoint.x / scale + bottomLeft.x,
    SWpoint.y / scale + topRight.y
  )
  const pt1 = map.getProjection().fromPointToLatLng(SWworld)

  const NEtopoint = map.getProjection().fromLatLngToPoint(NE)
  const NEpoint = new window.google.maps.Point(
    (NEtopoint.x - bottomLeft.x) * scale - paddingX,
    (NEtopoint.y - topRight.y) * scale + paddingY
  )
  const NEworld = new window.google.maps.Point(
    NEpoint.x / scale + bottomLeft.x,
    NEpoint.y / scale + topRight.y
  )
  const pt2 = map.getProjection().fromPointToLatLng(NEworld)

  return {
    northWest: { lat: pt1?.lat(), lon: pt2?.lng() },
    southEast: { lat: pt2?.lat(), lon: pt1?.lng() },
  }
}

const isObject = (item) =>
  item && typeof item === 'object' && !Array.isArray(item)

export const mergeDeep = (target, source) => {
  const output = { ...target }
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] })
        else output[key] = mergeDeep(target[key], source[key])
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }
  return output
}

export const getZoomFromBounds = (bounds, pixelWidth) => {
  const GLOBE_WIDTH = 256 // a constant in Google's map projection
  const ne = bounds.getNorthEast()
  const sw = bounds.getSouthWest()
  const west = sw.lng()
  const east = ne.lng()
  let angle = east - west
  if (angle < 0) {
    angle += 360
  }
  return Math.floor(
    Math.log((pixelWidth * 360) / angle / GLOBE_WIDTH) / Math.LN2
  )
}

export const isJsonString = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const hash = async (string) => {
  const utf8 = new TextEncoder().encode(string)
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('')
  return hashHex
}

const toastCountObject = {}

toastLibFunc.onChange((payload) => {
  if (payload.status === 'removed') delete toastCountObject[payload.id]
})

const toastFunction =
  (type) =>
  async (message, options = {}) => {
    const toastId = options.toastId || (await hash(message))

    if (toastLibFunc.isActive(toastId)) {
      toastCountObject[toastId] = (toastCountObject[toastId] || 1) + 1

      const newMessage =
        typeof message === 'string' ? (
          `${message} (${toastCountObject[toastId]})`
        ) : (
          <>
            {message} ({toastCountObject[toastId]})
          </>
        )

      toastLibFunc.update(toastId, {
        render: newMessage,
      })
    } else toastLibFunc[type](message, { toastId, ...options })
  }

export const toast = {
  ...toastLibFunc,
  info: toastFunction('info'),
  error: toastFunction('error'),
  success: toastFunction('success'),
  warn: toastFunction('warn'),
  loading: (content) => {
    const uuid = uuidv4()
    toastFunction('loading')(content || 'Loading...', {
      autoClose: false,
      toastId: uuid,
    })
    return uuid
  },
  loadingFinish: (id, render) =>
    toastLibFunc.update(id, {
      render,
      type: 'success',
      isLoading: false,
      autoClose: true,
    }),
}

export const isNumber = (value) => typeof value === 'number'

export const decode = (search) =>
  Object.fromEntries(
    Object.entries(parseQuery(search)).map(([key, value]) => [
      key,
      isJsonString(value) ? JSON.parse(value) : value,
    ])
  )

const valuesToStringify = ['object', 'array']

export const encode = (data) =>
  stringifyQuery(
    Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        value &&
          (valuesToStringify.includes(typeof value)
            ? JSON.stringify(value)
            : value),
      ])
    )
  )

export const transformMinutesToTime = (totalMinutes) => {
  if (!totalMinutes) return '0m'

  const hours = Math.floor(totalMinutes / 60)
  const minutes = Math.floor(totalMinutes % 60)

  let result = ''

  if (hours) {
    result = minutes ? `${minutes.toString().padStart(2, '0')}m` : ''

    result = `${hours.toString()}h ${result}`
  } else {
    result = `${minutes.toString().padStart(1, '0')}m ${result}`
  }

  return result
}

export const checkNextretreatImage = (url) =>
  url?.startsWith('https://cdn.nextretreat.com')

export const addBlurToImage = (url) => {
  if (checkNextretreatImage(url)) return `${url}?format=blur`
  return url
}

export const createStore = (initialState) => {
  let state = initialState
  const callbacks = new Set()
  const getState = () => state
  const setState = (nextState) => {
    state = typeof nextState === 'function' ? nextState(state) : nextState
    callbacks.forEach((callback) => callback())
  }
  const subscribe = (callback) => {
    callbacks.add(callback)
    return () => {
      callbacks.delete(callback)
    }
  }
  return { getState, setState, subscribe }
}

export const getTempHistoryValues = (email) => ({
  editedAt: new Date().getTime().toString(),
  createdAt: new Date().getTime().toString(),
  editedBy: email,
})
