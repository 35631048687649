import React from 'react'
import styled from '@emotion/styled'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import theme from '@nextretreat/ui-components/dist/Theme'
import { CurrencyContext } from 'CurrencyProvider'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import { COLORS, fontSizes, fontWeights, mq, space } from 'Theme'
import Button from 'components/atoms/Button'
import { Text } from 'components/atoms/Typography'
import RadioGroup from 'components/RadioGroup'
import { TOPBAR_HEIGHT } from 'constants/constants'
import Filter, { PADDING } from 'sharedComponents/Filter'
import { tripPopoverPassingProps } from 'sharedComponents/ModalManagement/TripPopover'
import { StyledArrowheadIcon } from './Header'

const StyledButton = styled(Button.Secondary, {
  shouldForwardProp: (prop) => !tripPopoverPassingProps.includes(prop),
})`
  min-width: ${rem('50px')};
  height: ${TOPBAR_HEIGHT};
  margin-left: auto;
  ${mq.from.tablet`
      margin-left: ${space.s};
  `}
`

StyledButton.defaultProps = {
  viewType: EButtonType.PLAIN,
}

const StyledOption = styled(RadioGroup.Option)`
  cursor: pointer;
  z-index: 0;
  position: relative;
  display: block;

  font-size: ${fontSizes.m};
  line-height: ${rem('34px')};
  white-space: nowrap;

  &::after {
    ${({ checked }) => checked && `content: '';`}

    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -${PADDING};
    right: -${PADDING};

    background-color: ${COLORS.IRRADIANT_IRIS};
  }

  &:hover:after {
    ${({ checked }) => !checked && `content: '';`}
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -${PADDING};
    right: -${PADDING};
    background-color: ${COLORS.LYNX_WHITE};
  }

  input {
    display: none;
  }
`

const CurrencySymbol = styled('span')`
  display: inline-block;

  width: ${rem('40px')};

  font-weight: ${fontWeights.bold};
`

const CurrencyLine = ({ code, symbol }) => (
  <>
    <CurrencySymbol>{symbol}</CurrencySymbol>
    <span>{code}</span>
  </>
)

CurrencyLine.propTypes = {
  code: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
}

const Currency = ({ innerLabel }) => {
  const matches = useMediaQuery()

  const { currency, currencies, setCurrency, getCurrencySymbol } =
    React.useContext(CurrencyContext)

  return (
    <Filter
      value={currency}
      targetComp={StyledButton}
      label={
        <>
          <Text color={theme.COLORS.TXT_MAIN}>
            {getCurrencySymbol(currency)} ({currency})
          </Text>
          <StyledArrowheadIcon />
        </>
      }
      modalLabel={innerLabel}
      hasFooter={false}
      customMinWidth={rem(220)}
    >
      {!matches.mobile && innerLabel && (
        <Text display="block" mb="s" fontSize="s" color={COLORS.DEEP_RESERVOIR}>
          {innerLabel}
        </Text>
      )}
      <RadioGroup
        selectedValue={currency}
        onSelect={(value) => {
          setCurrency(value)
        }}
        isUnstyled
      >
        {currencies.map((currencyCode) => (
          <StyledOption
            key={currencyCode}
            value={currencyCode}
            label={
              <CurrencyLine
                code={currencyCode}
                symbol={getCurrencySymbol(currencyCode)}
              />
            }
          />
        ))}
      </RadioGroup>
    </Filter>
  )
}

Currency.propTypes = {
  innerLabel: PropTypes.string,
}

export default Currency
