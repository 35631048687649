import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { isValid, parseISO } from 'date-fns'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { TripMutations } from 'api/Trip/TripMutations'
import { Box } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { useUploadImage } from 'hooks/useUploadImage'
import { CustomVenueForm } from 'sharedComponents/ModalManagement/CustomVenueModal/CustomVenueForm'
import { NavigationBox } from 'sharedComponents/ModalManagement/TripOnboarding/NavigationBox'
import {
  findNextStepName,
  ONBOARDING_STEP_NAMES,
} from 'sharedComponents/ModalManagement/TripOnboarding/OnboardingStepper'
import { StepContext } from 'sharedComponents/ModalManagement/TripOnboarding/TripOnboarding'

const StyledWrap = styled(Box)`
  border-radius: 8px;
  border: 1px solid ${theme.COLORS.BG_DIVIDER};
`

export const OnboardingCustomVenue = ({ requirements, tripId }) => {
  const { setActiveStep } = useContext(StepContext)
  const [addCustomVenue, { loading: addLoading }] = useMutation(
    TripMutations.ADD_CUSTOM_VENUE
  )
  const { uploadImage, loading: uploadLoading } = useUploadImage()

  const [selectTripVenue, { loading: selectLoading }] = useMutation(
    TripMutations.SELECT_TRIP_VENUE_ONBOARDING
  )
  const [updateTrip, { loading: updateLoading }] = useMutation(
    TripMutations.UPDATE_TRIP
  )

  const onSelectVenue = async (venueId) => {
    const selectResponse = await selectTripVenue({
      variables: {
        id: venueId,
        tripId,
      },
    })

    const venueData = selectResponse?.data?.selectTripVenue

    if (venueData) {
      await updateTrip({
        variables: {
          input: {
            lat: venueData.venue?.data?.coordinates?.lat,
            lon: venueData.venue?.data?.coordinates?.lon,
            ...(requirements.startDate
              ? {
                  dateFrom: isValid(requirements.startDate)
                    ? requirements.startDate
                    : parseISO(requirements.startDate),
                  dateTo: isValid(requirements.endDate)
                    ? requirements.endDate
                    : parseISO(requirements.endDate),
                }
              : {}),
          },
          tripId,
        },
      })
    }
  }

  const onSuccess = async (venueId) => {
    await onSelectVenue(venueId)

    toast.success('Custom venue added successfully')
    setActiveStep(findNextStepName(ONBOARDING_STEP_NAMES.VENUES))
  }

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)

      let imageResponse

      if (values.thumbnail) {
        try {
          imageResponse = await uploadImage(
            values.thumbnail,
            values.thumbnail?.name,
            { tripId }
          )
        } catch {
          toast.error('Image was not uploaded')
          return
        }
      }

      if (!imageResponse?.content?.url) {
        toast.error('Image was not uploaded, try again!')
        setSubmitting(false)
        return
      }

      const response = await addCustomVenue({
        variables: {
          input: {
            ...values,
            thumbnail: imageResponse.content.url,
          },
          tripId,
        },
      })
      const success = response.data.addCustomVenue

      if (success) {
        onSelectVenue(success.id)

        toast.success('Custom venue added successfully')
        setActiveStep(findNextStepName(ONBOARDING_STEP_NAMES.VENUES))
      }
    } catch {
      // do nothing
    }
  }

  return (
    <>
      <Text
        as="p"
        fontWeight="700"
        fontSize={theme.fontSizes.xxl}
        lineHeight={theme.lineHeights.xxl}
        mb={rem(16)}
      >
        Add your selected venue
      </Text>

      <StyledWrap>
        <CustomVenueForm
          venueIds={[]}
          onSubmit={onSubmit}
          onSuccess={onSuccess}
          endContent={
            <NavigationBox
              isLoading={
                uploadLoading || selectLoading || addLoading || updateLoading
              }
            />
          }
          tripId={tripId}
        />
      </StyledWrap>
    </>
  )
}

OnboardingCustomVenue.propTypes = {
  tripId: PropTypes.number,
  requirements: PropTypes.object.isRequired,
}
