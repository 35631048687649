import React from 'react'
import styled from '@emotion/styled'
import { math } from 'polished'
import PropTypes from 'prop-types'
import { space as styledSpace } from 'styled-system'
import { mq } from 'Theme'
import { NUMBER_SIZE, OFFSET } from './Step'

const SHIFT = math(`${NUMBER_SIZE} + ${OFFSET}`)

const OrderedList = styled('ol')`
  list-style: none;
  counter-reset: steps;

  ${mq.from.tv`
    position: relative;
    left: -${SHIFT};

    width: calc(100% + ${SHIFT});
    padding-left: ${SHIFT};
  `}

  ${styledSpace}
`

const Stepper = ({ children, ...props }) => (
  <OrderedList {...props}>{children}</OrderedList>
)

Stepper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Stepper
