import React from 'react'
import styled from '@emotion/styled'
import { ToastStyles } from '@nextretreat/ui-components/dist/Toast'
import { Slide, ToastContainer } from 'react-toastify'
import { ReactComponent as CloseSVG } from 'assets/images/svg/close.svg'
import { TOAST_AUTO_CLOSE } from 'constants/constants'

import 'react-toastify/dist/ReactToastify.min.css'

const StyledContainer = styled(ToastContainer)`
  ${ToastStyles}

  /* TODO: add to lib */
  .Toastify__toast-body {
    white-space: pre-wrap;
  }
`

const StyledButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'closeToast',
})``

/*
 * @usage:
 *  light-  add {className: 'light'} to options to make toast light
 *  closebutton- add {className: 'hideButton'} to options to hide closebutton
 *  icon- add {icon: false} to options to remove icon from toast
 */
const Container = () => (
  <StyledContainer
    position="bottom-left"
    autoClose={TOAST_AUTO_CLOSE}
    hideProgressBar
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    limit={5}
    pauseOnHover
    transition={Slide}
    closeButton={
      <StyledButton type="button" className="Toastify__close-button">
        <CloseSVG />
      </StyledButton>
    }
  />
)

export default Container
