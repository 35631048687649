const scriptExists = (url) => {
  const scripts = document.getElementsByTagName('script')
  // eslint-disable-next-line no-plusplus
  for (let i = scripts.length; i--; ) {
    if (scripts[i].src === url) return true
  }
  return false
}

export const analytics = (w, d, s, l, i) => {
  // eslint-disable-next-line no-param-reassign
  w.dataLayer = window.dataLayer || []
  w.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
  const dl = l !== 'dataLayer' ? `&l=${l}` : ''
  const scr = `https://gtm.nextretreat.com/nr.js?id=${i}${dl}`
  /*
        To avoid Multiple installations of google tag manager detected warning
    */
  if (!scriptExists(scr)) {
    const f = d.getElementsByTagName(s)[0]
    const j = d.createElement('script')
    j.async = true
    j.src = scr
    f?.parentNode?.insertBefore(j, f)
  }
}
