/* eslint-disable no-loop-func */
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  defaultDataIdFromObject,
  fromPromise,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { rollbar, rollbarConfig } from 'App'
import meta from 'meta.json'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { AuthMutations } from 'api/Auth/AuthMutations'
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
} from 'constants/constants'
import { PUBLIC_ROUTES } from 'constants/routes'
import { afterwareLink } from 'sharedComponents/ModalManagement/RefreshModal'
import { toast } from 'utils/helpers'
import { authStorage } from './utils/storage'

const authLink = setContext((_, { headers }) => {
  const token = authStorage.get(LOCAL_STORAGE_ACCESS_TOKEN_KEY)

  return {
    headers: {
      ...headers,
      'X-request-id': uuidv4(),
      'X-app-with': 'app',
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

let client

let isRefreshing = false
let pendingRequests = []

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback())
  pendingRequests = []
}

const ERROR_CODES_WITHOUT_ROLLBAR = [403, 401]
const ERROR_CODES_WITHOUT_TOAST = [401]

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      // eslint-disable-next-line no-restricted-syntax
      for (const err of graphQLErrors) {
        let uuid = ''
        if (!ERROR_CODES_WITHOUT_ROLLBAR.includes(err.code)) {
          rollbar.configure({
            ...rollbarConfig,
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
              requestId: operation.getContext().headers['X-request-id'],
            },
          })

          const response =
            process.env.REACT_APP_ENV === 'production'
              ? rollbar.error(
                  `${meta.version ? `${meta.version}: ` : ''}${err?.message}`,
                  err
                )
              : { uuid: '' }

          uuid = response.uuid
        }

        if (!ERROR_CODES_WITHOUT_TOAST.includes(err.code))
          toast.error(`${err.message}${uuid ? `\n${uuid}` : ''}`, {
            toastId: err.message,
          })

        const refreshToken = authStorage.get(LOCAL_STORAGE_REFRESH_TOKEN_KEY)

        switch (err.code) {
          case 401:
            if (refreshToken) {
              let forward$

              if (!isRefreshing) {
                isRefreshing = true
                forward$ = fromPromise(
                  client
                    .mutate({
                      mutation: AuthMutations.REFRESH_TOKEN,
                      variables: {
                        token: refreshToken,
                      },
                    })
                    .then(({ data }) => {
                      // Store the new tokens for your auth link
                      const oldHeaders = operation.getContext().headers

                      operation.setContext({
                        headers: {
                          ...oldHeaders,
                          authorization: `Bearer ${data.refreshToken.accessToken}`,
                        },
                      })

                      const { refreshToken, accessToken } =
                        data.refreshToken ?? {}
                      if (refreshToken) {
                        authStorage.set(
                          LOCAL_STORAGE_REFRESH_TOKEN_KEY,
                          refreshToken
                        )
                      }
                      if (accessToken) {
                        authStorage.set(
                          LOCAL_STORAGE_ACCESS_TOKEN_KEY,
                          accessToken
                        )
                      }

                      resolvePendingRequests()

                      return data.refreshToken.accessToken
                    })
                    .catch(() => {
                      pendingRequests = []

                      toast.error('Unauthorized, please log in again')
                      authStorage.remove(LOCAL_STORAGE_ACCESS_TOKEN_KEY)
                      authStorage.remove(LOCAL_STORAGE_REFRESH_TOKEN_KEY)

                      useNavigate()(`/${PUBLIC_ROUTES.LOGIN}`)
                    })
                    .finally(() => {
                      isRefreshing = false
                    })
                ).filter((value) => Boolean(value))
              } else {
                // Will only emit once the Promise is resolved
                forward$ = fromPromise(
                  new Promise((resolve) => {
                    pendingRequests.push(() => resolve())
                  })
                )
              }

              return forward$.flatMap(() => forward(operation))
            }
            if (authStorage.get(LOCAL_STORAGE_ACCESS_TOKEN_KEY)) {
              toast.error('Unauthorized, please log in again')
              authStorage.remove(LOCAL_STORAGE_ACCESS_TOKEN_KEY)
            }
          // eslint-disable-next-line no-fallthrough
          default:
        }
      }
    }
    if (networkError && networkError.code !== 20) {
      toast.error(
        'Network error ocurred. Check your internet connection or refresh the page.'
      )
    }
    return null
  }
)

client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'AmenitiesGroup':
          return `AmenitiesGroup:${responseObject.id}:${responseObject.amenities
            .map((amenity) => amenity[0])
            .join()}`
        case 'TripIncludesVenueResponse':
          return `TripIncludesVenueResponse:${responseObject.tripId}:${responseObject.venueId}`
        case 'ActivityPhoto':
          return `ActivityPhoto:${responseObject.url}`
        case 'TripAccess':
          return `TripAccess:${responseObject.level}`
        case 'AttendanceInfo':
          return `AttendanceInfo:${responseObject.token}`
        case 'AirportIsochrone':
          return `AirportIsochrone:${responseObject.id}:${responseObject.distanceInMinutes}`
        case 'TeamLocation':
          return `TeamLocation:${responseObject.tripId}:${responseObject.id}`
        default:
          return defaultDataIdFromObject(responseObject)
      }
    },
    typePolicies: {
      Trip: {
        merge: true,
        fields: {
          feed: {
            keyArgs: false,

            merge(existing, incoming) {
              return [...(existing || []), ...incoming]
            },
          },
          data: {
            keyArgs: false,

            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
        },
      },
    },
  }),
  link: ApolloLink.from([
    errorLink,
    authLink,
    afterwareLink,
    createUploadLink({
      uri: process.env.REACT_APP_API_URL,
    }),
  ]),
  fetchOptions: {
    mode: 'no-cors',
  },
})

const ApolloProviderComp = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
)

ApolloProviderComp.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ApolloProviderComp
