import { useContext } from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Input } from '@nextretreat/ui-components/dist/Input'
import theme from '@nextretreat/ui-components/dist/Theme'
import { Form, Formik } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { TripQueries } from 'api/Trip/TripQueries'
import { ReactComponent as NoVenueMark } from 'assets/images/svg/no-venue-mark.svg'
import Button from 'components/atoms/Button'
import { Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { EmptyState } from 'components/EmptyState'
import { Icon } from 'components/Icon'
import { LoadingWrapper } from 'components/LoadingWrapper'
import { useAddTripVenue } from 'hooks/trip/useAddTripVenue'
import { NavigationBox } from 'sharedComponents/ModalManagement/TripOnboarding/NavigationBox'
import {
  findNextStepName,
  ONBOARDING_STEP_NAMES,
} from 'sharedComponents/ModalManagement/TripOnboarding/OnboardingStepper'
import { StepContext } from 'sharedComponents/ModalManagement/TripOnboarding/TripOnboarding'
import { VenueCard } from 'sharedComponents/VenueCard'
import { toast } from 'utils/helpers'

const recommendationalidationSchema = Yup.object().shape({
  venues: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        destinationId: Yup.string().nullable(),
      })
    )
    .min(1)
    .required('Please select at least one venue'),
})

const StyledCheckbox = styled(Input.Checkbox)`
  position: absolute;
  z-index: 2;
  top: ${rem(14)};
  left: ${rem(14)};
  & > span {
    background-color: white;
  }
`

const StyledEmptyState = styled(EmptyState)`
  box-shadow: none;
`

const StyledForm = styled(Form)`
  width: 100%;
`

export const OnboardingVenueRecommendation = ({
  requirements,
  tripId,
  setContentType,
}) => {
  const { setActiveStep } = useContext(StepContext)
  const { fullBudget, teamSize, dayCount, destination } = requirements ?? {}

  const { data, loading } = useQuery(TripQueries.VENUE_RECOMMENDATIONS, {
    variables: {
      input: {
        pax: teamSize,
        destination: destination || '',
        budget: Math.round(fullBudget / dayCount),
      },
    },
  })

  const [addTripVenue, { loading: addLoading }] = useAddTripVenue()

  const onSubmit = async ({ venues }, { setSubmitting }) => {
    setSubmitting(true)

    let addedVenuesLength = 0

    await Promise.all(
      venues.map(async ({ id: venueId, destinationId }) => {
        const response = await addTripVenue({
          variables: {
            input: { venueId, destinationId },
            tripId,
          },
        })

        if (response.data?.addTripVenue?.id) addedVenuesLength += 1
      })
    )

    if (addedVenuesLength === venues.length) {
      toast.success('Trip venues added successfully')
    } else {
      toast.error(
        `Some venues were not added to trip. You can add them later. Count: ${
          venues.length - addedVenuesLength
        }`
      )
    }

    setActiveStep(findNextStepName(ONBOARDING_STEP_NAMES.VENUES))
    setSubmitting(false)
  }

  const renderCard = (venue, values, setFieldValue) =>
    venue.tile || (
      <VenueCard
        source="recommendation"
        key={venue.id}
        venue={venue}
        additionalContent={
          <StyledCheckbox
            checked={values.venues?.some(({ id }) => id === venue.id)}
            value={venue.id}
            label=""
            onChange={() =>
              setFieldValue(
                'venues',
                values.venues?.some(({ id }) => id === venue.id)
                  ? values.venues.filter(({ id }) => id !== venue.id)
                  : [
                      ...values.venues,
                      {
                        id: venue.id,
                        destinationId: venue.destinationId,
                      },
                    ]
              )
            }
          />
        }
      />
    )

  const venueRecommendations = (data?.venueRecommendations || []).slice(0, 6)

  return (
    <LoadingWrapper loading={loading}>
      {venueRecommendations.length ? (
        <Formik
          validationSchema={recommendationalidationSchema}
          initialValues={recommendationalidationSchema.cast({
            venues: venueRecommendations
              .filter((venue) => venue.id)
              .slice(0, 8)
              .map(({ id, destinationId }) => ({ id, destinationId })),
          })}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <StyledForm>
              <Text
                as="p"
                fontWeight="700"
                fontSize={theme.fontSizes.xxl}
                lineHeight={theme.lineHeights.xxl}
                mb={rem(16)}
              >
                Recommended venues
              </Text>

              <Grid gridTemplateColumns="1fr 1fr 1fr" gridGap="m">
                {venueRecommendations.map((venue) =>
                  renderCard(venue, values, setFieldValue)
                )}
              </Grid>
              <NavigationBox isLoading={addLoading} />
            </StyledForm>
          )}
        </Formik>
      ) : (
        <StyledEmptyState
          icon={<NoVenueMark />}
          title="No venues"
          subtitle="Start planning your trip by adding venues"
          button={
            <Button.Secondary
              iconLeft={<Icon name="add" />}
              onClick={() => setContentType('custom')}
            >
              Create custom venue
            </Button.Secondary>
          }
        />
      )}
    </LoadingWrapper>
  )
}

OnboardingVenueRecommendation.propTypes = {
  tripId: PropTypes.number,
  requirements: PropTypes.shape({
    fullBudget: PropTypes.number,
    teamSize: PropTypes.number,
    dayCount: PropTypes.number,
    destination: PropTypes.string,
  }).isRequired,
  setContentType: PropTypes.func.isRequired,
}
