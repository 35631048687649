import { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Select as UISelect } from '@nextretreat/ui-components/dist/Select'
import theme from '@nextretreat/ui-components/dist/Theme'
import { Field, useFormikContext } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import { COLORS } from 'Theme'
import { AccountQueries } from 'api/Account/AccountQueries'
import { PlanQueries } from 'api/Plan/PlanQueries'
import { ReactComponent as ArrowIconActive } from 'assets/images/svg/select-arrow-active.svg'
import { Box } from 'components/atoms/Layout'
import Input from 'components/Input'
import { Label } from 'components/Input/Input'
import Tooltip from 'components/molecules/Tooltip'
import { requireDynamicFlag } from 'utils/flags'

const ArrowActive = (props) => (
  <components.DropdownIndicator {...props}>
    <ArrowIconActive />
  </components.DropdownIndicator>
)

const StyledSelect = styled(Select)`
  position: absolute;
  height: calc(100% - 2px);
  top: 1px;
  left: 1px;
  width: 58px;
  background: ${COLORS.LYNX_WHITE};
  border-radius: 4px 0px 0px 4px;
  border: 0;
`

const StyledBox = styled(Box)`
  position: relative;
`

const StyledInput = styled(Input)`
  text-indent: 58px;
  box-shadow: none;
  border-width: 1px;
  border-color: ${theme.COLORS.BG_DIVIDER};
  &:hover,
  &:focus {
    border-width: 1px;
    border-color: ${theme.COLORS.BG_DARK_DIVIDER};
    background-color: white;
  }

  &:focus-visible {
    outline: 2px solid #96c0ff;
    outline-offset: 2px;
  }
`

const StyledFlagImage = styled.img`
  height: ${rem(14)};
`

const customStyles = {
  indicatorSeparator: () => null,
  singleValue: (styles) => ({
    ...styles,
    paddingRight: '20px',
  }),
  input: (styles) => ({
    ...styles,
    maxWidth: '30px',
    overflow: 'hidden',
    flex: '1',
  }),
  container: (styles) => ({
    ...styles,
    position: 'absolute',
    ':focus-visible': {
      ...styles[':active'],
      outline: 'none',
    },
  }),
  control: (styles) => ({
    ...styles,
    minHeight: '100%',
    height: '100%',
    position: 'absolute',
    borderWidth: '0',
    boxShadow: 'none',
    cursor: 'pointer',
    backgroundColor: COLORS.LYNX_WHITE,
    ':focus-visible': {
      ...styles[':active'],
      border: '0',
    },
  }),
}

export const TaxInput = ({
  taxIdType,
  name = 'taxId',
  setFieldValue,
  ...passingProps
}) => {
  const { data } = useQuery(AccountQueries.TAX_IDS)
  const firstTaxId = data?.taxIds?.[0]
  useEffect(() => {
    if (!taxIdType && firstTaxId?.value) {
      setFieldValue(`${name}.type`, firstTaxId.value)
    }
  }, [data])

  const euFlagObject = {
    label: <StyledFlagImage src={requireDynamicFlag('eu')} alt="eu" />,
    value: 'eu_vat',
  }

  return data ? (
    <Box>
      <Label>Tax ID*</Label>
      <StyledBox>
        <StyledSelect
          defaultValue={
            taxIdType === 'eu_vat'
              ? euFlagObject
              : {
                  label: (
                    <Tooltip text={firstTaxId.description}>
                      <StyledFlagImage
                        src={requireDynamicFlag(firstTaxId.code)}
                        alt={firstTaxId.code}
                      />
                    </Tooltip>
                  ),
                  value: `${firstTaxId.value}.${firstTaxId.code}.${firstTaxId.country}`,
                }
          }
          inputProps={{
            autoComplete: 'off',
            autoCorrect: 'off',
            spellCheck: 'off',
          }}
          isSearchable
          styles={customStyles}
          options={[
            ...(data.taxIds || []).map(
              ({ country, description, value, code }) => ({
                label: (
                  <Tooltip text={description}>
                    <StyledFlagImage
                      src={requireDynamicFlag(code)}
                      alt={code}
                    />
                  </Tooltip>
                ),
                value: `${value}.${code}.${country}`,
              })
            ),
            euFlagObject,
          ]}
          placeholder=""
          onChange={({ value }) =>
            setFieldValue(`${name}.type`, value.split('.')[0])
          }
          components={{
            DropdownIndicator: ArrowActive,
          }}
        />
        <Field name={`${name}.value`}>
          {({ field, meta }) => (
            <StyledInput
              required
              isInvalid={meta.touched && meta.error !== undefined}
              {...field}
              placeholder={
                data.taxIds.find(({ value }) => taxIdType === value)?.example ||
                'Enter tax ID'
              }
              {...passingProps}
            />
          )}
        </Field>
      </StyledBox>
    </Box>
  ) : null
}

TaxInput.propTypes = {
  taxIdType: PropTypes.string.isRequired,
  name: PropTypes.string,
  setFieldValue: PropTypes.func,
}

export const CountrySelect = ({ name }) => {
  const { setFieldValue, values, errors, touched } = useFormikContext()
  const { data } = useQuery(PlanQueries.GET_COUNTRIES)

  const countriesSelectOptions = useMemo(
    () =>
      data?.countries.map(({ name: label, code }) => ({
        label,
        value: { code, name: label },
      })) || [],
    [data]
  )

  const country = name ? values[name]?.country : values.country
  const error = name ? errors[name]?.country : errors.country
  const fieldTouched = name ? touched[name]?.country : touched.country

  const selectedCountryFromString =
    typeof country === 'string'
      ? data?.countries.find(({ code }) => code === country)
      : undefined

  const countryObject = selectedCountryFromString
    ? {
        label: selectedCountryFromString.name,
        value: {
          code: selectedCountryFromString.code,
          name: selectedCountryFromString.name,
        },
      }
    : country

  return (
    <UISelect
      isBlock
      label="Country"
      required
      options={countriesSelectOptions}
      value={countryObject}
      placeholder="Enter country"
      invalid={error && fieldTouched}
      menuPlacement="top"
      onChange={(value) =>
        setFieldValue(`${name ? `${name}.` : ''}country`, value)
      }
    />
  )
}

CountrySelect.propTypes = {
  name: PropTypes.string,
}
