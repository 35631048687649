export const PRIVATE_ROUTES = {
  NOT_FOUND: '404',
  PLAN: 'plan',
  ACCOUNT: 'account',
  MY_TRIPS: 'my-trips',
  CONCIERGE: 'concierge',
  TRIP: 'trip',
  MY_TRIP: 'my-trip',
}

export const PUBLIC_ROUTES = {
  ATTENDANCE: 'attendance',
  DEMO_ATTENDANCE: 'demo-attendance',
  LOGIN: 'login',
  REGISTRATION: 'registration',
  CONFIRMATION: 'user-confirmation',
  REQUEST_RESET_PASSWORD: 'request-reset',
  SET_NEW_PASSWORD: 'reset-password',
  TRIP_SHARED: 'trip/shared',
  TERMS: 'terms',
  GRANT_ACCESS: 'grant-access',
}
