import { useCallback, useState } from 'react'
import { MODAL_TYPES, useModalManagement } from 'ModalManagementProvider'
import PropTypes from 'prop-types'
import { TripMutations } from 'api/Trip/TripMutations'
import { Confirmation } from 'components/Confirmation'
import { TRIP_TAB_ACCESS_NAMES } from 'constants/constants'
import { useTripMutation } from 'hooks/trip/useTripMutation'
import { EnsureWriteAccess } from 'routes/Trip/AccessComponents'
import { HistoryMenuItem } from 'sharedComponents/ModalManagement/HistoryDrawer'
import {
  MenuItem,
  MoreButton,
  MoreIcon,
  RemoveButton,
} from 'sharedComponents/MoreButton'
import { updateChangeTodoCacheFunction } from '../Itinerary/AddTodoWithEntry'

export const TodosMoreButton = ({
  todoItem,
  tripId,
  editedAt,
  editedBy,
  createdAt,
}) => {
  const [isDisabled, setIsDisabled] = useState()

  const { openModal } = useModalManagement()

  const onOpenModal = () => {
    openModal({
      type: MODAL_TYPES.TODO_MODAL,
      tripId,
      todoItem,
    })
  }

  const [removeTodo, { loading }] = useTripMutation(TripMutations.REMOVE_TODO)

  const onRemoveTodo = useCallback(() => {
    removeTodo({
      variables: {
        id: todoItem.id,
        tripId,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        removeTodo: true,
      },
      update: (cache, { data: newData }) => {
        if (newData.removeTodo) {
          updateChangeTodoCacheFunction(
            cache,
            todoItem.itineraryEntryId,
            tripId
          )

          cache.modify({
            id: cache.identify({ __typename: 'Todo', id: todoItem.id }),
            fields: (_, { DELETE }) => DELETE,
          })
        }
      },
    })
  }, [todoItem.id, todoItem.itineraryEntryId, tripId])

  return (
    <MoreButton targetComp={MoreIcon} isDisabled={isDisabled}>
      {(closePopup) => (
        <>
          <EnsureWriteAccess name={TRIP_TAB_ACCESS_NAMES.TODOS}>
            <MenuItem
              as="button"
              type="button"
              icon="edit"
              text="Edit task"
              onClick={() => {
                onOpenModal()
                closePopup()
              }}
            />
          </EnsureWriteAccess>

          <HistoryMenuItem
            editedAt={editedAt}
            editedBy={editedBy}
            createdAt={createdAt}
            closePopup={closePopup}
          />

          <EnsureWriteAccess name={TRIP_TAB_ACCESS_NAMES.TODOS}>
            <Confirmation
              isLoading={loading}
              onConfirm={() => {
                onRemoveTodo()
                setIsDisabled(false)
              }}
              onClose={() => setIsDisabled(false)}
              onOpen={() => {
                setIsDisabled(true)
                closePopup()
              }}
              label={`Do you want to remove "${todoItem.title}" from todos?`}
              targetComp={RemoveButton}
              type="delete"
              targetLabel="Remove"
            />
          </EnsureWriteAccess>
        </>
      )}
    </MoreButton>
  )
}

TodosMoreButton.propTypes = {
  todoItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    deadline: PropTypes.string,
    assignee: PropTypes.string,
    itineraryEntryId: PropTypes.number,
  }).isRequired,
  tripId: PropTypes.number.isRequired,
  editedAt: PropTypes.string,
  editedBy: PropTypes.string,
  createdAt: PropTypes.string,
}
