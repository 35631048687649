import { useMutation } from '@apollo/client'
import { TripMutations } from 'api/Trip/TripMutations'
import { TripQueries } from 'api/Trip/TripQueries'

const mapTripsAndAddVenueCount = (trips, tripId) =>
  trips.map((trip) => {
    if (trip.id === tripId) {
      return {
        ...trip,
        data: {
          ...trip.data,
          venuesCount: trip.data.venuesCount + 1,
        },
      }
    }
    return trip
  })

export const useAddTripVenue = () =>
  useMutation(TripMutations.ADD_TRIP_VENUE, {
    update: (cache, { data: newData }) => {
      if (newData.addTripVenue) {
        cache.modify({
          id: `TripVenue:${newData.addTripVenue.id}`,
          fields: {
            venues: (existing) => [...existing, newData.addTripVenue.venueId],
          },
        })

        const query = {
          query: TripQueries.GET_ALL_TRIPS,
        }

        const data = cache.readQuery(query)

        if (data) {
          cache.writeQuery({
            ...query,
            data: {
              ...data,
              userMe: {
                ...data.userMe,
                trips: mapTripsAndAddVenueCount(
                  data.userMe.trips,
                  newData.addTripVenue.id
                ),
                sharedTrips: mapTripsAndAddVenueCount(
                  data.userMe.sharedTrips,
                  newData.addTripVenue.id
                ),
              },
            },
          })
        }
      }
    },
  })
