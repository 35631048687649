import { useState } from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem, transparentize } from 'polished'
import PropTypes from 'prop-types'
import ModalComponent from 'react-modal'
import { BOXSHADOWS, mq } from 'Theme'
import nextRetreatLogo from 'assets/images/svg/logo-next-retreat.svg'
import { shouldForwardPropOptions } from 'utils/helpers'
import { disableBodyScroll, enableBodyScroll } from 'utils/scroll'
import { Box, Flex } from './atoms/Layout'
import { Ellipsis } from './atoms/Typography'
import Device from './Device'
import { Icon } from './Icon'

export const ModalPortal = styled('div')`
  & .ReactModal__Overlay {
    overflow: auto;
    z-index: 1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${transparentize(0.2, theme.COLORS.WHITE)} !important;
  }

  &,
  & > * {
    z-index: 50 !important;
  }
`

ModalPortal.defaultProps = {
  id: 'modal-portal',
}

const PADDING = 0
const CLOSE_BUTTON_SIZE = rem('32px')

const Content = styled(ModalComponent, shouldForwardPropOptions)`
  position: relative;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;

  max-height: 90vh;
  ${({ $contentWidth }) =>
    $contentWidth
      ? `width: ${$contentWidth};max-width: 100vw;`
      : `min-width: ${rem('520px')};`}

  box-shadow: ${BOXSHADOWS.CARD};
  ${({ $customBackgroundColor }) =>
    $customBackgroundColor
      ? `background-color: ${$customBackgroundColor};`
      : `background-color: ${theme.COLORS.WHITE};`}

  border-radius: 8px;
  outline: none;

  ${({ $isFullscreen }) =>
    $isFullscreen &&
    `
    max-height: 100vh;
    min-width: 0;
    width: 100vw;
    height: var(--nr-100vh, 100vh);
  `}

  ${mq.to.tablet`
    max-height: 100vh;
    min-width: 0;
    width: 100vw;
    height: var(--nr-100vh, 100vh);
    `}
`

const CloseButton = styled('button')`
  position: absolute;
  top: ${rem(14)};
  right: ${rem(16)};

  display: flex;
  align-items: center;
  justify-content: center;

  height: ${CLOSE_BUTTON_SIZE};
  width: ${CLOSE_BUTTON_SIZE};
  margin: ${({ customPadding }) => customPadding || PADDING};
`

export const HeaderBox = styled(Flex)`
  position: relative;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.COLORS.BG_DIVIDER};

  & > * {
    flex: 1;
  }

  & > span {
    text-align: right;
  }
`

export const FooterBox = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  gap: ${rem(12)};
  justify-content: flex-end;
  padding: ${rem(16)} ${rem(24)};
  border-top: 1px solid ${theme.COLORS.BG_DIVIDER};
`

const Logo = styled('img')`
  max-width: ${rem('165px')};
  height: ${rem(20)};
  flex: none;
`

const Modal = ({
  children,
  isOpen,
  closeModal,
  ariaLabel,
  title,
  hasCloseButton = true,
  preventScroll = true,
  className,
  contentWidth,
  customPadding,
  customBackgroundColor,
  isFullscreen,
  childrenWrapProps,
}) => {
  const [ref, setRef] = useState()

  return (
    <Content
      id="modal"
      preventScroll={preventScroll}
      parentSelector={() => document.querySelector('#modal-portal')}
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={className}
      aria-label={ariaLabel}
      $isFullscreen={isFullscreen}
      $contentWidth={contentWidth}
      $customBackgroundColor={customBackgroundColor}
      onAfterOpen={
        preventScroll
          ? () =>
              disableBodyScroll(ref, {
                allowTouchMove: (el) => ref?.contains(el),
              })
          : undefined
      }
      appElement={document.querySelector('#root')}
      onAfterClose={preventScroll ? () => enableBodyScroll(ref) : undefined}
    >
      <Box position="relative">
        {title ? (
          <HeaderBox p={`${rem(16)} ${rem(24)}`}>
            {isFullscreen && (
              <Device sizes={['tablet', 'desktop', 'tv']}>
                <Flex flex="1">
                  <Logo src={nextRetreatLogo} alt="Next retreat logo" />
                </Flex>
              </Device>
            )}

            {typeof title === 'string' ? (
              <Ellipsis
                as="p"
                fontWeight="700"
                textAlign={
                  isFullscreen ? { mobile: 'left', tablet: 'center' } : 'left'
                }
                fontSize="l"
                color={theme.COLORS.TXT_MAIN}
              >
                {title}
              </Ellipsis>
            ) : (
              title
            )}

            {isFullscreen && hasCloseButton && (
              <Icon name="close" onClick={closeModal} />
            )}
          </HeaderBox>
        ) : null}

        {hasCloseButton && !isFullscreen && (
          <CloseButton
            type="button"
            onClick={closeModal}
            data-cy="btn-close-modal"
          >
            <Icon
              fontSize="20px"
              name="close"
              color={theme.COLORS.TXT_DEFAULT}
            />
          </CloseButton>
        )}
      </Box>
      <Box
        ref={setRef}
        overflow="auto"
        flex="1"
        p={customPadding}
        {...childrenWrapProps}
      >
        {children}
      </Box>
    </Content>
  )
}

Modal.propTypes = {
  childrenWrapProps: PropTypes.object,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  ariaLabel: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  preventScroll: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  className: PropTypes.string,
  contentWidth: PropTypes.string,
  customPadding: PropTypes.string,
  customBackgroundColor: PropTypes.string,
  isFullscreen: PropTypes.bool,
}

export const useModal = (openOnInit) => {
  const [isOpen, setOpen] = useState(openOnInit)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const toggleModal = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  return { isOpen, openModal, closeModal, toggleModal }
}

export default Modal
