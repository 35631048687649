import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { decode, encode } from 'utils/helpers'
import { local } from '../../utils/storage'

export const useSteps = () => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  const parsedSearch = React.useMemo(() => decode(search), [search])

  const getNextSearch =
    (fn) =>
    (nextSteps, { overwrite, replace } = {}) => {
      const nextStepsObj =
        typeof nextSteps === 'function' ? nextSteps(parsedSearch) : nextSteps

      const nextKeys = Object.keys(nextStepsObj || {})

      return fn(
        `?${encode({
          ...(overwrite ? {} : parsedSearch),
          step: nextKeys?.[nextKeys.length - 1],
          ...nextStepsObj,
        })}`,
        replace
      )
    }

  const getStorageStepData = () => {
    if (local.getItem('stepData')) {
      try {
        return JSON.parse(local.getItem('stepData'))
      } catch (error) {
        return null
      }
    }
    return null
  }

  const storageStepData = getStorageStepData()

  const setNextSteps = getNextSearch((nextSearch, replace) => {
    local.setItem('stepData', JSON.stringify(decode(nextSearch)))
    if (search !== nextSearch) {
      navigate(`${pathname}${nextSearch}`, { replace: !!replace })
    }
  })

  const getNextUrl = getNextSearch((nextSearch) => `/plan${nextSearch}`)

  return {
    stepData: parsedSearch,
    updateStepData: setNextSteps,
    getNextUrl,
    storageStepData,
  }
}
