import { useAuth } from 'AuthProvider'
import PropTypes from 'prop-types'

export const ProductGuidesCheck = ({ children }) => {
  const { user } = useAuth()

  return !user?.productGuidesDisabled ? children : null
}

ProductGuidesCheck.propTypes = {
  children: PropTypes.node,
}

export const AgencyFeaturesCheck = ({ children }) => {
  const { user } = useAuth()

  return user?.agencyFeaturesEnabled ? children : null
}

AgencyFeaturesCheck.propTypes = {
  children: PropTypes.node,
}

export const BetaFeaturesCheck = ({ children }) => {
  const { user } = useAuth()

  return user?.betaFeaturesEnabled ? children : null
}

BetaFeaturesCheck.propTypes = {
  children: PropTypes.node,
}
