import { rem } from 'polished'
import { COLORS } from 'Theme'
import BreakfastIcon from 'assets/images/svg/breakfast.svg'
import { escapeRegExp } from 'utils/helpers'

const MB = 1000000
export const MAX_FILE_SIZE = MB * 20

export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&language=en&key=${process.env.REACT_APP_GOOGLE_KEY}`

const LIBRARIES = ['places', 'geometry', 'drawing']

export const GOOGLE_MAPS_HOOK_PROPS = {
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
  libraries: LIBRARIES,
}

export const TOPBAR_HEIGHT = rem('60px')

export const MAP_SETTINGS = {
  DEFAULT_MAP_OPTIONS: {
    scrollwheel: false,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
  },
  DEFAULT_CENTER: { lat: 57, lng: 20 },
  DEFAULT_ZOOM: 4,
  MARKER_SIZE: {
    EXTRA_SMALL: 10,
    SMALL: 30,
    MEDIUM: 40,
  },
  PIXEL_OFFSET: {
    MARKER: {
      X: 0,
      Y: -35,
    },
    MARKER_BIG: {
      X: 0,
      Y: -60,
    },
    LINE: {
      X: 0,
      Y: 20,
    },
  },
  POLYLINE_OPTIONS: {
    DASHED: {
      geodesic: true,
      strokeOpacity: 0,
      strokeWeight: 2,
      strokeColor: COLORS.BLUEBERRY_SODA,
      icons: [
        {
          icon: {
            path: 'M 0,0 0,1',
            strokeOpacity: 1,
            strokeWeight: 2,
            scale: 3,
          },
          offset: '0',
          repeat: '10px',
        },
      ],
    },
    REGULAR: {
      geodesic: true,
      strokeOpacity: 1,
      strokeWeight: 2,
      strokeColor: COLORS.EXPLORATION_GREEN,
    },
  },
  DIRECTIONS_OPTIONS: { suppressMarkers: true, preserveViewport: true },
}

export const PHONE_REGEX =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const SYMBOL_REGEX = `[${escapeRegExp(
  `*.!@#$%^&(){}[]:;<>,.?/~_+-=|'\\"\` `
)}]`

export const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'accessToken'
export const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refreshToken'
export const LOCAL_STORAGE_CURRENCY_KEY = 'nr-currency'
export const LOCAL_STORAGE_DEMO_ATTENDANCE_KEY = 'demoAttendance'
export const LOCAL_STORAGE_FIRST_LOGIN_KEY = 'firstLogin'
export const LOCAL_STORAGE_NPS_MODAL_CLOSED = 'npsClosed'
export const LOCAL_STORAGE_UI_TYPE = 'uiType'
export const DEFAULT_CURRENCY_ISO_CODE = 'EUR'
export const DEFAULT_CURRENCY_SYMBOL = '€'
export const ENABLED_CURRENCIES = ['EUR', 'GBP', 'USD']

export const ATTENDANCE_UI_TYPES = {
  OLD: 'old',
  NEW: 'new',
}

export const BOARDING_TYPES = {
  NONE: { text: 'No-meal included', icon: BreakfastIcon },
  BREAKFAST: { text: 'Breakfast included', icon: BreakfastIcon },
  ALL_INCLUSIVE: { text: 'All inclusive', icon: BreakfastIcon },
}

export const TAB_VALUES = {
  TRIP_REQUIREMENTS: 'trip-requirements',
  MY_TEAM: 'my-team',
  VENUES: 'venues',
  ACTIVITIES: 'activities',
  VOTING: 'voting',
  DATES: 'dates',
  TRAVEL: 'travel',
  ITINERARY: 'itinerary',
  TODO_LIST: 'todo_list',
  SUMMARY: 'summary',
  DASHBOARD: 'dashboard',
  EXPENSES: 'expenses',
  TRANSFER: 'transfer',
  REVIEWS: 'reviews',
  EMISSIONS: 'emissions',
  FEED: 'feed',
  SLEEPING_LAYOUT: 'rooming-list',
  CONTACTS: 'contacts',
  QUESTIONS: 'questions',
}

export const ACCESS_LEVELS = {
  OWNER: 'owner',
  EDITOR: 'write',
  VIEWER: 'read',
}

export const ACCESS_LEVEL_NUMBER = {
  [ACCESS_LEVELS.VIEWER]: 1,
  [ACCESS_LEVELS.EDITOR]: 2,
}

export const TRIP_TAB_ACCESS_NAMES = {
  MY_TEAM: 'team',
  SUMMARY: 'summary',
  TRIP_REQUIREMENTS: 'tripRequirements',
  TRANSPORT: 'team',
  VENUES: 'venues',
  SETTINGS: 'settings',
  ITINERARY: 'itinerary',
  TODOS: 'todos',
  ACTIVITIES: 'activities',
  VOTING: 'voting',
  DATES: 'dates',
  EXPENSES: 'expenses',
  TRANSFER: 'transfer',
  REVIEWS: 'reviews',
  SLEEPING_LAYOUT: 'sleepingLayout',
  CONTACTS: 'contacts',
  QUESTIONS: 'questions',
}

export const TRIP_TAB_ACCESS_OPTIONS = [
  {
    value: TRIP_TAB_ACCESS_NAMES.MY_TEAM,
    label: 'My team & Travel data',
    tab: TAB_VALUES.MY_TEAM,
  },
  {
    value: TRIP_TAB_ACCESS_NAMES.TODOS,
    label: 'Todos',
    tab: TAB_VALUES.TODO_LIST,
  },
  {
    value: TRIP_TAB_ACCESS_NAMES.VENUES,
    label: 'Venues',
    tab: TAB_VALUES.VENUES,
  },
  {
    value: TRIP_TAB_ACCESS_NAMES.TRIP_REQUIREMENTS,
    label: 'Requirements',
    tab: TAB_VALUES.TRIP_REQUIREMENTS,
  },
  { value: TRIP_TAB_ACCESS_NAMES.DATES, label: 'Dates', tab: TAB_VALUES.DATES },
  {
    value: TRIP_TAB_ACCESS_NAMES.ITINERARY,
    label: 'Itinerary',
    tab: TAB_VALUES.ITINERARY,
  },
  {
    value: TRIP_TAB_ACCESS_NAMES.SETTINGS,
    label: 'Settings & Sharing',
  },
  {
    value: TRIP_TAB_ACCESS_NAMES.ACTIVITIES,
    label: 'Activities',
    tab: TAB_VALUES.ACTIVITIES,
  },
  {
    value: TRIP_TAB_ACCESS_NAMES.VOTING,
    label: 'Poll',
    tab: TAB_VALUES.VOTING,
  },
  {
    value: TRIP_TAB_ACCESS_NAMES.EXPENSES,
    label: 'Expenses',
    tab: TAB_VALUES.EXPENSES,
  },
  {
    value: TRIP_TAB_ACCESS_NAMES.TRANSFER,
    label: 'Airport transfer',
    tab: TAB_VALUES.TRANSFER,
  },
  {
    value: TRIP_TAB_ACCESS_NAMES.SLEEPING_LAYOUT,
    label: 'Rooming list',
    tab: TAB_VALUES.SLEEPING_LAYOUT,
  },
  {
    value: TRIP_TAB_ACCESS_NAMES.REVIEWS,
    label: 'Rating',
    tab: TAB_VALUES.REVIEWS,
  },
  {
    value: TRIP_TAB_ACCESS_NAMES.SUMMARY,
    label: 'Spreadsheet',
    tab: TAB_VALUES.SUMMARY,
  },
  {
    value: TRIP_TAB_ACCESS_NAMES.CONTACTS,
    label: 'Contacts',
    tab: TAB_VALUES.CONTACTS,
  },
  {
    value: TRIP_TAB_ACCESS_NAMES.QUESTIONS,
    label: 'Questions',
    tab: TAB_VALUES.QUESTIONS,
  },
]

export const PERMISSIONS_COLUMNS = [
  {
    title: 'No access',
    column: 'noAccess',
  },
  {
    title: 'Viewer',
    column: 'viewer',
  },
  {
    title: 'Editor',
    column: 'editor',
  },
]

export const PERMISSIONS_TYPES = PERMISSIONS_COLUMNS.map(({ column }) => column)

export const EXPENSE_TYPES = {
  ACCOMMODATION: 'Accommodation',
  FOOD: 'Food',
  ACTIVITIES: 'Activities',
  TRANSPORTATION: 'Transportation',
  OTHER: 'Other',
}

export const UNASSIGNED_LOCATION_OBJECT = {
  id: -1,
  name: 'Unassigned',
  members: [],
  __typename: 'TeamLocation',
  cityId: null,
  country: null,
  countryCode: null,
  originLocationId: null,
  originLocationCode: null,
  lat: null,
  lon: null,
}

export const LAST_OPENED_TRIPS = 'lastOpenedTrips'

export const blockTransientProps = {
  shouldForwardProp: (propName) => !propName.startsWith('$'),
}

export const DEADLINE_TYPES = {
  ATTENDANCE: 'attendance',
  RATING: 'rating',
  TRAVEL: 'travel',
}

export const DEADLINE_TYPES_TAB_NAMES = {
  [DEADLINE_TYPES.ATTENDANCE]: 'My Team',
  [DEADLINE_TYPES.RATING]: 'Reviews',
  [DEADLINE_TYPES.TRAVEL]: 'Travel data',
}

export const DEADLINE_API_KEYS_FROM_TYPES = {
  [DEADLINE_TYPES.ATTENDANCE]: 'deadlineAttendance',
  [DEADLINE_TYPES.RATING]: 'deadlineRating',
  [DEADLINE_TYPES.TRAVEL]: 'deadlineTravel',
}

export const DEADLINE_TYPES_API_KEYS = {
  ATTENDANCE: 'deadlineAttendance',
  RATING: 'deadlineRating',
  TRAVEL: 'deadlineTravel',
}

export const SOURCES = { WIMCO: 'WIM' }

export const EVENT_CATEGORISATION = {
  accounting: 'services',
  airport: 'transportation',
  amusement_park: 'activity',
  aquarium: 'activity',
  art_gallery: 'activity',
  atm: 'services',
  bakery: 'food_drink',
  bank: 'services',
  bar: 'food_drink',
  beauty_salon: 'services',
  bicycle_store: 'transportation',
  book_store: 'shopping',
  bowling_alley: 'activity',
  bus_station: 'transportation',
  cafe: 'food_drink',
  campground: 'other',
  car_dealer: 'transportation',
  car_rental: 'transportation',
  car_repair: 'services',
  car_wash: 'services',
  casino: 'other',
  cemetery: 'other',
  church: 'other',
  city_hall: 'other',
  clothing_store: 'shopping',
  convenience_store: 'shopping',
  courthouse: 'other',
  dentist: 'services',
  department_store: 'shopping',
  doctor: 'services',
  drugstore: 'shopping',
  electrician: 'services',
  electronics_store: 'shopping',
  embassy: 'services',
  fire_station: 'other',
  florist: 'shopping',
  funeral_home: 'other',
  furniture_store: 'shopping',
  gas_station: 'transportation',
  gym: 'activity',
  hair_care: 'services',
  hardware_store: 'shopping',
  hindu_temple: 'other',
  home_goods_store: 'shopping',
  hospital: 'services',
  insurance_agency: 'services',
  jewelry_store: 'shopping',
  laundry: 'services',
  lawyer: 'services',
  library: 'activity',
  light_rail_station: 'transportation',
  liquor_store: 'food_drink',
  local_government_office: 'other',
  locksmith: 'services',
  lodging: 'accomodation',
  meal_delivery: 'food_drink',
  meal_takeaway: 'food_drink',
  mosque: 'other',
  movie_rental: 'other',
  movie_theater: 'activity',
  moving_company: 'services',
  museum: 'activity',
  night_club: 'food_drink',
  painter: 'other',
  park: 'other',
  parking: 'transportation',
  pet_store: 'shopping',
  pharmacy: 'shopping',
  physiotherapist: 'services',
  plumber: 'services',
  police: 'other',
  post_office: 'other',
  primary_school: 'other',
  real_estate_agency: 'services',
  restaurant: 'food_drink',
  roofing_contractor: 'services',
  rv_park: 'other',
  school: 'other',
  secondary_school: 'other',
  shoe_store: 'shopping',
  shopping_mall: 'shopping',
  spa: 'activity',
  stadium: 'other',
  storage: 'services',
  store: 'shopping',
  subway_station: 'transportation',
  supermarket: 'shopping',
  synagogue: 'other',
  taxi_stand: 'transportation',
  tourist_attraction: 'activity',
  train_station: 'transportation',
  transit_station: 'transportation',
  travel_agency: 'transportation',
  university: 'other',
  veterinary_care: 'services',
  zoo: 'activity',
}

export const ICON_CATEGORIES_MAP = {
  accomodation: { color: '#4C5AA7', icon: 'bed' },
  activity: { color: '#0DB1B1', icon: 'local_activity' },
  food_drink: { color: '#EB994D', icon: 'local_dining' },
  services: { color: '#DE4D1F', icon: 'cleaning_services' },
  shopping: { color: '#AF4DEB', icon: 'shopping_basket' },
  transportation: { color: '#4D79EB', icon: 'directions_bus' },
  other: { color: '#EF6EDA', icon: 'add_box' },
}

export const EMAIL_VARIABLES = ['name', 'tripName', 'deadline']

export const SEGMENTATION_STEPS = {
  ROLE: 'role',
  ORGANISING: 'organising',
  JOURNEY_START: 'journey_start',
}

export const TRAVEL_SELECT_VALUES = {
  FLIGHT: 'flight',
  TRAIN: 'train',
  BUS: 'bus',
  CAR: 'car',
  OTHER: 'other',
}

export const TRAVEL_SELECT_OPTIONS = [
  {
    label: 'Flight',
    value: TRAVEL_SELECT_VALUES.FLIGHT,
  },
  {
    label: 'Train',
    value: TRAVEL_SELECT_VALUES.TRAIN,
  },
  {
    label: 'Bus',
    value: TRAVEL_SELECT_VALUES.BUS,
  },
  {
    label: 'Car',
    value: TRAVEL_SELECT_VALUES.CAR,
  },
  {
    label: 'Other',
    value: TRAVEL_SELECT_VALUES.OTHER,
  },
]

export const TRANSPORT_TYPE_ICONS_MAP = {
  [TRAVEL_SELECT_VALUES.FLIGHT]: {
    arrival: 'flight_land',
    departure: 'flight_takeoff',
  },
  [TRAVEL_SELECT_VALUES.TRAIN]: 'directions_railway',
  [TRAVEL_SELECT_VALUES.CAR]: 'directions_car',
  [TRAVEL_SELECT_VALUES.BUS]: 'directions_bus',
  [TRAVEL_SELECT_VALUES.OTHER]: 'directions',
}

export const TOAST_AUTO_CLOSE = 3000

export const TRIP_VENUE_HEADING_ID = 'trip-venue-heading'

export const DEMO_TRIP_ID = 746
