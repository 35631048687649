import { useState } from 'react'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useAuth } from 'AuthProvider'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import { useLocation } from 'react-router-dom'
import { mq, space } from 'Theme'
import * as Yup from 'yup'
import { AccountMutations } from 'api/Account/AccountMutations'
import { Box, Flex } from 'components/atoms/Layout'
import { H3, Text } from 'components/atoms/Typography'
import Stepper from 'components/Stepper'
import { PRIVATE_ROUTES } from 'constants/routes'
import { toast } from 'utils/helpers'
import { subscriptions } from 'utils/subscriptions'
import { FormikStepper } from './FormikStepper'
import { MESSAGE_TYPE, usePaymentMessage } from './PaymentMessageContext'
import {
  BillingDetailsStep,
  PaymentStep,
  PersonalDataStep,
} from './SubscriptionSteps'

const StyledFlex = styled(Flex)`
  gap: ${rem(60)};
  ${mq.to.tablet`
    flex-direction: column;
    gap: ${rem(20)};
  `}
`
const StyledStepBox = styled(Box)`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const StyledStep = styled(Stepper.Step)`
  padding-bottom: ${space.l} !important;
  margin-top: ${space.s};
  align-items: center;

  & > div {
    padding-bottom: ${space.s};
  }
`

export const SubscribeForm = ({
  billingDetails = {},
  user = {},
  paymentMethod,
  selectedPackage,
  closeModal,
}) => {
  const { showMessage } = usePaymentMessage()
  const { refetchData } = useAuth()
  const { pathname } = useLocation()
  const stripe = useStripe()
  const elements = useElements()
  const [step, setStep] = useState(0)
  const [showCardInput, setShowCardInput] = useState(null)
  const matches = useMediaQuery()

  const { title, price, intervalCount, interval } = subscriptions.find(
    ({ id }) => id === selectedPackage
  )

  const [createSubscription] = useMutation(
    AccountMutations.CREATE_SUBSCRIPTION,
    {
      refetchQueries: ['GetBillingDetails', 'PaymentMethod'],
    }
  )

  const onCreateSubscription = async (values, { setSubmitting }) => {
    setSubmitting(true)

    const paymentMethodId = !showCardInput && paymentMethod?.id

    if (!values.payWithInvoice && (!stripe || !elements)) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    let result

    if (!(values.payWithInvoice || paymentMethodId)) {
      result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements?.getElement('card'),
      })
    }

    if (result?.error) {
      toast.error(result.error.message)
    } else {
      try {
        const response = await createSubscription({
          variables: {
            input: {
              billingDetails: {
                address: {
                  line1: values?.line1,
                  line2: values?.line2,
                  postalCode: values?.postalCode,
                  city: values?.city,
                  state: values?.state,
                  country:
                    typeof values?.country === 'string'
                      ? values.country
                      : values?.country?.value?.code,
                },
                company: values.payAsCompany ? values.company : '',
                firstName: values.firstName,
                lastName: values.lastName,
                phoneNumber: values.phoneNumber,
                taxIds: values.payAsCompany
                  ? [values.taxId]
                  : values.taxId?.id
                  ? [{ id: values.taxId?.id, type: '', value: '' }]
                  : [],
              },
              priceId: selectedPackage,
              coupon:
                values.hasCoupon && values.coupon ? values.coupon : undefined,
              paymentMethodId: values.payWithInvoice
                ? undefined
                : paymentMethodId || result.paymentMethod.id,
              collectionMethod: values.payWithInvoice
                ? 'send_invoice'
                : 'charge_automatically',
            },
          },
        })

        if (
          response?.data?.createSubscription?.paymentStatus ===
          'requires_action'
        ) {
          const confirmationResult = await stripe.confirmCardPayment(
            response?.data?.createSubscription.clientSecret,
            {
              payment_method: paymentMethodId || result.paymentMethod.id,
            }
          )

          if (confirmationResult?.paymentIntent?.status === 'succeeded') {
            if (pathname === `/${PRIVATE_ROUTES.CONCIERGE}`) {
              toast.success('Your payment was successfull')
              closeModal?.(true)
            } else showMessage(MESSAGE_TYPE.SUCCESS)
            refetchData()
          } else if (pathname === `/${PRIVATE_ROUTES.CONCIERGE}`)
            toast.error('Subscription purchase have failed')
          else showMessage(MESSAGE_TYPE.FAILURE)
        } else {
          if (pathname === `/${PRIVATE_ROUTES.CONCIERGE}`) {
            toast.success(
              values.payWithInvoice
                ? 'Invoice sent'
                : 'Your payment was successfull'
            )
            closeModal?.(true)
          }
          showMessage(
            values.payWithInvoice ? MESSAGE_TYPE.INVOICE : MESSAGE_TYPE.SUCCESS
          )
          refetchData()
        }
      } catch {
        // do nothing
      }

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setSubmitting(false)
  }

  const steps = [
    'Personal information',
    'Billing information',
    'Summary & Payment',
  ]

  const { address, taxIds } = billingDetails

  return (
    <Box px={rem(24)} py={rem(16)} pb={{ mobile: rem(40) }}>
      <StyledFlex>
        <Box flex="1">
          <H3 fontWeight="600" mb={{ mobile: '10px', tablet: 0 }}>
            {title}
          </H3>
          <Text
            as="p"
            fontWeight="600"
            mb="l"
            fontSize={{ mobile: 'xl', tablet: 'l' }}
          >
            {price} € / {intervalCount !== 1 && intervalCount} {interval}
          </Text>

          {matches.mobile ? (
            <Flex>
              {steps.map((text, index) => (
                <Stepper.Step
                  key={`${text}_${index}`}
                  alwaysShowStep
                  active={step === index}
                >
                  <StyledStepBox
                    disabled={step < index}
                    onClick={step > index ? () => setStep(index) : undefined}
                  >
                    {text}
                  </StyledStepBox>
                </Stepper.Step>
              ))}
            </Flex>
          ) : (
            <Box ml="3rem">
              {steps.map((text, index) => (
                <StyledStep
                  key={`${text}_${index}`}
                  alwaysShowStep
                  active={step === index}
                >
                  <StyledStepBox
                    disabled={step < index}
                    onClick={step > index ? () => setStep(index) : undefined}
                  >
                    {text}
                  </StyledStepBox>
                </StyledStep>
              ))}
            </Box>
          )}
        </Box>
        <Box zIndex={20} flex="2">
          <FormikStepper
            initialValues={{
              email: user.email,
              firstName: billingDetails.firstName || '',
              lastName: billingDetails.lastName || '',
              phoneNumber: billingDetails.phoneNumber || '',
              company: billingDetails.company || '',
              line1: address?.line1 || '',
              line2: address?.line2 || '',
              postalCode: address?.postalCode || '',
              city: address?.city || '',
              state: address?.state || '',
              country: address?.country || '',
              taxId: {
                value: taxIds?.[0]?.value || '',
                type: taxIds?.[0]?.type || '',
                id: taxIds?.[0]?.id,
              },
              payWithInvoice: false,
              payAsCompany: !!billingDetails.company || !!taxIds?.[0],
              hasCoupon: false,
              coupon: '',
            }}
            step={step}
            setStep={setStep}
            onSubmit={onCreateSubscription}
          >
            <PersonalDataStep
              validationSchema={Yup.object({
                email: Yup.string().email().required(),
                firstName: Yup.string().required(),
                lastName: Yup.string().required(),
                phoneNumber: Yup.string().required(),
              })}
            />
            <BillingDetailsStep
              validationSchema={Yup.object({
                company: Yup.string(),
                line1: Yup.string().required(),
                line2: Yup.string(),
                postalCode: Yup.string().required(),
                city: Yup.string().required(),
                state: Yup.string(),
                country: Yup.mixed().required(),
                taxId: Yup.object().when(
                  'payAsCompany',
                  ([payAsCompany], schema) =>
                    payAsCompany
                      ? schema.shape({
                          type: Yup.string().required(),
                          value: Yup.string().required(),
                        })
                      : schema.shape({
                          type: Yup.string(),
                          value: Yup.string(),
                        })
                ),
                payAsCompany: Yup.boolean(),
              })}
            />
            <PaymentStep
              validationSchema={Yup.object({
                payWithInvoice: Yup.boolean(),
                hasCoupon: Yup.boolean(),
                coupon: Yup.string().when('hasCoupon', ([hasCoupon], schema) =>
                  hasCoupon ? schema.required() : schema
                ),
              })}
              setStep={setStep}
              paymentMethod={paymentMethod}
              priceId={selectedPackage}
              showCardInput={showCardInput}
              onShowClick={() => setShowCardInput(true)}
            />
          </FormikStepper>
        </Box>
      </StyledFlex>
    </Box>
  )
}

SubscribeForm.propTypes = {
  selectedPackage: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    company: PropTypes.string,
  }),
  paymentMethod: PropTypes.object,
  billingDetails: PropTypes.shape({
    address: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
    }),
    taxId: PropTypes.shape({
      value: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
}
