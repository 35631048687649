import { useState } from 'react'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useAuth } from 'AuthProvider'
import { useEvent } from 'EventLogProvider'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { AuthMutations } from 'api/Auth/AuthMutations'
import Button from 'components/atoms/Button'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Modal, { useModal } from 'components/Modal'
import {
  LOCAL_STORAGE_FIRST_LOGIN_KEY,
  SEGMENTATION_STEPS,
} from 'constants/constants'
import { toast } from 'utils/helpers'
import { local } from 'utils/storage'
import { FIRST_LOGIN_SEGMENTS, JourneyStartStep } from './JourneyStartStep'
import { OrganisingStep } from './OrganisingStep'
import { RoleStep } from './RoleStep'

const StyledButton = styled(Button.Primary)`
  text-decoration: underline;

  &:hover {
    text-decoration: none !important;
  }
`

const validationSchema = Yup.object().shape({
  purpose: Yup.string(),
  route: Yup.string(),
  position: Yup.string(),
  otherPosition: Yup.string(),
  alreadyPlanned: Yup.boolean(),
  currentlyPlanning: Yup.boolean(),
  ownCompany: Yup.boolean(),
})

const StyledForm = styled(Form)`
  height: 100%;
`

export const SegmentationProvider = () => {
  const { isSignedIn, user } = useAuth()

  return isSignedIn && user && !user.firstLogin ? <Segmentation /> : null
}

const Segmentation = () => {
  const { pushEvent } = useEvent()
  const { user } = useAuth()
  const navigate = useNavigate()

  const [step, setStep] = useState(SEGMENTATION_STEPS.ROLE)

  const localFirstLogin = local.getItem(LOCAL_STORAGE_FIRST_LOGIN_KEY)

  const { isOpen, closeModal } = useModal(!localFirstLogin)

  const [saveSegmentationData] = useMutation(
    AuthMutations.SAVE_SEGMENTATION_DATA
  )

  if (!localFirstLogin) local.setItem(LOCAL_STORAGE_FIRST_LOGIN_KEY, true)

  const steps = [
    {
      id: SEGMENTATION_STEPS.ROLE,
      component: (
        <RoleStep
          onSubmit={() => {
            setStep(SEGMENTATION_STEPS.ORGANISING)
          }}
        />
      ),
    },
    {
      id: SEGMENTATION_STEPS.ORGANISING,
      component: (
        <OrganisingStep
          onSubmit={() => {
            setStep(SEGMENTATION_STEPS.JOURNEY_START)
          }}
        />
      ),
    },
    {
      id: SEGMENTATION_STEPS.JOURNEY_START,
      component: <JourneyStartStep />,
    },
  ]

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)

    try {
      const response = await saveSegmentationData({
        variables: {
          input: {
            ...values,
            route: undefined,
            position:
              values.position === 'Other'
                ? values.otherPosition
                : values.position,
            otherPosition: undefined,
            ownCompany: values.currentlyPlanning
              ? values.ownCompany
              : undefined,
          },
        },
      })

      if (response?.data?.saveSegmentationData) {
        const purposeObject = FIRST_LOGIN_SEGMENTS.find(
          ({ title }) => title === values.purpose
        )
        if (purposeObject) {
          navigate(`/${values.route || purposeObject.route}`)
          pushEvent(purposeObject.event)
        }

        toast.success('Submited succesfully')
      }
    } catch {
      //
    }

    setSubmitting(false)

    closeModal()
  }

  return isOpen ? (
    <Modal
      isOpen={isOpen}
      ariaLabel={`Welcome ${user?.firstName}!`}
      isFullscreen
      title={
        <>
          <Text
            as="p"
            fontWeight="700"
            fontSize="l"
            textAlign={{ mobile: 'left', tablet: 'center' }}
            color={theme.COLORS.TXT_MAIN}
          >
            Welcome {user?.firstName}!
          </Text>

          <Flex justifyContent="flex-end" flex={{ mobile: 0.5, tablet: 1 }}>
            {step === SEGMENTATION_STEPS.JOURNEY_START ? (
              <StyledButton
                type="button"
                viewType={EButtonType.PLAIN}
                onClick={() => {
                  pushEvent('user-segmentation-own')
                  closeModal()
                }}
              >
                I’ll get started on my own
              </StyledButton>
            ) : (
              <StyledButton
                type="button"
                viewType={EButtonType.PLAIN}
                onClick={() => {
                  setStep(SEGMENTATION_STEPS.JOURNEY_START)
                }}
              >
                Skip for now
              </StyledButton>
            )}
          </Flex>
        </>
      }
      hasCloseButton={false}
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={validationSchema.cast({})}
        onSubmit={onSubmit}
      >
        <StyledForm>
          <Flex
            height="100%"
            backgroundColor={theme.COLORS.BG_SOFT}
            alignItems="center"
            flexDirection="column"
          >
            {steps.find((s) => s.id === step)?.component}
          </Flex>
        </StyledForm>
      </Formik>
    </Modal>
  ) : null
}
