import { useEffect, useState } from 'react'

const breakpoints = {
  mobile: 0,
  tablet: 768,
  desktop: 1024,
  tv: 1440,
}

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(null)

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)

      handleResize()

      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  const breakpointNames = Object.keys(breakpoints)

  return windowWidth
    ? {
        windowWidth,
        matches: breakpointNames.reduce(
          (arr, val) => ({
            ...arr,
            [val]:
              windowWidth >= breakpoints[val] &&
              (breakpointNames[
                breakpointNames.findIndex((bp) => bp === val) + 1
              ]
                ? windowWidth <=
                  breakpoints[
                    breakpointNames[
                      breakpointNames.findIndex((bp) => bp === val) + 1
                    ]
                  ]
                : true),
          }),
          {}
        ),
      }
    : { windowWidth }
}
