import { useState } from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useEvent } from 'EventLogProvider'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { ProductGuidesCheck } from 'sharedComponents/FeaturesChecks'
import { local } from 'utils/storage'
import { Box, Flex } from './atoms/Layout'
import { Text } from './atoms/Typography'
import Device from './Device'
import { Icon } from './Icon'
import { Tag } from './Tag'

const Wrap = styled(Box)`
  width: 100%;
  min-width: ${rem(320)};
  border: 1px solid ${theme.COLORS.BG_DIVIDER};
  border-radius: 8px;

  ${({ $isDark }) =>
    $isDark
      ? `
        background-color: ${theme.COLORS.BG_DARK};

        & h2 {
            color: ${theme.COLORS.WHITE};
        }
        & p, & .actionBox span {
            color: ${theme.COLORS.BG_DISABLED};
        }

        & .actionBox button {
            color: ${theme.COLORS.WHITE};
        }
        & .actionBox button:hover:not(:active){
            color: ${theme.COLORS.WHITE} !important;
        }
  `
      : `
        background-color: ${theme.COLORS.BG_SOFT};

        & .actionBox button,  & .actionBox button:hover,  & .actionBox button:focus {
            color: ${theme.COLORS.BRAND_DEFAULT};
        }
  `}
`

const PRODUCT_GUIDE_LOCAL_PREFIX = 'closed-product-guide-'

export const ProductGuide = ({
  id,
  // tags,
  title,
  description,
  actions,
  illustration,
  isDark,
  onClose,
  ...passingProps
}) => {
  const { pushEvent } = useEvent()

  const [isClosed, setIsClosed] = useState(
    local.getItem(`${PRODUCT_GUIDE_LOCAL_PREFIX}${id}`)
  )

  const _onClose = (e) => {
    onClose?.(e)
    pushEvent(`product-guide-c-${id}`)
    local.setItem(`${PRODUCT_GUIDE_LOCAL_PREFIX}${id}`, true)
    setIsClosed(true)
  }

  return !isClosed ? (
    <Device sizes={['desktop', 'tv']}>
      <ProductGuidesCheck>
        <Wrap
          key={id}
          flex="1"
          height="fit-content"
          $isDark={isDark}
          {...passingProps}
        >
          <Box p={rem(20)}>
            <Flex
              mb={rem(16)}
              alignItems="flex-start"
              justifyContent="space-between"
              $gap={rem(12)}
            >
              <Flex $gap={rem(8)} flexWrap="wrap">
                {/* {tags.map((tag) => (
                  <Tag key={tag}>{tag}</Tag>
                ))} */}
                <Tag>Product guide</Tag>
              </Flex>

              <Icon
                name="close"
                color={
                  isDark ? theme.COLORS.TXT_DISABLED : theme.COLORS.TXT_DEFAULT
                }
                onClick={_onClose}
              />
            </Flex>

            <Text
              fontWeight="700"
              fontSize={theme.fontSizes.l}
              lineHeight={theme.lineHeights.l}
              as="h2"
              mb={rem(4)}
              mt={rem(20)}
            >
              {title}
            </Text>

            <Text fontSize={theme.fontSizes.s} as="p">
              {description}
            </Text>

            {actions && (
              <Flex
                mt={rem(16)}
                $gap={rem(8)}
                className="actionBox"
                onClick={() => pushEvent(`product-guide-cta-${id}`)}
              >
                {actions}
              </Flex>
            )}
          </Box>

          {illustration ? (
            <Flex justifyContent="center">{illustration}</Flex>
          ) : null}
        </Wrap>
      </ProductGuidesCheck>
    </Device>
  ) : null
}

ProductGuide.propTypes = {
  id: PropTypes.string.isRequired,
  tags: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  actions: PropTypes.node,
  illustration: PropTypes.node,
  isDark: PropTypes.bool,
  onClose: PropTypes.func,
}

const StyledProductGuidesStack = styled(Flex)`
  position: relative;
  z-index: 1;
  ${({ $isCollapsed }) => ($isCollapsed ? `cursor: pointer;` : '')}

  flex-direction: column;
  gap: ${rem(12)};
  max-height: 100%;
  /* min-height: 100%; */
  overflow: auto;
`

const StyledProductGuide = styled(ProductGuide)`
  transition: all 0.2s ease-in-out;
  z-index: ${({ $maxIndex }) => $maxIndex};
  position: relative;

  ${({ $isCollapsed, $index, $maxIndex }) =>
    $isCollapsed && $index > 0
      ? `
      height: 100%;
      overflow: hidden;
      top: calc(${rem($index * 20)} - ${rem($maxIndex * 15)});
      transform: scale(${1 - $index * 0.05});
      position: absolute;
      z-index: ${$maxIndex - $index};
    `
      : ''}
`

export const ProductGuidesStack = ({ items, ...passingProps }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const filteredItems = items
    .map(({ id }) =>
      local.getItem(`${PRODUCT_GUIDE_LOCAL_PREFIX}${id}`) ? false : id
    )
    .filter(Boolean)

  const [closedNumber, setClosedNumber] = useState(
    items.length - filteredItems.length
  )

  return closedNumber !== items.length ? (
    <Device sizes={['desktop', 'tv']}>
      <ProductGuidesCheck>
        <Box flex="0" {...passingProps}>
          <StyledProductGuidesStack
            $isCollapsed={isCollapsed}
            pb={isCollapsed ? rem((filteredItems.length - 1) * 15) : undefined}
            onClick={() => setIsCollapsed(false)}
          >
            {items
              .filter(({ id }) => filteredItems?.includes(id))
              .map((item, index) => (
                <StyledProductGuide
                  $maxIndex={filteredItems.length - 1}
                  $isCollapsed={isCollapsed}
                  $index={index}
                  key={item.id}
                  {...item}
                  onClose={() => setClosedNumber((prev) => prev + 1)}
                />
              ))}
          </StyledProductGuidesStack>
        </Box>
      </ProductGuidesCheck>
    </Device>
  ) : null
}

ProductGuidesStack.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export const ProductGuidesWrap = ({ items }) => {
  const [closedNumber, setClosedNumber] = useState(
    items
      .map(({ id }) => local.getItem(`${PRODUCT_GUIDE_LOCAL_PREFIX}${id}`))
      .filter(Boolean).length
  )

  return closedNumber !== items.length ? (
    <Device sizes={['desktop', 'tv']}>
      <ProductGuidesCheck>
        <Flex flex="0" flexDirection="column" $gap={rem(12)}>
          {items.map((item) => (
            <ProductGuide
              key={item.id}
              {...item}
              onClose={() => setClosedNumber((prev) => prev + 1)}
            />
          ))}
        </Flex>
      </ProductGuidesCheck>
    </Device>
  ) : null
}

ProductGuidesWrap.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}
