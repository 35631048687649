import { gql } from '@apollo/client'

export const PlanFragments = {
  STATIC_ROOM_FRAGMENT: gql`
    fragment StaticRoomFragment on Room {
      id
      thumbnailUrls
      title
      description
      roomSquareFootage
      roomSquareMeters
      capacity
      rates {
        id
        title
        description
        beddingData {
          bedCount
          bedType
        }
        occupancyLimit
        quantity
        pricePerNight {
          amount
          currencyIsoCode
        }
      }
    }
  `,

  ROOM_RATE_FRAGMENT: gql`
    fragment RoomRateFragment on Rate {
      id
      quantity
      occupancyLimit
      title
      description
      boardType
      beddingData {
        bedType
        bedCount
      }
      total {
        amount
        currencyIsoCode
      }
      pricePerNight {
        amount
        currencyIsoCode
      }
      taxesAndFeesSum {
        amount
        currencyIsoCode
      }
      cancelationPolicy {
        isCancelable
        description
      }
      icon
    }
  `,
}
