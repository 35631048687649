import React from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { BOXSHADOWS, fontSizes, radius, space } from 'Theme'
import { useDebouncedCallback } from 'use-debounce'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Popover from 'components/Popover'
import { shouldForwardPropOptions } from 'utils/helpers'

const TooltipRef = React.forwardRef((props, ref) => (
  <Flex {...props} ref={ref} />
))

const Info = ({ fill, iconSize }) => (
  <svg
    width={iconSize || 16}
    height={iconSize || 16}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM9 12H7V7H9V12ZM8 6C7.4 6 7 5.6 7 5C7 4.4 7.4 4 8 4C8.6 4 9 4.4 9 5C9 5.6 8.6 6 8 6Z"
      fill={fill || theme.COLORS.BRAND_DEFAULT}
    />
  </svg>
)

Info.propTypes = {
  fill: PropTypes.string,
  iconSize: PropTypes.number,
}

const Content = styled(Flex)`
  overflow: hidden;

  justify-content: center;

  width: max-content;
  margin: ${space.s};
  min-width: ${rem(100)};
  max-width: ${({ maxWidth }) => maxWidth || rem(250)};

  background-color: ${theme.COLORS.WHITE};
  border-radius: ${radius.m};
  box-shadow: ${BOXSHADOWS.TOOLTIP};

  ${({ hasText }) => !hasText && `padding: ${space.s};`}
`

const StyledText = styled(Text)`
  width: 100%;
  padding: ${space.s};

  font-size: ${fontSizes.xs};
  line-height: 1.2;
  white-space: initial;
  text-align: ${({ textAlign }) => textAlign || 'center'};

  background-color: ${theme.COLORS.BG_DARK};
  color: ${theme.COLORS.WHITE};
`

const LabelText = styled(Text, shouldForwardPropOptions)`
  ${({ $isDisabled }) =>
    !$isDisabled &&
    ` cursor: help;
  
      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
  `}
`

const Tooltip = ({
  text,
  label,
  onClick,
  className,
  maxWidth,
  position,
  iconFill,
  iconSize,
  textAlign,
  contentComp,
  fontSize = 'xxs',
  textFontSize = 'xxs',
  width = 'fit-content',
  justifyContent,
  children,
  placement = 'right',
  isDisabled = false,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = React.useState(false)
  const onHover = useDebouncedCallback((value) => {
    if (!isDisabled) {
      setIsTooltipVisible(value)
    }
  }, 200)

  return (
    <Popover
      placement={placement}
      isVisible={isTooltipVisible}
      targetComp={
        <TooltipRef
          className={className}
          width={width}
          alignItems="center"
          justifyContent={justifyContent}
          style={{
            cursor: isDisabled ? 'default' : onClick ? 'pointer' : 'help',
          }}
          onClick={onClick}
          onMouseEnter={() => {
            onHover(true)
          }}
          onMouseLeave={() => {
            onHover(false)
          }}
        >
          {label && (
            <LabelText
              $isDisabled={isDisabled}
              fontSize={fontSize}
              color={iconFill}
              mr="s"
            >
              {label}
            </LabelText>
          )}

          {children ||
            (!isDisabled && <Info fill={iconFill} iconSize={iconSize} />)}
        </TooltipRef>
      }
      zIndex={10}
    >
      {isTooltipVisible && (
        <Content
          onMouseEnter={() => {
            onHover(true)
          }}
          onMouseLeave={() => {
            onHover(false)
          }}
          hasText={text}
          position={position}
          maxWidth={maxWidth}
        >
          {contentComp || (
            <StyledText fontSize={textFontSize} textAlign={textAlign}>
              {text}
            </StyledText>
          )}
        </Content>
      )}
    </Popover>
  )
}

Tooltip.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  iconSize: PropTypes.number,
  iconFill: PropTypes.string,
  position: PropTypes.string,
  maxWidth: PropTypes.string,
  contentComp: PropTypes.node,
  textAlign: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  fontSize: PropTypes.string,
  textFontSize: PropTypes.string,
  placement: PropTypes.string,
  isDisabled: PropTypes.bool,
  justifyContent: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

export default Tooltip
