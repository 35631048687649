import { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import { formatISO } from 'date-fns'
import { rem } from 'polished'
import { AccountQueries } from 'api/Account/AccountQueries'
import Button from 'components/atoms/Button'
import { Icon } from 'components/Icon'
import { LoadingWrapper } from 'components/LoadingWrapper'
import Pagination from 'components/organisms/Pagination'
import { Table } from 'components/Table'
import { formatDate } from 'sharedComponents/FlightGrid/helpers'
import { formatPrice } from 'utils/money'
import { TabCard } from '../../Account'

const ActionButton = styled(Button.Tertiary)`
  padding: 0;
  border: none;
  background-color: transparent;
`

const PAGINATION_LIMIT = 4

export const Payments = () => {
  const { data, loading } = useQuery(AccountQueries.GET_INVOICES)
  const [activePage, setActivePage] = useState(1)

  const header = useMemo(
    () => [
      { label: 'Date', id: 'date' },
      { label: 'Order number', id: 'order_number' },
      { label: 'Price', id: 'price' },
      { label: 'Action', id: 'action', hideOnMobile: true },
    ],
    []
  )

  const rows =
    data?.invoices.map(({ number, created, total, currency, invoicePdf }) => ({
      cells: [
        { label: formatDate(formatISO(+created * 1000)), id: 'date' },
        { label: number, id: 'order_number' },
        {
          label: formatPrice({
            value: (total || 0) / 100,
            currencyIsoCode: currency,
          }),
          id: 'price',
        },
        {
          label: invoicePdf ? (
            <ActionButton
              viewType={EButtonType.PLAIN}
              element="a"
              download
              href={invoicePdf}
            >
              <Icon fontSize="24px" name="file_download" />
            </ActionButton>
          ) : undefined,
          id: 'action',
        },
      ],
    })) || []

  const firstPage = 1
  const lastPage = rows ? Math.ceil(rows.length / PAGINATION_LIMIT) : undefined
  const currentPage = activePage
  const previousPage = activePage === firstPage ? null : activePage - 1
  const nextPage = activePage === lastPage ? null : activePage + 1

  const paginatedRows = rows?.slice(
    PAGINATION_LIMIT * (activePage - 1),
    PAGINATION_LIMIT * activePage
  )

  return (
    <TabCard
      title="Payments"
      subtitle={
        !(loading || data?.invoices.length)
          ? 'You don’t have any invoices yet. Invoices will appear here after processing your payment.'
          : undefined
      }
      flexDirection="column"
      mt={rem(20)}
    >
      <LoadingWrapper loading={loading} height={rem(80)}>
        {data?.invoices.length ? (
          <>
            <Table header={header} rows={paginatedRows} />

            {rows.length > PAGINATION_LIMIT && (
              <Pagination
                firstPage={firstPage}
                lastPage={lastPage}
                currentPage={currentPage}
                previousPage={previousPage}
                nextPage={nextPage}
                onNextClick={setActivePage}
                onPrevClick={setActivePage}
                onPageClick={setActivePage}
                mt={rem(20)}
              />
            )}
          </>
        ) : null}
      </LoadingWrapper>
    </TabCard>
  )
}
