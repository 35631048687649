import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useAuth } from 'AuthProvider'
import { rem } from 'polished'
import { AccountMutations } from 'api/Account/AccountMutations'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Tag } from 'components/Tag'
import { useUploadImage } from 'hooks/useUploadImage'
import { toast } from 'utils/helpers'
import { UserAvatar } from '../../../../UserAvatar'

const Wrap = styled(Flex)`
  width: 100%;
  background-color: ${theme.COLORS.BG_SOFT};
  border-radius: 8px;
  gap: ${rem(48)};
  padding: ${rem(24)} ${rem(32)};
  margin-bottom: ${rem(16)};
`

export const ProfilePictureBox = () => {
  const { user } = useAuth()

  const { uploadImage } = useUploadImage('user-upload')

  const onUploadImage = useCallback(
    async (image) => {
      let imageResponse

      if (!image) return ''

      if (typeof image === 'string') return image

      try {
        imageResponse = await uploadImage(image, image?.name)
      } catch {
        toast.error('Image was not uploaded')
        return
      }

      if (!imageResponse?.content?.url) {
        toast.error(`Avatar was not uploaded, try again!`)
      }

      return imageResponse?.content?.url || ''
    },
    [uploadImage]
  )

  const [saveUserDetails, { loading: fileLoading }] = useMutation(
    AccountMutations.SAVE_USER_DETAILS
  )

  const onSubmit = useCallback(
    async (file) => {
      const avatarUrl = await onUploadImage(file, true)

      try {
        const response = await saveUserDetails({
          variables: {
            input: {
              avatarUrl: file && !avatarUrl ? user?.avatarUrl : avatarUrl,
            },
          },
        })

        if (response.data.saveUserDetails)
          toast.success('Profilter picture uploaded successfully')
      } catch {
        // do nothing
      }
    },
    [user?.avatarUrl]
  )

  return (
    <Wrap>
      <Flex alignItems="center">
        <UserAvatar
          size={116}
          borderColor={theme.COLORS.BG_SOFT}
          value={user?.avatarUrl}
          onChange={onSubmit}
          loading={fileLoading}
        />
      </Flex>

      <Box>
        <Tag mb={rem(16)}>NextRetreat Tip</Tag>

        <Text fontWeight="700" fontSize={theme.fontSizes.l} mb={rem(4)} as="p">
          Set a profile picture to increase response rate
        </Text>

        <Text fontSize={theme.fontSizes.s} as="p">
          When inviting team members, having set your avatar increases the
          reputation and the reply rate by more than 20%.
        </Text>
      </Box>
    </Wrap>
  )
}
