import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import spinner from 'assets/gif/spinner-loader.gif'

const StyledSpinner = styled.img`
  max-height: 100px;
  max-width: 100px;
  height: 100%;
`

export const LoaderGif = (props) => (
  <StyledSpinner
    src={spinner}
    alt="spinner"
    style={{ height: props.height }}
    {...props}
  />
)

LoaderGif.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
