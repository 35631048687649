import { useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useAuth } from 'AuthProvider'
import PropTypes from 'prop-types'
import { AccountMutations } from 'api/Account/AccountMutations'
import { AccountQueries } from 'api/Account/AccountQueries'
import Modal from 'components/Modal'
import { toast } from 'utils/helpers'
import { ChooseSubscriptionModal } from './ChooseSubscriptionModal'
import { SubscribeForm } from './SubscribeForm'

export const SubscriptionModals = ({
  user,
  billingDetails,
  paymentMethod,
  isModalOpen,
  onCloseModal,
}) => {
  const [selectedPackage, setSelectedPackage] = useState(null)

  const { user: loggedUser } = useAuth()

  const closeModal = (withSuccess) => {
    onCloseModal(withSuccess)
    setSelectedPackage(null)
  }

  const [changePlan, { loading: changePlanLoading }] = useMutation(
    AccountMutations.CHANGE_PLAN,
    {
      update: (cache, { data: newData }) => {
        if (newData.changePlan) {
          cache.writeQuery({
            query: AccountQueries.GET_SUBSCRIPTION,
            data: { subscription: newData.changePlan },
          })
        }
      },
    }
  )

  const onChangePlan = useCallback(
    async (priceId) => {
      try {
        const response = await changePlan({ variables: { priceId } })

        if (response.data.changePlan)
          toast.success('Plan was changed successfully')
        else toast.error('Something went wrong!')

        closeModal()
      } catch {
        // do nothing
      }
    },
    [changePlan]
  )

  const onChoosePackage = (selPackage) => {
    setSelectedPackage(selPackage)
  }

  return (
    <Modal
      contentWidth="800px"
      ariaLabel="Buy Subscription"
      isOpen={isModalOpen}
      closeModal={closeModal}
      title={selectedPackage ? 'Buy subscription' : 'Choose package'}
    >
      {selectedPackage ? (
        <SubscribeForm
          billingDetails={billingDetails}
          user={user}
          selectedPackage={selectedPackage}
          paymentMethod={paymentMethod}
          closeModal={closeModal}
        />
      ) : (
        <ChooseSubscriptionModal
          onChoosePackage={
            loggedUser.hasSubscription ? onChangePlan : onChoosePackage
          }
          loading={changePlanLoading}
          closeModal={closeModal}
        />
      )}
    </Modal>
  )
}

SubscriptionModals.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    company: PropTypes.string,
  }),
  paymentMethod: PropTypes.object,
  billingDetails: PropTypes.shape({
    address: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
    }),
    taxId: PropTypes.shape({
      value: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  isModalOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
}
