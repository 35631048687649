import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import fallbackImg from 'assets/images/ImageFallbackNR.jpeg'
import { Box } from 'components/atoms/Layout'
import { Ellipsis } from 'components/atoms/Typography'
import VenueSlider from 'routes/Plan/components/Venues/Slider'
import { STEP_NAMES } from 'routes/Plan/constants'
import { useSteps } from 'routes/Plan/useSteps'
import { requireDynamicFlag } from 'utils/flags'
import { FallbackImg } from './FallbackImg'
import RouterLink from './RouterLink'

const StyledImage = styled('div')`
  position: relative;
  width: 100%;
  height: ${rem(140)};
  object-fit: cover;
  background:
    url('${({ src }) => src}') center / cover no-repeat,
    url('${fallbackImg}') center / cover no-repeat;
  border-radius: 8px 8px 0 0;
`
const LocationImage = styled(FallbackImg)`
  height: ${rem(140)};
  width: 100%;
  object-fit: cover;
`

const SlideWrap = styled('div')`
  width: 100%;
  position: relative;
`

const StyledFlag = styled('img')`
  position: relative;
  top: -1px;
  height: 10px;
  margin-right: 4px;
`

const TextBox = styled(Box)`
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid ${theme.COLORS.BG_DIVIDER};
  border-top: none;
  padding: 12px 12px;
`

const RatingChip = styled('div')`
  z-index: 2;
  position: absolute;
  bottom: 8px;
  left: 12px;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${theme.COLORS.BG_SOFT};
`

const StyledVenueSlider = styled(VenueSlider)`
  & .swiper {
    border-radius: 8px 8px 0 0 !important;
    padding: 0 !important;
  }

  & .swiper-slide {
    overflow: hidden;
    border-radius: 8px 8px 0 0 !important;
  }
`

export const VenueCard = ({
  source = 'similarity',
  venue,
  additionalContent,
}) => {
  const { getNextUrl } = useSteps()

  const images = [venue.thumbnailUrl, ...venue.thumbnailUrls].filter(
    (url) => url !== null
  )

  return (
    <Box minWidth="0" position="relative">
      <div>
        {images?.length > 1 ? (
          <SlideWrap>
            <StyledVenueSlider
              id={venue.id}
              slide={LocationImage}
              thumbnailUrls={images}
              getDetailUrlByVenueId={() =>
                getNextUrl({
                  step: STEP_NAMES.detail,
                  venue: { id: venue.id, source },
                })
              }
            />

            {venue.reviewRating && (
              <RatingChip>{venue.reviewRating.toFixed(1)}</RatingChip>
            )}
          </SlideWrap>
        ) : (
          <RouterLink
            to={getNextUrl({
              step: STEP_NAMES.detail,
              venue: { id: venue.id, source: 'similarity' },
            })}
            rel="noreferrer noopener"
            target="_blank"
          >
            <StyledImage src={venue.thumbnailUrl} alt="Venue thumbnail">
              {venue.reviewRating && (
                <RatingChip>{venue.reviewRating.toFixed(1)}</RatingChip>
              )}
            </StyledImage>
          </RouterLink>
        )}

        <TextBox>
          <Ellipsis
            as="p"
            fontSize={theme.fontSizes.m}
            color={theme.COLORS.TXT_MAIN}
            fontWeight="700"
          >
            {venue.title}
          </Ellipsis>

          {(venue.city || venue.country) && (
            <Box>
              <Ellipsis
                as="p"
                mt="4px"
                fontSize={theme.fontSizes.s}
                color={theme.COLORS.TXT_DEFAULT}
              >
                {venue.countryCode && (
                  <StyledFlag
                    src={requireDynamicFlag(venue.countryCode)}
                    alt="flag"
                  />
                )}
                {venue.country} {venue.city}
              </Ellipsis>
            </Box>
          )}
        </TextBox>
      </div>

      {additionalContent}
    </Box>
  )
}
VenueCard.propTypes = {
  source: PropTypes.string,
  venue: PropTypes.shape({
    id: PropTypes.number.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    thumbnailUrls: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    city: PropTypes.string,
    country: PropTypes.string,
    countryCode: PropTypes.string,
    reviewRating: PropTypes.number,
  }).isRequired,
  additionalContent: PropTypes.node,
}
