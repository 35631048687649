import React, { forwardRef, useCallback } from 'react'
import styled from '@emotion/styled/macro'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useAuth } from 'AuthProvider'
import { rem, rgba } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import ContentLoader from 'react-content-loader'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { BOXSHADOWS, COLORS, mq, radius, space } from 'Theme'
import { ReactComponent as CrownSVG } from 'assets/images/svg/crown.svg'
import nextRetreatLogo from 'assets/images/svg/logo-next-retreat.svg'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { EmailAvatar } from 'components/Avatar'
import Device from 'components/Device'
import { Icon } from 'components/Icon'
import OutsideClickHandler from 'components/OutsideClickHandler'
import Topbar from 'components/Topbar'
import { TOPBAR_HEIGHT } from 'constants/constants'
import { PRIVATE_ROUTES } from 'constants/routes'
import Filter from 'sharedComponents/Filter'
import { tripPopoverPassingProps } from 'sharedComponents/ModalManagement/TripPopover'
import { MenuIcon, MenuItem, MenuItemWrap } from 'sharedComponents/MoreButton'
import RouterLink from 'sharedComponents/RouterLink'
import Currency from './Currency'
import { News } from './News'
import TouchMenuButton from './TouchMenuButton'
import Trips from './Trips'
import UserSignedMenu, { Border, UserSignedMenuItems } from './UserSignedMenu'

export const Loader = () => (
  <Flex width="100%">
    <ContentLoader
      speed="2"
      width="300"
      height="50"
      viewBox="0 0 300 50"
      backgroundColor={COLORS.IRRADIANT_IRIS}
      foregroundColor={COLORS.SUPER_SILVER}
    >
      <rect x="80" y="15" rx="3" ry="3" width="200" height="20" />
      <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
      <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
      <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
      <circle cx="40" cy="25" r="25" />
    </ContentLoader>
  </Flex>
)

const CONSISTENT_PADDING = space.m

const StyledDiv = styled('div')`
  display: inline-block;
  position: relative;
  top: 3px;
`

export const StyledArrowheadIcon = () => (
  <StyledDiv>
    <Icon fontSize={rem(20)} name="expand_more" />
  </StyledDiv>
)

const StickyWrapper = styled('div')`
  z-index: 1;
  position: sticky;
  top: 0;
`

const Logo = styled('img')`
  max-width: ${rem('165px')};
  height: ${rem(20)};
  margin: 0 ${CONSISTENT_PADDING};
  flex: none;
`

const EndContent = styled(Flex)`
  padding-right: ${CONSISTENT_PADDING};
  align-items: center;

  white-space: nowrap;
  ${mq.from.tablet`
      margin-left: auto;
  `}

  > * {
    vertical-align: middle;
  }

  > :not(:first-of-type) {
    ${mq.from.tablet`
      margin-left: auto;
      padding: 0 ${CONSISTENT_PADDING};
    `}
  }
`

const MenuOverlay = styled('div')`
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;

  height: var(--nr-100vh, 100vh);

  background-color: ${rgba(theme.COLORS.WHITE, 0.8)};
`

const Menu = styled('nav')`
  border-radius: 0 ${radius.m} ${radius.m} 0;
  background-color: ${theme.COLORS.WHITE};
  box-shadow: ${BOXSHADOWS.CARD};
  padding: 12px 0;
  height: 100vh;

  > * {
    width: 100%;
  }
`

const StyledSupportButton = styled(Button.Secondary, {
  shouldForwardProp: (prop) => !tripPopoverPassingProps.includes(prop),
})`
  height: ${TOPBAR_HEIGHT};
`

StyledSupportButton.defaultProps = {
  viewType: EButtonType.PLAIN,
}

const LogoFlex = styled(Flex)`
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    & {
      flex: 0 !important;
    }
  }
`
const StyledPrimaryButton = styled(Button.Primary)`
  cursor: pointer;
  text-decoration: none;
`

export const MiddleButton = styled(NavLink)`
  border: none;
  color: ${theme.COLORS.TXT_DEFAULT};
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: ${rem(8)};
  font-weight: 700;
  text-decoration: none;

  &:hover,
  &.active {
    color: ${theme.COLORS.BRAND_DEFAULT};
  }
`

const RequestButton = ({ isBlock }) => (
  <StyledPrimaryButton
    href={process.env.REACT_APP_REQUEST_RETREAT_URL}
    isBlock={isBlock}
  >
    Request retreat
  </StyledPrimaryButton>
)

RequestButton.propTypes = {
  isBlock: PropTypes.bool,
}

const UserButton = React.forwardRef((props, ref) => {
  const { user } = useAuth()

  return user ? (
    <StyledSupportButton type="button" {...props} ref={ref}>
      <EmailAvatar url={user.avatarUrl} size={35} />

      <Text color={theme.COLORS.TXT_MAIN} mx="s">
        {user.email}
      </Text>

      {user && <StyledArrowheadIcon />}
    </StyledSupportButton>
  ) : null
})

const MyTripsButton = forwardRef((props, ref) => (
  <MenuItemWrap {...props} ref={ref}>
    <Flex flex="1" alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <MenuIcon name="flight" />
        My trips
      </Flex>
      <Icon name="chevron_right" />
    </Flex>
  </MenuItemWrap>
))

const Header = () => {
  const matches = useMediaQuery()
  const navigate = useNavigate()
  const { pathname, search, hash } = useLocation()

  const { isSignedIn, isSigningIn } = useAuth()
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const menuButtonRef = React.useRef(null)
  const [preventOutsideClick, setPreventOutsideClick] = React.useState(false)

  React.useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
  }, [isMenuOpen])

  const onOpenSideMenu = useCallback(() => {
    setPreventOutsideClick(true)
  }, [setPreventOutsideClick])

  const onCloseSideMenu = useCallback(() => {
    setPreventOutsideClick(false)
  }, [setPreventOutsideClick])

  const navigateAndCloseMenu = (route) => () => {
    if (!(route === pathname + hash && !search)) navigate(route)

    setIsMenuOpen(false)
  }

  return (
    <StickyWrapper>
      <Topbar>
        <Flex flex="1" justifyContent="space-between">
          <LogoFlex flex="1" alignItems="center">
            <RouterLink to="/">
              <Logo src={nextRetreatLogo} alt="Next retreat logo" />
            </RouterLink>
          </LogoFlex>

          <Device sizes={['desktop', 'tv']}>
            <Flex
              flex="1"
              justifyContent="center"
              alignItems="center"
              $gap={rem(40)}
            >
              {isSignedIn && <Trips />}

              <MiddleButton to="/plan">
                <Icon name="search" />
                <div>Discover</div>
              </MiddleButton>

              <MiddleButton to="/concierge">
                <CrownSVG />
                <div>
                  <b>Concierge</b>
                </div>
              </MiddleButton>
            </Flex>
          </Device>

          <Flex
            flex="1"
            ml={{ tablet: 'm' }}
            justifyContent={{ mobile: 'flex-end', desktop: 'center' }}
            alignItems="center"
          >
            <EndContent>
              <Currency innerLabel="Choose a currency" />

              {isSignedIn && <News />}

              {!matches.mobile && isSigningIn && <Loader />}
              {!matches.mobile && !isSigningIn && isSignedIn && (
                <Filter
                  label=""
                  targetComp={UserButton}
                  hasFooter={false}
                  hasPadding={false}
                  customMinWidth={rem(240)}
                >
                  <UserSignedMenu onCloseMenu={() => setIsMenuOpen(false)} />
                </Filter>
              )}
              {matches.mobile && (
                <TouchMenuButton
                  ref={menuButtonRef}
                  onClick={() => {
                    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen)
                  }}
                />
              )}
            </EndContent>
          </Flex>
        </Flex>
      </Topbar>

      {matches.mobile && isMenuOpen && (
        <MenuOverlay>
          <OutsideClickHandler
            disabled={!isMenuOpen || matches.mobile}
            onOutsideClick={(e) => {
              if (
                !menuButtonRef.current.contains(e.target) &&
                (!preventOutsideClick || matches.mobile)
              ) {
                setIsMenuOpen(false)
              }
            }}
          >
            <Menu>
              {isSignedIn && (
                <Trips
                  onCloseSideMenu={onCloseSideMenu}
                  customTargetComponent={MyTripsButton}
                  onOpen={onOpenSideMenu}
                  closeMenu={() => setIsMenuOpen(false)}
                />
              )}

              <MenuItem
                as="button"
                type="button"
                icon="search"
                text="Discover"
                onClick={navigateAndCloseMenu(`/${PRIVATE_ROUTES.PLAN}`)}
              />

              <MenuItem
                as="button"
                type="button"
                icon={
                  <Box mr={{ mobile: '14px', tablet: rem(10) }}>
                    <CrownSVG />
                  </Box>
                }
                text={<b>Concierge</b>}
                onClick={navigateAndCloseMenu(`/${PRIVATE_ROUTES.CONCIERGE}`)}
              />

              <Box px={rem(16)}>
                <Border />
              </Box>

              <UserSignedMenuItems onCloseMenu={() => setIsMenuOpen(false)} />
            </Menu>
          </OutsideClickHandler>
        </MenuOverlay>
      )}
    </StickyWrapper>
  )
}

export default Header
