import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useFormikContext } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import { mq } from 'Theme'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Device from 'components/Device'
import { Icon } from 'components/Icon'

const SegmentationBoxWrap = styled(Flex)`
  background-color: ${theme.COLORS.WHITE};
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: ${rem(24)};
  flex: 1;

  ${mq.to.tablet`
     background-color: ${theme.COLORS.WHITE};
  `}
`

const StyledButton = styled(({ as: T = Button.Primary, ...props }) => (
  <T {...props} />
))`
  position: relative;
  overflow: hidden;

  & > div {
    width: 100%;
  }

  & span {
    transition: filter 1.2s ease-out;
  }

  .circle {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 150px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
  }
  .wave {
    background-color: ${theme.COLORS.BRAND_DEFAULT};
    position: absolute;
    top: 50%;
    height: 400px;
    width: 200%;

    border-radius: 38%;
    left: -50%;
    transform: rotate(360deg);
    transition: all 1.5s ease-out;
    animation: wave 30s linear infinite;
  }

  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(3600deg);
    }
  }

  &:hover {
    & span {
      filter: brightness(0) invert(1);
    }

    & .wave {
      top: -20%;
    }
  }
`

const StyledIcon = styled(Icon)`
  z-index: 2;
`

const StyledText = styled(Text)`
  z-index: 2;
`

const IconBox = styled(Box)`
  ${mq.to.tablet`
    & > svg {
      width: 48px;
      height: 48px;
  `}
`

const StyledFlex = styled(Flex)`
  ${mq.to.tablet`gap: ${rem(24)};`}
`

export const SegmentationBox = ({
  icon,
  title,
  description,
  route,
  buttons,
  onClick,
}) => {
  const { isSubmitting } = useFormikContext()
  const matches = useMediaQuery()

  return (
    <SegmentationBoxWrap
      onClick={matches.mobile ? () => onClick(route) : undefined}
      flexDirection={{ tablet: 'column' }}
    >
      <StyledFlex
        alignItems="center"
        flex="1"
        flexDirection={{ tablet: 'column' }}
        mb={{ tablet: rem(32) }}
      >
        <IconBox>{icon}</IconBox>

        <Text
          textAlign={{ tablet: 'center' }}
          fontSize="18px"
          fontWeight="700"
          mb={{ tablet: rem(8) }}
          mt={{ tablet: rem(32) }}
          as="p"
        >
          {title}
        </Text>

        <Device sizes={['tablet', 'desktop', 'tv']}>
          <Text textAlign="center" as="p">
            {description}
          </Text>
        </Device>
      </StyledFlex>

      <Device sizes={['tablet', 'desktop', 'tv']}>
        <Flex width="100%" flexDirection="column" $gap={rem(8)}>
          {buttons.map(({ type, text, buttonRoute }) => (
            <StyledButton
              key={text}
              as={type === 'secondary' ? Button.Secondary : undefined}
              isLoading={isSubmitting}
              type="button"
              isBlock
              onClick={() => onClick(buttonRoute || route)}
            >
              <StyledText as="span">{text}</StyledText>

              <div className="circle">
                <div className="wave" />
              </div>

              <StyledIcon name="arrow_forward" />
            </StyledButton>
          ))}
        </Flex>
      </Device>
    </SegmentationBoxWrap>
  )
}

SegmentationBox.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  route: PropTypes.string,
  onClick: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.object),
}
