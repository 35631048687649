import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useAuth } from 'AuthProvider'
import PropTypes from 'prop-types'
import { AuthQueries } from 'api/Auth/AuthQueries'
import { GamificationModal } from './GamificationModal'

export const GamificationContext = React.createContext({
  data: null,
  loading: true,
  refetch: () => {},
})

export const useGamificationData = () => React.useContext(GamificationContext)

export const GamificationProvider = ({ children }) => {
  const { isSignedIn } = useAuth()

  const { data, loading, refetch } = useQuery(AuthQueries.GAMIFICATION_DATA, {
    fetchPolicy: 'network-only',
    skip: !isSignedIn,
  })

  const contextValue = useMemo(
    () => ({
      data: data?.gamificationData,
      loading,
      refetch,
    }),
    [data, loading]
  )

  return isSignedIn ? (
    <GamificationContext.Provider value={contextValue}>
      {children}
      <GamificationModal />
    </GamificationContext.Provider>
  ) : (
    children
  )
}

GamificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
