import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import { COLORS, fontSizes, space } from 'Theme'
import { shouldForwardPropOptions } from 'utils/helpers'
import { Text } from './atoms/Typography'

const tableStyles = `width: 100%;
border-radius: 8px;
overflow: hidden;
border-collapse: separate;
border-spacing: 0 ${rem(12)};`

const StyledTable = styled('table')`
  ${tableStyles}
`

const StyledMobileWrap = styled('div')`
  ${tableStyles}
`

const StyledRow = styled('tr')`
  font-size: ${fontSizes.s};
  border-radius: 8px;

  background-color: ${theme.COLORS.BG_SOFT};

  & td:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  & td:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`
const MobileRow = styled('div')`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${COLORS.IRRADIANT_IRIS};
  }
`

const StyledBody = styled('div')`
  margin-top: 20px;
`

const StyledCell = styled('td', shouldForwardPropOptions)`
  padding: ${space.l};
  ${({ $align }) => ($align ? `text-align: ${$align}` : '')};
  font-size: ${fontSizes.l};
`

const StyledHeader = styled('thead')`
  background-color: ${COLORS.LYNX_WHITE};

  & th:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  & th:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

const StyledHeaderCell = styled('th', shouldForwardPropOptions)`
  padding: ${space.l};
  ${({ $align }) => ($align ? `text-align: ${$align}` : '')};
  font-size: ${fontSizes.l};
  font-weight: ${600};
`

export const Table = ({ header, rows }) => {
  const matches = useMediaQuery()

  return matches.mobile ? (
    <StyledMobileWrap>
      <StyledBody>
        {rows?.map(({ cells }, rowIndex) => (
          <MobileRow key={rowIndex}>
            {cells.map(({ label }, cellIndex) => (
              <Text
                as="p"
                fontSize="16px"
                mb={
                  rowIndex === rows.length - 1 && cellIndex === cells.length - 1
                    ? undefined
                    : '10px'
                }
                mt={cellIndex === 0 && rowIndex !== 0 ? '25px' : undefined}
                key={`${rowIndex}_${cellIndex}`}
              >
                <Text fontWeight="600">
                  {!header?.[cellIndex]?.hideOnMobile
                    ? `${header?.[cellIndex]?.label}: `
                    : ''}
                </Text>
                {label}
              </Text>
            ))}
          </MobileRow>
        ))}
      </StyledBody>
    </StyledMobileWrap>
  ) : (
    <StyledTable>
      <StyledHeader>
        <tr>
          {header?.map(({ id, label, align = 'left' }) => (
            <StyledHeaderCell $align={align} key={id}>
              {label}
            </StyledHeaderCell>
          ))}
        </tr>
      </StyledHeader>
      <tbody>
        {rows?.map(({ cells }, rowIndex) => (
          <StyledRow key={rowIndex}>
            {cells.map(({ label, align = 'left' }, cellIndex) => (
              <StyledCell $align={align} key={`${rowIndex}_${cellIndex}`}>
                {label}
              </StyledCell>
            ))}
          </StyledRow>
        ))}
      </tbody>
    </StyledTable>
  )
}

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      cells: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.any.isRequired,
        })
      ),
    })
  ),
  header: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.any.isRequired,
      align: PropTypes.string,
      hideOnMobile: PropTypes.bool,
    })
  ),
}
