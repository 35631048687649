import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'

const Device = ({ children, size, sizes = [] }) => {
  const matches = useMediaQuery()

  if (size) sizes.push(size)

  if (sizes.some((s) => matches[s])) return children

  return null
}

Device.propTypes = {
  size: PropTypes.string,
  sizes: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
}

export default Device
