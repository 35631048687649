import { storageFactory } from 'storage-factory'
import Cookies from 'universal-cookie'

export const local = storageFactory(() => localStorage)
export const session = storageFactory(() => sessionStorage)

export const cookies = new Cookies()

const addPrefixToKey = (key) =>
  process.env.REACT_APP_ENV === 'development' ? `dev-${key}` : `prod-${key}`

const domain =
  process.env.REACT_APP_APP_URL === 'http://localhost:3000'
    ? undefined
    : '.nextretreat.com'

export const authStorage = {
  set: (key, value) => {
    cookies.set(addPrefixToKey(key), value, {
      domain,
    })
    local.setItem(addPrefixToKey(key), value)
  },

  get: (key) =>
    cookies.get(addPrefixToKey(key)) || local.getItem(addPrefixToKey(key)),

  remove: (key) => {
    cookies.remove(addPrefixToKey(key), { domain })
    local.removeItem(addPrefixToKey(key))
  },
}
