import { useEffect } from 'react'
import styled from '@emotion/styled'
import { MODAL_TYPES, useModalManagement } from 'ModalManagementProvider'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { COLORS, mq } from 'Theme'
import { ReactComponent as Funnel } from 'assets/images/svg/funnel.svg'
import { ReactComponent as Calendar } from 'assets/images/svg/icon-calendar.svg'
import { ReactComponent as Laptop } from 'assets/images/svg/laptop-device.svg'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { H3, Text } from 'components/atoms/Typography'
import { Callout } from 'components/Callout'

const StyledFunnel = styled(Funnel)`
  width: 100%;
  position: relative;
  margin-top: -${rem(150)};
  z-index: -1;

  ${mq.to.tablet`
      margin-bottom: -${rem(50)};
    `}
`

const SuccessBoxWrap = styled(Box)`
  padding-top: ${rem(100)};
  background: linear-gradient(180deg, rgba(219, 223, 231, 0) 100%, #ffffff 0%);
`

const WhatNextFlex = styled(Flex)`
  border: 1px solid #a9b4c8;
  border-radius: 8px;
  padding: ${rem(36)} ${rem(32)};
  max-width: 450px;
  min-width: 350px;
`

const WhatNextButton = styled(Button.Tertiary)`
  height: ${rem(54)};
  font-weight: 600;
`

export const SuccessPaymentMessage = ({ isInvoice }) => {
  const navigate = useNavigate()
  const { openModal } = useModalManagement()

  const isCalendlyEvent = (e) =>
    e.origin === 'https://calendly.com' &&
    e.data.event &&
    e.data.event.indexOf('calendly.') === 0

  const handleCalendlyEvent = (e) => {
    if (isCalendlyEvent(e)) {
      try {
        if (window?.dataLayer) window.dataLayer.push({ event: e.data.event })
      } catch (error) {
        // do nothing
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleCalendlyEvent)

    return () => {
      window.removeEventListener('message', handleCalendlyEvent)
    }
  }, [])

  return (
    <>
      <SuccessBoxWrap>
        <Callout
          title={isInvoice ? 'Invoice sent' : 'Payment successful'}
          text={
            isInvoice
              ? ''
              : 'Your payment was sucesful and you should recieve a confirmation email. Now you can access the app or schedule a demo.'
          }
          timer={0}
        />
      </SuccessBoxWrap>
      <StyledFunnel />

      <Box mt={{ tablet: rem(34) }} pb={rem(80)} px="20px">
        <Text as="h2" fontSize="xxxxxl" mb={rem(45)} textAlign="center">
          What to do next?
        </Text>
        <Flex flexWrap="wrap" $gap={rem(24)} justifyContent="center">
          <WhatNextFlex $gap={rem(18)}>
            <Box flexShrink={0}>
              <Calendar />
            </Box>
            <Box mt={rem(3)}>
              <H3>Schedule demo</H3>
              <Text
                fontSize="l"
                color={COLORS.DEEP_RESERVOIR}
                mb={rem(22)}
                as="p"
              >
                Having a short demo call with your Retreat Specialist is a great
                way to start & understand all features.
              </Text>
              <WhatNextButton
                onClick={() => openModal({ type: MODAL_TYPES.CALENDLY_MODAL })}
                isBlock
              >
                Schedule
              </WhatNextButton>
            </Box>
          </WhatNextFlex>
          {!isInvoice && (
            <WhatNextFlex $gap={rem(18)}>
              <Box flexShrink={0}>
                <Laptop />
              </Box>
              <Box mt={rem(3)}>
                <H3>Explore yourself</H3>
                <Text
                  fontSize="l"
                  color={COLORS.DEEP_RESERVOIR}
                  mb={rem(22)}
                  as="p"
                >
                  Ready to roll? Visit the app and start exploring yourself. You
                  can also try out our built-in Tour.
                </Text>
                <WhatNextButton onClick={() => navigate('/plan')} isBlock>
                  Start now
                </WhatNextButton>
              </Box>
            </WhatNextFlex>
          )}
        </Flex>
      </Box>
    </>
  )
}

SuccessPaymentMessage.propTypes = {
  isInvoice: PropTypes.bool,
}
