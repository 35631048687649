import { useMutation, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useAuth } from 'AuthProvider'
import { MODAL_TYPES, useModalManagement } from 'ModalManagementProvider'
import { rem } from 'polished'
import { AuthQueries } from 'api/Auth/AuthQueries'
import { LayoutMutations } from 'api/Layout/LayoutMutations'
import { LayoutQueries } from 'api/Layout/LayoutQueries'
import { ReactComponent as NewsSVG } from 'assets/images/svg/news.svg'
import { ReactComponent as NewsBrandSVG } from 'assets/images/svg/news-brand.svg'
import { Box } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'

const NewsBox = styled(Box)`
  margin-left: ${rem(8)};
  margin-right: ${rem(12)};
  position: relative;

  cursor: pointer;
`

const Heading = styled(Text)`
  font-size: ${theme.fontSizes.l};
  line-height: ${theme.lineHeights.l};
  font-weight: 700;
`

Heading.defaultProps = { as: 'p' }

export const News = () => {
  const { user } = useAuth()
  const { openModal } = useModalManagement()

  const { data } = useQuery(LayoutQueries.GET_NEWS)

  const [newsRead] = useMutation(LayoutMutations.NEWS_READ, {
    update: (cache, { data: newData }) => {
      if (newData.newsRead) {
        const cacheData = cache.readQuery({
          query: AuthQueries.AUTH_INFO,
        })

        if (cacheData) {
          cache.writeQuery({
            query: AuthQueries.AUTH_INFO,
            data: {
              ...cacheData,
              userMe: {
                ...cacheData.userMe,
                readNewsId: data.news[0].id,
              },
            },
          })
        }
      }
    },
  })

  const onOpenModal = () => {
    if (data.news[0].id !== user.readNewsId) {
      newsRead({ variables: { id: data.news[0].id } })
    }

    openModal({
      type: MODAL_TYPES.NEWS_DRAWER,
      news: data.news,
    })
  }

  const hasUnseenNews = data?.news?.some(
    (news) => news.id > (user.readNewsId || 0)
  )

  return data?.news?.length ? (
    <NewsBox onClick={onOpenModal}>
      {hasUnseenNews ? <NewsBrandSVG /> : <NewsSVG />}
    </NewsBox>
  ) : null
}
