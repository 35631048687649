import { useCallback, useState } from 'react'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Device from 'components/Device'
import Modal, { FooterBox } from 'components/Modal'
import { PERMISSIONS_COLUMNS } from 'constants/constants'
import { toast } from 'utils/helpers'
import { PermissionsColumn } from './PermissionsColumn'
import { PermissionsColumnMobile } from './PermissionsColumnMobile'

export const PermissionsSetter = ({
  isOpen,
  closeModal,
  loading,
  email,
  initialValues,
  onSubmit,
}) => {
  const matches = useMediaQuery()

  const initialState = {
    noAccess: [...initialValues.noAccess],
    viewer: [...initialValues.viewer],
    editor: [...initialValues.editor],
  }

  const [columns, setColumns] = useState(initialState)
  const findCard = useCallback(
    (column, { id }) => {
      const item = columns[column].find((c) => c.id === id)

      return {
        item,
        index: columns[column].indexOf(item),
      }
    },
    [columns]
  )

  const moveItem = useCallback(
    ({ column, id }, newColumn) => {
      if (newColumn !== column) {
        const { item, index } = findCard(column, { id })
        if (item) {
          setColumns((prevColumns) => {
            const copiedPrevColumns = { ...prevColumns }

            // Remove the item from the current array
            copiedPrevColumns[column].splice(index, 1)

            // Add the item to the new array
            copiedPrevColumns[newColumn].push(item)

            return copiedPrevColumns
          })
        } else toast.error('Item was not found')
      }
    },
    [findCard, setColumns]
  )

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      ariaLabel="Edit permissions"
      title={`Edit permissions${email ? ` (${email})` : ''}`}
      contentWidth={rem(900)}
      hasCloseButton={matches.mobile}
    >
      <Box
        flex="1"
        p={{ mobile: 0, tablet: rem(24) }}
        maxHeight={{ mobile: 'unset', tablet: '500px' }}
        overflow="auto"
      >
        <Device sizes={['mobile']}>
          {[...PERMISSIONS_COLUMNS].reverse().map(({ title, column }) => (
            <PermissionsColumnMobile
              key={column}
              title={title}
              column={column}
              moveItem={moveItem}
              columnsState={columns}
            />
          ))}
        </Device>

        <Device sizes={['tablet', 'desktop', 'tv']}>
          <Text
            as="p"
            mb={rem(24)}
            color={theme.COLORS.TXT_DEFAULT}
            fontSize={theme.fontSizes.s}
          >
            To set permissions, drag&drop pages between columns and manage
            access to each page.
          </Text>
          <DndProvider backend={HTML5Backend}>
            <Flex $gap={rem(16)}>
              {PERMISSIONS_COLUMNS.map(({ title, column }) => (
                <PermissionsColumn
                  key={column}
                  title={title}
                  column={column}
                  moveItem={moveItem}
                  items={columns[column]}
                />
              ))}
            </Flex>
          </DndProvider>
        </Device>
      </Box>
      <FooterBox>
        <Device sizes={['tablet', 'desktop', 'tv']}>
          <Button.Tertiary type="button" onClick={() => closeModal()}>
            Cancel
          </Button.Tertiary>
        </Device>

        <Button.Primary
          type="button"
          isBlock={matches.mobile}
          isLoading={loading}
          onClick={async () => {
            await onSubmit(columns)
            closeModal()
          }}
        >
          Save
        </Button.Primary>
      </FooterBox>
    </Modal>
  )
}

PermissionsSetter.propTypes = {
  loading: PropTypes.bool,
  isOpen: PropTypes.bool,
  email: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    noAccess: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    viewer: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    editor: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  }),
}
