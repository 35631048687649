import { useContext } from 'react'
import { Input } from '@nextretreat/ui-components/dist/Input'
import { CurrencyContext } from 'CurrencyProvider'
import PropTypes from 'prop-types'

const PREFIXED_CURRENCIES = {
  EUR: false,
  USD: true,
  GBP: true,
}

export const PriceInput = ({ currency, ...passingProps }) => {
  const { getCurrencySymbol } = useContext(CurrencyContext)

  const isPrefix = PREFIXED_CURRENCIES[currency]

  const symbol = getCurrencySymbol(currency)
  return (
    <Input
      isBlock
      prefix={isPrefix ? symbol : undefined}
      suffix={!isPrefix ? symbol : undefined}
      placeholder="0"
      type="number"
      step="0.01"
      {...passingProps}
    />
  )
}

PriceInput.defaultProps = {
  currency: 'EUR',
}

PriceInput.propTypes = {
  currency: PropTypes.string,
}
