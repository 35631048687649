import { Fragment, useMemo } from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { TripMutations } from 'api/Trip/TripMutations'
import { Box, Flex } from 'components/atoms/Layout'
import { Ellipsis, Text } from 'components/atoms/Typography'
import { EmailAvatar } from 'components/Avatar'
import Device from 'components/Device'
import { Icon } from 'components/Icon'
import { useModal } from 'components/Modal'
import Tooltip from 'components/molecules/Tooltip'
import {
  ACCESS_LEVELS,
  PERMISSIONS_COLUMNS,
  PERMISSIONS_TYPES,
  TRIP_TAB_ACCESS_NAMES,
  TRIP_TAB_ACCESS_OPTIONS,
} from 'constants/constants'
import { usePermissions } from 'hooks/trip/usePermissions'
import { useTripMutation } from 'hooks/trip/useTripMutation'
import { MenuItem, MoreButton, MoreIcon } from 'sharedComponents/MoreButton'
import { toast } from 'utils/helpers'
import { PermissionsSetter } from './PermissionsSetter/PermissionsSetter'
import { EmptyLine } from './TripShareModal'
import { EnsureWriteAccess } from '../AccessComponents'
import { useTrip } from '../TripProvider'

const SharedUserFlex = styled(Flex)`
  align-items: center;
`

const StyledText = styled(Text)`
  width: 100%;
  padding: ${rem(12)};

  font-size: ${theme.fontSizes.s};
  line-height: 1.2;
  white-space: initial;
  text-align: ${({ textAlign }) => textAlign || 'center'};

  background-color: ${theme.COLORS.BG_DARK};
  color: ${theme.COLORS.WHITE};
`

const VerticalLine = styled('div')`
  width: 1px;
  height: ${rem(24)};
  background-color: ${theme.COLORS.BG_DIVIDER};
  margin: 0 ${rem(12)};
`

export const SharedUserItem = ({ loading, sharedUser, onChangeType }) => {
  const { trip } = useTrip()

  const { isOpen, closeModal, openModal } = useModal()
  const hasReadPermissions = usePermissions(
    TRIP_TAB_ACCESS_NAMES.SETTINGS,
    ACCESS_LEVELS.VIEWER
  )
  const hasWritePermissions = usePermissions(
    TRIP_TAB_ACCESS_NAMES.SETTINGS,
    ACCESS_LEVELS.EDITOR
  )

  const [revokeAccess, { loading: revokeLoading }] = useTripMutation(
    TripMutations.REMOVE_USER,
    {
      refetchQueries: ['TripTodos'],
      update: (cache, { data: newData }) => {
        if (newData.deleteTripSharedUser) {
          cache.evict({
            broadcast: false,
            id: `TripSharedUser:${newData.deleteTripSharedUser}`,
          })

          cache.gc()
        }
      },
    }
  )

  const onRevokeAccess = async (sharedUser = {}) => {
    const response = await revokeAccess({
      variables: {
        id: sharedUser.id,
        tripId: trip.id,
      },
    })

    if (response.data.deleteTripSharedUser)
      toast.success('Access revoked successfully')
  }

  const permissionsObject = useMemo(() => {
    const output = { noAccess: [], viewer: [], editor: [] }

    TRIP_TAB_ACCESS_OPTIONS.forEach(({ value, label }) => {
      if (typeof sharedUser[value] === 'number') {
        const newKey = PERMISSIONS_TYPES[sharedUser[value]]

        output[newKey].push({ id: value, name: label })
      }
    })

    return output
  }, [sharedUser])

  return (
    <>
      <Box my={{ mobile: rem(16), tablet: 0 }}>
        <SharedUserFlex
          key={sharedUser.id}
          mb={rem(12)}
          justifyContent="space-between"
          $gap={rem(16)}
        >
          <Flex $gap={rem(16)} minWidth="0" alignItems="center" flex="1">
            <EmailAvatar
              url={sharedUser.userData?.avatar}
              size={32}
              email={sharedUser.userEmail}
            />
            <Ellipsis as="p" fontSize={{ mobile: '16px' }} fontWeight="510">
              {sharedUser.userEmail}
            </Ellipsis>
          </Flex>

          <EnsureWriteAccess name={TRIP_TAB_ACCESS_NAMES.SETTINGS}>
            <Device size="mobile">
              <MoreButton targetComp={MoreIcon}>
                {(closePopup) => (
                  <>
                    <MenuItem
                      as="button"
                      type="button"
                      onClick={() => {
                        openModal()
                        closePopup()
                      }}
                      text="Edit permissions"
                      icon="edit"
                    />
                    <MenuItem
                      isLoading={revokeLoading}
                      style={{ color: theme.COLORS.ERROR_DEFAULT }}
                      as="button"
                      type="button"
                      onClick={() => onRevokeAccess(sharedUser)}
                      iconProps={{ color: theme.COLORS.ERROR_DEFAULT }}
                      icon="cancel"
                      text="Revoke access"
                    />
                  </>
                )}
              </MoreButton>
            </Device>

            <Device sizes={['tv', 'desktop', 'tablet']}>
              <Flex $gap={rem(16)} alignItems="center">
                <Flex alignItems="center">
                  {Object.keys(permissionsObject).map((key, index) => (
                    <Fragment key={key}>
                      {permissionsObject[key].length ? (
                        <Tooltip
                          position="left"
                          key={key}
                          text="viewer"
                          contentComp={
                            <StyledText>
                              {permissionsObject[key].map(({ id, name }) => (
                                <p key={id}>{name}</p>
                              ))}
                            </StyledText>
                          }
                          onClick={openModal}
                        >
                          <Text
                            color={theme.COLORS.TXT_DEFAULT}
                            fontSize={theme.COLORS.TXT_DEFAULT}
                          >
                            <u>
                              {
                                PERMISSIONS_COLUMNS.find(
                                  ({ column }) => column === key
                                ).title
                              }
                            </u>{' '}
                            ({permissionsObject[key].length})
                          </Text>
                        </Tooltip>
                      ) : (
                        <Text
                          color={theme.COLORS.TXT_DEFAULT}
                          fontSize={theme.COLORS.TXT_DEFAULT}
                          style={{ cursor: 'pointer' }}
                          onClick={openModal}
                        >
                          <u>
                            {
                              PERMISSIONS_COLUMNS.find(
                                ({ column }) => column === key
                              ).title
                            }
                          </u>{' '}
                          ({permissionsObject[key].length})
                        </Text>
                      )}
                      {index !== 2 && <VerticalLine />}
                    </Fragment>
                  ))}
                </Flex>

                <Tooltip text="Revoke access">
                  <Icon
                    name="cancel"
                    disabled={revokeLoading}
                    onClick={() => onRevokeAccess(sharedUser)}
                  />
                </Tooltip>
              </Flex>
            </Device>

            {isOpen && (
              <PermissionsSetter
                isOpen={isOpen}
                email={sharedUser.userEmail}
                closeModal={closeModal}
                initialValues={permissionsObject}
                loading={loading}
                onSubmit={async (values) => {
                  if (values)
                    await onChangeType(
                      sharedUser.id,
                      sharedUser.userEmail,
                      values
                    )
                  else toast.error('You have to select at least one value')
                }}
              />
            )}
          </EnsureWriteAccess>
        </SharedUserFlex>

        {hasReadPermissions && !hasWritePermissions && (
          <Flex alignItems="center">
            {Object.keys(permissionsObject).map((key, index) => (
              <Fragment key={key}>
                {permissionsObject[key].length ? (
                  <Tooltip
                    position="left"
                    key={key}
                    text="viewer"
                    contentComp={
                      <StyledText>
                        {permissionsObject[key].map(({ id, name }) => (
                          <p key={id}>{name}</p>
                        ))}
                      </StyledText>
                    }
                  >
                    <Text
                      color={theme.COLORS.TXT_DEFAULT}
                      fontSize={theme.COLORS.TXT_DEFAULT}
                    >
                      {
                        PERMISSIONS_COLUMNS.find(({ column }) => column === key)
                          .title
                      }{' '}
                      ({permissionsObject[key].length})
                    </Text>
                  </Tooltip>
                ) : (
                  <Text
                    color={theme.COLORS.TXT_DEFAULT}
                    fontSize={theme.COLORS.TXT_DEFAULT}
                  >
                    {
                      PERMISSIONS_COLUMNS.find(({ column }) => column === key)
                        .title
                    }{' '}
                    ({permissionsObject[key].length})
                  </Text>
                )}

                {index !== 2 && <VerticalLine />}
              </Fragment>
            ))}
          </Flex>
        )}

        <Device sizes={['mobile']}>
          <EnsureWriteAccess name={TRIP_TAB_ACCESS_NAMES.SETTINGS}>
            <Flex>
              {Object.keys(permissionsObject).map((key, index) => (
                <Fragment key={key}>
                  <Text
                    color={theme.COLORS.TXT_DEFAULT}
                    fontSize={theme.COLORS.TXT_DEFAULT}
                    style={{ cursor: 'pointer' }}
                    onClick={openModal}
                  >
                    {
                      PERMISSIONS_COLUMNS.find(({ column }) => column === key)
                        .title
                    }{' '}
                    ({permissionsObject[key].length})
                  </Text>

                  {index !== 2 && <VerticalLine />}
                </Fragment>
              ))}
            </Flex>
          </EnsureWriteAccess>
        </Device>
      </Box>

      <Device sizes={['mobile']}>
        <EmptyLine width="100vw !important" mx="-16px" />
      </Device>
    </>
  )
}

SharedUserItem.propTypes = {
  onChangeType: PropTypes.func,
  loading: PropTypes.bool,
  sharedUser: PropTypes.object,
}
