import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import theme from '@nextretreat/ui-components/dist/Theme'
import { FieldArray, Form, Formik } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import { TripMutations } from 'api/Trip/TripMutations'
import Button from 'components/atoms/Button'
import { Box } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'
import { ProductGuide } from 'components/ProductGuide'
import { PUBLIC_ROUTES } from 'constants/routes'
import { NavigationBox } from 'sharedComponents/ModalManagement/TripOnboarding/NavigationBox'
import {
  findNextStepName,
  ONBOARDING_STEP_NAMES,
} from 'sharedComponents/ModalManagement/TripOnboarding/OnboardingStepper'
import { StepContext } from 'sharedComponents/ModalManagement/TripOnboarding/TripOnboarding'
import { toast } from 'utils/helpers'
import { AtendeeItem } from './AtendeeItem'
import { ProfilePictureBox } from './ProfilePictureBox'
import { OnboardingHeading } from '../../OnboardingLayout'

const validationSchema = Yup.object().shape({
  atendees: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email('Invalid email'),
      id: Yup.string().required('Required'),
      name: Yup.mixed(),
    })
  ),
})

export const Atendees = ({ tripId, tripName }) => {
  const { setActiveStep } = useContext(StepContext)
  const [addTeamMembers, { loading }] = useMutation(
    TripMutations.ADD_TEAM_MEMBERS_ONBOARDING
  )

  const onSubmit = async (values) => {
    try {
      const validAtendees = values.atendees.filter(
        (atendee) => atendee.email && atendee.name
      )

      if (validAtendees.length > 0) {
        const response = await addTeamMembers({
          variables: {
            input: {
              sendEmail: true,
              tripName: tripName || 'New created Trip',
              teamMembers: validAtendees.map(({ name, email }) => ({
                name,
                email,
                status: 2,
              })),
            },
            tripId,
          },
        })

        if (response.data.addTeamMembers) {
          toast.success('Atendees successfully added!')
          setActiveStep(findNextStepName(ONBOARDING_STEP_NAMES.ATENDEES))
        }
      } else setActiveStep(findNextStepName(ONBOARDING_STEP_NAMES.ATENDEES))
    } catch {
      // do nothing
    }
  }

  return (
    <Box pb={rem(40)}>
      <OnboardingHeading>Atendees</OnboardingHeading>
      <ProductGuide
        id="plan-destinations"
        title="Why should you add atendees?"
        description="Every team member will be able to provide their attendance status, respond to surveys, upload their travel data and much more. You will be able to control the shared information and the information required from your team members in the Trip settings. To see how it looks like, see the demo attendance page."
        mb={rem(24)}
        actions={
          <Button.Secondary
            viewType={EButtonType.PLAIN}
            element="a"
            href={`/${PUBLIC_ROUTES.DEMO_ATTENDANCE}`}
            target="_blank"
          >
            See demo Attendance page
          </Button.Secondary>
        }
      />

      <Text mb={rem(24)} as="p" color={theme.COLORS.TXT_DEFAULT}>
        Notify your team members about the upcoming {tripName} and involve them
        in the planning! Every team member will receive their own access to
        their Attendance page, trough they will be working with you.
      </Text>

      <ProfilePictureBox />

      <Text
        as="p"
        fontWeight="700"
        fontSize={theme.fontSizes.xxl}
        lineHeight={theme.lineHeights.xxl}
        mb={rem(16)}
      >
        Invite atendees
      </Text>

      <Formik
        validationSchema={validationSchema}
        initialValues={validationSchema.cast({
          atendees: [{ email: '', id: uuidv4(), name: '' }],
        })}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <FieldArray name="atendees">
              {({ push, remove }) => (
                <>
                  {values.atendees.map((atendee, index) => (
                    <AtendeeItem
                      key={atendee.id}
                      atendee={atendee}
                      index={index}
                      onRemove={remove}
                    />
                  ))}
                  <Button.Secondary
                    viewType={EButtonType.PLAIN}
                    type="button"
                    iconLeft={<Icon name="add_circle_outline" />}
                    onClick={() => push({ email: '', name: '', id: uuidv4() })}
                  >
                    Add new atendee
                  </Button.Secondary>
                </>
              )}
            </FieldArray>

            <NavigationBox isLoading={loading} />
          </Form>
        )}
      </Formik>
    </Box>
  )
}

Atendees.propTypes = {
  tripId: PropTypes.number,
  tripName: PropTypes.string,
}
