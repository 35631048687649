import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useAuth } from 'AuthProvider'
import { differenceInCalendarDays, format, isValid, parseISO } from 'date-fns'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import { BOXSHADOWS, mq, space } from 'Theme'
import { AccountMutations } from 'api/Account/AccountMutations'
import { AccountQueries } from 'api/Account/AccountQueries'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Confirmation } from 'components/Confirmation'
import Device from 'components/Device'
import { Icon } from 'components/Icon'
import { InfoPanel } from 'components/InfoPanel'
import { LoadingWrapper } from 'components/LoadingWrapper'
import Modal, { useModal } from 'components/Modal'
import { EditPaymentMethod } from 'routes/Account/EditPaymentMethod'
import { formatDate } from 'sharedComponents/FlightGrid/helpers'
import { capitalize, toast } from 'utils/helpers'
import { subscriptions } from 'utils/subscriptions'
import { Payments } from './Payments'
import { TabCard } from '../../Account'
import { SubscriptionModals } from '../../SubscriptionModals'

const StyledBox = styled(Flex)`
  background-color: ${theme.COLORS.BG_DARK};
  color: ${theme.COLORS.WHITE};
  padding: ${rem(32)} 24px;
  text-align: center;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${rem(20)};

  ${mq.to.tablet`
  padding: ${space.l} 24px;
  `}
`

const Circle = styled(Box)`
  background-color: ${theme.COLORS.WHITE};
  padding: ${rem(20)};
  border-radius: 72px;

  & > * {
    display: block;
  }
`

const CurrentPackage = styled(Flex)`
  background-color: ${theme.COLORS.BG_SOFT};
  border-radius: 8px;
  overflow: hidden;
  gap: ${rem(24)};
  box-shadow: ${BOXSHADOWS.DARK};
  padding: ${rem(24)};

  ${mq.to.tablet`
  &:first-of-type {
    display: none;
  }
    padding: 20px 24px;
    `}
`

const TrialPackage = styled(Flex)`
  background-color: ${theme.COLORS.BG_DARK};
  border-radius: 8px;
  overflow: hidden;
  gap: ${rem(24)};
  box-shadow: ${BOXSHADOWS.DARK};
  padding: ${rem(24)};

  ${mq.to.tablet`
&:first-of-type {
  display: none;
}
  padding: 20px 24px;
  `}
`

const ChooseDifferent = styled(Button.Secondary)``

ChooseDifferent.defaultProps = {
  viewType: EButtonType.PLAIN,
  'data-cy': 'btn-cancel-subscription',
}

const PackageBox = styled(Box)`
  flex: 1;
`

export const Subscriptions = ({ user, billingDetails, paymentMethod }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    isOpen: isPaymentMethodModalOpen,
    closeModal: closePaymentMethodModal,
    openModal: openPaymentMethodModal,
  } = useModal()

  const matches = useMediaQuery()

  const { user: loggedUser } = useAuth()

  const { data: subscriptionData, loading } = useQuery(
    AccountQueries.GET_SUBSCRIPTION
  )
  const [cancelSubscription, { loading: cancelLoading }] = useMutation(
    AccountMutations.CANCEL_SUBSCRIPTION,
    {
      refetchQueries: ['UserMe'],
      update: (cache, { data: newData }) => {
        if (newData.cancelSubscription) {
          cache.writeQuery({
            query: AccountQueries.GET_SUBSCRIPTION,
            data: { subscription: null },
          })
        }
      },
    }
  )

  const onCancelSubscription = async () => {
    try {
      const response = await cancelSubscription()

      if (response.data.cancelSubscription) toast.info('Plan was canceled')
      else toast.error('Something went wrong!')
    } catch {
      // do nothing
    }
  }

  const subscription =
    subscriptions[subscriptionData?.subscription?.interval === 'year' ? 0 : 1]

  const toSubscriptionEnd = differenceInCalendarDays(
    parseISO(subscriptionData?.subscription?.periodEnd),
    new Date()
  )

  const displaySubscriptionEndsBadge =
    toSubscriptionEnd < 30 &&
    new Date(subscriptionData?.subscription?.periodEnd) > new Date()

  return (
    <>
      <Flex
        alignItems="center"
        mb={{
          mobile: displaySubscriptionEndsBadge ? rem(24) : undefined,
          desktop: rem(24),
        }}
        mt={{ desktop: rem(58) }}
        justifyContent="space-between"
        flexWrap="wrap"
        $gap={rem(10)}
      >
        <Device sizes={['desktop', 'tv']}>
          <Text
            as="h2"
            mb={0}
            fontSize={theme.fontSizes.xxxxl}
            lineHeight={theme.lineHeights.xxxxl}
          >
            Subscription
          </Text>
        </Device>

        {displaySubscriptionEndsBadge && (
          <InfoPanel
            padding={`${space.s} ${space.m}`}
            color={`${theme.COLORS.BRAND_DEFAULT}26`}
          >
            <Text fontSize="l">
              Your subscription expires in{' '}
              <Text color={theme.COLORS.BRAND_DEFAULT} fontWeight="600">
                {toSubscriptionEnd} days
              </Text>
            </Text>
          </InfoPanel>
        )}
      </Flex>

      {new Date(subscriptionData?.subscription?.periodEnd) < new Date() && (
        <Box mb={rem(20)}>
          <InfoPanel>
            <Text fontSize="l">
              <Text as="p" fontWeight="600">
                Your subscription has expired on{' '}
                {format(
                  parseISO(subscriptionData.subscription.periodEnd),
                  'dd/MM/yyyy'
                )}
                .
              </Text>{' '}
              If you wish to continue using your previous plan, please renew
              your subscription.
            </Text>
          </InfoPanel>
        </Box>
      )}

      <LoadingWrapper loading={loading} height={rem(80)}>
        {loggedUser.isActive ? (
          loggedUser.hasSubscription ? (
            <TabCard flexDirection="column" title="Current subscription">
              <CurrentPackage mt="m">
                <Device sizes={['tablet', 'desktop', 'tv']}>
                  <Box>
                    <Circle>
                      <Icon name="widgets" fontSize="32px" />
                    </Circle>
                  </Box>
                </Device>

                <PackageBox>
                  <Flex mt={rem(12)} justifyContent="space-between">
                    <Box>
                      <Text as="p" fontWeight="700">
                        Application {subscription.title} package
                      </Text>

                      {subscriptionData?.subscription?.periodEnd ? (
                        <Text
                          maxWidth={rem(300)}
                          mb={{ mobile: '10px', tablet: rem(20) }}
                          as="p"
                          fontSize="s"
                        >
                          {format(
                            parseISO(subscriptionData?.subscription?.periodEnd),
                            'dd/MM/yyyy'
                          )}
                        </Text>
                      ) : null}
                    </Box>

                    <Text as="p" mb={rem(20)} fontWeight="700">
                      €{subscription.price} / {subscription.intervalCount}{' '}
                      {subscription.interval}
                    </Text>
                  </Flex>

                  <Flex alignItems="center" flexWrap="wrap" $gap={rem(16)}>
                    <Button.Secondary onClick={() => setIsModalOpen(true)}>
                      Change package
                    </Button.Secondary>

                    <Confirmation
                      isLoading={cancelLoading}
                      onConfirm={onCancelSubscription}
                      label="Do you want to cancel your subscription?"
                      buttonLabel="Cancel subscription"
                      type="delete"
                      targetComp={ChooseDifferent}
                      targetLabel="Cancel your subscription"
                    />
                  </Flex>
                </PackageBox>
              </CurrentPackage>

              <Text mt={rem(16)} as="p" color={theme.COLORS.TXT_DEFAULT}>
                You can cancel your subscription at any time. Plan automatically
                renews until cancelled.
              </Text>
            </TabCard>
          ) : (
            <TabCard flexDirection="column" title="Current subscription">
              <TrialPackage mt="m">
                <Device sizes={['tablet', 'desktop', 'tv']}>
                  <Box>
                    <Circle>
                      <Icon name="widgets" fontSize="32px" />
                    </Circle>
                  </Box>
                </Device>

                <PackageBox>
                  <Flex mt={rem(12)} justifyContent="space-between">
                    <Box>
                      <Text as="p" fontWeight="700" color={theme.COLORS.WHITE}>
                        Application free trial
                      </Text>
                      <Text
                        maxWidth={rem(300)}
                        mb={{ mobile: '10px', tablet: rem(20) }}
                        as="p"
                        fontSize="s"
                        color={theme.COLORS.WHITE}
                      >
                        Expires on{' '}
                        {isValid(parseISO(loggedUser?.trialEnd)) &&
                          formatDate(loggedUser.trialEnd)}
                      </Text>
                    </Box>
                    <Button.Primary
                      data-cy="btn-choose-package"
                      onClick={() => setIsModalOpen(true)}
                    >
                      Choose package
                    </Button.Primary>
                  </Flex>
                </PackageBox>
              </TrialPackage>
            </TabCard>
          )
        ) : (
          <StyledBox>
            <Text mb={rem(10)} as="h2" fontSize="xxl">
              Trial expired. You have no subscription yet.
            </Text>
            <Text mb={rem(20)} fontSize="l" as="p">
              Check out our packages and choose the best fit for you.
            </Text>
            <Button.Primary
              onClick={() => setIsModalOpen(true)}
              isBlock={matches.mobile}
              data-cy="btn-choose-package"
            >
              Choose a package
            </Button.Primary>
          </StyledBox>
        )}
      </LoadingWrapper>

      {paymentMethod ? (
        <TabCard
          justifyContent="space-between"
          alignItems="center"
          title="Payment method"
          subtitle={`${capitalize(paymentMethod.brand)} ending **** ${
            paymentMethod.lastFour
          }`}
          $gap={rem(20)}
          flexWrap="wrap"
        >
          <Button.Tertiary
            iconLeft={<Icon name="credit_card" />}
            onClick={openPaymentMethodModal}
          >
            Edit
          </Button.Tertiary>
        </TabCard>
      ) : null}

      <Payments />

      <Modal
        contentWidth="650px"
        ariaLabel="Edit payment method"
        isOpen={isPaymentMethodModalOpen}
        closeModal={closePaymentMethodModal}
        customPadding={`${rem(16)} ${rem(24)}`}
        title="Edit payment method"
      >
        <EditPaymentMethod
          address={billingDetails?.address}
          user={user}
          closeModal={closePaymentMethodModal}
        />
      </Modal>

      <SubscriptionModals
        user={user}
        billingDetails={billingDetails}
        paymentMethod={paymentMethod}
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
      />
    </>
  )
}

Subscriptions.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    company: PropTypes.string,
    trialEnd: PropTypes.string,
  }),
  paymentMethod: PropTypes.object,
  billingDetails: PropTypes.shape({
    address: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
    }),
    taxId: PropTypes.shape({
      value: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
}
