import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { LoaderGif } from 'components/Loader'
import { LoadingWrapper } from 'components/LoadingWrapper'
import { shouldForwardPropOptions } from 'utils/helpers'

const hoverStyles = `
    border: 1px solid ${theme.COLORS.BRAND_HOVER};
    background-color: ${theme.COLORS.BRAND_LIGHT};
`

const defaultStyles = `
    border: 1px solid ${theme.COLORS.BG_DIVIDER};
    background-color: ${theme.COLORS.WHITE};
`

const StyledLoaderGif = styled(LoaderGif)`
  height: 48px;
  width: 48px;
`

const StyledFlex = styled(
  Flex,
  shouldForwardPropOptions
)(
  ({ $isActive, $isLoading }) => `
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  flex: 1;
  gap: ${rem(20)};
  padding: ${rem(20)};
  
  ${
    $isLoading
      ? ` pointer-events: none;
          opacity: 0.5;
        `
      : ''
  }
    
  ${
    $isActive
      ? `
      border: 1px solid ${theme.COLORS.BRAND_DEFAULT};
      background-color: ${theme.COLORS.BRAND_LIGHT};
        `
      : defaultStyles
  } 


  &:hover { 
    ${hoverStyles}
}
`
)

export const FillBox = ({ icon, text, isLoading, ...passingProps }) => (
  <StyledFlex $isLoading={isLoading} {...passingProps}>
    <Flex alignItems="center" justifyContent="center" width={48} height={48}>
      <LoadingWrapper
        loading={isLoading}
        loadingComponent={<StyledLoaderGif />}
      >
        {icon}
      </LoadingWrapper>
    </Flex>

    <Text as="p" fontWeight={700}>
      {text}
    </Text>
  </StyledFlex>
)

FillBox.propTypes = {
  icon: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}
