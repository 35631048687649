import { useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { AccountMutations } from 'api/Account/AccountMutations'
import { AccountQueries } from 'api/Account/AccountQueries'
import Button from 'components/atoms/Button'
import { Box } from 'components/atoms/Layout'
import { Label } from 'components/Input/Input'
import { toast } from 'utils/helpers'
import { stripeElementOptions } from './Payment'

export const EditPaymentMethod = ({ closeModal }) => {
  const [isSubmitting, setSubmitting] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const [savePaymentMethod] = useMutation(
    AccountMutations.CHANGE_PAYMENT_METHOD,
    {
      update: (cache, { data: newData }) => {
        if (newData.changePaymentMethod) {
          cache.writeQuery({
            query: AccountQueries.PAYMENT_METHOD,
            data: { paymentMethod: newData.changePaymentMethod },
          })
        }
      },
    }
  )

  const onSubmitChange = useCallback(async () => {
    setSubmitting(true)

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements?.getElement('card'),
    })

    if (result.error) {
      toast.error(result.error.message)
    } else {
      try {
        const response = await savePaymentMethod({
          variables: {
            paymentMethodId: result.paymentMethod.id,
          },
        })

        if (response?.data?.changePaymentMethod) {
          toast.success('Payment method changed successfully')
          closeModal()
        }
      } catch {
        // do nothing
      }
    }
    setSubmitting(false)
  }, [])

  return (
    <>
      <Label>Card number</Label>

      <CardElement options={stripeElementOptions} />

      <Box mt={rem(20)}>
        <Button.Primary
          isLoading={isSubmitting}
          isBlock
          onClick={onSubmitChange}
        >
          Save card details
        </Button.Primary>
      </Box>
    </>
  )
}

EditPaymentMethod.propTypes = {
  closeModal: PropTypes.func.isRequired,
}
