import React from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Datepicker } from '@nextretreat/ui-components/dist/Datepicker'
import { Input } from '@nextretreat/ui-components/dist/Input'
import { Select } from '@nextretreat/ui-components/dist/Select'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useAuth } from 'AuthProvider'
import { Field, Form, Formik } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { TripMutations } from 'api/Trip/TripMutations'
import { TripQueries } from 'api/Trip/TripQueries'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Icon } from 'components/Icon'
import Modal, { FooterBox } from 'components/Modal'
import { ACCESS_LEVELS } from 'constants/constants'
import { useTripMutation } from 'hooks/trip/useTripMutation'
import { updateChangeTodoCacheFunction } from 'routes/Trip/Tabs/Itinerary/AddTodoWithEntry'
import { convertLocalToUTCDate } from 'utils/date'
import { getTempHistoryValues, toast } from 'utils/helpers'
import { TODO_STEP } from '../../routes/Trip/Tabs/TodoList/TodoList'

const PSEUDO_FIRST_ORDER = -11111

const addNewTodoValidationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string(),
  deadline: Yup.date().nullable(),
  assignee: Yup.mixed().nullable(),
  isDone: Yup.boolean(),
})

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const TodoModal = ({
  todoItem,
  isOpen,
  closeModal,
  onSuccess,
  tripId,
  firstItemOrder,
}) => {
  const { user } = useAuth()
  const { data, loading: assigneesLoading } = useQuery(
    TripQueries.GET_SHARED_USERS_FOR_TODOS,
    {
      variables: { tripId },
    }
  )

  const [saveTodo, { loading }] = useTripMutation(TripMutations.SAVE_TODO)

  const onSubmit = React.useCallback(
    async (input) => {
      let response
      try {
        const assignee = input?.assignee?.value || undefined
        const id = toast.loading()

        response = await saveTodo({
          variables: {
            input: {
              ...input,
              assignee,
              deadline: input.deadline
                ? convertLocalToUTCDate(input.deadline)
                : undefined,
            },
            tripId,
            sendAssigneeNotification: !!(
              !!assignee &&
              ![user.email, todoItem?.assigneeData?.email].includes(assignee)
            ),
          },
          optimisticResponse: {
            __typename: 'Mutation',
            saveTodo: {
              id: todoItem?.id || Math.random(),
              title: input.title,
              description: input.description,
              isDone: input.isDone,
              order: todoItem?.order || PSEUDO_FIRST_ORDER,
              deadline: input.deadline
                ? Date.parse(convertLocalToUTCDate(input.deadline)).toString()
                : null,
              assigneeData: assignee
                ? {
                    email: assignee,
                    avatar: '',
                    __typename: 'UserAvatar',
                  }
                : null,
              itineraryEntry: null,
              ...getTempHistoryValues(user.email),
              __typename: 'Todo',
            },
          },
          onError: () => toast.dismiss(id),
          onCompleted: (data) => {
            if (data.saveTodo)
              toast.loadingFinish(
                id,
                `Todo was ${todoItem ? 'updated' : 'created'}`
              )
          },
          update: (cache, { data: newData }) => {
            if (newData.saveTodo) {
              if (todoItem) {
                updateChangeTodoCacheFunction(
                  cache,
                  todoItem.itineraryEntryId,
                  tripId,
                  newData.saveTodo
                )
              } else {
                const query = {
                  query: TripQueries.GET_TRIP_TODOS,
                  variables: { tripId },
                }

                const data = cache.readQuery(query)
                if (data)
                  cache.writeQuery({
                    ...query,
                    data: {
                      ...data,
                      trip: {
                        ...data.trip,
                        todos: [newData.saveTodo, ...data.trip.todos],
                      },
                    },
                  })
              }

              closeModal()
            }
          },
        })

        if (response.data.saveTodo) {
          if (onSuccess) {
            await onSuccess(response?.data?.saveTodo)
          }
        }
      } catch {
        // do nothing
      }
    },
    [saveTodo, tripId, onSuccess, closeModal, firstItemOrder, todoItem]
  )

  const modalTitle = todoItem ? 'Edit task' : 'Add new task'

  const assigneeEmail = todoItem?.assigneeData?.email

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      ariaLabel={modalTitle}
      title={modalTitle}
      contentWidth={rem(600)}
    >
      <Formik
        enableReinitialize
        validationSchema={addNewTodoValidationSchema}
        initialValues={addNewTodoValidationSchema.cast({
          isDone: false,
          deadline: todoItem?.deadline ? new Date(+todoItem.deadline) : null,
          id: todoItem?.id,
          title: todoItem?.title || '',
          description: todoItem?.description || '',
          assignee: assigneeEmail
            ? { value: assigneeEmail, label: assigneeEmail }
            : null,
          order: todoItem?.order ?? firstItemOrder - TODO_STEP,
        })}
        onSubmit={onSubmit}
      >
        {({ isValid, values, errors, touched, setFieldValue }) => (
          <StyledForm>
            <Box flex="1" p={`${rem(16)} ${rem(24)}`}>
              <Field name="title">
                {({ field, meta }) => (
                  <Box flex="1">
                    <Input
                      isBlock
                      label="Title"
                      required
                      placeholder="What needs to be done?"
                      invalid={meta.touched && meta.error !== undefined}
                      data-cy="inp-todo-item-title"
                      {...field}
                    />
                  </Box>
                )}
              </Field>

              <Flex mt={rem(16)} alignItems="center" $gap={rem(16)}>
                <Box flex="1">
                  <Select
                    isBlock
                    isLoading={assigneesLoading}
                    label="Assignee"
                    options={[
                      ...(values.assignee
                        ? [{ value: null, label: 'None' }]
                        : []),
                      ...(data?.tripSharedUsers || [])
                        .filter(
                          ({ level, todos }) =>
                            todos !== 0 || level === ACCESS_LEVELS.OWNER
                        )
                        .map(({ userEmail }) => ({
                          value: userEmail,
                          label: userEmail,
                        })),
                    ]}
                    noOptionsMessage={() =>
                      'No users with Todos access to this trip'
                    }
                    onChange={(value) => setFieldValue('assignee', value)}
                    value={values.assignee}
                  />
                </Box>

                <Box flex="1">
                  <Datepicker
                    minDate={new Date()}
                    popperProps={{ strategy: 'fixed' }}
                    selected={values.deadline}
                    dateFormat="yyyy-MM-dd"
                    onChange={(date) => {
                      setFieldValue('deadline', date)
                    }}
                    inputProps={{
                      type: 'input',
                      label: 'Deadline',
                      isBlock: true,
                      invalid: !!(errors.deadline && !touched.deadline),
                      suffix: values.deadline ? (
                        <span>
                          <Icon
                            fontSize="16px"
                            style={{
                              verticalAlign: 'text-bottom',
                            }}
                            color={theme.COLORS.TXT_DEFAULT}
                            name="close"
                            onClick={() => setFieldValue('deadline', null)}
                          />
                        </span>
                      ) : undefined,
                    }}
                  />
                </Box>
              </Flex>

              <Field name="description">
                {({ field, meta }) => (
                  <Box mt={rem(12)} flex="1">
                    <Input.Textarea
                      isBlock
                      label="Description"
                      placeholder="Add notes"
                      invalid={meta.touched && meta.error !== undefined}
                      {...field}
                    />
                  </Box>
                )}
              </Field>
            </Box>

            <FooterBox>
              <Button.Tertiary type="button" onClick={closeModal}>
                Cancel
              </Button.Tertiary>

              <Button.Primary
                isLoading={loading}
                disabled={!isValid}
                type="submit"
                data-cy="btn-save-todo-item"
              >
                Save
              </Button.Primary>
            </FooterBox>
          </StyledForm>
        )}
      </Formik>
    </Modal>
  )
}

TodoModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  tripId: PropTypes.number.isRequired,
  firstItemOrder: PropTypes.number,
  todoItem: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    deadline: PropTypes.string,
    order: PropTypes.number,
    assigneeData: PropTypes.shape({
      email: PropTypes.string,
    }),
    itineraryEntryId: PropTypes.number,
  }),
}

export default TodoModal
