import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { mq } from 'Theme'
import { ReactComponent as ExclamationMark } from 'assets/images/svg/exclamation-circle.svg'
import { shouldForwardPropOptions } from 'utils/helpers'
import { Box, Flex } from './atoms/Layout'

const StyledFlex = styled(Flex, shouldForwardPropOptions)`
  background-color: ${({ $bgColor }) => $bgColor};
  border-radius: 5px;
  padding: ${({ padding }) => !padding && `${rem(12)} ${rem(16)}`};

  ${mq.to.tablet`
    width: 100%;
  `}
`

export const InfoPanel = ({
  className,
  padding,
  children,
  icon = <ExclamationMark />,
  color = theme.COLORS.ERROR_LIGHT,
  ...passingProps
}) => (
  <StyledFlex
    className={className}
    padding={padding}
    $gap={rem(7)}
    $bgColor={color}
    {...passingProps}
  >
    <Flex alignItems="center" flexShrink="0">
      {icon}
    </Flex>
    <Box flex="1">{children}</Box>
  </StyledFlex>
)

InfoPanel.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  className: PropTypes.string,
  padding: PropTypes.string,
  color: PropTypes.string,
}
