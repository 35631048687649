import { gql } from '@apollo/client'

export const AccountFragments = {
  SUBSCRIPTION_FRAGMENT: gql`
    fragment SubscriptionFragment on Subscription {
      id
      interval
      intervalCount
      periodEnd
      periodStart
      product
      priceId
    }
  `,

  PAYMENT_METHOD_FRAGMENT: gql`
    fragment PaymentMethodFragment on PaymentMethod {
      id
      lastFour
      type
      brand
    }
  `,
}
