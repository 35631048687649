import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'

export const OnboardingHeading = styled(Text)`
  font-weight: 700;
  font-size: ${theme.fontSizes.xxxl};
  line-height: ${theme.lineHeights.xxxl};
`

OnboardingHeading.defaultProps = {
  as: 'h1',
  mb: rem(16),
}

export const OnboargingLayout = ({ stepper, children }) => (
  <Flex
    height="100%"
    px={{ mobile: rem(20), desktop: 0 }}
    flex="1"
    maxWidth={{ desktop: '100vw' }}
    flexDirection={{ mobile: 'column', desktop: 'row' }}
  >
    <Box
      maxWidth={rem(420)}
      width="100%"
      height="100%"
      bg={theme.COLORS.BG_SOFT}
      overflow="auto"
      p={rem(24)}
    >
      {stepper}
    </Box>

    <Box position="relative" pb={rem(65)} width="100%">
      <Flex justifyContent="center" overflow="auto" width="100%" height="100%">
        <Flex
          maxWidth={`min(80%, ${rem(820)})`}
          pb={rem(40)}
          pt={rem(40)}
          width="100%"
          minHeight="fit-content"
          flexDirection="column"
        >
          {children}
        </Flex>
      </Flex>
    </Box>
  </Flex>
)

OnboargingLayout.propTypes = {
  stepper: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}
