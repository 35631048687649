import { useMutation } from '@apollo/client'
import { Input } from '@nextretreat/ui-components/dist/Input'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { AccountMutations } from 'api/Account/AccountMutations'
import { Text } from 'components/atoms/Typography'
import Device from 'components/Device'
import { LoadingWrapper } from 'components/LoadingWrapper'
import { toast } from 'utils/helpers'
import { TabCard } from '../Account'

export const UserSettings = ({ user }) => {
  const [saveUserDetails, { loading }] = useMutation(
    AccountMutations.SAVE_USER_DETAILS
  )

  return (
    <>
      <Device sizes={['desktop', 'tv']}>
        <Text
          as="h2"
          mt={rem(58)}
          mb={rem(24)}
          fontSize={theme.fontSizes.xxxxl}
          lineHeight={theme.lineHeights.xxxxl}
        >
          App settings
        </Text>
      </Device>

      <LoadingWrapper loading={!user} height={rem(80)}>
        <TabCard
          $gap={rem(30)}
          justifyContent="space-between"
          alignItems="center"
          title="Disable product guides"
          subtitle="Hide all product guides in the application."
        >
          <Input.Switch
            disabled={loading}
            checked={user?.productGuidesDisabled}
            data-cy="chb-product-guides"
            onChange={async (value) => {
              const response = await saveUserDetails({
                variables: {
                  input: { productGuidesDisabled: value },
                },
              })
              if (response.data.saveUserDetails) {
                toast.success('Change was successfull')
              }
            }}
          />
        </TabCard>

        <TabCard
          $gap={rem(30)}
          justifyContent="space-between"
          alignItems="center"
          title="Agency features"
          subtitle="Includes features that can be used by TMCs, tours, events & retreat organisation agencies."
        >
          <Input.Switch
            disabled={loading}
            checked={user?.agencyFeaturesEnabled}
            data-cy="chb-agency-features"
            onChange={async (value) => {
              const response = await saveUserDetails({
                variables: {
                  input: { agencyFeaturesEnabled: value },
                },
              })
              if (response.data.saveUserDetails) {
                toast.success('Change was successfull')
              }
            }}
          />
        </TabCard>
        <TabCard
          $gap={rem(30)}
          justifyContent="space-between"
          alignItems="center"
          title="Beta features"
          subtitle="Beta includes any new features that are very close to being released as 100% working. When you choose to use Beta features, you are acknowledging that there may be some small issues and are willing to take that risk."
        >
          <Input.Switch
            disabled={loading}
            checked={user?.betaFeaturesEnabled}
            data-cy="chb-beta-features"
            onChange={async (value) => {
              const response = await saveUserDetails({
                variables: {
                  input: { betaFeaturesEnabled: value },
                },
              })
              if (response.data.saveUserDetails) {
                toast.success('Change was successfull')
              }
            }}
          />
        </TabCard>
      </LoadingWrapper>
    </>
  )
}

UserSettings.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    betaFeaturesEnabled: PropTypes.bool,
    agencyFeaturesEnabled: PropTypes.bool,
    productGuidesDisabled: PropTypes.bool,
  }),
}
