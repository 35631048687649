import React from 'react'
import { Input } from '@nextretreat/ui-components/dist/Input'
import { Field, useFormikContext } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { ReactComponent as StripeLogo } from 'assets/images/svg/stripe-logo.svg'
import { Box, Flex } from 'components/atoms/Layout'
import { FakeLink, H3 } from 'components/atoms/Typography'
import { Payment } from '../Payment'
import { SummaryTable } from '../SummaryTable'

export const PaymentStep = ({ setStep, ...passingProps }) => {
  const { values, setFieldValue } = useFormikContext()

  return (
    <>
      <H3 fontWeight="600" mb={rem(20)}>
        Summary
      </H3>

      <Box mb={rem(20)}>
        <SummaryTable
          rows={[
            {
              cells: [
                { label: 'Name & Surname:' },
                { label: `${values.firstName} ${values.lastName}` },
              ],
            },
            {
              cells: [{ label: 'E-mail address:' }, { label: values.email }],
            },
            {
              cells: [
                { label: 'Phone number:' },
                { label: values.phoneNumber },
              ],
            },
            {
              cells: [
                { label: 'Address' },
                {
                  label: `${values.line1} ${values.line2}`,
                },
              ],
            },
            {
              cells: [{ label: 'Zip code' }, { label: values.postalCode }],
            },
            {
              cells: [{ label: 'City' }, { label: values.city }],
            },
            {
              cells: [{ label: 'State' }, { label: values.state }],
            },
            {
              cells: [
                { label: 'Country' },
                {
                  label:
                    typeof values.country === 'string'
                      ? values.country
                      : values.country?.label,
                },
              ],
            },
            ...(values.payAsCompany
              ? [
                  {
                    cells: [
                      { label: 'Tax ID:' },
                      { label: values.taxId?.value },
                    ],
                  },
                  {
                    cells: [{ label: 'Company:' }, { label: values.company }],
                  },
                ]
              : []),
          ]}
        />
      </Box>

      <FakeLink
        fontWeight="600"
        fontSize="m"
        mb={rem(20)}
        display="block"
        onClick={() => setStep(1)}
      >
        Edit billing details
      </FakeLink>

      <Flex alignItems="center" mb={rem(20)}>
        <StripeLogo />

        <H3 ml={rem(10)} mb={0} fontWeight="600">
          Payment
        </H3>
      </Flex>

      <Input.Checkbox
        checked={values.hasCoupon}
        onChange={() => {
          setFieldValue('hasCoupon', !values.hasCoupon)
          if (values.coupon) {
            setFieldValue('coupon', '')
          }
        }}
        label="I have a coupon"
      />

      {values.hasCoupon && (
        <Field name="coupon">
          {({ field, meta }) => (
            <Box mt="m" flex="1">
              <Input
                isBlock
                required
                label="Coupon"
                placeholder="Enter coupon"
                invalid={meta.touched && meta.error !== undefined}
                {...field}
              />
            </Box>
          )}
        </Field>
      )}

      <Payment {...passingProps} />
      <div />
    </>
  )
}

PaymentStep.propTypes = {
  setStep: PropTypes.func.isRequired,
}
