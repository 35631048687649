import React from 'react'
import { ButtonSwitcher } from '@nextretreat/ui-components/dist/ButtonSwitcher'
import { Input } from '@nextretreat/ui-components/dist/Input'
import { Field, useFormikContext } from 'formik'
import { rem } from 'polished'
import { Box, Flex } from 'components/atoms/Layout'
import { H3 } from 'components/atoms/Typography'
import { ChildMarginBottom } from '../FormikStepper'
import { CountrySelect, TaxInput } from '../SubscriptionInputs'

export const BillingDetailsStep = () => {
  const { values, setFieldValue } = useFormikContext()

  return (
    <ChildMarginBottom>
      <H3 fontWeight="600" mb={rem(20)}>
        Billing information
      </H3>

      <ButtonSwitcher
        isBlock
        selectedValue={values.payAsCompany}
        onSelect={(val) => setFieldValue('payAsCompany', val)}
        options={[
          { label: 'Pay as company', value: true },
          {
            label: 'Pay as individual',
            value: false,
            'data-cy': 'btn-pay-as-individual',
          },
        ]}
      />

      {values.payAsCompany && (
        <>
          <Field name="company">
            {({ field, meta }) => (
              <Input
                isBlock
                required
                label="Company name"
                placeholder="Enter company"
                invalid={meta.touched && meta.error !== undefined}
                {...field}
              />
            )}
          </Field>

          <Box mt={rem(20)}>
            <TaxInput
              setFieldValue={setFieldValue}
              taxIdType={values.taxId.type}
            />
          </Box>
        </>
      )}

      <Box>
        <Field name="line1">
          {({ field, meta }) => (
            <Input
              isBlock
              required
              data-cy="inp-address"
              label="Address"
              placeholder="Address line 1"
              invalid={meta.touched && meta.error !== undefined}
              {...field}
            />
          )}
        </Field>
        <Field name="line2">
          {({ field, meta }) => (
            <Box mt={rem(10)}>
              <Input
                isBlock
                placeholder="Address line 2"
                invalid={meta.touched && meta.error !== undefined}
                {...field}
              />
            </Box>
          )}
        </Field>
      </Box>
      <Flex
        display={{ mobile: 'block', tablet: 'flex' }}
        mt={rem(20)}
        $gap={rem(20)}
      >
        <Field name="postalCode">
          {({ field, meta }) => (
            <Box
              flex="1.2"
              mb={{ mobile: rem(20), tablet: 0 }}
              maxWidth={{ mobile: '120px' }}
            >
              <Input
                isBlock
                required
                data-cy="inp-postal-code"
                label="Post ZIP code"
                placeholder="Zip code"
                invalid={meta.touched && meta.error !== undefined}
                {...field}
              />
            </Box>
          )}
        </Field>
        <Field name="city">
          {({ field, meta }) => (
            <Box flex="3">
              <Input
                isBlock
                required
                data-cy="inp-city"
                label="City"
                placeholder="Enter city"
                invalid={meta.touched && meta.error !== undefined}
                {...field}
              />
            </Box>
          )}
        </Field>
      </Flex>
      <Flex mt={rem(20)} $gap={rem(20)}>
        <Field name="state">
          {({ field, meta }) => (
            <Box flex="1">
              <Input
                isBlock
                label="State"
                placeholder="Enter state"
                invalid={meta.touched && meta.error !== undefined}
                {...field}
              />
            </Box>
          )}
        </Field>

        <Box flex="1">
          <CountrySelect />
        </Box>
      </Flex>
    </ChildMarginBottom>
  )
}
