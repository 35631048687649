import { createContext, useCallback, useContext } from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { TripMutations } from 'api/Trip/TripMutations'
import Button from 'components/atoms/Button'
import { Flex } from 'components/atoms/Layout'
import { Callout } from 'components/Callout'
import { Icon } from 'components/Icon'
import Modal, { useModal } from 'components/Modal'
import { ACCESS_LEVELS, TAB_VALUES } from 'constants/constants'
import { useTripMutation } from 'hooks/trip/useTripMutation'
import { shouldForwardPropOptions, toast } from 'utils/helpers'
import { useTrip } from './TripProvider'

const StyledCallout = styled(Callout, shouldForwardPropOptions)`
  & > div:first-of-type {
    background-color: ${({ $bgColor }) => $bgColor};
  }

  padding: ${rem(48)};
`

// toto sa môže sklbiť s tabom
export const EnsureReadAccess = ({ children, hideMessage, name }) => {
  const { accessLevel, trip } = useTrip()
  const navigate = useNavigate()

  const [requestReadAccess] = useTripMutation(
    TripMutations.REQUEST_TRIP_TAB_ACCESS
  )

  const onRequestAccess = useCallback(async () => {
    const response = await requestReadAccess({
      variables: { tripId: trip.id, tab: name },
    })

    if (response.data.requestReadAccess) {
      toast.success('Access requested successfully')
      navigate(`#${TAB_VALUES.DASHBOARD}`)
    }
  }, [requestReadAccess, name])

  if (accessLevel[name] >= 1 || accessLevel?.level === ACCESS_LEVELS.OWNER) {
    return children
  }

  return !hideMessage ? (
    <Modal
      isOpen
      closeModal={() => navigate(`#${TAB_VALUES.DASHBOARD}`)}
      ariaLabel=" "
      contentWidth={rem(740)}
    >
      <StyledCallout
        buttonText="Request access"
        $bgColor={theme.COLORS.BG_SOFT}
        onClick={onRequestAccess}
        title="Locked item"
        size="small"
        text="This tab and its contents have not been shared with you yet. If you'd like to get access, send out a request to your travel manager."
        timer={0}
        icon={<Icon color={theme.COLORS.BG_DARK} fontSize="24px" name="lock" />}
      />
    </Modal>
  ) : null
}

EnsureReadAccess.propTypes = {
  children: PropTypes.node,
  hideMessage: PropTypes.bool,
  name: PropTypes.string,
}

// toto sa má potom používať na jednotlivé časti, kde je potreba mať write access
export const EnsureWriteAccess = ({ children, name }) => {
  const { accessLevel } = useTrip()

  const hasAccess =
    accessLevel &&
    (accessLevel.level === ACCESS_LEVELS.OWNER || accessLevel[name] === 2)

  return hasAccess ? children : null
}

EnsureWriteAccess.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
}

export const SubscriptionAccessContext = createContext({
  isOpen: false,
  openModal: () => {},
  closeModal: () => {},
  toggleModal: () => {},
})

export const SubscriptionAccessProvider = ({ children }) => {
  const values = useModal()
  const { isTripOwner } = useTrip()

  return (
    <SubscriptionAccessContext.Provider value={values}>
      {children}

      <Modal
        isOpen={values.isOpen}
        closeModal={values.closeModal}
        ariaLabel=" "
        contentWidth={rem(740)}
      >
        <StyledCallout
          buttonElement={
            <Flex mt={rem(16)} alignItems="center" justifyContent="center">
              <Button.Tertiary onClick={values.closeModal}>
                OK, thanks
              </Button.Tertiary>
            </Flex>
          }
          $bgColor={theme.COLORS.WARNING_LIGHT}
          title="Subscription has expired"
          size="small"
          text={`Without active subscription plan you can browse the trip in view-only mode. ${
            isTripOwner
              ? 'Please renew your subscription.'
              : 'Please contact the team owner to renew the subscription.'
          }`}
          timer={0}
          icon={
            <Icon
              color={theme.COLORS.WARNING_DEFAULT}
              fontSize="24px"
              name="error"
            />
          }
        />
      </Modal>
    </SubscriptionAccessContext.Provider>
  )
}

export const useSubscriptionAccess = () => useContext(SubscriptionAccessContext)

SubscriptionAccessProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
