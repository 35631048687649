import { useRef } from 'react'
import styled from '@emotion/styled'
import { Input } from '@nextretreat/ui-components/dist/Input'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'
import { mq } from 'Theme'
import { TripMutations } from 'api/Trip/TripMutations'
import { TripQueries } from 'api/Trip/TripQueries'
import { ReactComponent as DragIcon } from 'assets/images/svg/drag-vertical.svg'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Device from 'components/Device'
import { TRIP_TAB_ACCESS_NAMES } from 'constants/constants'
import { usePermissions } from 'hooks/trip/usePermissions'
import { useTripMutation } from 'hooks/trip/useTripMutation'
import { EnsureWriteAccess } from 'routes/Trip/AccessComponents'
import { convertLocalToUTCDate, convertUTCToLocalDate } from 'utils/date'
import { calculateOrder } from 'utils/helpers'
import { TodoInfoBox } from './TodoInfoBox'
import { TODO_STEP } from './TodoList'
import { TodosMoreButton } from './TodosMoreButton'
import { updateChangeTodoCacheFunction } from '../Itinerary/AddTodoWithEntry'

const StyledWrap = styled(Box)`
  border-radius: 8px;
  background-color: ${theme.COLORS.WHITE};

  &:not(:last-of-type) {
    margin-bottom: ${rem(8)};
  }
`

const DescriptionBox = styled(Box)`
  padding: ${rem(12)} ${rem(16)};
  border-top: 1px solid ${theme.COLORS.BG_DIVIDER};
  padding-left: calc(20px + ${rem(66)});

  ${mq.to.desktop`padding-left: ${rem(16)};`}
`

const IconWrap = styled(Flex)`
  cursor: move;
  align-items: center;
`

export const TodoItem = ({
  description,
  id,
  isDone,
  isHidden,
  deadline,
  assigneeData,
  order,
  title,
  todos,
  tripId,
  itineraryEntry,
  moveItem,
  moveTodoOnDropOutside,
  dataCy,
  editedAt,
  createdAt,
  editedBy,
  updateLoading,
}) => {
  const ref = useRef(null)

  const hasWritePermissions = usePermissions(TRIP_TAB_ACCESS_NAMES.TODOS)

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: 'todo_item',
      canDrag: !updateLoading,
      item: { id, order },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      end: (_, monitor) => {
        if (!monitor.didDrop()) {
          moveTodoOnDropOutside()
        }
      },
    }),
    [moveTodoOnDropOutside, updateLoading]
  )

  const sortTodos = async (item, monitor, isHover) => {
    if (!ref.current) {
      return
    }

    const dragOrder = todos.find((todo) => item.id === todo.id).order
    const hoverOrder = todos.find((todo) => id === todo.id).order

    // // Don't replace items with themselves
    if (dragOrder === hoverOrder && isHover) {
      return
    }

    const hoverBoundingRect = ref.current?.getBoundingClientRect()
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    const clientOffset = monitor.getClientOffset()
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Dragging downwards
    if (dragOrder < hoverOrder && hoverClientY < hoverMiddleY) {
      return
    }
    // Dragging upwards
    if (dragOrder > hoverOrder && hoverClientY > hoverMiddleY) {
      return
    }

    moveItem(dragOrder, hoverOrder, isHover, isDone)
  }

  const [{ handlerId }, drop] = useDrop({
    accept: 'todo_item',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    drop(item, monitor) {
      sortTodos(item, monitor)
    },
    hover(item, monitor) {
      sortTodos(item, monitor, true)
    },
  })

  const [updateTodo, { loading }] = useTripMutation(TripMutations.SAVE_TODO, {
    update: (cache, { data: newData }) => {
      updateChangeTodoCacheFunction(
        cache,
        itineraryEntry?.id,
        tripId,
        newData.saveTodo
      )
    },
  })
  const [reorderTodos] = useTripMutation(TripMutations.REORDER_TODOS, {
    refetchQueries: TripQueries.GET_TRIP_TODOS,
  })

  dragPreview(drop(ref))

  return (
    <StyledWrap
      style={{ opacity: isDragging || isHidden ? 0 : 1 }}
      ref={ref}
      data-handler-id={handlerId}
      data-cy={dataCy}
    >
      <Flex py={rem(12)} px={rem(16)} justifyContent="space-between">
        <Flex flex="1">
          <EnsureWriteAccess name={TRIP_TAB_ACCESS_NAMES.TODOS}>
            <IconWrap mr="m" ref={drag}>
              <DragIcon />
            </IconWrap>
          </EnsureWriteAccess>

          <Input.Checkbox
            checked={isDone}
            disabled={loading || !hasWritePermissions}
            label={
              <Text
                color={theme.COLORS.TXT_MAIN}
                fontWeight="700"
                data-cy="txt-todo-title"
              >
                {title}
              </Text>
            }
            onChange={async () => {
              const firstNotDone = todos.findIndex(
                (todo) => todo.isDone === true
              )

              let newOrder = calculateOrder(
                todos,
                firstNotDone === -1 ? todos.length : firstNotDone,
                TODO_STEP
              )

              if (newOrder % 1 !== 0) {
                const response = await reorderTodos({ variables: { tripId } })

                newOrder = calculateOrder(
                  response.data.reorderTodos,
                  firstNotDone,
                  TODO_STEP
                )
              }

              updateTodo({
                variables: {
                  input: {
                    isDone: !isDone,
                    title,
                    assignee: assigneeData?.email || undefined,
                    deadline: deadline
                      ? convertUTCToLocalDate(convertLocalToUTCDate(+deadline))
                      : undefined,
                    id,
                    order: newOrder,
                    description: description || '',
                  },
                  tripId,
                },
                optimisticResponse: {
                  __typename: 'Mutation',
                  saveTodo: {
                    id,
                    isDone: !isDone,
                    title,
                    assigneeData,
                    editedAt,
                    createdAt,
                    editedBy,
                    itineraryEntry,
                    deadline,
                    order: newOrder,
                    description,
                    __typename: 'Todo',
                  },
                },
              })
            }}
          />
        </Flex>

        <Flex alignItems="center">
          <Device sizes={['desktop', 'tv', 'tablet']}>
            <TodoInfoBox assigneeData={assigneeData} deadline={deadline} />
          </Device>

          <TodosMoreButton
            todoItem={{
              id,
              isDone,
              order,
              title,
              description,
              deadline,
              assigneeData,
              itineraryEntryId: itineraryEntry?.id,
            }}
            tripId={tripId}
            editedAt={editedAt}
            createdAt={createdAt}
            editedBy={editedBy}
          />
        </Flex>
      </Flex>

      <Device size="mobile">
        <TodoInfoBox assigneeData={assigneeData} deadline={deadline} />
      </Device>

      {description && (
        <DescriptionBox>
          <Text fontSize={theme.fontSizes.s} lineHeight={theme.lineHeights.s}>
            {description}
          </Text>
        </DescriptionBox>
      )}
    </StyledWrap>
  )
}

TodoItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  description: PropTypes.string,
  order: PropTypes.number,
  tripId: PropTypes.number.isRequired,
  deadline: PropTypes.string,
  assigneeData: PropTypes.object,
  isDone: PropTypes.bool,
  itineraryEntry: PropTypes.object,
  isHidden: PropTypes.bool,
  todos: PropTypes.array,
  moveItem: PropTypes.func.isRequired,
  moveTodoOnDropOutside: PropTypes.func.isRequired,
  dataCy: PropTypes.string,
  editedAt: PropTypes.string,
  createdAt: PropTypes.string,
  editedBy: PropTypes.string,
  updateLoading: PropTypes.bool,
}
