import { useCallback, useRef } from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import PropTypes from 'prop-types'
import { Flex } from 'components/atoms/Layout'
import { BoringAvatar } from 'components/BoringAvatar'
import { Icon } from 'components/Icon'
import { LoadingWrapper } from 'components/LoadingWrapper'
import { shouldForwardPropOptions } from 'utils/helpers'

const DEFAULT_SIZE = 96

const UploadWrap = styled(Flex, shouldForwardPropOptions)`
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: relative;

  cursor: pointer;

  ${({ $hasUrl }) => !$hasUrl && `background-color: ${theme.COLORS.BG_SOFT};`}
`

const UploadIcon = styled(Icon)`
  position: absolute;
  right: -6px;
  bottom: -6px;
  border-radius: 50px;
  color: ${theme.COLORS.WHITE};
  background-color: ${theme.COLORS.BRAND_DEFAULT};
  padding: 6px;
  border-width: 4px;
  border-style: solid;
`

const AvatarImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 100px;

  object-fit: cover;
`

export const UserAvatar = ({
  value,
  size = DEFAULT_SIZE,
  onChange,
  loading,
  borderColor = theme.COLORS.WHITE,
}) => {
  const handleFileChange = useCallback(
    async (event) => {
      const file = event.target.files[0]

      if (file) {
        onChange(file)
      }
    },
    [onChange]
  )

  const hiddenFileInput = useRef(null)

  return (
    <Flex alignItems="center">
      <UploadWrap
        width={`${size}px`}
        height={`${size}px`}
        $hasUrl={!!value}
        onClick={() => hiddenFileInput.current.click()}
      >
        {value ? (
          <AvatarImage
            src={typeof value === 'string' ? value : URL.createObjectURL(value)}
          />
        ) : (
          <LoadingWrapper loading={loading}>
            <BoringAvatar size={96} />
          </LoadingWrapper>
        )}
        <UploadIcon style={{ borderColor }} name="camera_alt" />
      </UploadWrap>

      <input
        accept="image/*"
        type="file"
        disabled={loading}
        ref={hiddenFileInput}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </Flex>
  )
}

UserAvatar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  borderColor: PropTypes.string,
  size: PropTypes.number,
}
