import { isValid } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

const DEFAULT_DATETIME_FORMAT = 'yyyy-MM-dd HH:mm'

export const formatUTC = (date, format = DEFAULT_DATETIME_FORMAT) =>
  formatInTimeZone(date, 'Etc/UTC', format)

export const formatUTCValid = (
  date,
  fallback = '-',
  format = DEFAULT_DATETIME_FORMAT
) => (isValid(new Date(date)) ? formatUTC(date, format) : fallback)

export const convertUTCToLocalDate = (date) => {
  if (!date || !isValid(new Date(date))) {
    return date
  }
  let dateNew = new Date(date)
  dateNew = new Date(
    dateNew.getUTCFullYear(),
    dateNew.getUTCMonth(),
    dateNew.getUTCDate(),
    dateNew.getUTCHours(),
    dateNew.getUTCMinutes()
  )

  return dateNew
}

export const convertLocalToUTCDate = (date) => {
  if (!date || !isValid(new Date(date))) {
    return date
  }

  let dateNew = new Date(date)
  dateNew = new Date(
    Date.UTC(
      dateNew.getFullYear(),
      dateNew.getMonth(),
      dateNew.getDate(),
      dateNew.getHours(),
      dateNew.getMinutes()
    )
  )
  return dateNew
}
