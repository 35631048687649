import { useState } from 'react'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { ReactComponent as AddFillBoxSVG } from 'assets/images/svg/add-fill-box.svg'
import { ReactComponent as HotelFillBoxSVG } from 'assets/images/svg/hotel-fill-box.svg'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { FillBox } from 'components/FillBox'
import { ProductGuide } from 'components/ProductGuide'
import { OnboardingCustomVenue } from './OnboardingCustomVenue'
import { OnboardingVenueRecommendation } from './OnboardingVenueRecommendation'
import { OnboardingHeading } from '../../OnboardingLayout'

const typeMap = [
  {
    id: 'recommendations',
    text: 'Discover recommended venues',
    icon: <HotelFillBoxSVG />,
  },
  { id: 'custom', text: 'Add your selected venue', icon: <AddFillBoxSVG /> },
]

export const Venues = ({ requirements, tripId }) => {
  const [contentType, setContentType] = useState('recommendations')

  return (
    <Box pb={rem(40)}>
      <OnboardingHeading>Venues</OnboardingHeading>

      <ProductGuide
        id="venues-step"
        title="Why you should add a venue?"
        description="The venue is an essential part of your trip. Do not forget to add a venue to your trip. Adding a venue will help you find activities and plan the itinerary to your trip. You can always go to the Discovery section and find a venue for yourself to add it to your trip."
        mb={rem(24)}
      />

      <Text as="p" color={theme.COLORS.TXT_DEFAULT}>
        Looking for a venue inspiration or already have your venue selected?
        Based on your inputs our AI have selected the best options for your
        trip. Add them to your trip or in case you have already selected a
        venue, add your custom venue.
      </Text>

      <Flex
        my={rem(40)}
        flexDirection={{ mobile: 'column', desktop: 'row' }}
        $gap={rem(24)}
      >
        {typeMap.map(({ id, text, icon }) => (
          <FillBox
            key={id}
            $isActive={contentType === id}
            icon={icon}
            text={text}
            onClick={() => setContentType(id)}
          />
        ))}
      </Flex>

      {contentType === 'recommendations' ? (
        <OnboardingVenueRecommendation
          requirements={requirements}
          tripId={tripId}
          setContentType={setContentType}
        />
      ) : (
        <OnboardingCustomVenue requirements={requirements} tripId={tripId} />
      )}
    </Box>
  )
}

Venues.propTypes = {
  tripId: PropTypes.number,
  requirements: PropTypes.shape({
    fullBudget: PropTypes.number,
    teamSize: PropTypes.number,
    dayCount: PropTypes.number,
    destination: PropTypes.string,
  }),
}
