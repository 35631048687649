import { useState } from 'react'
import styled from '@emotion/styled'
import { Accordion } from '@nextretreat/ui-components/dist/Accordion'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { mq } from 'Theme'
import { ReactComponent as ExpandMoreIcon } from 'assets/images/svg/expand_more.svg'
import { Drawer } from 'components/Drawer'
import { TOPBAR_HEIGHT } from 'constants/constants'
import Button from '../atoms/Button'
import { Box, Flex } from '../atoms/Layout'
import { Text } from '../atoms/Typography'
import Device from '../Device'
import { Icon } from '../Icon'

export const VERTICAL_TABS_BOX_WIDTH = rem(220)

const StickyWrapper = styled('div')`
  width: 100%;
  padding-top: ${rem(20)};
  border-right: 1px solid ${theme.COLORS.BG_DIVIDER};

  & > div {
    position: sticky;
    top: ${TOPBAR_HEIGHT};
    width: 100%;
  }
`

const VerticalLayout = styled(Flex)`
  flex: 1;

  & > div:first-of-type {
    width: ${VERTICAL_TABS_BOX_WIDTH};
  }

  & > div:not(:first-of-type) {
    flex: 5;
    min-width: 0;
  }
`

export const StyledTab = styled.button`
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  padding: ${rem(8)} ${rem(12)};
  cursor: pointer;
  color: ${theme.COLORS.TXT_DEFAULT};
  text-align: left;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  align-items: center;

  background: white;
  border: 0;
  border-radius: 4px;
  outline: 0;

  & > div {
    position: relative;

    & path {
      fill: ${theme.COLORS.TXT_DEFAULT};
    }
  }

  ${mq.from.tablet`
    & > div {
      top: -2px;
    }
  `}

  &:hover {
    color: ${theme.COLORS.BRAND_DEFAULT} !important;

    & > div path {
      fill: ${theme.COLORS.BRAND_DEFAULT};
    }
  }

  ${({ active }) =>
    active &&
    `
    color: ${theme.COLORS.BRAND_DEFAULT} !important;
    background-color: ${theme.COLORS.BRAND_LIGHT};
    
    & > div path {
      fill: ${theme.COLORS.BRAND_DEFAULT};
    }
  `}
`

const StyledTitleFlex = styled(Flex)`
  border-top: 1px solid ${theme.COLORS.BG_DIVIDER};
  cursor: pointer;
  padding: ${rem(24)} 0;
  margin: ${rem(4)} ${rem(12)} 0;
  align-items: center;
  justify-content: space-between;
`

const Content = styled(Box)`
  background-color: ${theme.COLORS.BG_SOFT};
`

export const VerticalTabs = ({
  tabs,
  className,
  children,
  openAccordion,
  setOpenAccordion,
  categorisedTabs,
  activeTabObject,
  activeTab,
  setActiveTab,
  title,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false)

  return (
    <>
      <Device sizes={['mobile', 'tablet']}>
        <Box px="m" my="16px">
          <Button.Secondary
            type="button"
            onClick={() => setOpenDrawer(true)}
            viewType={EButtonType.PLAIN}
          >
            <Text fontSize="20px" lineHeight="28px" fontWeight="bold">
              {activeTabObject?.selectLabel || activeTabObject?.label}
              <ExpandMoreIcon />
            </Text>
          </Button.Secondary>
        </Box>

        {children}

        <Drawer
          title={title}
          onClose={() => setOpenDrawer(false)}
          isOpen={openDrawer}
        >
          <Box mb="34px">
            {tabs.map(({ label, icon, value }) => (
              <Box key={value} p={`0 16px ${rem(8)}`}>
                <StyledTab
                  type="button"
                  active={activeTab === value}
                  data-cy={`tab-btn-${value}`}
                  onClick={() => {
                    setActiveTab(value)
                    setOpenDrawer(false)
                  }}
                >
                  <div>{icon}</div>
                  {label}
                </StyledTab>
              </Box>
            ))}
          </Box>
        </Drawer>
      </Device>
      <Device sizes={['desktop', 'tv']}>
        <VerticalLayout>
          <StickyWrapper className={className}>
            <Box>
              {Object.keys(categorisedTabs).map((key) => (
                <Accordion
                  key={key}
                  childrenStyle={{ backgroundColor: 'transparent' }}
                  expanded={key === '' || openAccordion === key}
                  onToggle={() => {
                    setOpenAccordion((prev) => (key === prev ? '' : key))
                  }}
                  title={
                    key ? (
                      <StyledTitleFlex
                        data-cy={`trip-tab-group-${(
                          key || ''
                        ).toLocaleLowerCase()}`}
                      >
                        <Text fontWeight="700">{key}</Text>

                        <Icon
                          fontSize="24px"
                          name={
                            openAccordion === key
                              ? 'expand_less'
                              : 'expand_more'
                          }
                        />
                      </StyledTitleFlex>
                    ) : (
                      ''
                    )
                  }
                >
                  {categorisedTabs[key].map(({ label, icon, value }) => (
                    <Box key={value} p={`0 ${rem(12)} ${rem(8)}`}>
                      <StyledTab
                        type="button"
                        data-cy={`tab-btn-${value}`}
                        active={activeTab === value}
                        onClick={() => setActiveTab(value)}
                      >
                        <div style={{ width: '20px' }}>{icon}</div>
                        {label}
                      </StyledTab>
                    </Box>
                  ))}
                </Accordion>
              ))}
            </Box>
          </StickyWrapper>
          <Content>{children}</Content>
        </VerticalLayout>
      </Device>
    </>
  )
}

VerticalTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    })
  ),
  activeTab: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  openAccordion: PropTypes.string,
  setOpenAccordion: PropTypes.func.isRequired,
  categorisedTabs: PropTypes.object,
  activeTabObject: PropTypes.object,
  setActiveTab: PropTypes.func.isRequired,
}
