import { gql } from '@apollo/client'
import { PlanFragments } from './PlanFragments'

export const PlanQueries = {
  GET_DESTINATIONS: gql`
    query Destinations($input: DestinationsInput!) {
      destinations(input: $input) {
        data {
          id
          title
          description
          avgPrice {
            amount
            currencyIsoCode
          }
          carbonFootprint
          pictureUrl
          types {
            id
            name
          }
          country
          continent
          state
          isDomestic
          kiwiCityId
          rank
          visaScore
          visas {
            id
            level
            text
            country
            countryName
            duration
          }
          averageJetlag
          availableOriginLocationIds
          avgTravelTimeInMinutes
          avgTemperaturesByMonth {
            month
            celsius
            fahrenheit
          }
          coordinates {
            lat
            lon
          }
          connections {
            originLocation
            jetlagInMinutes
            coordinates {
              lat
              lon
            }
            main {
              duration {
                min
                max
                avg
              }
              cost {
                min
                max
                avg
              }
              type
              carbonFootprint
              stepovers
              distance
              airportTo
            }
            alternatives {
              duration {
                min
                max
                avg
              }
              cost {
                min
                max
                avg
              }
              type
              carbonFootprint
              stepovers
              distance
              airportTo
            }
          }
        }
        originLocations {
          code
          name
          coordinates {
            lat
            lon
          }
        }
      }
    }
  `,

  GET_AVAILABLE_DATES: gql`
    query AvailableDestinationsDates {
      availableDestinationsDates {
        dateFrom
        dateTo
      }
    }
  `,

  GET_DESTINATION_TYPES: gql`
    query DestinationTypes {
      destinationTypes {
        id
        name
      }
    }
  `,

  GET_DESTINATIONS_FOR_VENUES_MAP: gql`
    query AvailableVenuesMapDestinations {
      availableDestinations {
        id
        title
        coordinates {
          lat
          lon
        }
      }
    }
  `,

  GET_LOCATION_SUGGESTIONS: gql`
    query SearchLocations($filter: SearchLocationsInput!) {
      searchLocations(filter: $filter) {
        title
        type
        suggestions {
          id
          idn
          name
          country
          type
          coordinates {
            lat
            lon
          }
        }
      }
    }
  `,

  GET_SEARCH_ORIGIN_LOCATIONS: gql`
    query searchOriginLocation($search: String!) {
      searchOriginLocation(search: $search) {
        cityId
        originLocationId
        name
        country
        countryCode
        lat
        lon
        type
        suggestions {
          originLocationId
          name
          country
          countryCode
          lat
          lon
          type
          distance
        }
      }
    }
  `,

  GET_DESTINATIONS_COUNT: gql`
    query availableDestinationsCount {
      availableDestinationsCount
    }
  `,

  GET_ORIGIN_LOCATIONS: gql`
    query OriginLocations($filter: OriginLocationsInput!) {
      originLocations(filter: $filter) {
        id
        name
        country
        coordinates {
          lat
          lon
        }
      }
    }
  `,

  GET_ORIG_LOCATIONS: gql`
    query OriginLocationsStepperData($filter: OriginLocationsInput!) {
      originLocations(filter: $filter) {
        name
        id
      }
    }
  `,

  GET_DESTINATION: gql`
    query DestinationStepperData($input: DestinationInput!) {
      singleDestination(input: $input) {
        id
        title
      }
    }
  `,

  GET_SUMMARY_VENUE_DETAIL: gql`
    query SummaryVenueDetail($input: VenueInput!) {
      venue(input: $input) {
        id
        data {
          type
          title
          address
          starRating
          venueDestination {
            id
            title
          }
          hotelidPpn
          thumbnailUrls
          country
          coordinates {
            lat
            lon
          }
          houseRules {
            checkIn
            checkOut
          }
        }
        venueRoomData {
          taxesData
          externalUrls {
            url
            inventory
          }
          roomData {
            rooms {
              id
              title
              description
              thumbnailUrls
              roomSquareFootage
              rates {
                id
                ...RoomRateFragment
                resortFee {
                  amount
                  currencyIsoCode
                }
                subtotalSum {
                  amount
                  currencyIsoCode
                }
                totalSum {
                  amount
                  currencyIsoCode
                }
              }
            }

            totalPrice {
              amount
              currencyIsoCode
            }
            totalTaxesAndFees {
              amount
              currencyIsoCode
            }
            totalResortFees {
              amount
              currencyIsoCode
            }
          }
        }
      }
    }
    ${PlanFragments.ROOM_RATE_FRAGMENT}
  `,

  GET_SUMMARY_STEPPER_DATA: gql`
    query StepperData(
      $filter: OriginLocationsInput!
      $input: DestinationInput!
    ) {
      originLocations(filter: $filter) {
        id
        name
        coordinates {
          lat
          lon
        }
      }
      destination(input: $input) {
        id
        title
        country
        coordinates {
          lat
          lon
        }
        availableOriginLocationIds
      }
    }
  `,

  GET_COUNTRIES: gql`
    query CountrySelect {
      countries {
        code
        name
      }
    }
  `,

  GET_VENUE_DETAIL: gql`
    query VenueDetail($input: VenueInput!) {
      venue(input: $input) {
        id
        data {
          venueType
          type
          title
          rating
          closestAirportDistance
          coordinates {
            lat
            lon
          }
          venueDestination {
            id
            title
            airports {
              distanceInKilometers
              name
              code
              coordinates {
                lat
                lon
              }
            }
          }
          houseRules {
            checkIn
            checkOut
            cancellationDescription
            roomTypeAssignmentDescription
          }
          address
          amenities {
            amenityGroups {
              amenities
              name
              id
            }
            featured {
              name
              photoUrl
            }
          }
          starRating
          description
          destinationId
          country
          countryCode
          numberOfRooms
          thumbnailUrl
          thumbnailUrls
          isSearchable
          isSustainable
          isMeetingRoomIncluded
          isEntireHouse
          carbonEmissions {
            room
            meeting
          }
          meetingSpaceSize
          source
          capacity
          price {
            amount
            currencyIsoCode
          }
          pointsOfInterests(limit: 100) {
            id
            name
            lat
            lon
            distance
            categoryId
            categoryName
          }
          calendar {
            occupancies {
              start
              end
            }
          }
          staticData {
            rooms {
              id
              ...StaticRoomFragment
            }
            meetingRooms {
              id
              ...StaticRoomFragment
            }
          }
          carbonEmissions {
            room
          }
          location {
            distanceToCoast
            download
            upload
            sightseeing
            restaurants
            nightlife
          }
          hotelDetails {
            guestRatings {
              staff
              cleanliness
              location
            }
            importantInformation
          }
          ratings {
            id
            reviewerName
            goodReview
            badReview
            review
            rating
            ratingDescription
            reviewerType
          }
        }
      }
    }
    ${PlanFragments.STATIC_ROOM_FRAGMENT}
  `,

  GET_ROOM_DATA: gql`
    query RoomTypes($input: VenueInput!) {
      venue(input: $input) {
        id
        venueRoomData {
          externalUrls {
            url
            inventory
          }
          roomData {
            rooms {
              id
              title
              description
              thumbnailUrls
              roomSquareFootage
              roomSquareMeters
              rates {
                id
                ...RoomRateFragment
              }
            }
          }
        }
      }
    }
    ${PlanFragments.ROOM_RATE_FRAGMENT}
  `,

  GET_VENUES: gql`
    query Venues($input: VenuesInput, $thumbnailsLimit: Int) {
      venues(input: $input) {
        data {
          id

          thumbnailUrl
          thumbnailUrls(limit: $thumbnailsLimit)
          type
          rating
          title
          numberOfRooms
          capacity
          isMeetingRoomIncluded
          isPromoted
          isFeatured
          isSustainable
          closestAirportDistance
          closestAirport
          # venueDestination {
          #   id
          #   airports {
          #     distanceInKilometers
          #     code
          #   }
          # }
          price {
            amount
            currencyIsoCode
          }
          priceTotal {
            amount
            currencyIsoCode
          }
          coordinates {
            lat
            lon
          }
        }
        paginationInfo {
          currentPage
          firstPage
          lastPage
          nextPage
          previousPage
          totalCount
        }
        airportData {
          name
          code
          coordinates {
            lat
            lon
          }
        }
        filterData {
          priceMin {
            amount
            currencyIsoCode
          }
          priceMax {
            amount
            currencyIsoCode
          }
        }
      }
    }
  `,

  GET_STEPPER_DATA: gql`
    query StepperData(
      $filter: OriginLocationsInput!
      $input: DestinationInput!
    ) {
      originLocations(filter: $filter) {
        name
      }
      singleDestination(input: $input) {
        id
        title
        country
        kiwiCityId
      }
    }
  `,

  GET_SIMILAR_VENUES: gql`
    query similarVenues($input: SimilarVenuesInput!) {
      similarVenues(input: $input) {
        id
        title
        city
        country
        countryCode
        thumbnailUrl
        thumbnailUrls
        reviewRating
      }
    }
  `,

  SEARCH_DESTINATIONS: gql`
    query SearchDestinations($search: String!) {
      searchDestinations(search: $search) {
        destinationId
        cities
      }
    }
  `,

  GET_USER_TRIPS: gql`
    query TripsToPreload {
      tripsToPreload {
        id
        name
        teamLocations {
          id
          name
          originLocationCode
          membersCount
        }
      }
    }
  `,

  GET_DESTINATION_ISOCHRONES: gql`
    query DestinationIsochrones($destination: String!) {
      isochrones(destination: $destination) {
        id
        distanceInMinutes
        code
        lat
        lon
        geometry {
          type
          coordinates
        }
      }
    }
  `,
}
