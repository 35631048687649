import { gql } from '@apollo/client'
import { AccountFragments } from './AccountFragments'

export const AccountMutations = {
  CHANGE_PLAN: gql`
    mutation ChangePlan($priceId: String!) {
      changePlan(priceId: $priceId) {
        id
        ...SubscriptionFragment
      }
    }
    ${AccountFragments.SUBSCRIPTION_FRAGMENT}
  `,

  CANCEL_SUBSCRIPTION: gql`
    mutation CancelSubscription {
      cancelSubscription
    }
  `,

  SAVE_BILLING_DETAILS: gql`
    mutation SaveBillingDetails($input: BillingDetailsInput!) {
      saveBillingDetails(input: $input)
    }
  `,

  SAVE_USER_DETAILS: gql`
    mutation SaveUserDetails($input: SaveUserDetailsInput!) {
      saveUserDetails(input: $input) {
        id
        email
        firstName
        lastName
        phoneNumber
        company
        passwordChangedAt
        avatarUrl
        betaFeaturesEnabled
        agencyFeaturesEnabled
        productGuidesDisabled
      }
    }
  `,

  CHANGE_PASSWORD: gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
      changePassword(input: $input) {
        accessToken
        refreshToken
      }
    }
  `,

  CREATE_SUBSCRIPTION: gql`
    mutation CreateSubscription($input: CreateSubscriptionInput!) {
      createSubscription(input: $input) {
        status
        paymentStatus
        clientSecret
      }
    }
  `,

  CHANGE_PAYMENT_METHOD: gql`
    mutation ChangePaymentMethod($paymentMethodId: String!) {
      changePaymentMethod(paymentMethodId: $paymentMethodId) {
        id
        ...PaymentMethodFragment
      }
    }
    ${AccountFragments.PAYMENT_METHOD_FRAGMENT}
  `,

  DELETE_ACCOUNT: gql`
    mutation Mutation {
      deleteAccount
    }
  `,
}
