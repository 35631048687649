import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { Button } from '@nextretreat/ui-components/dist/Button'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { BOXSHADOWS, COLORS, fontSizes, radius, space } from 'Theme'
import { ReactComponent as ArrowSVG } from 'assets/images/svg/arrow-button.svg'
import { shouldForwardPropOptions } from 'utils/helpers'

const borderOnHover = `1px solid ${theme.COLORS.BRAND_DEFAULT}`
const borderDisabled = `1px solid ${theme.COLORS.BG_DIVIDER}`
export const BUTTON_HEIGHT = rem('40px')
const ROUND_BUTTON_HEIGHT = rem('32px')

const ButtonBase = styled('button', shouldForwardPropOptions)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: ${radius.m};
  min-width: ${rem(62)};
  background-color: ${theme.COLORS.WHITE};
  ${({ isBlock }) => isBlock && `width: 100%`};
  ${({ $fontWeight }) => ($fontWeight ? `font-weight: ${$fontWeight}` : '')}
`

ButtonBase.defaultProps = {
  type: 'button',
}

const ArrowImg = styled(ArrowSVG)`
  height: ${rem(15)};
  margin-right: ${space.s};
  & path {
    fill: white !important;
  }
`

const Back = ({ children, ...buttonProps }) => (
  <Button.Tertiary {...buttonProps}>
    <ArrowImg />
    {children}
  </Button.Tertiary>
)

Back.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export const tertiaryStyle = `
  display: inline-flex;
  align-items: center;
  padding: 0 ${space.s};
  height: ${BUTTON_HEIGHT};
  color: ${theme.COLORS.BRAND_DEFAULT};
`

const pillActiveStyle = `
  box-shadow: none;
  background-color: ${COLORS.IRRADIANT_IRIS};
  border: ${borderOnHover};
`

const StyledPill = styled(ButtonBase, shouldForwardPropOptions)`
  font-size: ${fontSizes.s};
  height: ${ROUND_BUTTON_HEIGHT};
  min-width: ${rem('46px')};
  padding: 0 ${space.m};
  border-radius: ${radius.pill};
  color: ${theme.COLORS.TXT_MAIN};
  background-color: ${theme.COLORS.WHITE};
  border: ${borderDisabled};
  @media (hover: hover) {
    &:hover {
      border: ${borderOnHover};
    }
  }
  :active {
    ${pillActiveStyle}
  }
  :disabled {
    box-shadow: none;
    background-color: ${COLORS.LYNX_WHITE};
    color: ${COLORS.BLUEBERRY_SODA};
    border: ${borderDisabled};
  }

  ${({ $isActive }) => $isActive && pillActiveStyle}

  ${({ $isOutlined, $isActive }) =>
    $isOutlined &&
    !$isActive &&
    `
      &:not(:hover):not(:disabled) {
        box-shadow: ${BOXSHADOWS.DARK}, inset 0 0 0 1.5px ${theme.COLORS.BRAND_DEFAULT};
        border-color: transparent;
      }
    `}
`

const Pill = forwardRef(({ isActive, isOutlined, ...passingProps }, ref) => (
  <StyledPill
    ref={ref}
    {...passingProps}
    $isOutlined={isOutlined}
    $isActive={isActive}
  />
))

Pill.propTypes = {
  isActive: PropTypes.bool,
  isOutlined: PropTypes.bool,
}

const Circle = styled(Pill)`
  overflow: hidden;

  flex: none;
  width: ${ROUND_BUTTON_HEIGHT};
  min-width: 0;
  padding: 0 ${space.xs};

  font-size: ${fontSizes.m};

  &:disabled {
    cursor: not-allowed;
  }
`

export default {
  Primary: Button.Primary,
  Secondary: Button.Secondary,
  Tertiary: Button.Tertiary,
  Negative: Button.Negative,
  Pill,
  Circle,
  Back,
}
