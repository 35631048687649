import React from 'react'
import { ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import {
  ErrorBoundary as RollbarErrorBoundary,
  Provider as RollbarProvider,
} from '@rollbar/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import ApolloProvider from 'ApolloProvider'
import AuthProvider from 'AuthProvider'
import CurrencyProvider from 'CurrencyProvider'
import DriftContainer from 'DriftContainer'
import EventProvider from 'EventLogProvider'
import meta from 'meta.json'
import ModalManagementProvider from 'ModalManagementProvider'
import PropTypes from 'prop-types'
import QueryProvider from 'QueryProvider'
import { BrowserRouter as Router } from 'react-router-dom'
import Rollbar from 'rollbar'
import { GlobalStyles, theme } from 'Theme'
import { ModalPortal } from 'components/Modal'
import ToastContainer from 'components/ToastContainer'
import { Router as RouterComponent } from 'routes/Router'
import { GamificationProvider } from 'sharedComponents/Gamification/GamificationProvider'
import ModalManagementModals from 'sharedComponents/ModalManagement/ModalManagementModals'
import { SegmentationProvider } from 'sharedComponents/Segmentation/Segmentation'
import { analytics } from 'utils/analytics'
import InternalServerError from './routes/InternalServerError'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

export const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_API_KEY,
  environment: process.env.REACT_APP_ENV,
  codeVersion: meta.version,
}

export const rollbar = new Rollbar(rollbarConfig)

const ErrorFallback = () => (
  <Router>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <InternalServerError />
    </ThemeProvider>
  </Router>
)

const SelectPortal = styled('div')`
  &,
  & > * {
    z-index: 50 !important;
  }
  text-align: left;
`

const PopoverPortal = styled('div')`
  z-index: 49 !important;
`

const RollbarConditionalProvider = ({ children }) =>
  process.env.REACT_APP_ENV === 'production' ? (
    <RollbarProvider instance={rollbar}>{children}</RollbarProvider>
  ) : (
    children
  )

RollbarConditionalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const App = () => {
  React.useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty(
        '--nr-100vh',
        `${window.innerHeight}px`
      )
    }

    window.addEventListener('resize', handleResize)
    document.documentElement.style.setProperty(
      '--nr-100vh',
      `${window.innerHeight}px`
    )

    if (process.env.REACT_APP_ENV === 'production') {
      analytics(window, document, 'script', 'dataLayer', 'GTM-594Q2PNM')
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <RollbarConditionalProvider>
      <RollbarErrorBoundary fallbackUI={ErrorFallback}>
        <ApolloProvider>
          <Elements stripe={stripePromise}>
            <Router>
              <CurrencyProvider>
                <AuthProvider>
                  <QueryProvider>
                    <EventProvider>
                      <ModalManagementProvider>
                        <ThemeProvider theme={theme}>
                          <GamificationProvider>
                            <GlobalStyles />
                            <ModalManagementModals />
                            <ToastContainer />
                            <RouterComponent />
                            <DriftContainer />
                            <SegmentationProvider />
                          </GamificationProvider>
                        </ThemeProvider>
                      </ModalManagementProvider>
                    </EventProvider>
                  </QueryProvider>

                  <SelectPortal id="select-menu" />
                  <PopoverPortal id="popover" />

                  <ModalPortal />
                </AuthProvider>
              </CurrencyProvider>
            </Router>
          </Elements>
        </ApolloProvider>
      </RollbarErrorBoundary>
    </RollbarConditionalProvider>
  )
}

export default App
