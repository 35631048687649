import React from 'react'
import { Input } from '@nextretreat/ui-components/dist/Input'
import { Field } from 'formik'
import { rem } from 'polished'
import { Flex } from 'components/atoms/Layout'
import { H3 } from 'components/atoms/Typography'
import { ChildMarginBottom } from '../FormikStepper'

export const PersonalDataStep = () => (
  <ChildMarginBottom>
    <H3 fontWeight="600" mb={rem(20)}>
      Personal information
    </H3>

    <Flex $gap={rem(20)}>
      <Field name="firstName">
        {({ field, meta }) => (
          <Input
            isBlock
            required
            label="Your first name"
            placeholder="Enter first name"
            invalid={meta.touched && meta.error !== undefined}
            {...field}
          />
        )}
      </Field>

      <Field name="lastName">
        {({ field, meta }) => (
          <Input
            isBlock
            required
            label="Your surname"
            placeholder="Enter surname"
            invalid={meta.touched && meta.error !== undefined}
            {...field}
          />
        )}
      </Field>
    </Flex>

    <Field name="email">
      {({ field, meta }) => (
        <Input
          isBlock
          disabled
          required
          label="Email address"
          placeholder="Enter your email address"
          invalid={meta.touched && meta.error !== undefined}
          {...field}
        />
      )}
    </Field>
    <Field name="phoneNumber">
      {({ field, meta }) => (
        <Input
          isBlock
          required
          label="Phone number"
          placeholder="Enter phone number"
          invalid={meta.touched && meta.error !== undefined}
          {...field}
        />
      )}
    </Field>
  </ChildMarginBottom>
)
