import { useMutation } from '@apollo/client'
import theme from '@nextretreat/ui-components/dist/Theme'
import { Formik } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { AccountMutations } from 'api/Account/AccountMutations'
import { Text } from 'components/atoms/Typography'
import Device from 'components/Device'
import { toast } from 'utils/helpers'
import { BillingDetailsFormFields } from './BillingDetailsFormFields'
import { TabCard } from '../../Account'

const saveBillingDetailsValidationSchema = Yup.object().shape({
  email: Yup.string().email(),
  company: Yup.string(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  phoneNumber: Yup.string().required(),
  payAsCompany: Yup.boolean(),
  address: Yup.object().shape({
    line1: Yup.string().required(),
    line2: Yup.string(),
    postalCode: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string(),
    country: Yup.mixed().required(),
  }),
  taxId: Yup.object().when('payAsCompany', ([payAsCompany], schema) =>
    payAsCompany
      ? schema.shape({
          type: Yup.string().required(),
          value: Yup.string().required(),
        })
      : schema.shape({
          type: Yup.string(),
          value: Yup.string(),
        })
  ),
})

export const BillingDetails = ({ billingDetails = {}, user = {}, loading }) => {
  const [saveBillingDetails] = useMutation(
    AccountMutations.SAVE_BILLING_DETAILS,
    {
      refetchQueries: ['GetBillingDetails', 'GetUserData'],
    }
  )

  const onSubmit = async (input, { setFieldValue, setSubmitting }) => {
    setSubmitting(true)

    const copiedInput = { ...input }

    delete copiedInput.email
    delete copiedInput.taxId
    delete copiedInput.payAsCompany

    try {
      const response = await saveBillingDetails({
        variables: {
          input: {
            ...copiedInput,
            name: undefined,
            company: input.payAsCompany ? copiedInput.company : '',
            address: {
              ...copiedInput?.address,
              country:
                typeof copiedInput?.address?.country === 'string'
                  ? copiedInput?.address.country
                  : copiedInput?.address?.country?.value?.code,
            },
            taxIds: input.payAsCompany
              ? [input.taxId]
              : input.taxId?.id
              ? [{ id: input.taxId?.id, type: '', value: '' }]
              : [],
          },
        },
      })
      setFieldValue('taxId.id', undefined)

      if (response) toast.success('Data changes successfully')
    } catch {
      // do nothing
    }

    setSubmitting(false)
  }

  const { address, taxIds } = billingDetails

  return (
    <>
      <Device sizes={['desktop', 'tv']}>
        <Text
          as="h2"
          mt={rem(58)}
          mb={rem(24)}
          fontSize={theme.fontSizes.xxxxl}
          lineHeight={theme.lineHeights.xxxxl}
        >
          Billing
        </Text>
      </Device>

      <TabCard title="Personal information" display="block">
        <Formik
          enableReinitialize
          validationSchema={saveBillingDetailsValidationSchema}
          initialValues={saveBillingDetailsValidationSchema.cast({
            email: user.email,
            phoneNumber: billingDetails.phoneNumber || '',
            firstName: billingDetails.firstName || '',
            lastName: billingDetails.lastName || '',
            company: billingDetails.company || '',
            payAsCompany: !!billingDetails.company || !!taxIds?.[0],
            taxId: {
              value: taxIds?.[0]?.value || '',
              type: taxIds?.[0]?.type || '',
              id: taxIds?.[0]?.id,
            },
            address: {
              line1: address?.line1 || '',
              line2: address?.line2 || '',
              postalCode: address?.postalCode || '',
              city: address?.city || '',
              state: address?.state || '',
              country: address?.country || '',
            },
          })}
          onSubmit={onSubmit}
        >
          <BillingDetailsFormFields loading={loading} />
        </Formik>
      </TabCard>
    </>
  )
}

BillingDetails.propTypes = {
  loading: PropTypes.bool,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    company: PropTypes.string,
  }),
  billingDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    company: PropTypes.string,
    address: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
    }),
    taxIds: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.string,
      })
    ),
  }),
}
