import { css } from '@emotion/react'
import styled from '@emotion/styled'
import propTypes, { createPropTypes } from '@styled-system/prop-types'
import {
  alignItems,
  background,
  color,
  flex,
  flexbox,
  grid,
  gridArea,
  justifyContent,
  justifyItems,
  layout,
  order,
  position,
  space,
  typography,
} from 'styled-system'
import { theme } from 'Theme'

const gutter = ({ gutterX, gutterY }) => css`
  ${gutterX && `> * + * { margin-left: ${theme.space[gutterX] || gutterX}};`}
  ${gutterY && `> * + * { margin-top: ${theme.space[gutterY] || gutterY}};`}
`

const commonPropFunctions = [
  layout,
  space,
  position,
  typography,
  flex,
  order,
  gridArea,
  color,
  background,
  gutter,
]

const commonPropTypes = {
  ...propTypes.layout,
  ...propTypes.space,
  ...propTypes.position,
  ...propTypes.typography,
  ...propTypes.color,
  ...propTypes.background,
  ...createPropTypes(flex.propNames),
  ...createPropTypes(order.propNames),
  ...createPropTypes(gridArea.propNames),
  ...createPropTypes(['gutter']),
}

const Box = styled('div')`
  ${commonPropFunctions}
`

Box.propTypes = commonPropTypes

const Flex = styled('div')`
  display: flex;
  ${({ $gap }) => ($gap ? `gap: ${$gap};` : '')}
  ${commonPropFunctions}
  ${flexbox}
`

Flex.propTypes = {
  ...commonPropTypes,
  ...propTypes.flexbox,
}

const Grid = styled('div')`
  display: grid;
  ${commonPropFunctions}
  ${grid}
  ${alignItems}
  ${justifyContent}
  ${justifyItems}
`

Grid.propTypes = {
  ...commonPropTypes,
  ...propTypes.grid,
  ...createPropTypes(alignItems.propNames),
  ...createPropTypes(justifyContent.propNames),
  ...createPropTypes(justifyItems.propNames),
}

export { Box, Flex, Grid }
