import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'

const TabHeaderWrap = styled(Flex)`
  background-color: ${theme.COLORS.WHITE};
  padding: ${rem(20)} ${rem(24)};
  box-shadow: ${theme.BOXSHADOWS.LEVEL_0};
  justify-content: space-between;
  align-items: center;
`
export const TabHeader = ({ children, buttons, subtitle, ...passingProps }) => (
  <TabHeaderWrap
    display={{ mobile: 'none', desktop: 'flex' }}
    {...passingProps}
  >
    <Box flex="1">
      <Text fontSize="xxl" as="h2" mb={0}>
        {children}
      </Text>
      {subtitle && (
        <Text mt="4px" fontSize="s" color={theme.COLORS.TXT_DEFAULT} as="div">
          {subtitle}
        </Text>
      )}
    </Box>

    {buttons}
  </TabHeaderWrap>
)

TabHeader.propTypes = {
  children: PropTypes.node.isRequired,
  buttons: PropTypes.node,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}
