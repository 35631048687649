import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { ReactComponent as GroupsSVG } from 'assets/images/svg/groups.svg'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'

const EmptyFlex = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  background-color: ${theme.COLORS.WHITE};
  box-shadow: ${theme.BOXSHADOWS.LEVEL_1};
  border-radius: 8px;
  padding: 10px;
  height: auto;
`

export const EmptyState = ({
  className,
  icon = <GroupsSVG />,
  title,
  subtitle,
  button,
  ...passingProps
}) => (
  <EmptyFlex {...passingProps} className={className}>
    {icon}
    <Text
      mt={rem(34)}
      mb="s"
      textAlign="center"
      fontSize={theme.fontSizes.xxl}
      lineHeight={theme.lineHeights.xxl}
      fontWeight="700"
    >
      {title}
    </Text>

    {subtitle && (
      <Text
        color={theme.COLORS.TXT_DEFAULT}
        textAlign="center"
        mb={rem(24)}
        style={{ whiteSpace: 'break-spaces' }}
        maxWidth={rem(800)}
        fontSize={theme.fontSizes.m}
        lineHeight={theme.lineHeights.m}
      >
        {subtitle}
      </Text>
    )}

    {button}
  </EmptyFlex>
)

EmptyState.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  button: PropTypes.node,
  icon: PropTypes.node,
}
