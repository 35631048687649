import styled from '@emotion/styled'
import { useFormikContext } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { mq } from 'Theme'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { SegmentationItem, SegmentationTitle } from './SegmentationComponents'

const StyledFlex = styled(Flex)`
  ${mq.to.tablet`gap: 12px;`}
`

export const OrganisingStep = ({ onSubmit }) => {
  const { values, setFieldValue } = useFormikContext()

  const ORGANISING = [
    {
      id: 'alreadyPlanned',
      title: 'Have you ever organised a team retreat?',
      answers: [
        {
          title: 'Yes, I have planned',
          value: true,
        },
        {
          title: 'No, I have not',
          value: false,
        },
      ],
    },
    {
      id: 'currentlyPlanning',
      title: 'Are you currently organising a retreat?',
      answers: [
        {
          title: 'Yes, I am planning currently',
          value: true,
        },
        {
          title: 'No, I do not plan now',
          value: false,
        },
      ],
    },
    ...(values.currentlyPlanning
      ? [
          {
            id: 'ownCompany',
            title:
              'Do you organise a retreat for your company or other company?',
            answers: [
              {
                title: 'My Company',
                value: true,
              },
              {
                title: 'Other company',
                value: false,
              },
            ],
          },
        ]
      : []),
  ]

  return (
    <Box px={rem(16)} mb={rem(24)} width="100%" maxWidth={rem(864)}>
      {ORGANISING.map(({ id, title, answers }) => (
        <Box key={id}>
          <SegmentationTitle mb={rem(20)} text={title} />

          <StyledFlex
            flexDirection={{ mobile: 'column', tablet: 'row' }}
            $gap={rem(16)}
          >
            {answers.map(({ title, value }) => (
              <SegmentationItem
                key={title}
                height={rem(88)}
                $isSelected={values[id] === value}
                onClick={() => setFieldValue(id, value)}
              >
                <b>{title}</b>
              </SegmentationItem>
            ))}
          </StyledFlex>
        </Box>
      ))}

      <Box mt={rem(24)}>
        <Button.Primary
          disabled={[
            values.alreadyPlanned,
            values.currentlyPlanning,
            ...(values.currentlyPlanning ? [values.ownCompany] : []),
          ].some((value) => value === undefined)}
          type="button"
          isBlock
          onClick={onSubmit}
        >
          Continue
        </Button.Primary>
      </Box>
    </Box>
  )
}

OrganisingStep.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
