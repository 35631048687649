export const PAGE_TITLES = {
  ATTENDANCE: 'Attendance',
  GRANT_ACCESS: 'Grant access',
  LOGIN: 'App Log in',
  CONFIRMATION: 'Account confirmed',
  REQUEST_RESET_PASSWORD: 'Change your Password',
  SET_NEW_PASSWORD: 'Set new password',
  REGISTRATION: 'Sign Up',
  ACCOUNT_BILLING: 'Billing & Account',
  MY_TRIPS: 'My Trips',
  PAYMENT_SUCCESS: 'App Successful Payment',
  PAYMENT_FAILURE: 'App Unsuccessful Payment',
}

export const PAGE_DESCRIPTIONS = {
  LOGIN:
    'Simplify the process of organizing team travel with NextRetreat App. Login to start using the app, research and find destinations, venues, dates and much more.',
  REQUEST_RESET_PASSWORD:
    'Simplify the process of organizing team travel with NextRetreat app. Reset your password.',
  REGISTRATION:
    'Simplify the process of organizing team travel with NextRetreat app. Sign-up and choose a package to start using the app.',
  ACCOUNT_BILLING:
    'Easily change your package, preview payment history and adjust your settings.',
}
