import styled from '@emotion/styled'
import { Input } from '@nextretreat/ui-components/dist/Input'
import { Field, useFormikContext } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { mq } from 'Theme'
import Button from 'components/atoms/Button'
import { Box, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import {
  // SegmentationDescription,
  SegmentationItem,
  SegmentationTitle,
} from './SegmentationComponents'

const ROLES = [
  {
    title: 'C-Level Executive',
  },
  {
    title: 'Senior Management / Director',
  },
  {
    title: 'Human Resources / People Ops',
  },
  {
    title: 'Team Lead / Manager',
  },
  {
    title: 'Individual Contributor / Staff',
  },
  {
    title: 'Other',
    subtitle: '(please specify)',
  },
]

const StyledGrid = styled(Grid)`
  grid-auto-rows: auto;
  grid-gap: ${rem(16)};
  grid-template-columns: repeat(auto-fill, minmax(${rem(250)}, 1fr));

  ${mq.to.tablet`
    grid-template-columns: 1fr;
  `}
`

export const RoleStep = ({ onSubmit }) => {
  const { values, setFieldValue } = useFormikContext()
  return (
    <>
      <SegmentationTitle text="Which of the following best describes your role at your organization?" />

      <Box px={rem(16)} mb={rem(24)} width="100%" maxWidth={rem(864)}>
        <StyledGrid>
          {ROLES.map(({ title, subtitle }) => (
            <SegmentationItem
              $isSelected={title === values.position}
              height={{ mobile: rem(100), tablet: rem(140) }}
              key={title}
              onClick={() => setFieldValue('position', title)}
            >
              <b>{title}</b>
              {subtitle && <Text>{subtitle}</Text>}
            </SegmentationItem>
          ))}
        </StyledGrid>

        {values.position === 'Other' && (
          <Box mt={rem(32)}>
            <Field name="otherPosition">
              {({ field, meta }) => (
                <Input
                  label="Specify your role"
                  placeholder="Your role"
                  aria-invalid={meta.touched && meta.error !== undefined}
                  invalid={meta.touched && meta.error !== undefined}
                  isBlock
                  {...field}
                />
              )}
            </Field>
          </Box>
        )}

        <Box mt={rem(24)}>
          <Button.Primary
            type="button"
            disabled={
              !values.position ||
              (values.position === 'Other' && !values.otherPosition)
            }
            isBlock
            onClick={onSubmit}
          >
            Continue
          </Button.Primary>
        </Box>
      </Box>
    </>
  )
}

RoleStep.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
