import { gql } from '@apollo/client'

export const LayoutQueries = {
  GET_CURRENCIES: gql`
    query Currencies {
      currencies {
        isoCode
        symbol
      }
    }
  `,

  GET_ORIGIN_COUNTRIES: gql`
    query OriginCountries($filter: OriginLocationsInput!) {
      originLocations(filter: $filter) {
        country
        countryCode
      }
    }
  `,

  GET_DESTINATION: gql`
    query Destination($input: DestinationInput!) {
      singleDestination(input: $input) {
        id
        countryCode
      }
    }
  `,

  GET_NEWS: gql`
    query News {
      news {
        id
        title
        description
        createdAt
        linkText
        link
        newTab
      }
    }
  `,
}
