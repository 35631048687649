import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const AppHelmet = ({ indexable, children, ...passingProps }) => (
  <Helmet {...passingProps}>
    {children}
    {(!indexable || process.env.REACT_APP_ENV === 'production') && (
      <meta name="robots" content="noindex, nofollow" />
    )}
  </Helmet>
)

AppHelmet.propTypes = {
  indexable: PropTypes.bool,
  children: PropTypes.node,
}

export default AppHelmet
