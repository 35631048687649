import React from 'react'
import styled from '@emotion/styled'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import {
  addDays,
  addYears,
  differenceInCalendarDays,
  format,
  isBefore,
  isSameDay,
} from 'date-fns'
import { ellipsis, rem } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import DatePicker from 'react-datepicker'
import { BORDER_WIDTH, COLORS, space } from 'Theme'
import Button from 'components/atoms/Button'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { getNearestDateFromInterval } from 'utils/helpers'
import { CalendarStyle } from './CalendarStyle'

import 'react-datepicker/dist/react-datepicker.css'

const TODAY = new Date()

export const MAX_DATE_RANGE = 21

const formatDate = (date) => format(date, 'MMM d, Y')

const SelectedDates = styled(Text)`
  ${ellipsis()}
`

const Footer = styled(Flex)`
  display: flex;
  align-items: center;

  height: ${rem('77px')};

  white-space: nowrap;

  border-top: ${BORDER_WIDTH} solid ${COLORS.IRRADIANT_IRIS};

  > * + * {
    margin-left: ${space.m};
  }
`

const AvailabilityCalendar = ({
  minDate,
  maxDate,
  startDate: initialStartDate,
  endDate: initialEndDate,
  closeModal,
  onApply,
  onChange,
  selectButtonText = 'Check availability',
  showTitle,
  excludeDateIntervals,
}) => {
  const matches = useMediaQuery()
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)

  const handleChange = (dates) => {
    const [start, end] = dates
    if (start !== null && isSameDay(start, end)) {
      return
    }
    setStartDate(start)
    setEndDate(end)
    if (onChange) onChange({ startDate: start, endDate: end })
  }

  React.useEffect(() => {
    setStartDate(initialStartDate)
    setEndDate(initialEndDate)
  }, [initialStartDate, initialEndDate])

  const getMaxDateForDatepicker = () => {
    if (startDate && !endDate) {
      // case of date range selection restriction
      if (excludeDateIntervals?.length) {
        return getNearestDateFromInterval(startDate, excludeDateIntervals)
      }
      // max date should be either max date (if set) or start date + max trip duration (21 days)
      if (!maxDate || isBefore(addDays(startDate, MAX_DATE_RANGE), maxDate)) {
        return addDays(startDate, MAX_DATE_RANGE)
      }
    }
    // if end date is set or no date is set max date should be max date
    return maxDate
  }

  return (
    <div>
      {showTitle && (
        <Text display="block" fontSize="xxl" fontWeight="semi_bold" mb="l">
          Availability Calendar
        </Text>
      )}
      <CalendarStyle showTitle={showTitle}>
        <DatePicker
          excludeDateIntervals={excludeDateIntervals}
          onChange={handleChange}
          startDate={startDate}
          endDate={endDate}
          selected={startDate}
          minDate={minDate}
          inline
          selectsRange
          monthsShown={2}
          maxDate={getMaxDateForDatepicker()}
          shouldCloseOnSelect={false}
          showDisabledMonthNavigation
          disabledKeyboardNavigation // https://github.com/Hacker0x01/react-datepicker/issues/2117
          // selected={startDate} https://github.com/Hacker0x01/react-datepicker/issues/2117
        />
        {matches.mobile && (
          <>
            {startDate && endDate && (
              <Footer>
                <SelectedDates mr="auto">
                  {startDate &&
                    endDate &&
                    `${formatDate(startDate)} - ${formatDate(
                      endDate
                    )} (${differenceInCalendarDays(
                      endDate,
                      startDate
                    )} nights)`}
                </SelectedDates>
              </Footer>
            )}

            <Footer justifyContent="space-between">
              <Button.Primary
                viewType={EButtonType.PLAIN}
                fontSize="s"
                onClick={() => {
                  handleChange([null, null])
                }}
              >
                Clear
              </Button.Primary>

              {selectButtonText && (
                <Button.Primary
                  disabled={Boolean(startDate) !== Boolean(endDate)}
                  onClick={() => {
                    if (onApply) onApply({ startDate, endDate })
                    closeModal()
                  }}
                >
                  {selectButtonText}
                </Button.Primary>
              )}
            </Footer>
          </>
        )}
        {!matches.mobile && (
          <Footer>
            <SelectedDates mr="auto">
              {startDate &&
                endDate &&
                `${formatDate(startDate)} - ${formatDate(
                  endDate
                )} (${differenceInCalendarDays(endDate, startDate)} nights)`}
            </SelectedDates>

            <Button.Primary
              viewType={EButtonType.PLAIN}
              fontSize="s"
              onClick={() => {
                handleChange([null, null])
              }}
            >
              Clear
            </Button.Primary>

            {selectButtonText && (
              <Button.Primary
                disabled={Boolean(startDate) !== Boolean(endDate)}
                onClick={() => {
                  onApply({ startDate, endDate })
                  closeModal()
                }}
              >
                {selectButtonText}
              </Button.Primary>
            )}
          </Footer>
        )}
      </CalendarStyle>
    </div>
  )
}

AvailabilityCalendar.defaultProps = {
  minDate: TODAY,
  maxDate: addYears(TODAY, 1),
  closeModal: () => {},
  selectButtonText: 'Check availability',
}

AvailabilityCalendar.propTypes = {
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  closeModal: PropTypes.func,
  onApply: PropTypes.func,
  selectButtonText: PropTypes.string,
  showTitle: PropTypes.bool,
  excludeDateIntervals: PropTypes.array,
  onChange: PropTypes.func,
}

export default AvailabilityCalendar
