import { useState } from 'react'
import styled from '@emotion/styled'
import { Input } from '@nextretreat/ui-components/dist/Input'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import Modal, { FooterBox } from 'components/Modal'
import { TRIP_TAB_ACCESS_OPTIONS } from 'constants/constants'

const StyledCheckbox = styled(Input.Checkbox)`
  flex-direction: row-reverse;
  width: 100%;
  margin: 0;

  & > div:last-of-type {
    flex: 1;
    margin-left: 0;
  }
`

const StyledBox = styled(Box)`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.COLORS.BG_DIVIDER};
  }
`

export const MobilePageSelect = ({
  isOpen,
  closeModal,
  initValues,
  onSelect,
}) => {
  const [values, setValues] = useState(
    initValues.map(({ name, id }) => ({ value: id, label: name })) || []
  )

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      ariaLabel="Pages"
      title="Pages"
      contentWidth={rem(900)}
    >
      <Flex height="100%" flex="1" flexDirection="column">
        <Box
          flex="1"
          p={{ mobile: 0, tablet: rem(24) }}
          maxHeight={{ mobile: 'unset', tablet: '500px' }}
          overflow="auto"
        >
          {TRIP_TAB_ACCESS_OPTIONS.map((item) => (
            <StyledBox mx={rem(24)} py={rem(12)} key={item.label}>
              <StyledCheckbox
                checked={!!values.find(({ value }) => value === item.value)}
                label={item.label}
                onChange={(val) =>
                  val
                    ? setValues((prev) => [...prev, item])
                    : setValues((prev) =>
                        prev.filter(({ value }) => item.value !== value)
                      )
                }
              />
            </StyledBox>
          ))}
        </Box>

        <FooterBox>
          <Button.Primary
            isBlock
            type="button"
            onClick={() => {
              onSelect(values)
              closeModal()
            }}
          >
            Save
          </Button.Primary>
        </FooterBox>
      </Flex>
    </Modal>
  )
}

MobilePageSelect.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  initValues: PropTypes.array,
  onSelect: PropTypes.func,
}
