import React from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useAuth } from 'AuthProvider'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { mq } from 'Theme'
import { ReactComponent as CrownSVG } from 'assets/images/svg/crown.svg'
import { Box } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Device from 'components/Device'
import { Icon } from 'components/Icon'
import { PRIVATE_ROUTES } from 'constants/routes'
import { accountTabs } from 'routes/Account/Account'
import { MenuItem, MenuItemWrap } from 'sharedComponents/MoreButton'

const Menu = styled('nav')`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
`

const StyledLogoutIcon = styled(Icon)`
  color: ${theme.COLORS.ERROR_DEFAULT};
  display: block;
`

export const Border = styled('div')`
  height: 1px;
  width: 100%;
  background-color: ${theme.COLORS.BG_DIVIDER};
  ${mq.to.tablet`
    margin: 12px 0;
  `}
`

export const UserSignedMenuItems = ({ onCloseMenu }) => {
  const { signOut } = useAuth()
  const navigate = useNavigate()
  const { pathname, hash, search } = useLocation()

  const navigateAndCloseMenu = (route) => () => {
    if (!(route === pathname + hash && !search)) navigate(route)

    onCloseMenu?.()
  }

  return (
    <>
      <Device size="tablet">
        <MenuItem
          as="button"
          type="button"
          icon="flight"
          text="My trips"
          onClick={navigateAndCloseMenu(`/${PRIVATE_ROUTES.MY_TRIPS}`)}
        />

        <MenuItem
          as="button"
          type="button"
          icon="search"
          text="Discover"
          onClick={navigateAndCloseMenu(`/${PRIVATE_ROUTES.PLAN}`)}
        />

        <MenuItem
          as="button"
          type="button"
          icon={
            <Box mr={{ mobile: '14px', tablet: rem(10) }}>
              <CrownSVG />
            </Box>
          }
          text={<b>Concierge</b>}
          onClick={navigateAndCloseMenu(`/${PRIVATE_ROUTES.CONCIERGE}`)}
        />

        <Box px={rem(16)}>
          <Border />
        </Box>
      </Device>

      {accountTabs.map(({ label, value, iconName }) => (
        <MenuItem
          as="button"
          type="button"
          text={label}
          icon={iconName}
          onClick={navigateAndCloseMenu(`/${PRIVATE_ROUTES.ACCOUNT}#${value}`)}
          key={value}
        />
      ))}

      <Box px={rem(16)}>
        <Border />
      </Box>

      <Text
        as="p"
        mt={rem(10)}
        mb={rem(4)}
        pl={rem(16)}
        fontSize="16px"
        lineHeight="24px"
        fontWeight="700"
      >
        Support
      </Text>

      <MenuItem
        href="https://nextretreat-old.notion.site/NextRetreat-Crash-Course-acce4d330a504387a2e1684ec7022b2b"
        target="_blank"
        icon="tv"
        text="How to use - Crash Course"
      />

      <MenuItem
        href="https://scribehow.com/page/How_to_use_the_NextRetreat_app__Fzl66QxhTy2CSmqoglxmsA"
        target="_blank"
        icon="menu_book"
        text="How to use - Knowledge Base"
      />

      <MenuItem
        as="button"
        type="button"
        icon="forum"
        text="Chat"
        onClick={() => {
          if (window.drift) {
            window.drift.api.openChat()
          }
        }}
      />

      <MenuItem href="tel:+421944333612" icon="phone" text="+421 944 333 612" />

      <MenuItem
        href="mailto:support@nextretreat.com"
        icon="mail_outline"
        text="Email"
      />

      <Box px={rem(16)}>
        <Border />
      </Box>

      <MenuItemWrap as="button" type="button" onClick={signOut}>
        <Text as="p" color={theme.COLORS.ERROR_DEFAULT} mr="auto">
          Logout
        </Text>

        <StyledLogoutIcon fontSize={rem(22)} name="logout" />
      </MenuItemWrap>
    </>
  )
}

UserSignedMenuItems.propTypes = {
  onCloseMenu: PropTypes.func,
}

const UserSignedMenu = ({ onCloseMenu }) => (
  <Menu>
    <UserSignedMenuItems onCloseMenu={onCloseMenu} />
  </Menu>
)

UserSignedMenu.propTypes = {
  onCloseMenu: PropTypes.func,
}

export default UserSignedMenu
