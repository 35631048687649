import { gql } from '@apollo/client'
import { AccountFragments } from './AccountFragments'

export const AccountQueries = {
  GET_SUBSCRIPTION: gql`
    query Subscription {
      subscription {
        id
        ...SubscriptionFragment
      }
    }
    ${AccountFragments.SUBSCRIPTION_FRAGMENT}
  `,

  BILLING_DETAILS: gql`
    query GetBillingDetails {
      billingDetails {
        firstName
        lastName
        company
        phoneNumber
        address {
          line1
          city
          line2
          postalCode
          state
          country
        }
        taxIds {
          type
          value
          id
        }
      }
    }
  `,

  PAYMENT_METHOD: gql`
    query PaymentMethod {
      paymentMethod {
        id
        ...PaymentMethodFragment
      }
    }
    ${AccountFragments.PAYMENT_METHOD_FRAGMENT}
  `,

  ACCOUNT_DATA: gql`
    query GetUserData {
      userMe {
        id
        email
        firstName
        lastName
        phoneNumber
        company
        passwordChangedAt
        betaFeaturesEnabled
        agencyFeaturesEnabled
        productGuidesDisabled
        trialEnd
        avatarUrl
      }
    }
  `,

  CALCULATE_PRICES: gql`
    query CalculateUpcomingInvoice($input: CalculateUpcomingInvoiceInput!) {
      calculateUpcomingInvoice(input: $input) {
        subtotal
        tax
        total
        taxPercent
        discount {
          amount
          amount_off
          name
          percent_off
        }
      }
    }
  `,

  TAX_IDS: gql`
    query TaxIds {
      taxIds {
        country
        value
        example
        description
        code
      }
    }
  `,

  GET_INVOICES: gql`
    query Invoices {
      invoices {
        id
        created
        currency
        invoicePdf
        number
        total
      }
    }
  `,
}
