import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useEvent } from 'EventLogProvider'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import { useLocation } from 'react-router-dom'
import { fontSizes, mq } from 'Theme'
import { AccountQueries } from 'api/Account/AccountQueries'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'
import { Tab, Tabs } from 'components/Tabs'
import { PAGE_DESCRIPTIONS, PAGE_TITLES } from 'constants/pageTitles'
import AppHelmet from 'sharedComponents/AppHelmet'
import Page from 'sharedComponents/Page'
import { BillingDetails } from './Tabs/BillingDetails'
import { Payments } from './Tabs/Subscriptions/Payments'
import { Subscriptions } from './Tabs/Subscriptions/Subscriptions'
import { UserProfile } from './Tabs/UserProfile/UserProfile'
import { UserSettings } from './Tabs/UserSettings'
import PaymentMessageProvider from './PaymentMessageContext'

const TRIPS_PER_PAGE = {
  PC: 8,
  TABLET: 2,
  MOBILE: 2,
}

const StyledIcon = styled(Icon)`
  vertical-align: middle;
  font-size: 20px;
`

const PageWrap = styled(Box)`
  &,
  & main {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`

const StyledTabCard = styled(Flex)`
  background-color: ${theme.COLORS.WHITE};
  border-radius: 8px;
  margin-bottom: ${rem(20)};
`

export const TabCard = ({ title, subtitle, children, ...props }) => (
  <StyledTabCard
    p={{ mobile: rem(20), desktop: `${rem(24)} ${rem(32)}` }}
    {...props}
  >
    <Box>
      <Text
        as="p"
        mb={rem(8)}
        fontWeight="700"
        fontSize={theme.fontSizes.xl}
        lineHeight={theme.lineHeights.xl}
      >
        {title}
      </Text>
      <Text
        as="p"
        color={theme.COLORS.TXT_DEFAULT}
        fontSize={theme.fontSizes.m}
        lineHeight={theme.lineHeights.m}
      >
        {subtitle}
      </Text>
    </Box>

    {children}
  </StyledTabCard>
)

TabCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
}

export const accountTabs = [
  {
    label: 'User profile',
    value: 'account',
    icon: <StyledIcon name="person_outline" />,
    iconName: 'person_outline',
  },
  {
    label: 'Billing',
    value: 'billing',
    icon: <StyledIcon name="credit_card" />,
    iconName: 'credit_card',
  },
  {
    label: 'Subscription',
    value: 'subscription',
    icon: <StyledIcon name="list" />,
    iconName: 'list',
  },
  {
    label: 'App settings',
    value: 'settings',
    icon: <StyledIcon name="settings" />,
    iconName: 'settings',
  },
]

const StyledTabs = styled(Tabs)`
  border-right: none;
  width: ${rem(300)} !important;
  padding-top: ${rem(58)};

  & button {
    padding: ${rem(16)} ${rem(24)};
    font-weight: 510;
    color: ${theme.COLORS.TXT_MAIN};
  }

  ${mq.from.desktop`
    margin-right: ${rem(50)};
  `}
  ${mq.from.tv`
    margin-right: ${rem(100)};
  `}
`

const Account = () => {
  const { pushEvent } = useEvent()
  const matches = useMediaQuery()
  const { hash } = useLocation()
  const activeTab = hash.slice(1)

  const [tripsPerPage, setTripsPerPage] = useState(TRIPS_PER_PAGE.PC)

  const { data: billingData, loading: billingLoading } = useQuery(
    AccountQueries.BILLING_DETAILS
  )
  const { data: userData, loading: userLoading } = useQuery(
    AccountQueries.ACCOUNT_DATA
  )
  const { data: paymentMethodData } = useQuery(AccountQueries.PAYMENT_METHOD)

  useEffect(() => {
    if (matches?.desktop && tripsPerPage !== TRIPS_PER_PAGE.PC)
      setTripsPerPage(TRIPS_PER_PAGE.PC)
    if (matches?.tablet && tripsPerPage !== TRIPS_PER_PAGE.TABLET)
      setTripsPerPage(TRIPS_PER_PAGE.TABLET)
    if (matches?.mobile && tripsPerPage !== TRIPS_PER_PAGE.MOBILE)
      setTripsPerPage(TRIPS_PER_PAGE.MOBILE)
  }, [matches])

  useEffect(() => {
    if (activeTab && accountTabs.some(({ value }) => value === activeTab))
      pushEvent(`account-${activeTab}-open`)
  }, [activeTab])

  const { billingDetails } = billingData ?? {}

  return (
    <>
      <AppHelmet
        titleTemplate="NextRetreat | %s"
        title={PAGE_TITLES.ACCOUNT_BILLING}
      >
        <meta name="description" content={PAGE_DESCRIPTIONS.ACCOUNT_BILLING} />
      </AppHelmet>

      <PageWrap>
        <Page goToPlan>
          <PaymentMessageProvider>
            <Flex
              backgroundColor={theme.COLORS.BG_SOFT}
              flexDirection="column"
              flex="1"
            >
              <Box
                m="0 auto"
                width="100%"
                maxWidth={{ mobile: '100%', desktop: '72rem' }}
                px={{ desktop: rem(20) }}
              >
                <StyledTabs
                  vertical
                  defaultTab="account"
                  title="Account menu"
                  tabs={accountTabs}
                >
                  <Box px={{ mobile: rem(20), desktop: 0 }}>
                    {' '}
                    <Tab
                      name="billing"
                      activeTab={activeTab}
                      element={
                        <BillingDetails
                          loading={billingLoading || userLoading}
                          billingDetails={billingDetails}
                          user={userData?.userMe}
                        />
                      }
                    />
                    <Tab
                      name="account"
                      activeTab={activeTab}
                      element={
                        <UserProfile
                          loading={userLoading}
                          user={userData?.userMe}
                        />
                      }
                    />
                    <Tab
                      name="subscription"
                      activeTab={activeTab}
                      element={
                        <Subscriptions
                          billingDetails={billingDetails}
                          user={userData?.userMe}
                          paymentMethod={paymentMethodData?.paymentMethod}
                        />
                      }
                    />
                    <Tab
                      name="payments"
                      activeTab={activeTab}
                      element={
                        <>
                          <Text as="h2" mb={rem(20)} fontSize={fontSizes.xxl}>
                            Payment history
                          </Text>

                          <Payments />
                        </>
                      }
                    />
                    <Tab
                      name="settings"
                      activeTab={activeTab}
                      element={<UserSettings user={userData?.userMe} />}
                    />
                  </Box>
                </StyledTabs>
              </Box>
            </Flex>
          </PaymentMessageProvider>
        </Page>
      </PageWrap>
    </>
  )
}

export default Account
