import { useAuth } from 'AuthProvider'
import { MODAL_TYPES, useModalManagement } from 'ModalManagementProvider'
import { useMediaQuery } from 'QueryProvider'

export const useOpenCreateTripModal = () => {
  const { userTrips } = useAuth()
  const matches = useMediaQuery()
  const { openModal } = useModalManagement()

  return () =>
    openModal({
      type:
        userTrips.length || matches.mobile || matches.tablet
          ? MODAL_TYPES.CREATE_MODAL
          : MODAL_TYPES.CREATE_TRIP_ONBOARDING,
    })
}
