/** @jsxImportSource @emotion/react */
/* eslint-disable react/no-unknown-property */
import React from 'react'
import styled from '@emotion/styled/macro' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { Manager, Popper, Reference } from 'react-popper'

const Popover = ({
  children,
  arrowStyle,
  targetComp,
  placement,
  isVisible,
  popoverStyle,
  zIndex,
}) => (
  <Manager>
    <Reference>
      {({ ref }) => React.cloneElement(targetComp, { ref })}
    </Reference>
    {isVisible &&
      ReactDOM.createPortal(
        <Popper
          positionFixed
          placement={placement}
          modifiers={[
            {
              name: 'preventOverflow',
              options: { padding: 0, boundariesElement: 'window' },
            },
            { name: 'flip', options: { enabled: false } },
            { name: 'hide', options: { enabled: true } },
          ]}
        >
          {({
            ref,
            style,
            hasPopperEscaped,
            placement: popperPlacement,
            arrowProps,
          }) => (
            <div
              data-popper-reference-hidden={hasPopperEscaped}
              ref={ref}
              style={popoverStyle || { zIndex, ...style }}
              data-placement={popperPlacement}
            >
              {children}
              <div
                ref={arrowProps.ref}
                style={arrowProps.style}
                data-placement={popperPlacement}
                placement={popperPlacement}
                css={arrowStyle}
              />
            </div>
          )}
        </Popper>,
        document.querySelector('#popover')
      )}
  </Manager>
)

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  arrowStyle: PropTypes.func,
  targetComp: PropTypes.node.isRequired,
  placement: PropTypes.string,
  isVisible: PropTypes.bool,
  popoverStyle: PropTypes.object,
  zIndex: PropTypes.number,
}

export default Popover
