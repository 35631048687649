import { forwardRef } from 'react'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import { Input } from '@nextretreat/ui-components/dist/Input'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useAuth } from 'AuthProvider'
import { Field, Form, Formik } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { AccountMutations } from 'api/Account/AccountMutations'
import { AccountQueries } from 'api/Account/AccountQueries'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Confirmation } from 'components/Confirmation'
import Device from 'components/Device'
import { Icon } from 'components/Icon'
import { LoadingWrapper } from 'components/LoadingWrapper'
import Modal, { useModal } from 'components/Modal'
import { PHONE_REGEX } from 'constants/constants'
import { useUploadImage } from 'hooks/useUploadImage'
import { UserAvatar } from 'sharedComponents/UserAvatar'
import { toast } from 'utils/helpers'
import { ChangePassword } from './ChangePassword'
import { TabCard } from '../../Account'

const saveUserDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().required(),
  phoneNumber: Yup.string()
    .matches(PHONE_REGEX, 'Phone number is not valid')
    .required(),
  avatarUrl: Yup.mixed(),
})

const StyledForm = styled(Form)`
  max-width: 100%;
  margin-top: ${rem(20)};

  & > *:not(:last-of-type) {
    margin-bottom: ${rem(20)};
  }
`

const DeleteButton = forwardRef((props, ref) => (
  <Button.Tertiary ref={ref} {...props}>
    Request deletion
  </Button.Tertiary>
))

export const UserProfile = ({ user, loading }) => {
  const { signOut } = useAuth()

  const {
    isOpen: isChangePasswordOpen,
    closeModal: closeChangePasswordModal,
    openModal: openChangePasswordModal,
  } = useModal()

  const [saveUserDetails] = useMutation(AccountMutations.SAVE_USER_DETAILS, {
    update: (cache, { data: { saveUserDetails } = {} }) => {
      if (saveUserDetails) {
        const query = {
          query: AccountQueries.BILLING_DETAILS,
        }
        const data = cache.readQuery(query)

        if (data)
          cache.writeQuery({
            ...query,
            data: {
              ...data,
              billingDetails: {
                ...data.billingDetails,
                firstName: saveUserDetails.firstName,
                lastName: saveUserDetails.lastName,
                phoneNumber: saveUserDetails.phoneNumber,
              },
            },
          })
      }
    },
  })
  const [deleteAccount, { loading: deleteLoading }] = useMutation(
    AccountMutations.DELETE_ACCOUNT
  )

  const { uploadImage } = useUploadImage('user-upload')

  const onUploadImage = async (image) => {
    let imageResponse

    if (!image) return ''

    if (typeof image === 'string') return image

    try {
      imageResponse = await uploadImage(image, image?.name)
    } catch {
      toast.error('Image was not uploaded')
      return
    }

    if (!imageResponse?.content?.url) {
      toast.error(`Avatar was not uploaded, try again!`)
    }

    return imageResponse?.content?.url || ''
  }

  const onSubmit = async (input, { setSubmitting }) => {
    setSubmitting(true)

    const avatarUrl = await onUploadImage(input.avatarUrl, true)

    try {
      const response = await saveUserDetails({
        variables: {
          input: {
            ...input,
            email: undefined,
            avatarUrl:
              input.avatarUrl && !avatarUrl ? user?.avatarUrl : avatarUrl,
          },
        },
      })

      if (response.data.saveUserDetails)
        toast.success('Data changes successfully')
    } catch {
      // do nothing
    }

    setSubmitting(false)
  }

  const onDeleteAccount = async () => {
    const response = await deleteAccount()
    if (response.data.deleteAccount) {
      toast.success('Account was deleted successfully')
      signOut()
    }
  }

  return (
    <>
      <Device sizes={['desktop', 'tv']}>
        <Text
          as="h2"
          mt={rem(58)}
          mb={rem(24)}
          fontSize={theme.fontSizes.xxxxl}
          lineHeight={theme.lineHeights.xxxxl}
        >
          User profile
        </Text>
      </Device>

      <TabCard
        title="Personal information"
        subtitle="Add or manage your personal information"
        flexDirection="column"
      >
        <Formik
          enableReinitialize
          validationSchema={saveUserDetailsValidationSchema}
          initialValues={saveUserDetailsValidationSchema.cast({
            phoneNumber: user?.phoneNumber || '',
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            email: user?.email || '',
            avatarUrl: user?.avatarUrl || '',
          })}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            dirty,
            isValid,
            values,
            setFieldValue,
            resetForm,
          }) => (
            <StyledForm>
              <LoadingWrapper loading={loading}>
                <UserAvatar
                  value={values.avatarUrl}
                  onChange={(file) => setFieldValue('avatarUrl', file)}
                  loading={isSubmitting}
                />

                <Flex $gap={rem(20)}>
                  <Field name="firstName">
                    {({ field, meta }) => (
                      <Input
                        isBlock
                        required
                        label="Your first name "
                        placeholder="Enter first name"
                        data-cy="inp-first-name"
                        invalid={meta.touched && meta.error !== undefined}
                        {...field}
                      />
                    )}
                  </Field>

                  <Field name="lastName">
                    {({ field, meta }) => (
                      <Input
                        isBlock
                        required
                        label="Your last name"
                        placeholder="Enter last name"
                        data-cy="inp-last-name"
                        invalid={meta.touched && meta.error !== undefined}
                        {...field}
                      />
                    )}
                  </Field>
                </Flex>

                <Field name="email">
                  {({ field, meta }) => (
                    <Input
                      isBlock
                      disabled
                      required
                      label="Email address"
                      placeholder="Enter your email address"
                      invalid={meta.touched && meta.error !== undefined}
                      {...field}
                    />
                  )}
                </Field>

                <Flex>
                  <Box flex="1">
                    <Field name="phoneNumber">
                      {({ field, meta }) => (
                        <Input
                          isBlock
                          required
                          label="Phone number"
                          placeholder="Enter phone number"
                          data-cy="inp-phone-number"
                          invalid={meta.touched && meta.error !== undefined}
                          {...field}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box flex="1" />
                </Flex>

                {dirty && (
                  <Flex $gap={rem(20)}>
                    <Button.Primary
                      type="submit"
                      disabled={!isValid}
                      isLoading={isSubmitting}
                      data-cy="btn-save"
                    >
                      Save changes
                    </Button.Primary>

                    <Button.Secondary
                      onClick={resetForm}
                      type="button"
                      viewType={EButtonType.PLAIN}
                    >
                      Discard changes
                    </Button.Secondary>
                  </Flex>
                )}
              </LoadingWrapper>
            </StyledForm>
          )}
        </Formik>
      </TabCard>

      <TabCard
        justifyContent="space-between"
        alignItems="center"
        title="Password"
        subtitle="Set or change your password"
        $gap={rem(20)}
        flexWrap="wrap"
      >
        <Button.Tertiary
          iconLeft={<Icon name="lock_outline" />}
          onClick={openChangePasswordModal}
        >
          Change password
        </Button.Tertiary>
      </TabCard>

      <TabCard
        flexDirection="column"
        title="GDPR"
        subtitle="As part of our ongoing commitment to your privacy, you may ask to delete your data from NextRetreat. You will receive an email confirmation after data deletion."
        $gap={rem(20)}
      >
        <Box>
          <Confirmation
            isLoading={deleteLoading}
            onConfirm={onDeleteAccount}
            label="Do you want to delete your account? The action is permanent"
            targetComp={DeleteButton}
            type="delete"
            targetLabel="Remove"
          />
        </Box>
      </TabCard>

      <Modal
        isOpen={isChangePasswordOpen}
        closeModal={closeChangePasswordModal}
        ariaLabel="Change password"
        title="Change password"
      >
        <ChangePassword closeModal={closeChangePasswordModal} />
      </Modal>
    </>
  )
}

UserProfile.propTypes = {
  loading: PropTypes.bool,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    avatarUrl: PropTypes.string,
  }),
}
