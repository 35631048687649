import { forwardRef } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const StyledSpan = styled('span')`
  text-decoration: none;
  display: inline-block;
`

/**
 * Icon from material-icons
 * @param {name} name of icon from material-icons
 * @returns icon
 */
export const Icon = forwardRef(
  (
    {
      className,
      fontSize = '20px',
      color,
      name,
      onClick,
      style,
      ...passingProps
    },
    ref
  ) => (
    <StyledSpan
      ref={ref}
      className={`material-icons ${className} notranslate`}
      style={{
        fontSize,
        color,
        cursor: onClick ? 'pointer' : undefined,
        ...style,
      }}
      onClick={onClick}
      {...passingProps}
    >
      {name}
    </StyledSpan>
  )
)

Icon.propTypes = {
  className: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
}
