import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import propTypes, { createPropTypes } from '@styled-system/prop-types'
import { color, display, layout, space, typography } from 'styled-system'
import { COLORS, fontSizes, fontWeights, mq } from 'Theme'
import { Box } from 'components/atoms/Layout'

const textFunctions = [display, space, color, typography, layout]

const styledPropTypes = {
  ...createPropTypes(display.propNames),
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.typography,
  ...propTypes.layout,
}

export const Text = styled('span')`
  ${textFunctions}
`

Text.propTypes = styledPropTypes

export const Ellipsis = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Label = styled('span')`
  font-weight: ${fontWeights.semi_bold};
  font-size: ${fontSizes.xxs};
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 1;

  color: ${COLORS.BLUEBERRY_SODA};

  ${textFunctions}
`

const Block = styled(Box)`
  > * + * {
    margin-top: ${({ gutter }) => space[gutter] || gutter};
  }
`

Block.defaultProps = {
  gutter: '1em',
}

Text.Block = Block

export const H1 = styled('h1')`
  font-size: ${fontSizes.xxxl};
  ${mq.from.tablet`
    font-size: ${fontSizes.xxxxl};
  `}
  ${textFunctions}
`

export const H2 = styled('h2')`
  font-size: ${fontSizes.xxl};
  ${mq.from.tablet`
    font-size: ${fontSizes.xxxl};
  `}
  ${textFunctions}
`

export const H3 = styled('h3')`
  font-size: ${fontSizes.xl};
  ${mq.from.tablet`
    font-size: ${fontSizes.xxl};
  `}
  ${textFunctions}
`

export const H4 = styled('h4')`
  font-size: ${fontSizes.l};
  ${mq.from.tablet`
    font-size: ${fontSizes.xl};
  `}
  ${textFunctions}
`

export const H5 = styled('h5')`
  font-size: ${fontSizes.m};
  ${mq.from.tablet`
    font-size: ${fontSizes.l};
  `}
  ${textFunctions}
`

export const H6 = styled('h6')`
  font-size: ${fontSizes.s};
  ${mq.from.tablet`
    font-size: ${fontSizes.m};
  `}
  ${textFunctions}
`

export const ErrorMessage = styled(Text)`
  color: ${theme.COLORS.ERROR_DEFAULT};
  font-size: ${fontSizes.s};
`

ErrorMessage.defaultProps = {
  as: 'p',
}

export const FakeLink = styled(Text)`
  cursor: pointer;
  color: ${theme.COLORS.BRAND_DEFAULT};

  &:hover {
    text-decoration: underline;
  }
`

export const LibLabel = styled('p')`
  color: ${theme.COLORS.TXT_MAIN};
  text-align: left;
  font-weight: 500;
  font-size: ${theme.fontSizes.s};
  line-height: ${theme.lineHeights.s};
  margin: 0;

  & > a {
    color: ${theme.COLORS.BRAND_DEFAULT};
  }
`
