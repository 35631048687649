import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { disableBodyScroll, enableBodyScroll } from 'utils/scroll'
import { Box, Flex } from './atoms/Layout'
import { Icon } from './Icon'

const BORDER_RADIUS = '16px'

const directions = (isOpen) => ({
  right: {
    transform: isOpen ? 'translateX(-100%)' : 'translateX(100%)',
    top: 0,
    left: '100%',
  },
  left: {
    transform: isOpen ? 'translateX(100%)' : 'translateX(-100%)',
    top: 0,
    right: '100%',
  },
  top: {
    transform: isOpen ? 'translateY(100%)' : 'translateY(-100%)',
    left: 0,
    bottom: '100%',
    borderBottomRightRadius: BORDER_RADIUS,
    borderBottomLeftRadius: BORDER_RADIUS,
  },
  bottom: {
    transform: isOpen ? 'translateY(-100%)' : 'translateY(100%)',
    left: 0,
    top: '100%',
    borderTopRightRadius: BORDER_RADIUS,
    borderTopLeftRadius: BORDER_RADIUS,
  },
})

const StyledCloseIcon = styled(Icon)`
  position: absolute;
  left: 0;
  top: 0;
`

const ActionHeader = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding: 16px 12px;
  border-bottom: 1px solid ${theme.COLORS.BG_DIVIDER};
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
`

const RightBox = styled(Box)`
  position: absolute;
  right: 0;
  top: 0;
`

const DrawerComp = ({
  children,
  isOpen = false,
  speed = 0.3,
  width = '100vw',
  height = 'fit-content',
  bgColor = 'white',
  direction = 'bottom',
  rightButton,
  zIndex = 49,
  className,
  title,
  onClose,
  style,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [ref, setRef] = useState()

  const _onClose = () => {
    setIsDrawerOpen(false)
    enableBodyScroll(ref)

    setTimeout(() => onClose && onClose(), speed * 1000)
  }

  useEffect(() => {
    setIsDrawerOpen(isOpen)

    if (ref) {
      if (isOpen)
        disableBodyScroll(ref, {
          allowTouchMove: (el) => ref?.contains(el),
        })
      else enableBodyScroll(ref)
    }
  }, [isOpen, ref])

  return (
    <>
      <Flex
        ref={(ref) => {
          setRef(ref)
        }}
        flexDirection="column"
        className={className}
        position="fixed"
        height={height}
        maxHeight={
          direction === 'right' || direction === 'left' ? height : '80vh'
        }
        width={{ mobile: '100vw', tablet: width }}
        maxWidth="100vw"
        backgroundColor={bgColor}
        zIndex={`${zIndex} !important`}
        boxShadow="0px -8px 24px rgba(0, 0, 0, 0.08)"
        style={{
          transition: `all ${speed}s ease`,
          ...directions(isDrawerOpen)[direction],
          ...style,
        }}
        data-testid="drawer"
      >
        {!!(title || onClose) && (
          <ActionHeader>
            <Flex
              alignItems="center"
              justifyContent="center"
              width="100%"
              position="relative"
            >
              {onClose && (
                <StyledCloseIcon
                  name="close"
                  data-cy={`close-${title}`}
                  fontSize="24px"
                  onClick={_onClose}
                />
              )}
              {title}

              {rightButton && <RightBox>{rightButton}</RightBox>}
            </Flex>
          </ActionHeader>
        )}
        <Flex flexDirection="column" flex="1" overflow="auto">
          {children}
        </Flex>
      </Flex>

      <Box
        position="fixed"
        height="100vh"
        width="100vw"
        onClick={_onClose}
        zIndex={`${zIndex - 1} !important`}
        style={{
          top: !isDrawerOpen ? '100%' : 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          opacity: isDrawerOpen ? 1 : 0,
          transition: `opacity ${speed}s ease`,
        }}
      />
    </>
  )
}

export const Drawer = ({ children, ...props }) =>
  createPortal(
    <DrawerComp {...props}>{children}</DrawerComp>,
    document.querySelector('#select-menu')
  )

Drawer.propTypes = {
  children: PropTypes.node,
  rightButton: PropTypes.node,
  isOpen: PropTypes.bool,
  speed: PropTypes.number,
  className: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  width: PropTypes.string,
  style: PropTypes.string,
  height: PropTypes.string,
  bgColor: PropTypes.string,
  zIndex: PropTypes.number,
  direction: PropTypes.string,
}

DrawerComp.propTypes = {
  children: PropTypes.node,
  rightButton: PropTypes.node,
  isOpen: PropTypes.bool,
  speed: PropTypes.number,
  className: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  width: PropTypes.string,
  style: PropTypes.string,
  height: PropTypes.string,
  bgColor: PropTypes.string,
  zIndex: PropTypes.number,
  direction: PropTypes.string,
}
