import { gql } from '@apollo/client'

export const TripFragments = {}

TripFragments.TRIP_CARD_FRAGMENT = gql`
  fragment TripCardFragment on Trip {
    id
    data {
      name
      heroUrl
      dateFrom
      dateTo
      createdAt
    }
    tripVenues {
      id
    }
  }
`

TripFragments.TRIP_LIST_DATA_FRAGMENT = gql`
  fragment TripListDataFragment on TripListData {
    id
    data {
      name
      dateFrom
      dateTo
      membersCount
      venuesCount
      thumbnail
      token
    }
  }
`

TripFragments.TEAM_MEMBER_TRANSPORT = gql`
  fragment TeamMemberTransportFragment on TeamMemberTransport {
    id
    arrivalType
    arrivalAirportCode
    arrivalAirportName
    arrivalAdditionalInfo
    arrivalPlace
    arrivalDatetime
    arrivalNote
    arrivalPrice
    departureType
    departureAirportCode
    departureAirportName
    departureAdditionalInfo
    departurePlace
    departureDatetime
    departureNote
    departurePrice
    requestSentAt
    editedAt
    createdAt
    editedBy
    attachments {
      id
      transportId
      url
      name
      type
      isArrival
    }
  }
`

TripFragments.TEAM_MEMBER = gql`
  fragment TeamMemberFragment on TeamMember {
    id
    tripTeamLocationId

    email
    name
    status
    token
    avatarUrl

    birthDate
    phoneNumber
    documentType
    documentId
    validUntil
    address

    editedAt
    createdAt
    editedBy
    requestSentAt

    preferences {
      food
      sleeping
      other
    }

    transport {
      id
      ...TeamMemberTransportFragment
    }
  }
  ${TripFragments.TEAM_MEMBER_TRANSPORT}
`

TripFragments.ITINERARY = gql`
  fragment ItineraryFragment on ItineraryEntry {
    id
    name
    placeName
    placeDescription
    address
    lat
    lon
    dateStart
    dateEnd
    order
    isAllDay
    photoUrl
    note
    publicNote
    source
    sourceId
    eventCategory
    editedAt
    createdAt
    editedBy
    attachments {
      id
      entryId
      url
      name
      type
    }

    isDone
    deadline
    assignee
  }
`

TripFragments.TODO_FRAGMENT = gql`
  fragment TodoFragment on Todo {
    id
    title
    description
    isDone
    order
    deadline

    assigneeData {
      email
      avatar
    }

    itineraryEntry {
      id
    }

    editedAt
    createdAt
    editedBy
  }

  ${TripFragments.ITINERARY}
`

TripFragments.TEAM_LOCATION = gql`
  fragment TeamLocationFragment on TeamLocation {
    id
    tripId
    originLocationId
    cityId
    originLocationCode
    name
    country
    countryCode
    lat
    lon
    members {
      id
      token
      ...TeamMemberFragment
    }
  }
  ${TripFragments.TEAM_MEMBER}
`

TripFragments.TRIP_FRAGMENT = gql`
  fragment TripVenueFragment on Venue {
    id
    data {
      thumbnailUrl
      thumbnailUrls(limit: 100)
      roomPhotos
      destinationId
      title
      countryCode
      country
      city
      type
      rating
      meetingSpaceSize
      price {
        amount
        currencyIsoCode
      }
      coordinates {
        lat
        lon
      }
      venueDestination {
        id
        title
        airports {
          name
          distanceInKilometers
          code
          coordinates {
            lat
            lon
          }
        }
      }
      houseRules {
        checkIn
        checkOut
        cancellationDescription
        roomTypeAssignmentDescription
      }
      address
      amenities {
        amenityGroups {
          amenities
          name
          id
        }
        featured {
          name
          photoUrl
        }
      }
      starRating
      description
      numberOfRooms
      isMeetingRoomIncluded
      isEntireHouse
      isSustainable
      location {
        distanceToCoast
        download
        upload
        sightseeing
        restaurants
        nightlife
      }
      hotelDetails {
        importantInformation
      }
      capacity
      pointsOfInterests(limit: 100) {
        id
        name
        lat
        lon
        distance
        categoryId
        categoryName
      }
      carbonEmissions {
        room
      }
      staticData {
        meetingRooms {
          id
          roomSquareFootage
          roomSquareMeters
        }
      }
    }
  }
`

TripFragments.ACTIVITY_FRAGMENT = gql`
  fragment ActivityFragment on Activity {
    id
    name
    description
    lat
    lon
    address
    rating
    pricePerPerson {
      amount
      currencyIsoCode
    }
    price {
      amount
      currencyIsoCode
    }
    pax
    photoUrl
    link
    bookingLink
    source

    editedAt
    createdAt
    editedBy

    photos {
      id
      url
    }
  }
`

TripFragments.VOTING_FRAGMENT = gql`
  fragment VotingFragment on Voting {
    id
    name
    isActive
    type
    allowPendingVoters

    editedAt
    createdAt
    editedBy

    options {
      id
      venueId
      activityId
      title
      description
      photoUrl
      votes {
        id
        memberId
      }
    }
  }
`

TripFragments.EXPENSE_FRAGMENT = gql`
  fragment ExpenseFragment on TripExpense {
    id
    name
    description
    date
    url
    amount
    currency
    type
    tripId
    createdAt
    editedAt
    editedBy
    attachments {
      id
      tripExpenseId
      url
    }
  }
`

TripFragments.ORIGIN_LOCATION_FRAGMENT = gql`
  fragment OriginLocationFragment on AttendanceInfo {
    token
    location {
      id
      originLocationId
      name
      country
      cityId
      countryCode
      lat
      lon
    }
  }
`

TripFragments.TRIP_QUESTION_FRAGMENT = gql`
  fragment TripQuestionFragment on TripQuestion {
    id
    question
    answer
    isPublic
    memberId
  }
`

TripFragments.REVIEW_GROUP_FRAGMENT = gql`
  fragment ReviewGroupFragment on TripReviewGroup {
    id
    tripId
    name
    description
    reviews {
      id
      value
      groupId
      member {
        id
        name
        email
        avatarUrl
      }

      createdAt
      editedAt
      editedBy
    }
  }
`

TripFragments.TRANSFER_GROUP_MEMBER_FRAGMENT = gql`
  fragment TransferGroupMemberFragment on TeamMember {
    id
    name
    avatarUrl
    transport {
      arrivalDatetime
      departureDatetime
    }
  }
`

TripFragments.TRANSFER_GROUP_FRAGMENT = gql`
  fragment TransferGroupFragment on TransferGroup {
    id
    note
    name
    isArrival
    createdAt
    editedAt
    editedBy
    members {
      id
      ...TransferGroupMemberFragment
    }
  }
  ${TripFragments.TRANSFER_GROUP_MEMBER_FRAGMENT}
`

TripFragments.SLEEPING_LAYOUT_GROUP_FRAGMENT = gql`
  fragment SleepingLayoutGroupFragment on SleepingLayoutGroup {
    id
    note
    name
    createdAt
    editedAt
    editedBy
    members {
      id
      name
      avatarUrl
    }
  }
`

TripFragments.TRIP_REQUIREMENTS_FRAGMENT = gql`
  fragment TripRequirementsFragment on TripRequirements {
    team {
      teamSize
    }
    nights {
      nightCount
      nightNotes
    }
    date {
      exactDate
      period
      startDate
      endDate
    }
    destination {
      destination
      fromList
      specific
      helpNeeded
      departingPoints
      transport
      idealDestinationFixProperties
      idealDestinationOtherProperties
    }
    accommodation {
      accommodationType
    }
    workingSpace {
      workingSpace
      informalSpace
      workspaceUnnecessary
      workspaceInfo
      breakoutRoomInfo
    }
    budget {
      minimumBudget
      maximumBudget
      budgetIncludes
      budgetAdditionalContent
    }
    otherInfo {
      otherInfo
    }
  }
`

TripFragments.ATTENDANCE_PAGE_SETTINGS_FRAGMENT = gql`
  fragment AttendancePageSettingsFragment on AttendancePageSettings {
    id
    venue
    travel
    itinerary
    preferencesFood
    preferencesSleeping
    preferencesOther
    document
    birthDate
    phoneNumber
    address
    questions
    sleepingLayout
    transfer
  }
`

TripFragments.TRIP_CONTACTS_FRAGMENT = gql`
  fragment TripContactsFragment on TripContact {
    id
    name
    description
    address
    phoneNumber
    email
    postalCode
    city
    country
    editedAt
    createdAt
    editedBy
  }
`

TripFragments.TRIP_QUESTIONS_FRAGMENT = gql`
  fragment TripQuestionsFragment on TripQuestion {
    id
    answer
    question
    memberId
    isPublic
    createdAt
    editedAt
    editedBy
  }
`
