import React from 'react'
import PropTypes from 'prop-types'

const DISPLAY = {
  BLOCK: 'block',
  FLEX: 'flex',
  INLINE: 'inline',
  INLINE_BLOCK: 'inline-block',
  CONTENTS: 'contents',
}

function addEventListener(target, eventName, listener, options) {
  target.addEventListener(eventName, listener, options)

  let isSubscribed = true
  const unsubscribe = () => {
    if (!isSubscribed) {
      return
    }

    isSubscribed = false

    target.removeEventListener(eventName, listener, options)
  }
  return unsubscribe
}

const propTypes = {
  children: PropTypes.node.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  useCapture: PropTypes.bool,
  display: PropTypes.oneOf(Object.values(DISPLAY)),
}
const defaultProps = {
  disabled: false,

  // `useCapture` is set to true by default so that a `stopPropagation` in the
  // children will not prevent all outside click handlers from firing - maja
  useCapture: true,
  display: DISPLAY.BLOCK,
}

export default class OutsideClickHandler extends React.Component {
  constructor(...args) {
    super(...args)

    this.removeMouseDown = null
    this.removeMouseUp = null
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.setChildNodeRef = this.setChildNodeRef.bind(this)
  }

  componentDidMount() {
    const { disabled, useCapture } = this.props

    if (!disabled) this.addMouseDownEventListener(useCapture)
  }

  componentDidUpdate({ disabled: prevDisabled }) {
    const { disabled, useCapture } = this.props
    if (prevDisabled !== disabled) {
      if (disabled) {
        this.removeEventListeners()
      } else {
        this.addMouseDownEventListener(useCapture)
      }
    }
  }

  componentWillUnmount() {
    this.removeEventListeners()
  }

  // Use mousedown/mouseup to enforce that clicks remain outside the root's
  // descendant tree, even when dragged. This should also get triggered on
  // touch devices.
  onMouseDown(e) {
    const { useCapture } = this.props

    const isDescendantOfRoot =
      this.childNode && this.childNode.contains(e.target)
    if (!isDescendantOfRoot) {
      if (this.removeMouseUp) {
        this.removeMouseUp()
        this.removeMouseUp = null
      }
      this.removeMouseUp = addEventListener(
        document,
        'mouseup',
        this.onMouseUp,
        { capture: useCapture }
      )
    }
  }

  // Use mousedown/mouseup to enforce that clicks remain outside the root's
  // descendant tree, even when dragged. This should also get triggered on
  // touch devices.
  onMouseUp(e) {
    const { onOutsideClick } = this.props

    const isDescendantOfRoot =
      this.childNode && this.childNode.contains(e.target)
    if (this.removeMouseUp) {
      this.removeMouseUp()
      this.removeMouseUp = null
    }

    if (!isDescendantOfRoot) {
      onOutsideClick(e)
    }
  }

  setChildNodeRef(ref) {
    this.childNode = ref
  }

  addMouseDownEventListener(useCapture) {
    this.removeMouseDown = addEventListener(
      document,
      'mousedown',
      this.onMouseDown,
      { capture: useCapture }
    )
  }

  removeEventListeners() {
    if (this.removeMouseDown) this.removeMouseDown()
    if (this.removeMouseUp) this.removeMouseUp()
  }

  render() {
    const { children, display } = this.props

    return (
      <div
        ref={this.setChildNodeRef}
        style={
          display !== DISPLAY.BLOCK && Object.values(DISPLAY).includes(display)
            ? { display }
            : undefined
        }
      >
        {children}
      </div>
    )
  }
}

OutsideClickHandler.propTypes = propTypes
OutsideClickHandler.defaultProps = defaultProps
