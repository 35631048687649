import React from 'react'
import styled from '@emotion/styled'
import Button, { BUTTON_HEIGHT } from 'components/atoms/Button'
import { Icon } from 'components/Icon'

const StyledButton = styled(Button.Tertiary)`
  min-width: 0;
  width: ${BUTTON_HEIGHT};
  padding: 0;

  border: none;
`

const TouchMenuButton = React.forwardRef((props, ref) => (
  <StyledButton {...props} ref={ref}>
    <Icon fontSize="24px" name="menu" />
  </StyledButton>
))

export default TouchMenuButton
