import { useMutation } from '@apollo/client'
import { useSubscriptionAccess } from 'routes/Trip/AccessComponents'

/**
 * Mutation hook that handles onError of mutation and shows modal if necessary
 * @param mutation mutation string
 * @param options options of mutation
 * @returns mutation
 */
export const useTripMutation = (mutation, options) => {
  const { openModal } = useSubscriptionAccess()

  return useMutation(mutation, {
    ...options,
    onError: (errors) => {
      options?.onError?.(errors)

      if (errors.graphQLErrors) {
        errors.graphQLErrors.forEach((err) => {
          if (err.extensions?.code === 'SUBSCRIPTION EXPIRED') {
            openModal()
          }
        })
      }
    },
  })
}
