import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { ButtonSwitcher } from '@nextretreat/ui-components/dist/ButtonSwitcher'
import theme from '@nextretreat/ui-components/dist/Theme'
import { MODAL_TYPES, useModalManagement } from 'ModalManagementProvider'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TripMutations } from 'api/Trip/TripMutations'
import { TripQueries } from 'api/Trip/TripQueries'
import { ReactComponent as PlusIcon } from 'assets/images/svg/add.svg'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Device from 'components/Device'
import { LoadingWrapper } from 'components/LoadingWrapper'
import { TRIP_TAB_ACCESS_NAMES } from 'constants/constants'
import { useTripMutation } from 'hooks/trip/useTripMutation'
import { EnsureWriteAccess } from 'routes/Trip/AccessComponents'
import { convertLocalToUTCDate, convertUTCToLocalDate } from 'utils/date'
import { calculateOrder } from 'utils/helpers'
import { TodoItem } from './TodoItem'
import { updateChangeTodoCacheFunction } from '../Itinerary/AddTodoWithEntry'
import { TabHeader } from '../Partials/TabHeader'

export const TODO_STEP = 512

export const TodoList = ({ tripId }) => {
  const { openModal } = useModalManagement()
  const [todosState, setTodosState] = useState([])
  const matches = useMediaQuery()
  const [showItineraryTasks, setShowItineraryTasks] = useState(false)

  const { data, loading, error } = useQuery(TripQueries.GET_TRIP_TODOS, {
    variables: { tripId },
  })
  const [updateTodo, { loading: updateLoading }] = useTripMutation(
    TripMutations.SAVE_TODO,
    {
      update: (cache, { data: newData }) => {
        if (newData.saveTodo) {
          const query = {
            query: TripQueries.GET_TRIP_TODOS,
            variables: { tripId },
          }

          const data = cache.readQuery(query)
          if (data)
            cache.writeQuery({
              ...query,
              data: {
                ...data,
                trip: {
                  ...data.trip,
                  todos: data.trip.todos
                    .slice()
                    .sort((a, b) => a.order - b.order),
                },
              },
            })

          updateChangeTodoCacheFunction(
            cache,
            newData.saveTodo.itineraryEntry?.id,
            tripId,
            newData.saveTodo
          )
        }
      },
    }
  )

  const [reorderTodos] = useTripMutation(TripMutations.REORDER_TODOS, {
    refetchQueries: TripQueries.GET_TRIP_TODOS,
  })

  useEffect(() => {
    const { todos } = data?.trip ?? {}

    const sortedTodos = todos?.slice().sort((a, b) => a.order - b.order)
    setTodosState(sortedTodos)
  }, [data])

  const itineraryTasks =
    todosState?.filter((todo) => todo?.itineraryEntry?.id) || []

  const hasBothGroups =
    !!itineraryTasks.length && todosState?.length > itineraryTasks.length

  const todosToDisplay =
    showItineraryTasks && hasBothGroups ? itineraryTasks : todosState

  const activeTodos = todosToDisplay?.filter((todo) => !todo?.isDone)
  const finishedTodos = todosToDisplay?.filter((todo) => todo?.isDone)

  const moveItem = useCallback(
    async (dragOrder, dropOrder, isHover, dropIsDone) => {
      if (isHover) {
        const moveTargetIndex = todosState?.findIndex(
          ({ order }) => order === dropOrder
        )
        const moveOriginIndex = todosState?.findIndex(
          ({ order }) => order === dragOrder
        )

        if (moveTargetIndex !== -1 && moveOriginIndex !== -1) {
          setTodosState((prevTodos) => {
            const copiedPrevTodos = prevTodos.map((prev) => ({
              ...prev,
              isHidden: false,
            }))
            copiedPrevTodos.splice(moveOriginIndex, 1)

            const moveOriginItem = { ...prevTodos[moveOriginIndex] }
            moveOriginItem.isHidden = true
            moveOriginItem.isDone = dropIsDone

            copiedPrevTodos.splice(moveTargetIndex, 0, moveOriginItem)

            return copiedPrevTodos
          })
        }
      } else if (dropOrder !== undefined) {
        const moveTargetIndex = todosState?.findIndex(
          ({ order }) => order === dropOrder
        )

        const moveOriginIndex = data?.trip?.todos?.findIndex(
          ({ order }) => order === dropOrder
        )

        const moveOriginItem = data?.trip?.todos[moveOriginIndex]

        let finalOrder = calculateOrder(
          data?.trip?.todos,
          moveTargetIndex > moveOriginIndex
            ? moveTargetIndex + 1
            : moveTargetIndex,
          TODO_STEP
        )

        if (finalOrder % 1 !== 0) {
          const response = await reorderTodos({ variables: { tripId } })

          finalOrder = calculateOrder(
            response.data.reorderTodos,
            moveTargetIndex > moveOriginIndex
              ? moveTargetIndex + 1
              : moveTargetIndex,
            TODO_STEP
          )
        }

        updateTodo({
          variables: {
            input: {
              isDone: dropIsDone,
              assignee: moveOriginItem.assigneeData?.email || undefined,
              deadline: moveOriginItem.deadline
                ? convertUTCToLocalDate(
                    convertLocalToUTCDate(+moveOriginItem.deadline)
                  )
                : undefined,
              title: moveOriginItem.title,
              id: moveOriginItem.id,
              description: moveOriginItem.description || '',
              order: finalOrder,
            },
            tripId,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            saveTodo: {
              ...moveOriginItem,
              isDone: dropIsDone,
              order: finalOrder,
              __typename: 'Todo',
            },
          },
        })
      }
    },
    [todosState, data, setTodosState]
  )

  const moveTodoOnDropOutside = useCallback(() => {
    setTodosState(data?.trip?.todos)
  }, [data?.trip?.todos, setTodosState])

  const renderTodo = useCallback(
    (todo, index) => (
      <TodoItem
        key={todo?.id}
        tripId={tripId}
        {...todo}
        todos={todosState}
        updateLoading={updateLoading}
        moveItem={moveItem}
        moveTodoOnDropOutside={moveTodoOnDropOutside}
        dataCy={`card-todo-item-${index}`}
      />
    ),
    [todosState, moveItem, moveTodoOnDropOutside, updateLoading]
  )

  const firstItemOrder = todosState?.[0]?.order

  return (
    <>
      <TabHeader
        subtitle="Create & manage a list of things to do"
        buttons={
          <EnsureWriteAccess name={TRIP_TAB_ACCESS_NAMES.TODOS}>
            <Button.Primary
              iconLeft={<PlusIcon />}
              onClick={() =>
                openModal({
                  type: MODAL_TYPES.TODO_MODAL,
                  tripId,
                  firstItemOrder: Number.isNaN(+firstItemOrder)
                    ? TODO_STEP
                    : firstItemOrder,
                })
              }
              data-cy="btn-add-new"
            >
              {!matches.mobile ? 'Add new task' : 'Add'}
            </Button.Primary>
          </EnsureWriteAccess>
        }
      >
        Todos
      </TabHeader>

      <LoadingWrapper loading={loading || !(todosState || error)}>
        <DndProvider backend={HTML5Backend}>
          <Box
            flex="1"
            background={theme.COLORS.BG_SOFT}
            mb={{ desktop: 'l' }}
            p={{ mobile: rem(16), desktop: 0 }}
            m={{ desktop: rem(24) }}
          >
            <Flex
              mb={rem(12)}
              alignItems="center"
              justifyContent="space-between"
            >
              <Text as="h2" fontSize={theme.fontSizes.xl}>
                There are{' '}
                <Text color={theme.COLORS.BRAND_DEFAULT}>
                  {activeTodos?.length || 0} active task
                  {activeTodos?.length === 1 ? '' : 's'}
                </Text>
              </Text>

              {hasBothGroups && (
                <Device sizes={['tablet', 'desktop', 'tv']}>
                  <ButtonSwitcher
                    selectedValue={showItineraryTasks}
                    onSelect={(val) => setShowItineraryTasks(val)}
                    options={[
                      {
                        label: `All tasks (${todosState?.length || 0})`,
                        value: false,
                      },
                      {
                        label: `Itinerary tasks (${itineraryTasks.length})`,
                        value: true,
                      },
                    ]}
                  />
                </Device>
              )}
            </Flex>

            {activeTodos?.map((todo, i) => renderTodo(todo, i))}

            {!!finishedTodos?.length && (
              <Text
                as="h2"
                mt={rem(24)}
                fontSize={theme.fontSizes.xl}
                mb={rem(12)}
              >
                Finished tasks ({finishedTodos.length})
              </Text>
            )}

            {finishedTodos?.map((todo) => renderTodo(todo))}
          </Box>
        </DndProvider>
      </LoadingWrapper>
    </>
  )
}

TodoList.propTypes = {
  tripId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
