import React from 'react'
import { useAuth } from 'AuthProvider'
// import Cookies from 'universal-cookie'

export const GTM_EVENTS = {
  successLogin: 'ce_login',
  successRegistration: 'ce_registration',
}

const EVENT_DATA_MAPPINGS = {}

const EVENT_ADDITIONAL_PARAMS = {}

// const cookies = new Cookies()

const transferArgs = (args) => {
  if (EVENT_DATA_MAPPINGS[args.event]) {
    return { event: args.event, ...EVENT_DATA_MAPPINGS[args.event](args.data) }
  }
  return args
}

export const useGoogleTracking = () => {
  const { user } = useAuth()

  const getDefaultParameters = () => ({
    // _gid: cookies.get('_gid'),
  })

  const logGTMEvent = React.useCallback(
    (args) => {
      if (process.env.REACT_APP_ENV === 'development') return

      const eventToLog = {
        uid: user?.id,
        ...getDefaultParameters(),
        ...transferArgs(args),
        ...(EVENT_ADDITIONAL_PARAMS[args.event] || {}),
      }
      window.dataLayer.push(eventToLog)
    },
    [user]
  )

  return { logGTMEvent }
}
