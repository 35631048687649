import React, { useCallback, useEffect } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { AuthQueries } from 'api/Auth/AuthQueries'
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
} from 'constants/constants'
import { PUBLIC_ROUTES } from 'constants/routes'
import { authStorage } from './utils/storage'

export const AuthContext = React.createContext({
  user: null,
  userTrips: [],
  data: [],
  isSignedIn: false,
  signOut: () => {},
  isFirstTripModalOpened: false,
  setIsFirstTripModalOpened: () => {},
  refetchData: async () => {},
  isSigningIn: false,
})

export const useAuth = () => React.useContext(AuthContext)

const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const client = useApolloClient()

  const [isFirstTripModalOpened, setIsFirstTripModalOpened] =
    React.useState(false)
  const [accessToken, setAccessToken] = React.useState(
    authStorage.get(LOCAL_STORAGE_ACCESS_TOKEN_KEY)
  )

  useEffect(() => {
    const storageAccessToken = authStorage.get(LOCAL_STORAGE_ACCESS_TOKEN_KEY)
    if (!accessToken && storageAccessToken) setAccessToken(storageAccessToken)

    if (!storageAccessToken && accessToken) {
      setAccessToken(undefined)
    }
  }, [authStorage.get(LOCAL_STORAGE_ACCESS_TOKEN_KEY)])

  const {
    data: loginData,
    loading: loginLoading,
    refetch: refetchLogin,
  } = useQuery(AuthQueries.AUTH_INFO, {
    onCompleted: (response) => {
      if (response?.userMe) {
        if (window.drift) {
          window.drift.identify(response?.userMe?.id, {
            email: response?.userMe?.email,
          })
        }
      }
    },
  })

  const { data, refetch, loading } = useQuery(AuthQueries.GET_USER_ME, {
    skip: !accessToken,
    fetchPolicy: 'no-cache',
  })

  const refetchQueries = useCallback(
    () => Promise.all([refetch(), refetchLogin()]),
    [refetch, refetchLogin, accessToken]
  )

  const signOut = React.useCallback(() => {
    authStorage.remove(LOCAL_STORAGE_ACCESS_TOKEN_KEY)
    authStorage.remove(LOCAL_STORAGE_REFRESH_TOKEN_KEY)
    setAccessToken(null)
    navigate(PUBLIC_ROUTES.LOGIN, { replace: true })
    client.clearStore()
  }, [navigate])

  const contextValue = React.useMemo(
    () => ({
      user: loginData?.userMe,
      userTrips: data?.userMe?.trips || [],
      isSignedIn:
        !!authStorage.get(LOCAL_STORAGE_ACCESS_TOKEN_KEY) &&
        !!loginData?.userMe,
      signOut,
      data: data || [],
      isFirstTripModalOpened,
      setIsFirstTripModalOpened,
      refetchData: refetchQueries,
      isSigningIn: loginLoading,
      areTripsLoading: loading,
    }),
    [
      authStorage.get(LOCAL_STORAGE_ACCESS_TOKEN_KEY),
      data,
      signOut,
      isFirstTripModalOpened,
      setIsFirstTripModalOpened,
      refetchQueries,
      loading,
      loginLoading,
      loginData,
    ]
  )

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthProvider
