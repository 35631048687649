import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { shouldForwardPropOptions } from 'utils/helpers'

export const SegmentationTitle = ({ text, ...passingProps }) => (
  <Text
    mt={{ mobile: '32px', tablet: rem(48) }}
    mb="12px"
    px={rem(16)}
    textAlign="center"
    fontSize={{ mobile: '24px', tablet: theme.fontSizes.xxl }}
    as="h1"
    {...passingProps}
  >
    {text}
  </Text>
)

SegmentationTitle.propTypes = {
  text: PropTypes.string.isRequired,
}

export const SegmentationDescription = ({ text, ...passingProps }) => (
  <Text
    mb={{ mobile: rem(24), tablet: rem(32) }}
    textAlign="center"
    as="p"
    color={theme.COLORS.TXT_DEFAULT}
    {...passingProps}
  >
    {text}
  </Text>
)

SegmentationDescription.propTypes = {
  text: PropTypes.string.isRequired,
}

export const SegmentationItem = styled(
  Flex,
  shouldForwardPropOptions
)(
  ({ $isSelected }) => `
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: ${theme.COLORS.WHITE};
  border-radius: 16px;
  padding: ${rem(24)};
  cursor: pointer;
  flex: 1;
  font-size: ${theme.fontSizes.l};
  line-height: ${theme.lineHeights.l};

  ${
    $isSelected
      ? `
      outline: 2px solid ${theme.COLORS.BRAND_DEFAULT};
      background-color: ${theme.COLORS.BRAND_LIGHT};
    `
      : `&:hover {outline: 2px solid ${theme.COLORS.BRAND_LIGHT};}`
  }
`
)
