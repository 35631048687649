import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import fallbackImg from 'assets/images/ImageFallbackNR.jpeg'
import { addBlurToImage } from 'utils/helpers'

const VenueImageBlurContainer = styled('div')`
  ${({ pictureUrl }) =>
    `background: url("${pictureUrl}") center / cover no-repeat, url("${fallbackImg}") center / cover no-repeat;`}

  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0;
    animation: pulse 1.5s infinite;
    background-color: white;
    filter: blur(20px);
  }

  @keyframes pulse {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }

  &.loaded::before {
    animation: none;
    content: none;
  }

  & img {
    opacity: 0;
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: opacity 250ms ease-in-out;
  }

  &.loaded img {
    opacity: 1;
  }
`

export const FallbackBlurImage = ({ pictureUrl, ...passingProps }) => {
  const [loaded, setIsLoaded] = React.useState(false)

  return (
    <VenueImageBlurContainer
      pictureUrl={addBlurToImage(pictureUrl)}
      className={loaded ? 'loaded' : ''}
      {...passingProps}
    >
      <img
        src={pictureUrl}
        alt="venue"
        loading="lazy"
        onLoad={() => setIsLoaded(true)}
      />
    </VenueImageBlurContainer>
  )
}

FallbackBlurImage.propTypes = {
  pictureUrl: PropTypes.string.isRequired,
}

export const FallbackImg = ({ alt = 'fallback image', ...props }) => (
  <img
    {...props}
    alt={alt}
    onError={(e) => {
      e.target.onerror = null
      e.target.src = fallbackImg
    }}
  />
)

FallbackImg.propTypes = {
  alt: PropTypes.string,
}
