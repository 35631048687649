import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import { space } from 'styled-system'
import { BOXSHADOWS, COLORS, fontSizes, radius } from 'Theme'
import { Text } from 'components/atoms/Typography'

const inputFuncs = [space]
const borderOnHover = `0.5px solid ${theme.COLORS.BRAND_DEFAULT}`
const borderIsInvalid = `0.5px solid ${theme.COLORS.ERROR_DEFAULT}`
export const INPUT_HEIGHT = rem('40px')

export const InputBase = styled('input')`
  display: block;
  height: ${INPUT_HEIGHT};
  width: 100%;
  font-size: ${fontSizes.m};
  font-size: 16px; // iOS zoom fix
  color: ${theme.COLORS.TXT_MAIN};
  box-shadow: ${BOXSHADOWS.INNER};
  border: 0.5px solid ${COLORS.IRRADIANT_IRIS};
  word-break: break-all;
  appearance: none;
  :disabled {
    background-color: ${COLORS.LYNX_WHITE};
  }
`

export const Input = styled(InputBase)`
  padding-left: ${space.m};
  border-radius: ${radius.m};
  @media (hover: hover) {
    &:hover {
      border: ${({ disabled }) => !disabled && borderOnHover};
    }
  }
  :focus {
    border: ${borderOnHover};
    background-color: ${COLORS.LYNX_WHITE};
  }
  ::placeholder {
    color: ${COLORS.SILK_SOX};
  }
  ${({ isInvalid }) =>
    isInvalid &&
    `
    border: ${borderIsInvalid};
    background-color: ${theme.COLORS.ERROR_LIGHT};
    :focus {
      border: ${borderIsInvalid};
      background-color: ${theme.COLORS.ERROR_LIGHT};
    }
    @media (hover: hover) {
      &:hover {
        border: ${borderIsInvalid};
      }
    }
  `}
  ${inputFuncs}
`

export const Label = styled(Text)`
  color: ${theme.COLORS.TXT_MAIN};
  text-align: left;
  font-size: ${fontSizes.m};
  margin-bottom: ${rem(10)};
  font-weight: 600;
`

Label.defaultProps = {
  as: 'p',
}
