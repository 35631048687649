import React, { memo } from 'react'
import { useLazyQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Input } from '@nextretreat/ui-components/dist/Input'
import theme from '@nextretreat/ui-components/dist/Theme'
import { Field, Form, Formik } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import Geocode from 'react-geocode'
import * as Yup from 'yup'
import { TripQueries } from 'api/Trip/TripQueries'
import fallbackImg from 'assets/images/ImageFallbackNR.jpeg'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'
import { Dropzone } from 'components/Input/Dropzone'
import { LoaderGif } from 'components/Loader'
import { FooterBox } from 'components/Modal'
import { useAddTripVenue } from 'hooks/trip/useAddTripVenue'
import { STEP_NAMES } from 'routes/Plan/constants'
import { useSteps } from 'routes/Plan/useSteps'
import { HighlightedVenueBox } from 'sharedComponents/HighlightedVenueBox'
import { toast } from 'utils/helpers'
import { AddressInput } from './AddressInput'

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY)

const customVenueModalValidationSchema = Yup.object().shape({
  hotelName: Yup.string().required(),
  lat: Yup.number().nullable().required(),
  lon: Yup.number().nullable().required(),
  hotelAddress: Yup.string().required(),
  thumbnail: Yup.mixed().required(),
  city: Yup.string().required(),
  country: Yup.string().required(),
  countryCode: Yup.string().required(),
})

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${rem(8)};
  border-radius: 50%;
  background-color: ${theme.COLORS.BG_SOFT}50;
  padding: ${rem(4)};
`

const HeroImage = styled.div`
  height: 80px;
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
  position: relative;

  background:
    ${({ $url }) => `url("${$url}")`} center/cover no-repeat,
    url('${fallbackImg}') center / contain no-repeat,
    #eee;
`

const ThumbnailImage = memo(({ file }) => (
  <HeroImage $url={URL.createObjectURL(file)} />
))

ThumbnailImage.propTypes = {
  file: PropTypes.object,
}

export const CustomVenueForm = ({
  venueIds,
  tripId,
  onSuccess,
  onSubmit,
  closeModal,
  endContent,
}) => {
  const { getNextUrl } = useSteps()

  const [searchVenues, { data, loading }] = useLazyQuery(
    TripQueries.SEARCH_VENUES
  )

  const [addTripVenue, { loading: addTripVenueLoading }] = useAddTripVenue()

  const onNameBlur = (search) => {
    if (search)
      searchVenues({
        variables: {
          search,
        },
      })
  }

  const searchedVenue = venueIds.includes(data?.searchVenues?.[0]?.id)
    ? null
    : data?.searchVenues?.[0]

  const onAddSearchedVenue = async () => {
    const response = await addTripVenue({
      variables: {
        input: {
          venueId: searchedVenue.id,
          destinationId: searchedVenue.destination,
        },
        tripId,
      },
    })

    if (response.data.addTripVenue) {
      onSuccess?.(response.data.addTripVenue.tripVenueId)
      closeModal?.()
    }
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={customVenueModalValidationSchema}
      initialValues={customVenueModalValidationSchema.cast(
        {
          hotelName: '',
          hotelAddress: '',
          city: '',
          thumbnail: '',
          country: '',
          countryCode: '',
        },
        { assert: false }
      )}
      onSubmit={onSubmit}
    >
      {({ values, errors, isSubmitting, setFieldValue }) => (
        <StyledForm>
          <Box flex="1" px={rem(24)} py={rem(20)}>
            <Box flex="1" mb={rem(16)}>
              <Field name="hotelName">
                {({ field, meta }) => (
                  <Input
                    isBlock
                    required
                    label="Name"
                    placeholder="Venue name..."
                    {...field}
                    onBlur={(e) => {
                      field.onBlur(e)
                      onNameBlur(field.value)
                    }}
                    invalid={meta.touched && meta.error !== undefined}
                    iconRight={loading ? <LoaderGif /> : undefined}
                  />
                )}
              </Field>
            </Box>
            {!!searchedVenue && (
              <Box flex="1" mb={rem(16)}>
                <Text
                  fontSize={theme.fontSizes.m}
                  fontWeight={700}
                  lineHeight={theme.lineHeights.m}
                >
                  Is this your venue?
                </Text>

                <HighlightedVenueBox
                  mt={rem(8)}
                  title={searchedVenue.name}
                  address={searchedVenue.hotelAddress}
                  thumbnailUrl={searchedVenue.thumbnail}
                  {...searchedVenue}
                >
                  <Flex
                    mt={rem(16)}
                    ml={{ tablet: `calc(${rem(80)})` }}
                    $gap={rem(12)}
                    flexDirection={{ mobile: 'column', tablet: 'row' }}
                  >
                    <Button.Primary
                      isBlock
                      type="button"
                      onClick={onAddSearchedVenue}
                      isLoading={addTripVenueLoading}
                    >
                      Yes, add it to the trip
                    </Button.Primary>
                    <Button.Tertiary
                      isBlock
                      element="a"
                      href={getNextUrl({
                        venue: {
                          id: searchedVenue.id,
                          source: 'venue-search',
                        },
                        step: STEP_NAMES.detail,
                      })}
                      target="_blank"
                    >
                      Venue detail
                    </Button.Tertiary>
                  </Flex>
                </HighlightedVenueBox>
              </Box>
            )}

            <AddressInput />

            {values.thumbnail ? (
              <Box position="relative">
                <ThumbnailImage file={values.thumbnail} />
                <StyledIcon
                  onClick={() => setFieldValue('thumbnail', null)}
                  name="close"
                />
              </Box>
            ) : (
              <Dropzone
                title="Click to upload a venue image"
                onDrop={async ([{ file }]) => {
                  if (file) {
                    setFieldValue('thumbnail', file)
                  }
                }}
              />
            )}
          </Box>

          {endContent}

          {closeModal && (
            <FooterBox>
              <Button.Tertiary
                disabled={isSubmitting}
                type="button"
                onClick={closeModal}
              >
                Cancel
              </Button.Tertiary>

              <Button.Primary
                isLoading={isSubmitting}
                disabled={!!(errors.name || errors.thumbnail)}
                type="submit"
                onClick={() => {
                  if (
                    errors.city ||
                    errors.country ||
                    errors.countryCode ||
                    errors.lat ||
                    errors.lon
                  ) {
                    toast.error(
                      'Something is wrong with your address. Please state correct address.'
                    )
                  }
                }}
              >
                Save
              </Button.Primary>
            </FooterBox>
          )}
        </StyledForm>
      )}
    </Formik>
  )
}

CustomVenueForm.propTypes = {
  venueIds: PropTypes.array,
  tripId: PropTypes.number,
  onSuccess: PropTypes.func,
  onSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  endContent: PropTypes.node,
}
