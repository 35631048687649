import React from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import { math, rem } from 'polished'
import PropTypes from 'prop-types'
import { COLORS, fontSizes, mq, radius, space } from 'Theme'
import { Box } from 'components/atoms/Layout'
import { Label } from 'components/atoms/Typography'
import { TOPBAR_HEIGHT } from 'constants/constants'
import { shouldForwardPropOptions } from 'utils/helpers'

export const NUMBER_SIZE = rem('32px')
export const OFFSET = space.m

const StyledLabel = styled(Label)`
  padding-bottom: ${space.s};

  line-height: 1.5;

  ${mq.from.tv`
    left: ${OFFSET};
  `}
  display: block;
`

const Content = styled('div')`
  align-self: center;
  width: 100%;
`

const Item = styled('div', shouldForwardPropOptions)`
  counter-increment: steps;

  position: relative;
  display: flex;
  align-items: flex-start;

  padding-bottom: ${math(`${space.m} + ${space.s}`)};
  min-height: ${NUMBER_SIZE};

  ${({ $isSticky, $minusTopOffset }) =>
    $isSticky &&
    `
      z-index: 2;
      position: sticky;
      top: ${
        $minusTopOffset
          ? math(`${TOPBAR_HEIGHT} - ${rem($minusTopOffset || 0)}`)
          : TOPBAR_HEIGHT
      };
    `}

  font-size: ${fontSizes.m};

  &::before,
  &::after {
    top: 25px;
    ${({ $alwaysShowStep }) =>
      $alwaysShowStep
        ? 'top: 0;'
        : mq.to.tv`
      content: initial !important;
    `}
  }

  &::before {
    content: counter(steps) '.';

    z-index: 1;
    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: none;

    margin-left: -${math(`${NUMBER_SIZE} + 1rem`)};
    margin-right: ${OFFSET};
    padding-left: 0.15em;
    width: ${NUMBER_SIZE};
    height: ${NUMBER_SIZE};

    font-size: ${fontSizes.s};

    background-color: ${COLORS.LYNX_WHITE};
    color: ${COLORS.BLUEBERRY_SODA};
    box-shadow: 0 0 0 ${rem('5px')} ${theme.COLORS.WHITE};
    border-radius: ${radius.circle};
  }

  &::after {
    content: '';

    position: absolute;
    top: 25px;
    bottom: -25px;
    left: -${math(`${math(`${NUMBER_SIZE} / 2`)} + 1rem`)};
    display: block;

    border-left: 1px dashed ${COLORS.IRRADIANT_IRIS};
  }

  &:last-of-type {
    padding-bottom: 0;

    &::before {
      background-color: ${theme.COLORS.WHITE};
      color: ${theme.COLORS.BRAND_DEFAULT};
      border: 1.5px solid;
    }

    &::after {
      content: unset;
    }
  }

  ${({ $active }) =>
    $active !== undefined
      ? $active
        ? `&::before {
          background-color: ${theme.COLORS.WHITE};
          color: ${theme.COLORS.BRAND_DEFAULT};
          border: 1.5px solid;
        }
        
        color: ${theme.COLORS.BRAND_DEFAULT};`
        : `&::before {
          background-color: ${COLORS.LYNX_WHITE} !important;
          color: ${COLORS.BLUEBERRY_SODA} !important;
          border: 0 !important;
        }`
      : ''}

  ${({ $alwaysShowStep }) =>
    $alwaysShowStep &&
    mq.to.tablet`
    gap: ${rem(8)};
    flex-direction: column;

    &>div {
      padding-right: 10px;
      z-index: 20;
    }

    &::before {
      margin-left: 0;
      top: 0;
    }
      
      &::after {
        top: ${math(`${NUMBER_SIZE} / 2`)};
        left: 0;
        right: 0;
    
        border-top: 1px dashed ${COLORS.IRRADIANT_IRIS};
        border-left: 0;
      }
      `}
`

const Step = React.forwardRef(
  (
    {
      isSticky,
      alwaysShowStep,
      minusTopOffset = 0,
      children,
      className,
      label,
      active,
    },
    ref
  ) => (
    <Item
      $isSticky={isSticky}
      $active={active}
      $minusTopOffset={minusTopOffset}
      $alwaysShowStep={alwaysShowStep}
      className={className}
      ref={ref}
    >
      <Box width="100%">
        <StyledLabel>{label}</StyledLabel>
        <Content>{children}</Content>
      </Box>
    </Item>
  )
)

Step.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  active: PropTypes.bool,
  isSticky: PropTypes.bool,
  minusTopOffset: PropTypes.number,
  alwaysShowStep: PropTypes.bool,
}

export default Step
