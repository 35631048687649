import { memo, useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import { Datepicker } from '@nextretreat/ui-components/dist/Datepicker'
import { Input } from '@nextretreat/ui-components/dist/Input'
import { Select } from '@nextretreat/ui-components/dist/Select'
import theme from '@nextretreat/ui-components/dist/Theme'
import { addDays, addYears, format } from 'date-fns'
import { Field, FieldArray, useFormikContext } from 'formik'
import get from 'lodash.get'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import { mq } from 'Theme'
import { TripQueries } from 'api/Trip/TripQueries'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'
import { PriceInput } from 'components/PriceInput'
import MonthFilter from 'routes/Plan/components/Destinations/MonthFilter'
import { DropdownButton } from 'sharedComponents/FlightGrid/HeaderUI'

const ConciergeItemWrapper = styled(Flex)`
  flex-direction: column;
  background: ${theme.COLORS.WHITE};
  border-radius: 8px;
  padding: ${rem(16)};
  ${mq.from.desktop`padding: ${rem(20)} ${rem(24)};`}
`

const ConciergeItemIcon = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 40px;
  border: 1px solid ${theme.COLORS.BG_DIVIDER};
  flex-shrink: 0;
`

const QuestionHeading = styled(Text)``

QuestionHeading.defaultProps = {
  as: 'p',
  color: theme.COLORS.TXT_MAIN,
  fontSize: theme.fontSizes.l,
  fontWeight: '700',
}

const FlexWrap = styled(Flex)`
  flex: 1;
  & > * {
    flex: 1;
  }

  & textarea {
    height: 100%;
  }
`

const ConciergeItemQuestion = styled(Flex)`
  height: ${rem(48)};
  align-items: center;
`

const Chip = styled(Flex)`
  align-items: center;
  cursor: pointer;
  padding: ${rem(8)} ${rem(16)};
  border-radius: 100px;
  font-weight: 510;
  background-color: ${theme.COLORS.WHITE};
  border: 1px solid ${theme.COLORS.BG_DIVIDER};
  color: ${theme.COLORS.TXT_DEFAULT};

  ${({ $disabled }) =>
    $disabled
      ? `cursor: not-allowed;opacity: .6;`
      : `
  &:hover {
    background-color: ${theme.COLORS.BRAND_LIGHT};
  }
`}

  ${({ $isSelected }) =>
    $isSelected &&
    `
    background-color: ${theme.COLORS.BRAND_LIGHT};
    border-color: ${theme.COLORS.BRAND_DEFAULT};
    color: ${theme.COLORS.BRAND_DEFAULT};
  `}
`

const DestinationsSelect = ({ onChange, value, ...passingProps }) => {
  const [destinations, setDestinations] = useState([])

  useQuery(TripQueries.GET_DESTINATIONS_FOR_TRIP_REQUIREMENTS, {
    onCompleted: ({ availableDestinations }) => {
      setDestinations(
        availableDestinations.map(({ id, title, country }) => ({
          value: id,
          label: `${title}, ${country}`,
        }))
      )
    },
  })

  return (
    <Select
      name="destination_select"
      onChange={onChange}
      value={destinations.find((option) => option.value === value)}
      {...passingProps}
      options={destinations}
    />
  )
}

DestinationsSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

const CityInput = memo(({ index, disabled, onRemoveInput }) => {
  const matches = useMediaQuery()

  return (
    <Flex alignItems="center" $gap={rem(8)} key={`city_${index}`} mb={rem(8)}>
      <Field name={`destination.value.departingPoints.${index}`}>
        {({ field }) => (
          <Input
            isBlock={!matches.tv}
            placeholder={`City #${index + 1}`}
            disabled={disabled}
            {...field}
          />
        )}
      </Field>

      {!disabled && index !== 0 ? (
        <Icon
          name="close"
          fontSize="24px"
          onClick={onRemoveInput}
          style={{ flexShrink: 0 }}
        />
      ) : (
        <Box width="24px" style={{ flexShrink: 0 }} />
      )}
    </Flex>
  )
})

CityInput.propTypes = {
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onRemoveInput: PropTypes.func,
}

const DestinationIconImage = styled('img')`
  width: 20px;
  height: 20px;
  margin-right: ${rem(8)};
`

const DestinationsHelp = ({
  onChange,
  value,
  disabled,
  destinationOptions,
  transportOptions,
  ...passingProps
}) => {
  const includesDestination = value?.idealDestinationFixProperties || []
  const includesTransport = value?.transport || []
  const { values } = useFormikContext()

  return (
    <Box>
      <QuestionHeading mb={rem(8)}>
        Which cities will your team travel from?
      </QuestionHeading>

      <Text mb={rem(8)} color={theme.COLORS.TXT_DEFAULT} as="p">
        Please state all the departing points of your team members or where are
        they based.
      </Text>

      <FieldArray name="destination.value.departingPoints">
        {({ push, remove }) => (
          <>
            {values.destination?.value?.departingPoints?.map((city, index) => (
              <CityInput
                key={`city_${index}`}
                city={city}
                index={index}
                disabled={disabled}
                onRemoveInput={() => remove(index)}
              />
            ))}

            <Button.Primary
              type="button"
              onClick={() => {
                push('')
              }}
              viewType={EButtonType.PLAIN}
              iconLeft={<Icon name="add" />}
              disabled={disabled}
            >
              Add another city
            </Button.Primary>
          </>
        )}
      </FieldArray>

      <QuestionHeading mt={rem(16)} mb={rem(8)}>
        What’s your preferred way of transport to destination?
      </QuestionHeading>

      {transportOptions.map((option) => (
        <Input.Checkbox
          key={option.name}
          checked={includesTransport?.includes(option.name)}
          label={option.name}
          value={option.name}
          onChange={() =>
            onChange({
              ...value,
              transport: includesTransport?.includes(option.name)
                ? includesTransport.filter((val) => val !== option.name)
                : [...includesTransport, option.name],
            })
          }
          disabled={disabled}
        />
      ))}

      <QuestionHeading mt={rem(16)} mb={rem(8)}>
        How does your ideal destination look like?
      </QuestionHeading>

      <Flex flexWrap="wrap" $gap={rem(8)} mb={rem(8)}>
        {destinationOptions.map((option) => (
          <Chip
            $disabled={disabled}
            key={option.name}
            onClick={
              !disabled
                ? () =>
                    onChange({
                      ...value,
                      idealDestinationFixProperties:
                        includesDestination?.includes(option.name)
                          ? includesDestination.filter(
                              (val) => val !== option.name
                            )
                          : [...includesDestination, option.name],
                    })
                : undefined
            }
            $isSelected={includesDestination?.includes(option.name)}
          >
            <DestinationIconImage src={option.icon} />

            {option.name}
          </Chip>
        ))}
      </Flex>

      <Input
        label="Other included items"
        isBlock
        placeholder="Other..."
        value={value?.idealDestinationOtherProperties}
        onChange={({ target: { value: inputValue } }) =>
          onChange({ ...value, idealDestinationOtherProperties: inputValue })
        }
        disabled={disabled}
        {...passingProps}
      />
    </Box>
  )
}
DestinationsHelp.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  destinationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  transportOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
}

export const ConciergeItemValues = ({
  itemKey,
  type,
  placeholder,
  options,
  value,
  onChange,
  disabled,
  additionalContent,
  additionalContentProps,
  ...passingProps
}) => {
  const matches = useMediaQuery()

  switch (type) {
    case 'number':
      return (
        <Box maxWidth={passingProps.maxWidth}>
          <Input.Number
            data-cy={`inp-${itemKey}`}
            min={0}
            value={value || ''}
            onChange={onChange}
            placeholder={placeholder}
            {...passingProps}
          />
        </Box>
      )
    case 'input':
      return (
        <>
          <Input
            isBlock
            placeholder={placeholder}
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
            disabled={disabled}
            {...passingProps}
          />

          {additionalContent?.(value, onChange, additionalContentProps)}
        </>
      )
    case 'input_number':
      return (
        <Box maxWidth={passingProps.maxWidth}>
          <Input
            data-cy={`inp-${itemKey}`}
            isBlock={!matches.tv}
            type="number"
            placeholder={placeholder}
            value={value || ''}
            onChange={({ target: { value } }) => onChange(value)}
            disabled={disabled}
            {...passingProps}
          />
        </Box>
      )
    case 'textarea':
      return (
        <FlexWrap>
          <Input.Textarea
            isBlock
            placeholder={placeholder}
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
            disabled={disabled}
            {...passingProps}
          />
        </FlexWrap>
      )
    case 'datepicker':
      return (
        <Datepicker
          popperProps={{ strategy: 'fixed' }}
          onChange={(dates) => {
            onChange({ startDate: dates[0], endDate: dates[1] })
          }}
          selectsRange
          selectedStart
          monthsShown={matches.mobile ? 1 : 2}
          startDate={value?.startDate}
          endDate={value?.endDate}
          dateFormat="yyyy-MM-dd"
          placeholderText={placeholder}
          disabled={disabled}
          inputProps={{
            type: 'input',
            required: true,
            isBlock: true,
            ...passingProps,
          }}
        />
      )
    case 'monthpicker': {
      const now = new Date()
      const lastAvailableDate = addDays(addYears(now, 3), -1)

      return (
        <MonthFilter
          label={
            value?.startDate
              ? `${
                  value?.startDate ? format(value?.startDate, 'MMM yyyy') : ''
                } - ${value?.endDate ? format(value?.endDate, 'MMM yyyy') : ''}`
              : 'Months'
          }
          zIndex={10}
          startDate={value?.startDate}
          endDate={value?.endDate}
          targetComp={DropdownButton}
          setUrlFilters={(filters) =>
            onChange({
              startDate: filters.startMonth
                ? new Date(filters.startMonth)
                : now,
              endDate: filters.endMonth
                ? new Date(filters.endMonth)
                : lastAvailableDate,
            })
          }
          availableDateFrom={now}
          availableDateTo={lastAvailableDate}
          clearLabel="Anytime"
        />
      )
    }
    case 'destination_select':
      return (
        <DestinationsSelect
          key={`destination_select${value}`}
          disabled={disabled}
          isBlock
          value={value}
          {...passingProps}
          onChange={({ value, label }) => onChange(value, label)}
          placeholder={placeholder}
        />
      )
    case 'destination_help':
      return (
        <DestinationsHelp
          key={`destination_help${value}`}
          disabled={disabled}
          isBlock
          value={value}
          {...passingProps}
          onChange={onChange}
          placeholder={placeholder}
        />
      )

    case 'select':
      return (
        <Select
          isBlock
          key={`select${value}`}
          options={options}
          {...passingProps}
          value={options.find((option) => option.value === value)}
          onChange={({ value }) => onChange(value)}
          placeholder={placeholder}
          disabled={disabled}
        />
      )
    case 'radio': {
      return (
        <Flex flexDirection="column" $gap={rem(8)}>
          {options.map((option, index) =>
            typeof option === 'string' ? (
              <Input.Checkbox
                key={option + index}
                checked={value === option}
                label={option}
                value={option}
                onChange={onChange}
                disabled={disabled}
              />
            ) : (
              <Box key={option.name + index} data-cy={`inp-radio-chb-${index}`}>
                <Input.Checkbox
                  disabled={disabled}
                  checked={value?.[option.key] === option[option.key]}
                  label={
                    <Box>
                      <Text as="p">{option.name}</Text>
                    </Box>
                  }
                  value={option.name}
                  onChange={() =>
                    onChange({
                      ...option,
                      [option.key]: value?.[option.key] !== option[option.key],
                    })
                  }
                />

                {option.type && value?.[option.key] === option[option.key] ? (
                  <Box mt={rem(8)} ml={rem(34)}>
                    <ConciergeItemValues
                      itemKey={option.key}
                      disabled={disabled}
                      {...option}
                      value={value?.value}
                      additionalContentProps={{ onChange }}
                      onChange={(newValue, label) =>
                        onChange({ ...value, value: newValue }, label)
                      }
                    />
                  </Box>
                ) : null}
              </Box>
            )
          )}
        </Flex>
      )
    }

    case 'specific_check':
      return (
        <Flex flexDirection="column" $gap={rem(8)}>
          {options.map((option, index) => (
            <Input.Checkbox
              key={option.name + index}
              disabled={disabled}
              checked={value?.[option.key] === option[option.key]}
              label={
                <Box>
                  <Text as="p">{option.name}</Text>
                </Box>
              }
              value={option.name}
              onChange={() => {
                onChange({
                  ...value,
                  ...option,
                  [option.key]: value?.[option.key] !== option[option.key],
                })
              }}
            />
          ))}
        </Flex>
      )

    case 'checks': {
      const nonbreakableValue = value || []

      return (
        <Flex flexWrap="wrap" $gap={rem(8)}>
          {options.map((option) =>
            typeof option === 'string' ? (
              <Box key={option} width="100%">
                <Input.Checkbox
                  key={option}
                  disabled={disabled}
                  checked={nonbreakableValue?.includes(option)}
                  label={option}
                  value={option}
                  onChange={() =>
                    onChange(
                      nonbreakableValue?.includes(option)
                        ? nonbreakableValue.filter((val) => val !== option)
                        : [...nonbreakableValue, option]
                    )
                  }
                />
              </Box>
            ) : (
              <Chip
                $disabled={disabled}
                key={option.name}
                onClick={
                  !disabled
                    ? () =>
                        onChange(
                          nonbreakableValue?.includes(option.name)
                            ? nonbreakableValue.filter(
                                (val) => val !== option.name
                              )
                            : [...nonbreakableValue, option.name]
                        )
                    : undefined
                }
                $isSelected={nonbreakableValue?.includes(option.name)}
              >
                {option.name}
              </Chip>
            )
          )}
        </Flex>
      )
    }

    case 'radio_chip':
      return (
        <Flex flexWrap="wrap" $gap={rem(8)}>
          {options.map((option) => (
            <Chip
              data-cy="chip"
              flexShrink="0"
              minWidth={{ mobile: '100%', tablet: 'auto' }}
              flex="1"
              justifyContent="center"
              $disabled={disabled}
              key={option.name}
              onClick={!disabled ? () => onChange(option.value) : undefined}
              $isSelected={
                JSON.stringify(value) === JSON.stringify(option.value)
              }
            >
              {option.name}
            </Chip>
          ))}
        </Flex>
      )

    case 'budget': {
      const includes = value?.budgetIncludes || []

      return (
        <Box>
          <Flex
            flexDirection={{ mobile: 'column', tablet: 'row' }}
            $gap={rem(16)}
            mb={rem(16)}
          >
            <PriceInput
              isBlock
              type="number"
              label="From"
              placeholder={placeholder}
              value={value?.minimumBudget || undefined}
              onChange={({ target: { value: inputValue } }) =>
                onChange({
                  ...value,
                  minimumBudget: parseFloat(inputValue || '0'),
                })
              }
              disabled={disabled}
            />

            <PriceInput
              isBlock
              type="number"
              label="To"
              placeholder={placeholder}
              value={value?.maximumBudget || undefined}
              onChange={({ target: { value: inputValue } }) =>
                onChange({
                  ...value,
                  maximumBudget: parseFloat(inputValue || '0'),
                })
              }
              disabled={disabled}
            />
          </Flex>

          <QuestionHeading mb={rem(8)}>{passingProps.title}</QuestionHeading>

          <Flex flexWrap="wrap" $gap={rem(8)} mb={rem(16)}>
            {options.map((option) => (
              <Chip
                $disabled={disabled}
                key={option.name}
                onClick={
                  !disabled
                    ? () =>
                        onChange({
                          ...value,
                          budgetIncludes: includes?.includes(option.name)
                            ? includes.filter((val) => val !== option.name)
                            : [...includes, option.name],
                        })
                    : undefined
                }
                $isSelected={includes?.includes(option.name)}
              >
                {option.name}
              </Chip>
            ))}
          </Flex>

          <Input
            label="Other included items"
            isBlock
            placeholder="Other..."
            value={value?.budgetAdditionalContent}
            onChange={({ target: { value: inputValue } }) =>
              onChange({ ...value, budgetAdditionalContent: inputValue })
            }
            disabled={disabled}
            {...passingProps}
          />
        </Box>
      )
    }
    default:
      return null
  }
}

ConciergeItemValues.propTypes = {
  itemKey: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  additionalContent: PropTypes.func,
  additionalContentProps: PropTypes.object,
}

export const ConciergeItem = ({ item, disabled }) => {
  const { setFieldValue, values } = useFormikContext()

  return (
    <ConciergeItemWrapper key={item.key}>
      <Flex
        mb={{ mobile: rem(16), tablet: rem(8) }}
        $gap={rem(24)}
        alignItems="center"
      >
        <ConciergeItemIcon>{item.icon}</ConciergeItemIcon>

        <ConciergeItemQuestion>
          <QuestionHeading>{item.question}</QuestionHeading>
        </ConciergeItemQuestion>
      </Flex>

      <Flex flex="1" flexDirection="column" ml={{ tablet: rem(72) }}>
        {item.subtitle && (
          <Text mb={rem(8)} color={theme.COLORS.TXT_DEFAULT} as="p">
            {item.subtitle}
          </Text>
        )}

        {item.inputs ? (
          <Flex $gap={rem(8)} flexDirection="column">
            {item.inputs.map((input) => (
              <ConciergeItemValues
                itemKey={input.key}
                disabled={disabled}
                onChange={(val) => setFieldValue(input.key, val)}
                value={get(values, input.key)}
                {...input}
              />
            ))}
          </Flex>
        ) : (
          <ConciergeItemValues
            itemKey={item.key}
            disabled={disabled}
            onChange={(val) => setFieldValue(item.key, val)}
            value={get(values, item.key)}
            {...item}
          />
        )}
      </Flex>
    </ConciergeItemWrapper>
  )
}

ConciergeItem.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.any),
    value: PropTypes.string,
    onChange: PropTypes.func,
    subtitle: PropTypes.string,
    inputs: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
  disabled: PropTypes.bool,
}
