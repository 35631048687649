import { ACCESS_LEVEL_NUMBER, ACCESS_LEVELS } from 'constants/constants'
import { useTrip } from 'routes/Trip/TripProvider'

/**
 * Hook to use only inside trip for permissions purposes
 * @param name name of tab category
 * @param level access level
 * @returns boolean - whether user has access of certain level to certain tab
 */
export const usePermissions = (name, level = ACCESS_LEVELS.EDITOR) => {
  const { isTripOwner, accessLevel } = useTrip()

  if (!accessLevel) return false

  if (isTripOwner || ACCESS_LEVEL_NUMBER[level] <= accessLevel[name])
    return true
  return false
}
