import React, { useEffect } from 'react'
import { Input } from '@nextretreat/ui-components/dist/Input'
import { Field, useFormikContext } from 'formik'
import { rem } from 'polished'
import Geocode from 'react-geocode'
import { useDebouncedCallback } from 'use-debounce'
import { Box } from 'components/atoms/Layout'
import { getTypeFromGeocodeAPI } from 'utils/helpers'

export const AddressInput = () => {
  const { values, setFieldValue } = useFormikContext()

  const debouncedAddressParse = useDebouncedCallback(() => {
    if (values.hotelAddress) {
      Geocode.fromAddress(values.hotelAddress).then(
        (response) => {
          const country = getTypeFromGeocodeAPI(
            response.results[0].address_components,
            ['country']
          )
          const locality = getTypeFromGeocodeAPI(
            response.results[0].address_components,
            [
              'locality',
              'administrative_area_level_3',
              'administrative_area_level_1',
            ]
          )

          setFieldValue('city', locality?.long_name || '')
          if (country) {
            setFieldValue('country', country.long_name)
            setFieldValue('countryCode', country.short_name)
          }
          const { lat, lng } = response.results[0].geometry.location
          setFieldValue('lat', lat)
          setFieldValue('lon', lng)
        },
        () => {
          setFieldValue('lat', undefined)
          setFieldValue('lon', undefined)
        }
      )
    }
  }, 500)

  useEffect(() => {
    if (!values.hotelAddress && values.lat && values.lon) {
      setFieldValue('lat', undefined)
      setFieldValue('lon', undefined)
    }

    debouncedAddressParse()

    return () => debouncedAddressParse.cancel()
  }, [values.hotelAddress])

  return (
    <Box flex="1" mb={rem(16)}>
      <Field name="hotelAddress">
        {({ field, meta }) => (
          <Input
            required
            isBlock
            label="Address"
            placeholder="Venue address..."
            invalid={meta.touched && meta.error !== undefined}
            {...field}
          />
        )}
      </Field>
    </Box>
  )
}
