import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Flex } from 'components/atoms/Layout'
import { Callout } from 'components/Callout'
import { TOPBAR_HEIGHT } from 'constants/constants'
import { PAGE_TITLES } from 'constants/pageTitles'
import AppHelmet from 'sharedComponents/AppHelmet'
import { SuccessPaymentMessage } from './SuccessPaymentMessage'

export const MESSAGE_TYPE = {
  INVOICE: 'invoice',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export const PaymentMessageContext = createContext({
  showMessage: () => {},
})

export const usePaymentMessage = () => useContext(PaymentMessageContext)

const PaymentMessageProvider = ({ children }) => {
  const { hash } = useLocation()
  const [paymentMessage, setPaymentMessage] = useState(null)

  const showMessage = useCallback((type) => {
    setPaymentMessage(type)
  }, [])

  useEffect(() => {
    if (hash.includes('account') && paymentMessage) setPaymentMessage(null)
  }, [hash])

  const contextValue = useMemo(
    () => ({
      showMessage,
    }),
    [showMessage]
  )

  return (
    <>
      <AppHelmet
        titleTemplate="NextRetreat | %s"
        title={
          paymentMessage === MESSAGE_TYPE.FAILURE
            ? PAGE_TITLES.PAYMENT_FAILURE
            : PAGE_TITLES.PAYMENT_SUCCESS
        }
      />
      <PaymentMessageContext.Provider value={contextValue}>
        {paymentMessage === MESSAGE_TYPE.INVOICE ? (
          <SuccessPaymentMessage isInvoice />
        ) : paymentMessage === MESSAGE_TYPE.SUCCESS ? (
          <SuccessPaymentMessage />
        ) : paymentMessage === MESSAGE_TYPE.FAILURE ? (
          <Flex
            height={`calc(var(--nr-100vh, 100vh) - ${TOPBAR_HEIGHT})`}
            alignItems="center"
            justifyContent="center"
          >
            <Callout
              title="Oops, something went wrong"
              text="We are sorry but your payment has failed and we were unable to process your request."
              buttonText="Go back and try again"
              onClick={() => setPaymentMessage(null)}
              type={MESSAGE_TYPE.FAILURE}
              timer={0}
            />
          </Flex>
        ) : (
          children
        )}
      </PaymentMessageContext.Provider>
    </>
  )
}

PaymentMessageProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PaymentMessageProvider
