import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import PropTypes from 'prop-types'
import { getInitials } from 'utils/helpers'
import { BoringAvatar } from './BoringAvatar'

const AvatarWrap = styled('div', {
  shouldForwardProp: (prop) => !prop.includes('$'),
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || theme.COLORS.BG_SOFT};
  color: ${theme.COLORS.TXT_DEFAULT};

  font-size: ${theme.fontSizes.s};
  line-height: ${theme.lineHeights.s};
  font-weight: 510;

  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: ${({ $size }) => $size || 48}px;
  height: ${({ $size }) => $size || 48}px;
`

const StyledAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Avatar = ({ url, size = 48, backgroundColor, children, ...props }) => {
  const [showUrl, setShowUrl] = useState(!!url)

  useEffect(() => {
    if (showUrl !== !!url) setShowUrl(!!url)
  }, [url])

  return (
    <AvatarWrap $backgroundColor={backgroundColor} $size={size} {...props}>
      {showUrl ? (
        <StyledAvatarImage
          src={url}
          alt="User avatar"
          onError={() => setShowUrl(false)}
        />
      ) : (
        children
      )}
    </AvatarWrap>
  )
}

Avatar.propTypes = {
  url: PropTypes.string,
  backgroundColor: PropTypes.string,
  size: PropTypes.number,
  children: PropTypes.node,
}

export const EmailAvatar = ({ email, size, ...props }) => (
  <Avatar size={size} {...props}>
    <BoringAvatar email={email} size={size} />
  </Avatar>
)

EmailAvatar.propTypes = {
  email: PropTypes.string,
  size: PropTypes.number,
}

export const NameAvatar = ({ name = '', ...props }) => (
  <Avatar {...props}>{getInitials(name)}</Avatar>
)

NameAvatar.propTypes = {
  name: PropTypes.string,
}
