import { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonGroup } from 'sharedComponents/FlightGrid/HeaderUI'
import { StyledTab, VerticalTabs } from './VerticalTabs'
import { Flex } from '../atoms/Layout'

export const VERTICAL_TABS_BOX_WIDTH = rem(220)

const StyledHeaderContainer = styled(Flex)`
  padding: 0 ${rem(20)} ${rem(24)};
  overflow-x: auto;
`

const StyledButtonGroup = styled(ButtonGroup)`
  flex: 1;
  gap: ${rem(12)};
`

export const Tab = ({ element, activeTab, name }) =>
  name === activeTab ? element : null

Tab.propTypes = {
  element: PropTypes.node,
  activeTab: PropTypes.string,
  name: PropTypes.string,
}

export const Tabs = ({
  tabs,
  className,
  defaultTab,
  children,
  headerChildren,
  hideNavigation,
  vertical = false,
  onClick,
  activeTab,
  title,
}) => {
  const navigate = useNavigate()
  const { hash, search } = useLocation()
  const _activeTab = activeTab || hash.slice(1)

  const [openAccordion, setOpenAccordion] = useState(null)

  const categorisedTabs = useMemo(
    () =>
      tabs.reduce(
        (acc, tab) => {
          if (tab.category) {
            if (!acc[tab.category]) {
              acc[tab.category] = []
            }
            acc[tab.category].push(tab)
          } else {
            acc[''].push(tab)
          }
          return acc
        },
        { '': [] }
      ),
    [tabs]
  )

  useEffect(() => {
    if (!onClick) {
      if (!tabs.some(({ value }) => value === _activeTab))
        navigate(`${search}#${defaultTab}`, { replace: true })
      else {
        Object.keys(categorisedTabs).forEach((key) => {
          if (
            key &&
            categorisedTabs[key].some(({ value }) => value === _activeTab)
          ) {
            setOpenAccordion(key)
          }
        })
      }
    }
  }, [hash])

  const setActiveTab = (tab) => {
    if (onClick) onClick(tab)
    else navigate(`${search}#${tab}`)
  }

  const activeTabObject = tabs.find(({ value }) => value === _activeTab)

  return hideNavigation ? (
    children
  ) : !vertical ? (
    <>
      <StyledHeaderContainer
        justifyContent="space-between"
        alignItems="baseline"
        mt="m"
      >
        <StyledButtonGroup flex={1}>
          {tabs.map(({ label, icon, value }) => (
            <StyledTab
              key={value}
              type="button"
              data-cy={`tab-btn-${value}`}
              active={_activeTab === value}
              onClick={() => setActiveTab(value)}
            >
              <div style={{ width: '20px' }}>{icon}</div>
              {label}
            </StyledTab>
          ))}
        </StyledButtonGroup>
        {headerChildren}
      </StyledHeaderContainer>

      {children}
    </>
  ) : (
    <VerticalTabs
      tabs={tabs}
      className={className}
      openAccordion={openAccordion}
      setOpenAccordion={setOpenAccordion}
      categorisedTabs={categorisedTabs}
      activeTabObject={activeTabObject}
      activeTab={_activeTab}
      setActiveTab={setActiveTab}
      title={title}
    >
      {children}
    </VerticalTabs>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    })
  ),
  activeTab: PropTypes.string,
  defaultTab: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  headerChildren: PropTypes.node,
  vertical: PropTypes.bool,
  hideNavigation: PropTypes.bool,
  onClick: PropTypes.func,
}
