import { useContext } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import theme from '@nextretreat/ui-components/dist/Theme'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'
import { DEMO_TRIP_ID } from 'constants/constants'
import { PRIVATE_ROUTES } from 'constants/routes'
import { StepContext } from './TripOnboarding'

export const ONBOARDING_STEP_NAMES = {
  BASIC_INFO: 'basicInfo',
  VENUES: 'venues',
  COLLABORATION: 'collaboration',
  ATENDEES: 'atendees',
  SUCCESS: 'success',
}

export const STEP_MAP = [
  {
    name: ONBOARDING_STEP_NAMES.BASIC_INFO,
    label: 'Basic information',
  },
  {
    name: ONBOARDING_STEP_NAMES.VENUES,
    label: 'Venues',
  },
  {
    name: ONBOARDING_STEP_NAMES.COLLABORATION,
    label: 'Collaboration',
  },
  {
    name: ONBOARDING_STEP_NAMES.ATENDEES,
    label: 'Atendees',
  },
]

export const STEPS_ARRAY = Object.values(ONBOARDING_STEP_NAMES)

export const findNextStepName = (stepName) => {
  const nextStepIndex = STEPS_ARRAY.findIndex((step) => step === stepName) + 1

  return STEPS_ARRAY[nextStepIndex]
}

const stepCss = css`
  display: flex;
  align-items: center;
  gap: ${rem(16)};
  border-radius: 8px;
  margin-bottom: ${rem(8)};
  padding: ${rem(12)} ${rem(16)};
`

const StepBox = styled(Box)`
  ${stepCss}
  background-color: ${theme.COLORS.WHITE};
`

const circleCss = css`
  width: ${rem(40)};
  height: ${rem(40)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

const StepNumber = styled.div`
  ${circleCss}

  border: 1px solid
    ${({ $isActive }) =>
    $isActive ? theme.COLORS.BRAND_DEFAULT : theme.COLORS.BG_DIVIDER};
  color: ${({ $isActive }) =>
    $isActive ? theme.COLORS.BRAND_DEFAULT : theme.COLORS.TXT_DEFAULT};
`

const CheckBox = styled.div`
  ${circleCss}
  background-color: ${theme.COLORS.BRAND_DEFAULT};
  color: ${theme.COLORS.WHITE};
`

const LinkStep = styled(Link)`
  ${stepCss}
  text-decoration: none;
  color: ${theme.COLORS.TXT_MAIN};
  border: 1px solid ${theme.COLORS.BG_DIVIDER};
`

const Line = styled(Box)`
  width: 1px;
  background-color: ${theme.COLORS.BG_DIVIDER};
  margin: 0 ${rem(8)};
  display: inline-block;
  vertical-align: middle;
`
Line.defaultProps = {
  height: rem(16),
}

export const OnboardingStepper = ({ tripId, activeStep }) => {
  const { closeModal } = useContext(StepContext)

  return (
    <Box>
      <Text
        as="p"
        mb={rem(4)}
        fontWeight="700"
        fontSize={theme.fontSizes.xl}
        lineHeight={theme.lineHeights.xl}
      >
        Walkthrough
      </Text>
      <Text
        as="p"
        color={theme.COLORS.TXT_DEFAULT}
        fontSize={theme.fontSizes.s}
        lineHeight={theme.lineHeights.s}
        mb={rem(24)}
      >
        4 steps <Line /> About 8 minutes
      </Text>
      {STEP_MAP.map(({ name, label }, index) => (
        <StepBox key={name}>
          {STEP_MAP.findIndex((item) => item.name === activeStep) > index ||
          activeStep === ONBOARDING_STEP_NAMES.SUCCESS ? (
            <CheckBox>
              <Icon name="check" />
            </CheckBox>
          ) : (
            <StepNumber $isActive={name === activeStep}>{index + 1}</StepNumber>
          )}
          <Text fontWeight="700">{label}</Text>
        </StepBox>
      ))}

      {tripId && (
        <>
          <Flex
            mb={rem(8)}
            justifyContent="center"
            ml={rem(16)}
            width={rem(40)}
          >
            <Line height={rem(40)} />
          </Flex>

          <LinkStep
            to={`/${PRIVATE_ROUTES.TRIP}/${tripId}`}
            onClick={() => closeModal()}
          >
            <StepNumber>
              <Icon name="devices" />
            </StepNumber>
            <Text fontWeight="700">Go to Trip dashboard</Text>
          </LinkStep>
        </>
      )}

      <Box mt={rem(24)}>
        <Button.Secondary
          viewType={EButtonType.PLAIN}
          element="a"
          href={`${process.env.REACT_APP_APP_URL}/${PRIVATE_ROUTES.TRIP}/${DEMO_TRIP_ID}`}
          iconRight={<Icon name="chevron_right" />}
          target="_blank"
        >
          Show demo trip
        </Button.Secondary>
      </Box>
    </Box>
  )
}
OnboardingStepper.propTypes = {
  tripId: PropTypes.number,
  activeStep: PropTypes.oneOf(Object.values(ONBOARDING_STEP_NAMES)),
}
