import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Datepicker } from '@nextretreat/ui-components/dist/Datepicker'
import { Select } from '@nextretreat/ui-components/dist/Select'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useFormikContext } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { TripQueries } from 'api/Trip/TripQueries'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Ellipsis, Text } from 'components/atoms/Typography'
import { Icon } from 'components/Icon'
import { ACCESS_LEVELS, TAB_VALUES } from 'constants/constants'
import { useTrip } from 'routes/Trip/TripProvider'
import { formatUTC } from 'utils/date'

const HR = styled.hr`
  border: none;
  border-top: 1px solid ${theme.COLORS.BG_DIVIDER};
  width: 100%;
  margin: 0;
`

const Wrap = styled(Box)`
  border-top: 1px solid ${theme.COLORS.BG_DIVIDER};
  margin-top: ${rem(16)};
  padding-top: ${rem(16)};
`

const TaskWrap = styled(Flex)`
  gap: ${rem(16)};
  background-color: ${theme.COLORS.BG_SOFT};
  padding: ${rem(16)};
  border-radius: 8px;
`

const TaskDetailsFlex = styled(Flex)`
  gap: ${rem(16)};
  align-items: center;
  flex-wrap: wrap;
  min-width: 0;

  & > * {
    flex: 1;
    min-width: 200px;
  }
`

export const updateChangeTodoCacheFunction = (
  cache,
  itineraryEntryId,
  tripId,
  newTodo
) => {
  if (itineraryEntryId) {
    const query = {
      query: TripQueries.GET_TRIP_DETAIL,
      variables: { tripId },
    }

    const data = cache.readQuery(query)

    if (data)
      cache.writeQuery({
        ...query,
        data: {
          ...data,
          trip: {
            ...data.trip,
            itineraryEntries: data.trip.itineraryEntries.map((entry) =>
              entry.id === itineraryEntryId
                ? {
                    ...entry,
                    assignee: newTodo?.assigneeData?.email || null,
                    deadline: newTodo?.deadline || null,
                    isDone: newTodo?.isDone ?? null,
                  }
                : entry
            ),
          },
        },
      })
  }
}

export const updateSaveTodoCacheFunction =
  (tripId, tripItineraryEntryId) =>
  (cache, { data: newData }) => {
    if (newData.saveTodo) {
      const query = {
        query: TripQueries.GET_TRIP_TODOS,
        variables: { tripId },
      }

      const data = cache.readQuery(query)

      if (data)
        cache.writeQuery({
          ...query,
          data: {
            ...data,
            trip: {
              ...data.trip,
              todos: [newData.saveTodo, ...data.trip.todos],
            },
          },
        })

      updateChangeTodoCacheFunction(
        cache,
        tripItineraryEntryId,
        tripId,
        newData.saveTodo
      )
    }
  }

export const AddTodoWithEntry = ({ isDone, assignee, deadline }) => {
  const { setFieldValue, values, errors, touched } = useFormikContext()
  const { trip } = useTrip()

  const { data, loading: assigneesLoading } = useQuery(
    TripQueries.GET_SHARED_USERS_FOR_TODOS,
    {
      variables: { tripId: trip.id },
    }
  )

  const hasTodo = typeof isDone === 'boolean'

  return hasTodo ? (
    <Wrap>
      <Text as="p" mb={rem(8)} fontWeight="700" fontSize={theme.fontSizes.l}>
        Task settings
      </Text>

      <TaskWrap flexDirection="column">
        <TaskDetailsFlex>
          {assignee && (
            <Ellipsis color={theme.COLORS.TXT_DEFAULT} as="p">
              <Text fontWeight="700" mr={rem(16)}>
                Assignee:
              </Text>{' '}
              {assignee}
            </Ellipsis>
          )}
          {deadline && (
            <Ellipsis color={theme.COLORS.TXT_DEFAULT} as="p">
              <Text fontWeight="700" mr={rem(16)}>
                Deadline:
              </Text>{' '}
              {formatUTC(new Date(+deadline), 'MMM d, yyyy')}
            </Ellipsis>
          )}
        </TaskDetailsFlex>

        <HR />

        <Text
          as="p"
          fontSize={theme.fontSizes.s}
          color={theme.COLORS.TXT_DEFAULT}
        >
          To edit the assignee, deadline or remove the task from the entry,
          please go to the{' '}
          <Text
            as={Link}
            color={theme.COLORS.BRAND_DEFAULT}
            to={`#${TAB_VALUES.TODO_LIST}`}
          >
            Todos tab
          </Text>
          .
        </Text>
      </TaskWrap>
    </Wrap>
  ) : !values.todo ? (
    <Wrap mt={rem(12)}>
      <Button.Tertiary
        type="button"
        iconLeft={<Icon name="add" />}
        onClick={() => setFieldValue('todo', {})}
      >
        Add task
      </Button.Tertiary>
    </Wrap>
  ) : (
    <Wrap>
      <Text as="p" mb={rem(8)} fontWeight="700" fontSize={theme.fontSizes.l}>
        Task settings
      </Text>
      <TaskWrap py={`${rem(12)} !important`} alignItems="center" $gap={rem(16)}>
        <Box flex="1">
          <Select
            isBlock
            isLoading={assigneesLoading}
            label="Assignee"
            menuPlacement="top"
            options={data?.tripSharedUsers
              ?.filter(
                ({ level, todos }) =>
                  todos !== 0 || level === ACCESS_LEVELS.OWNER
              )
              .map(({ userEmail }) => ({
                value: userEmail,
                label: userEmail,
              }))}
            noOptionsMessage={() => 'No users with Todos access to this trip'}
            onChange={(value) => setFieldValue('todo.assignee', value)}
            value={values.todo?.assignee}
          />
        </Box>

        <Box flex="1">
          <Datepicker
            minDate={new Date()}
            popperProps={{ strategy: 'fixed' }}
            selected={values.todo?.deadline}
            dateFormat="yyyy-MM-dd"
            onChange={(date) => {
              setFieldValue('todo.deadline', date)
            }}
            inputProps={{
              type: 'input',
              label: 'Deadline',
              placeholder: 'set deadline',
              isBlock: true,
              invalid: !!(errors.todo?.deadline && !touched.todo?.deadline),
            }}
          />
        </Box>

        <Box>
          <Box height={theme.lineHeights.m} />

          <Flex>
            <Icon
              name="highlight_off"
              onClick={() => {
                setFieldValue('todo', null)
              }}
            />
          </Flex>
        </Box>
      </TaskWrap>
    </Wrap>
  )
}

AddTodoWithEntry.propTypes = {
  assignee: PropTypes.string,
  deadline: PropTypes.string,
  isDone: PropTypes.bool,
}
