import { useContext } from 'react'
import styled from '@emotion/styled'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import theme from '@nextretreat/ui-components/dist/Theme'
import { useFormikContext } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Icon } from 'components/Icon'
import { ONBOARDING_STEP_NAMES, STEPS_ARRAY } from './OnboardingStepper'
import { StepContext } from './TripOnboarding'

const NavigationBoxWrap = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: ${rem(12)} ${rem(24)};
  border-top: 1px solid ${theme.COLORS.BG_DIVIDER};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${theme.COLORS.WHITE};
`

export const NavigationBox = ({ isLoading }) => {
  const { isValid, isSubmitting } = useFormikContext()
  const { activeStep, setActiveStep, closeModal } = useContext(StepContext)

  const nextStepIndex = STEPS_ARRAY.findIndex((step) => step === activeStep) + 1

  return (
    <NavigationBoxWrap data-cy="nav-panel">
      <Box>
        <Button.Secondary
          disabled={isLoading}
          viewType={EButtonType.PLAIN}
          iconRight={<Icon name="chevron_right" />}
          type="button"
          onClick={closeModal}
        >
          {activeStep === ONBOARDING_STEP_NAMES.BASIC_INFO
            ? 'Close onboarding'
            : 'Skip onboarding'}
        </Button.Secondary>
      </Box>

      <Flex $gap={rem(16)}>
        {activeStep !== ONBOARDING_STEP_NAMES.BASIC_INFO && (
          <Button.Secondary
            disabled={isLoading}
            onClick={() => setActiveStep(STEPS_ARRAY[nextStepIndex])}
            type="button"
          >
            Skip this step
          </Button.Secondary>
        )}
        <Button.Primary
          disabled={!isValid}
          data-cy="btn-continue"
          isLoading={isLoading || isSubmitting}
          type="submit"
        >
          Continue
        </Button.Primary>
      </Flex>
    </NavigationBoxWrap>
  )
}

NavigationBox.propTypes = {
  isLoading: PropTypes.bool,
}
