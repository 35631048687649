import React from 'react'
import styled from '@emotion/styled'
import theme from '@nextretreat/ui-components/dist/Theme'
import PropTypes from 'prop-types'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { FallbackImg } from 'sharedComponents/FallbackImg'

const StyledImage = styled(FallbackImg)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`

const StyledFlex = styled(Flex)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${theme.COLORS.BRAND_LIGHT};
`

const ActionIconWrapper = styled(Flex)`
  margin-left: auto;
  ${({ onClick, isDisabled }) =>
    onClick && `cursor: ${isDisabled ? 'not-allowed' : 'pointer'};`}

  visibility: hidden;
`

const TripItemWrapper = styled(Flex)`
  border-radius: 8px;
  ${({ onClick, isDisabled }) =>
    onClick && `cursor: ${isDisabled ? 'not-allowed' : 'pointer'};`}
  @media (hover: hover) {
    &:hover {
      ${({ isDisabled }) =>
        !isDisabled && `background-color: ${theme.COLORS.BG_SOFT};`}
    }
    &:hover ${ActionIconWrapper} {
      visibility: visible;
    }
  }
`

const TripItemTextWrapper = styled(Flex)`
  flex-direction: column;
`

const TripItem = ({
  onClick,
  primaryText,
  secondaryText,
  imageSrc,
  icon,
  actionIconSrc,
  onActionIconClick,
  hasPadding,
  isDisabled,
  dataCy,
  ...passingProps
}) => (
  <TripItemWrapper
    p={hasPadding && { desktop: '6px 12px', mobile: '10px 16px' }}
    pr={hasPadding ? { desktop: '12px', mobile: '16px' } : 's'}
    alignItems="center"
    onClick={isDisabled ? undefined : onClick}
    isDisabled={isDisabled}
    data-cy={dataCy}
    {...passingProps}
  >
    <StyledFlex alignItems="center" justifyContent="center">
      {icon || (imageSrc ? <StyledImage src={imageSrc} alt="" /> : null)}
    </StyledFlex>
    <TripItemTextWrapper flexDirection="column" mr="m" ml="12px">
      <Text
        letterSpacing={0}
        fontSize={{ desktop: 'm', mobile: '16px' }}
        fontWeight="700"
        color={isDisabled ? theme.COLORS.TXT_DISABLED : theme.COLORS.TXT_MAIN}
      >
        {primaryText}
      </Text>
      {secondaryText && (
        <Text
          fontSize={{ desktop: 's', mobile: '12px' }}
          letterSpacing={0}
          color={theme.COLORS.TXT_DEFAULT}
          display="block"
        >
          {secondaryText}
        </Text>
      )}
    </TripItemTextWrapper>
    {actionIconSrc && (
      <ActionIconWrapper
        ml="auto"
        onClick={onActionIconClick}
        isDisabled={isDisabled}
      >
        <img src={actionIconSrc} alt="" />
      </ActionIconWrapper>
    )}
  </TripItemWrapper>
)

TripItem.defaultProps = {
  hasPadding: true,
  isDisabled: false,
}

TripItem.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.node,
  primaryText: PropTypes.node.isRequired,
  secondaryText: PropTypes.node,
  imageSrc: PropTypes.node,
  actionIconSrc: PropTypes.node,
  onActionIconClick: PropTypes.func,
  hasPadding: PropTypes.bool,
  isDisabled: PropTypes.bool,
  dataCy: PropTypes.string,
}

export default TripItem
