import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { EButtonType } from '@nextretreat/ui-components/dist/Button'
import { ButtonSwitcher } from '@nextretreat/ui-components/dist/ButtonSwitcher'
import { Input } from '@nextretreat/ui-components/dist/Input'
import theme from '@nextretreat/ui-components/dist/Theme'
import { Field, Formik } from 'formik'
import { rem } from 'polished'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'QueryProvider'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { mq } from 'Theme'
import * as Yup from 'yup'
import { TripMutations } from 'api/Trip/TripMutations'
import { TripQueries } from 'api/Trip/TripQueries'
import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Device from 'components/Device'
import { Icon } from 'components/Icon'
import { useModal } from 'components/Modal'
import {
  ACCESS_LEVELS,
  PERMISSIONS_TYPES,
  TRIP_TAB_ACCESS_NAMES,
  TRIP_TAB_ACCESS_OPTIONS,
} from 'constants/constants'
import { PUBLIC_ROUTES } from 'constants/routes'
import { usePermissions } from 'hooks/trip/usePermissions'
import { useTripMutation } from 'hooks/trip/useTripMutation'
import { toast } from 'utils/helpers'
import { PermissionsSetter } from './PermissionsSetter/PermissionsSetter'
import { SharedUserItem } from './SharedUserItem'
import { EnsureReadAccess, EnsureWriteAccess } from '../AccessComponents'
import { useTrip } from '../TripProvider'

const WhiteBox = styled(Box)`
  background-color: ${theme.COLORS.WHITE};
  border-radius: 4px;
`

const Line = styled(Box)`
  border-bottom: 1px solid ${theme.COLORS.BG_DIVIDER};
`

export const TransparentBox = styled(Line)`
  margin-left: ${rem(24)};
  margin-right: ${rem(24)};
`

export const EmptyLine = styled(Box)`
  background-color: ${theme.COLORS.BG_SOFT};
  height: 12px;
  width: 100%;
`

const StyledForm = styled.form`
  width: 100%;
`

const StyledSetButton = styled(Button.Tertiary)`
  flex-shrink: 0;
  height: unset;

  ${mq.to.tablet`
    height: ${rem(42)};
    padding: ${rem(10)};
  `}
`

export const validationSchema = Yup.object({
  userEmail: Yup.string()
    .email('Invalid email')
    .label('This field is required')
    .required(),
  tabs: Yup.mixed().required(),
})

export const convertPermissionsToObject = (obj) => {
  const output = {}

  Object.keys(obj).forEach((key) => {
    obj[key].forEach(({ id }) => {
      output[id] = PERMISSIONS_TYPES.indexOf(key)
    })
  })

  return output
}

const TripShareModal = ({ tripId }) => {
  const { trip } = useTrip()
  const hasWritePermissions = usePermissions(TRIP_TAB_ACCESS_NAMES.SETTINGS)
  const hasReadPermissions = usePermissions(
    TRIP_TAB_ACCESS_NAMES.SETTINGS,
    ACCESS_LEVELS.VIEWER
  )
  const [viewType, setViewType] = useState(
    hasWritePermissions ? 'form' : 'shareLink'
  )

  const matches = useMediaQuery()

  const { isOpen, openModal, closeModal } = useModal()

  const tripShareToken = `${process.env.REACT_APP_APP_URL}/${PUBLIC_ROUTES.TRIP_SHARED}/${trip?.shareToken}`

  const { data } = useQuery(TripQueries.GET_SHARED_USERS, {
    variables: { tripId },
    skip: !hasReadPermissions,
  })

  const sharedUsers =
    data?.tripSharedUsers?.filter(
      ({ level }) => level !== ACCESS_LEVELS.OWNER
    ) || []

  const [shareTrip, { loading }] = useTripMutation(TripMutations.SHARE_TRIP, {
    update: (cache, { data: newData }) => {
      if (newData.shareTripWithUser) {
        const query = {
          query: TripQueries.GET_SHARED_USERS,
          variables: { tripId },
        }

        const data = cache.readQuery(query)

        if (
          data &&
          !data?.tripSharedUsers?.find(
            ({ id }) => id === newData.shareTripWithUser.id
          )
        )
          cache.writeQuery({
            ...query,
            data: {
              ...data,
              tripSharedUsers: [
                ...data.tripSharedUsers,
                newData.shareTripWithUser,
              ],
            },
          })
      }
    },
  })

  const [userAlreadyAdded, setUserAlreadyAdded] = useState(false)

  const onChangeType = async (id, email, categories = []) => {
    await shareTrip({
      variables: {
        input: {
          id,
          email,
          ...convertPermissionsToObject(categories),
        },
        tripId,
      },
    })
  }

  const onSubmit = async (values, { resetForm }) => {
    try {
      if (
        sharedUsers.some(
          (sharedUser) => sharedUser.userEmail === values.userEmail
        )
      ) {
        setUserAlreadyAdded(true)
        return
      }
      setUserAlreadyAdded(false)

      const response = await shareTrip({
        variables: {
          input: {
            email: values.userEmail,
            sendNotificationMail: true,
            ...convertPermissionsToObject(values.tabs),
          },
          tripId,
        },
      })

      if (response?.data?.shareTripWithUser) {
        resetForm()
        toast.success('Access added successfully')
      }
    } catch {
      // do nothing
    }
  }

  return (
    <Flex
      height="100%"
      flexDirection="column"
      justifyContent={{ mobile: 'flex-start', tablet: 'space-between' }}
    >
      <WhiteBox
        p={{
          mobile: '16px',
          tablet: hasReadPermissions || hasWritePermissions ? rem(24) : 0,
        }}
      >
        <Device sizes={['mobile']}>
          <EnsureWriteAccess name={TRIP_TAB_ACCESS_NAMES.SETTINGS}>
            <Box mb="20px">
              <ButtonSwitcher
                isBlock
                selectedValue={viewType}
                onSelect={(val) => setViewType(val)}
                options={[
                  { label: 'Invite via e-mail', value: 'form' },
                  { label: 'Share public link', value: 'shareLink' },
                ]}
              />
            </Box>
          </EnsureWriteAccess>
        </Device>

        {viewType === 'shareLink' && matches.mobile ? (
          <Box>
            <Box mb={rem(16)} maxWidth={rem(500)}>
              <Text
                as="p"
                fontSize="16px"
                lineHeight="24px"
                fontWeight="bold"
                color={theme.COLORS.TXT_MAIN}
                mb={rem(4)}
              >
                Share public link
              </Text>

              <Text as="p" fontSize="14px" lineHeight="24px">
                When sharing a trip through a public link, only Venues page will
                be visible, and no changes can be made by from the public link.
              </Text>
            </Box>

            <CopyToClipboard
              text={tripShareToken}
              onCopy={() => {
                toast.success('Copied to clipboard')
              }}
            >
              <Button.Primary
                viewType={EButtonType.PLAIN}
                iconLeft={<Icon name="content_copy" />}
              >
                Copy public link to share
              </Button.Primary>
            </CopyToClipboard>
          </Box>
        ) : (
          <EnsureWriteAccess name={TRIP_TAB_ACCESS_NAMES.SETTINGS}>
            <Flex
              backgroundColor={{ tablet: theme.COLORS.BG_SOFT }}
              style={{ borderRadius: '8px' }}
              padding={{ tablet: rem(24) }}
              flexDirection="column"
              justifyContent={{ mobile: 'flex-start', tablet: 'space-between' }}
            >
              <Text
                as="p"
                fontSize={{ mobile: '16px', tablet: theme.fontSizes.l }}
                lineHeight={{ mobile: '24px', tablet: theme.lineHeights.l }}
                fontWeight="bold"
                mb={rem(12)}
              >
                Invite via email
              </Text>
              <Formik
                initialValues={{
                  userEmail: '',
                  tabs: {
                    noAccess: [],
                    viewer: TRIP_TAB_ACCESS_OPTIONS.map(({ value, label }) => ({
                      id: value,
                      name: label,
                    })),
                    editor: [],
                  },
                }}
                validationSchema={validationSchema}
                validateOnMount
                onSubmit={onSubmit}
              >
                {({
                  values,
                  handleSubmit,
                  setFieldValue,
                  isValid,
                  isSubmitting,
                }) => (
                  <StyledForm onSubmit={handleSubmit}>
                    <Flex flexWrap="wrap" $gap="10px" alignItems="center">
                      <Flex
                        flex="1"
                        width={{ mobile: '100%', tablet: 'auto' }}
                        $gap="10px"
                        alignItems="center"
                      >
                        <Field name="userEmail">
                          {({ field, meta }) => (
                            <Input
                              isBlock
                              placeholder="E-mail..."
                              invalid={
                                meta.touched &&
                                (meta.error !== undefined || userAlreadyAdded)
                              }
                              required
                              {...field}
                            />
                          )}
                        </Field>

                        <StyledSetButton
                          iconLeft={<Icon name="tune" />}
                          type="button"
                          onClick={openModal}
                        >
                          <Device sizes={['tablet', 'desktop', 'tv']}>
                            Set permissions
                          </Device>
                        </StyledSetButton>
                      </Flex>

                      <Flex
                        width={{ mobile: '100%', tablet: 'auto' }}
                        flexShrink="0"
                      >
                        <Button.Primary
                          isBlock
                          disabled={!isValid || isSubmitting}
                          type="submit"
                          isLoading={isSubmitting}
                        >
                          Send invitation
                        </Button.Primary>
                      </Flex>
                    </Flex>

                    {userAlreadyAdded && (
                      <Text fontSize="xs" color={theme.COLORS.ERROR_DEFAULT}>
                        This user is already added to the Trip
                      </Text>
                    )}

                    {isOpen && (
                      <PermissionsSetter
                        isOpen={isOpen}
                        closeModal={closeModal}
                        initialValues={{ ...values.tabs }}
                        onSubmit={(val) => {
                          setFieldValue('tabs', val)
                        }}
                      />
                    )}
                  </StyledForm>
                )}
              </Formik>
            </Flex>
          </EnsureWriteAccess>
        )}
      </WhiteBox>

      <EnsureReadAccess hideMessage name={TRIP_TAB_ACCESS_NAMES.SETTINGS}>
        {!!sharedUsers.length && (
          <>
            <Device sizes={['tablet', 'desktop', 'tv']}>
              <TransparentBox />
            </Device>
            <Device size="mobile">
              <EmptyLine />
            </Device>

            <WhiteBox
              p={{ mobile: '16px', tablet: rem(24) }}
              py={{ mobile: 0, tablet: rem(24) }}
            >
              <Flex
                height="100%"
                flexDirection="column"
                justifyContent="space-between"
              >
                {sharedUsers.map((sharedUser) => (
                  <SharedUserItem
                    key={sharedUser.id}
                    sharedUser={sharedUser}
                    loading={loading}
                    onChangeType={onChangeType}
                  />
                ))}
              </Flex>
            </WhiteBox>
          </>
        )}

        <Device sizes={['tablet', 'desktop', 'tv']}>
          <Line />
        </Device>
      </EnsureReadAccess>

      <Device sizes={['tablet', 'desktop', 'tv']}>
        <WhiteBox p={rem(24)} pt={rem(16)}>
          <Flex alignItems="center" justifyContent="space-between">
            <Box maxWidth={rem(500)}>
              <Text
                as="p"
                fontSize={theme.fontSizes.l}
                lineHeight={theme.lineHeights.l}
                fontWeight="bold"
                color={theme.COLORS.TXT_MAIN}
                mb={rem(4)}
              >
                Share public link
              </Text>

              <Text
                as="p"
                fontSize={theme.fontSizes.s}
                lineHeight={theme.lineHeights.s}
              >
                When sharing a trip through a public link, only Venues page will
                be visible, and no changes can be made by from the public link.
              </Text>
            </Box>

            <CopyToClipboard
              text={tripShareToken}
              onCopy={() => {
                toast.success('Copied to clipboard')
              }}
            >
              <Button.Secondary iconLeft={<Icon name="content_copy" />}>
                Copy public link to share
              </Button.Secondary>
            </CopyToClipboard>
          </Flex>
        </WhiteBox>
      </Device>
    </Flex>
  )
}

TripShareModal.propTypes = {
  tripName: PropTypes.string.isRequired,
  tripId: PropTypes.number.isRequired,
}

export default TripShareModal
