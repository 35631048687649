import { gql } from '@apollo/client'

export const LayoutMutations = {
  NEWS_READ: gql`
    mutation NewsRead($id: Int!) {
      newsRead(id: $id)
    }
  `,

  SAVE_NPS_DATA: gql`
    mutation SaveNPSData($input: SaveNPSDataInput!) {
      saveNPSData(input: $input) {
        id
        npsScore
      }
    }
  `,
}
