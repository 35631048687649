import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { EventMutations } from 'api/Event/EventMutations'
import { NPSModal } from 'components/NPSModal'
import { getRandomInt } from 'utils/helpers'
import { local } from 'utils/storage'

export const EventContext = createContext({
  pushEvent: () => {},
})

const NUMBER_OF_MAX_EVENTS_IN_QUERY = 5
const TIME_TO_SEND_EVENTS_IN_SECONDS = 30

const EVENT_LOCAL_STORAGE_KEY = 'event_log_query'
const EVENT_LENGTH_LOCAL_STORAGE_KEY = 'event_log_length'
const NPS_MODAL_THRESHOLD = 30

export const useEvent = () => useContext(EventContext)

const EventProvider = ({ children }) => {
  const timer = useRef()
  const [showNPSModal, setShowNPSModal] = useState(false)

  const [event] = useMutation(EventMutations.EVENT)

  const logEvents = (randomId) => {
    const events =
      JSON.parse(local.getItem(EVENT_LOCAL_STORAGE_KEY) || '[]') || []

    if (events.length && events.find(({ id }) => id === randomId)) {
      event({
        variables: {
          input: events.map(({ id, ...event }) => event),
        },
      })

      local.removeItem(EVENT_LOCAL_STORAGE_KEY)
    }
  }

  const pushEvent = useCallback(
    (userInteraction, data) => {
      const oldEvents =
        JSON.parse(local.getItem(EVENT_LOCAL_STORAGE_KEY) || '[]') || []

      const randomId = getRandomInt(0, 1000)

      const eventsToLog = [
        ...oldEvents,
        {
          userInteraction,
          data: data ? JSON.stringify(data) : undefined,
          timestamp: new Date().toISOString().replace('T', ' '),
          id: randomId,
        },
      ]

      local.setItem(EVENT_LOCAL_STORAGE_KEY, JSON.stringify(eventsToLog))

      const eventLength = local.getItem(EVENT_LENGTH_LOCAL_STORAGE_KEY)

      if (+eventLength >= NPS_MODAL_THRESHOLD) setShowNPSModal(true)
      else
        local.setItem(
          EVENT_LENGTH_LOCAL_STORAGE_KEY,
          (Number.isNaN(+eventLength) ? 0 : +eventLength) + 1
        )

      if (timer.current) clearTimeout(timer.current)

      if (eventsToLog.length < NUMBER_OF_MAX_EVENTS_IN_QUERY) {
        timer.current = setTimeout(() => {
          logEvents(randomId)
        }, TIME_TO_SEND_EVENTS_IN_SECONDS * 1000)
      } else {
        logEvents(randomId)
      }
    },
    [local.getItem(EVENT_LOCAL_STORAGE_KEY)]
  )

  const contextValue = useMemo(
    () => ({
      pushEvent,
    }),
    [pushEvent]
  )

  return (
    <EventContext.Provider value={contextValue}>
      {showNPSModal ? <NPSModal /> : null}
      {children}
    </EventContext.Provider>
  )
}

EventProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default EventProvider
