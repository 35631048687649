import React, { useEffect } from 'react'
import { useAuth } from 'AuthProvider'

const NRDrift = () => {
  const insertScript = (scriptText) => {
    const script = document.createElement('script')
    script.innerText = scriptText
    script.async = true
    document.body.appendChild(script)
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && !window.drift) {
      const scriptText = `!function() {
        var t = window.driftt = window.drift = window.driftt || [];
        if (!t.init) {
          if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
          t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on", "setUserAttributes" ],
          t.factory = function(e) {
            return function() {
              var n = Array.prototype.slice.call(arguments);
              return n.unshift(e), t.push(n), t;
            };
          }, t.methods.forEach(function(e) {
            t[e] = t.factory(e);
          }), t.load = function(t) {
            var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
            o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
            var i = document.getElementsByTagName("script")[0];
            i.parentNode.insertBefore(o, i);
          };
        }
      }();
      drift.SNIPPET_VERSION = '0.3.1';
      drift.load('${process.env.REACT_APP_DRIFT_ID}');`

      insertScript(scriptText)
    }
  }, [])
}

const DriftContainer = () => {
  const { isSignedIn } = useAuth()

  return isSignedIn && process.env.REACT_APP_ENV === 'production' ? (
    <NRDrift />
  ) : null
}

export default DriftContainer
